/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import stringToBool from 'PlugAndPlay/_global/js/stringToBool';

/**
 * This class handle sliding elements.
 *
 * @class
 */
export class Slider {
    constructor(wrapper) {
        this.SLIDE_ACTIVE = 'slider-item--active';
        this.NAVIGATION_CLASSNAME_ACTIVE = 'carousel-navigation__item--active';
        this.SLIDER_TRANSITIONING = 'slider__inner--transitioning';
        this.SLIDER_TRANSITIONING_ANY = 'slider__inner--transitioning-any';
        this.SLIDER_TRANSITIONING_RIGHT = 'slider__inner--transition-right';
        this.SLIDER_TRANSITIONING_LEFT = 'slider__inner--transition-left';
        this.SLIDER_CSS_ANIMATION_DURATION_VAR = '--slider-animation-duration';
        this.SLIDER_CSS_SLIDE_DURATION_VAR = '--slider-slide-duration';
        this.wrapper = wrapper;

        this.moveSlideForwards = this.moveSlideForwards.bind(this);
        this.moveSlideBackwards = this.moveSlideBackwards.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);

        this.isMoving = false;
        this.eventsRelay = wrapper;
        this.xMove = null;

        if (wrapper) {
            this.setCSSAnimationDuration(wrapper);
            this.setCSSSlideDuration(wrapper);
            this.setTriggerPercent(wrapper);

            wrapper.addEventListener(
                'touchstart',
                this.handleTouchStart,
                false
            );
            wrapper.addEventListener('touchend', this.handleTouchEnd, false);
        }
    }

    set items(slides) {
        // In case we have only 2 slides, we will clone them making 4, so our animation pretend to wraps,
        // instead of creating white space.
        if (slides.length === 2) {
            const cloneOne = slides[0].cloneNode(true);
            const cloneTwo = slides[1].cloneNode(true);

            this.inner.append(cloneOne);
            this.inner.append(cloneTwo);

            this.isWrapping = true;
        }
        this.slides = this.inner.children;
        this.orderedSlides = Array.from(this.slides);

        // Change the last slide to be the first in the array to deal with setting the orders nicely
        this.orderedSlides.unshift(this.orderedSlides.at(-1));
        this.orderedSlides.pop();
        this.numOfSlides = slides.length - 1;
        // Set initial classes only once when slides are created.
        this.setInitialClasses();
    }

    get items() {
        return this.slides;
    }

    get numOfItems() {
        return this.numOfSlides;
    }

    set innerElement(inner) {
        this.inner = inner;
    }

    set navigationItems(controllers) {
        this.navigationControls = controllers;
    }

    get navigationItems() {
        return this.navigationControls;
    }

    set previousButton(previous) {
        this.previous = previous;
    }

    get previousButton() {
        return this.previous;
    }

    set nextButton(next) {
        this.next = next;
    }

    get nextButton() {
        return this.next;
    }

    set shouldLoop(shouldSliderLoop) {
        this.shouldSliderLoop = shouldSliderLoop;
    }

    get shouldLoop() {
        return this.shouldSliderLoop;
    }

    set animationDuration(sliderAnimationDuration) {
        this.sliderAnimationDuration = sliderAnimationDuration;
    }

    get animationDuration() {
        return this.sliderAnimationDuration;
    }

    set slideDuration(sliderAnimationDuration) {
        this.sliderAnimationDuration = sliderAnimationDuration;
    }

    get slideDuration() {
        return this.sliderAnimationDuration;
    }

    /**
     * Sets the initial classes for the slider items.
     *
     * @returns {void}
     */
    setInitialClasses() {
        const items = this.orderedSlides;
        if (items === undefined) {
            return;
        }

        // Reset the classes so that we are getting what we expect
        this.resetClasses();

        // Set the class on the current item
        items[1].classList.add(this.SLIDE_ACTIVE);
        // Set the aria hidden attibute on the initial slide
        items[1].setAttribute('aria-hidden', 'false');

        // Set the order on each of the slides
        items.forEach((item, key) => {
            // eslint-disable-next-line no-param-reassign
            item.dataset.slideOrder = key;
        });

        if (!this.liveRegionDiv) {
            // Create a live region to announce current slides
            this.liveRegionDiv = document.createElement('div');

            // Set the live region attributes
            this.liveRegionDiv.setAttribute('aria-live', 'polite');
            this.liveRegionDiv.setAttribute('aria-atomic', 'true');
            this.liveRegionDiv.setAttribute('class', 'visuallyhidden');
            this.liveRegionDiv.setAttribute(
                'data-component',
                'slider-live-region'
            );

            // Append the live region to the slider
            this.wrapper.appendChild(this.liveRegionDiv);
        }

        // Get the live region so that we can use it later
        this.liveRegion = this.wrapper.querySelector(
            '[data-component="slider-live-region"]'
        );

        // Set the initial live region text
        this.liveRegion.textContent = `Item 1 of ${this.slides.length}`;
    }

    /**
     * Gets the index of the current slide in the items array
     *
     * @returns {number} - the index of the current slide in the this items array
     */
    getIndexOfCurrentSlide() {
        return Array.from(this.items).findIndex(
            (slide) => slide.dataset?.slideOrder === '1'
        );
    }

    /**
     * Wrapper moves slides forwards.
     *
     * @returns {void}
     */
    moveSlideForwards() {
        this.moveSlide('forwards');
    }

    /**
     * Wrapper moves slides backwards.
     *
     * @returns {void}
     */
    moveSlideBackwards() {
        this.moveSlide('backwards');
    }

    /**
     * Disables interactions with the slider.
     *
     * @returns {void}
     */
    disableInteractions() {
        this.isMoving = true;

        // Set the moving status to be changed to false when the animation duration has stopped
        setTimeout(() => {
            this.isMoving = false;
        }, this.sliderAnimationDuration);
    }

    /**
     * Function that moves the slide.
     *
     * @param {string} direction - Defines slider directions.
     * @param {object} event - Click event.
     *
     * @returns {void}
     */
    moveSlide(direction = 'forwards', e) {
        this.toggleAriaLiveRegionState(e);

        // Check if the slider would be looping backwards
        const wouldLoopBackwards =
            this.orderedSlides.find(
                (slide) => parseInt(slide.dataset.slideOrder, 10) === 1
            ) === Array.from(this.slides).at(0) && direction === 'backwards';
        // Check if the slider would be looping forwards
        const wouldLoopForwards =
            this.orderedSlides.find(
                (slide) => parseInt(slide.dataset.slideOrder, 10) === 1
            ) === Array.from(this.slides).at(-1) && direction === 'forwards';
        // If we are in the process of animating or this move would loop the slider
        if (
            this.isMoving ||
            ((wouldLoopBackwards || wouldLoopForwards) && !this.shouldLoop)
        ) {
            return;
        }

        // Disable interactions while it is moving
        this.disableInteractions();

        this.resetClasses();

        let slideIndex = 1;

        if (this.inner) {
            this.inner.classList.add(this.SLIDER_TRANSITIONING);
            if (direction === 'forwards') {
                this.inner.classList.add(this.SLIDER_TRANSITIONING_LEFT);
                slideIndex += 1;
            }

            if (direction === 'backwards') {
                this.inner.classList.add(this.SLIDER_TRANSITIONING_RIGHT);
                slideIndex -= 1;
            }
        }

        // Set the current active slide
        this.orderedSlides[slideIndex].classList.add(this.SLIDE_ACTIVE);
        // Set the current slide aria hidden attribute
        this.orderedSlides[slideIndex].setAttribute('aria-hidden', 'false');

        // Get all of the slides
        const slides = Array.from(this.slides);

        // Find the index of the current active slide
        let index = slides.findIndex((slide) =>
            slide.classList.contains('carousel-item--active')
        );

        // When we have cloned elements we want our navigation to switch between first and second element only.
        // 3rd and 4th element are not supposed to be accessible for navigation.
        if (this.isWrapping) {
            index = index > 1 ? index - 2 : index;
        }

        // If we have navigation controls update these
        if (this.navigationControls) {
            this.navigationControls[index].classList.add(
                this.NAVIGATION_CLASSNAME_ACTIVE
            );
            this.navigationControls[index].setAttribute('aria-current', true);
        }

        // Wait until the transition has stopped then change the slide
        setTimeout(() => {
            if (this.inner) {
                this.inner.classList.remove(this.SLIDER_TRANSITIONING);
                this.inner.classList.remove(this.SLIDER_TRANSITIONING_LEFT);
                this.inner.classList.remove(this.SLIDER_TRANSITIONING_RIGHT);
            }

            if (direction === 'forwards') {
                // Move the slides order by one to the right
                this.orderedSlides.push(this.orderedSlides.at(0));
                this.orderedSlides.shift();

                this.orderedSlides.forEach((slide, key) => {
                    // eslint-disable-next-line no-param-reassign
                    slide.dataset.slideOrder = key;
                });
            }

            if (direction === 'backwards') {
                this.orderedSlides.unshift(this.orderedSlides.at(-1));
                this.orderedSlides.pop();

                this.orderedSlides.forEach((slide, key) => {
                    // eslint-disable-next-line no-param-reassign
                    slide.dataset.slideOrder = key;
                });
            }

            // Announce the slide change vial the live region
            this.liveRegion.textContent = `Item ${index + 1} of ${
                this.slides.length
            }`;

            // Create and dispatch event to relay.
            const event = new CustomEvent('pnp-slide-event', {
                detail: { slideIndex },
            });
            this.eventsRelay.dispatchEvent(event);

            // Reset Live Region, this is not click, only autoplay.
            this.toggleAriaLiveRegionState(e);
        }, this.sliderAnimationDuration);
    }

    /**
     * Register touch events.
     *
     * @param {object} event Touch event.
     *
     * @returns {void}
     */
    handleTouchStart(event) {
        const firstTouch = event.touches[0];

        this.xMove = firstTouch.clientX;
    }

    /**
     * Handle slide left or right based on touch event offsets.
     *
     * @param {object} event Touch event.
     *
     * @returns {void}
     */
    handleTouchEnd(event) {
        if (!this.xMove) {
            return;
        }

        // Difference between last horizontal coordinate and initial touch point.
        // Negative for left and positive for right direction.
        const deltaX = event.changedTouches[0].clientX - this.xMove;

        // Absolute value, we dont want negative numbers here, only absolute length of swipe.
        const distTraveled = Math.abs(deltaX);

        // Ratio of length of the swipe to width of our wrapper.
        const moveThreshold = distTraveled / this.inner.offsetWidth;

        if (moveThreshold > this.triggerPercent) {
            deltaX < 0 ? this.moveSlideForwards() : this.moveSlideBackwards();
        }
    }

    /**
     * Resets the classes on the previous and next slides
     *
     * @returns {void}
     */
    resetClasses() {
        // Reset the slide classes
        this.orderedSlides.forEach((item) => {
            item.classList.remove(this.SLIDE_ACTIVE);
            // Add aria attribute hidden to the slides
            item.setAttribute('aria-hidden', 'true');
        });

        if (this.navigationControls) {
            this.navigationControls.forEach((item) => {
                item.classList.remove(this.NAVIGATION_CLASSNAME_ACTIVE);
                item.removeAttribute('aria-current');
            });
        }
    }

    /**
     * Moves the slide to the specified item.
     *
     * @param {number} slideIndex - The index for the slide to move to.
     * @param {object} event - Click event.
     *
     * @returns {void}
     */
    moveToSlide(slideIndex, e) {
        this.toggleAriaLiveRegionState(e);
        if (this.numOfSlides <= 0) {
            return;
        }

        this.inner.classList.add(this.SLIDER_TRANSITIONING_ANY);

        // get the slide that we are moving to
        const slideToMoveTo = Array.from(this.slides).at(slideIndex);

        this.resetClasses();

        setTimeout(() => {
            // Set the active navigation items
            if (this.navigationControls) {
                this.navigationControls.forEach((item) => {
                    if (parseInt(item.dataset.slideTo, 10) === slideIndex) {
                        item.classList.add(this.NAVIGATION_CLASSNAME_ACTIVE);
                        item.setAttribute('aria-current', true);
                    }
                });
            }

            // Cycle through the ordered slides until the one we want to move to is in the 1 index, then update the order on all slides
            while (this.orderedSlides.at(1) !== slideToMoveTo) {
                // Move the slides order by one to the right
                this.orderedSlides.push(this.orderedSlides.at(0));
                this.orderedSlides.shift();
            }

            // Set the order attribute once the slides are in the right order
            this.orderedSlides.forEach((slide, key) => {
                // eslint-disable-next-line no-param-reassign
                slide.dataset.slideOrder = key;
            });

            // Create and dispatch event to relay.
            const event = new CustomEvent('pnp-slide-event', {
                detail: { slideIndex },
            });
            this.eventsRelay.dispatchEvent(event);

            // Add new classes
            this.slides[slideIndex].classList.add(this.SLIDE_ACTIVE);

            // Set the current slide aria hidden attribute
            this.slides[slideIndex].setAttribute('aria-hidden', 'false');

            // Announce the slide change vial the live region
            this.liveRegion.textContent = `Item ${slideIndex + 1} of ${
                this.slides.length
            }`;

            this.inner.classList.remove(this.SLIDER_TRANSITIONING_ANY);
            this.toggleAriaLiveRegionState(e);
        }, this.sliderAnimationDuration);
    }

    /**
     * Sets the CSS animation duration to match that set in the data properties.
     *
     * @param {HTMLElement} wrapper - The DOM object for the slider.
     *
     * @returns {void}
     */
    setCSSAnimationDuration(wrapper) {
        this.sliderAnimationDuration =
            parseInt(wrapper.dataset.sliderAnimationDuration, 10) || 500;
        wrapper.style.setProperty(
            this.SLIDER_CSS_ANIMATION_DURATION,
            `${this.sliderAnimationDuration}ms`
        );
    }

    /**
     * Sets the CSS side duration to match that set in the data properties.
     *
     * @param {HTMLElement} wrapper - The DOM object for the slider.
     *
     * @returns {void}
     */
    setCSSSlideDuration(wrapper) {
        this.sliderSlideDuration =
            parseInt(wrapper.dataset.carouselSlideInterval, 10) || 3000;
        wrapper.style.setProperty(
            this.SLIDER_CSS_SLIDE_DURATION,
            `${this.sliderSlideDuration}ms`
        );
    }

    /**
     * Sets sensitivity for touch distance before slide action is fired.
     * Values must be expressed in decimals, defaults to 0.2.
     *
     * @param {HTMLElement} wrapper - The DOM object for the slider.
     *
     * @returns {void}
     */
    setTriggerPercent(wrapper) {
        this.triggerPercent = parseFloat(wrapper.dataset.triggerPercent) || 0.2;
    }

    /**
     * handle changes to aria live region, in order to stop or start announcing which slide is active.
     *
     * @param {object|undefined} se Either click event or nothing.
     *
     * @return void
     */
    toggleAriaLiveRegionState(e) {
        const state = e ? 'polite' : 'off';

        this.liveRegionDiv.setAttribute('aria-live', state);
        this.wrapper.setAttribute('aria-live', state);
    }
}

/**
 * Slider wrapper selector.
 * @type {string}
 */
const SLIDER_WRAPPER = '[data-component="slider"]';

/**
 * Slider next button selector.
 * @type {string}
 */
const BUTTON_NEXT = '[data-component="slider-button--next"]';

/**
 * Slider previous button selector.
 * @type {string}
 */
const BUTTON_PREVIOUS = '[data-component="slider-button--previous"]';

/**
 * Slider inner selector.
 * @type {string}
 */
const SLIDER_INNER = '[data-component="slider-inner"]';

/**
 * Slider items selector.
 * @type {string}
 */
const ITEMS = '[data-component="slider-item"]';

export default function _slider() {
    const slider = document.querySelector(SLIDER_WRAPPER);
    const previousButton = document.querySelector(BUTTON_PREVIOUS);
    const nextButton = document.querySelector(BUTTON_NEXT);

    // If data attribute doesnt exist do nothing.
    if (!slider) {
        return;
    }

    // If slider already created we will remove button listeners.
    if (slider.instance) {
        // Removing buttons event listeners.
        if (nextButton) {
            nextButton.removeEventListener(
                'click',
                slider.instance.moveSlideForwards
            );
        }
        if (previousButton) {
            previousButton.removeEventListener(
                'click',
                slider.instance.moveSlideBackwards
            );
        }
        // Removing slider instance.
        slider.instance = null;
        delete slider.instance;
    }

    slider.instance = new Slider(slider);

    const { instance } = slider;

    // Should we enable slider looping depends on data attribute passed in markup.
    instance.shouldLoop = stringToBool(slider.dataset.sliderShouldLoop, false);

    // Sets the inner element
    instance.innerElement = slider.querySelector(SLIDER_INNER);

    // Sets the slides.
    instance.items = slider.querySelectorAll(ITEMS);

    // Define how to handle next and previous click events.
    if (nextButton) {
        nextButton.addEventListener('click', instance.moveSlideForwards);
    }
    if (previousButton) {
        previousButton.addEventListener('click', instance.moveSlideBackwards);
    }

    // Only example listener, for someone wishing to run custom code when event is fired.
    instance.eventsRelay.addEventListener('pnp-slide-event', (e) => e);
}

_slider();
