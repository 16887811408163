import * as focusTrap from 'focus-trap';
import maintainDisabled from 'ally.js/maintain/disabled';
import maintainHidden from 'ally.js/maintain/hidden';

/**
 * This is helper function, determining if given class exist in given DOM element.
 *
 * @param {Object} el DOM element we are testing.
 * @param {string} className Class name we are testing for.
 *
 * @returns {boolean} Flag if class exist in DOM element.
 */
function hasClass(el, className) {
    return el.classList.contains(className);
}

export class Drawer {
    /**
     * @type {string} Drawer wrapper class selector.
     */
    // eslint-disable-next-line
    static CONTAINER = 'drawer';

    /**
     *
     * @type {string} Drawer body class selector.
     */
    static CONTENT = 'drawer__body';

    /**
     *
     * @type {string} Drawer close button class selector.
     */
    static CLOSE_BUTTON = 'drawer__close';

    /**
     *
     * @type {string} Drawer open class selector.
     */
    static SHOW_CLASS = 'drawer--is-visible';

    /**
     *
     * @type {string} Class to apply to body when drawer is opened.
     */
    static FREEZE_BACKGROUND = 'modal-active';

    constructor(drawerWrapper) {
        this.drawerElement = drawerWrapper;
        this.content = drawerWrapper.querySelector(Drawer.CONTENT);

        this.handleEvent = this.handleEvent.bind(this);
        this.activateTriggerListener = this.activateTriggerListener.bind(this);

        this.overrideProps(drawerWrapper);

        this.allyHandles = {
            originalFocus: undefined,
            disabled: undefined,
            tabFocus: undefined,
            hidden: undefined,
            keyHandle: undefined,
        };
    }

    /**
     * Event listener used for activating/deactivating drawer.
     *
     * @param {object} event Click event.
     */
    activateTriggerListener(event) {
        event.preventDefault();
        if (hasClass(this.drawerElement, Drawer.SHOW_CLASS)) {
            this.closeDrawer();
            return;
        }
        this.showDrawer();
    }

    /**
     * Opens drawer and activate accessibility.
     *
     * @returns void
     */
    showDrawer() {
        this.drawerElement.classList.add(Drawer.SHOW_CLASS);
        this.activateAccessibility();
        this.initDrawerEvents();
    }

    /**
     * Close drawer and deactivate accessibility.
     *
     * @returns void
     */
    closeDrawer() {
        this.drawerElement.classList.remove(Drawer.SHOW_CLASS);
        this.deactivateAccessibility();
        this.cancelDrawerEvents();
    }

    /**
     *  Attach event listeners.
     *
     *  @returns void
     */
    initDrawerEvents() {
        this.drawerElement.addEventListener('keydown', this.handleEvent);
        this.drawerElement.addEventListener('click', this.handleEvent);
    }

    /**
     * Remove event listeners
     *
     * @returns void
     */
    cancelDrawerEvents() {
        this.drawerElement.removeEventListener('keydown', this.handleEvent);
        this.drawerElement.removeEventListener('click', this.handleEvent);
    }

    /**
     * Route what event init, depending on arriving event type.
     *
     * @param {object} event Click or key event.
     *
     * @returns void
     */
    handleEvent(event) {
        const key = event.which || event.keyCode;
        if (key) {
            this.initKeyDown(event);
        }

        if (event.type === 'click') {
            this.initClick(event);
        }
    }

    /**
     * When escape key pressed, closing drawer.
     *
     * @param {object} event Key event.
     *
     * @returns void
     */
    initKeyDown(event) {
        if (
            (event.keyCode && event.keyCode === 27) ||
            (event.key && event.key === 'Escape')
        ) {
            this.closeDrawer();
        }
    }

    /**
     * Close drawer when clicking on close button.
     *
     * @param {object} event Click event.
     *
     * @returns void
     */
    initClick(event) {
        if (
            !event.target.closest(`.${Drawer.CLOSE_BUTTON}`) &&
            !hasClass(event.target, Drawer.CONTAINER)
        )
            return;
        event.preventDefault();
        this.closeDrawer();
    }

    /**
     * Create focus trap and attach events.
     *
     * @returns void
     */
    activateAccessibility() {
        this.allyHandles.disabled = maintainDisabled({
            filter: this.drawerElement,
        });
        this.allyHandles.hidden = maintainHidden({
            filter: this.drawerElement,
        });
        this.allyHandles.tabFocus = focusTrap.createFocusTrap(
            this.drawerElement,
            { clickOutsideDeactivates: true }
        );
        document.querySelector('body').classList.add(Drawer.FREEZE_BACKGROUND);

        this.allyHandles.tabFocus.activate();
    }

    /**
     * Removes focus trap, and restore original focus.
     * Removes attached events.
     *
     * @returns void
     */
    deactivateAccessibility() {
        this.allyHandles.disabled.disengage();
        this.allyHandles.hidden.disengage();
        this.allyHandles.tabFocus.deactivate();

        document
            .querySelector('body')
            .classList.remove(Drawer.FREEZE_BACKGROUND);
    }

    /**
     * We can pass data attribute in order to override default values.
     * Available options are [data-drawer-width, data-drawer-height, data-transition-speed].
     *
     * @param {object} wrapper DOM Element.
     *
     * @returns void
     */
    overrideProps(wrapper) {
        if (wrapper.dataset.drawerWidth) {
            this.drawerElement.style.setProperty(
                '--drawer-width',
                `${wrapper.dataset.drawerWidth}%`
            );
        }
        if (wrapper.dataset.drawerHeight) {
            this.drawerElement.style.setProperty(
                '--drawer-height',
                `${wrapper.dataset.drawerHeight}%`
            );
        }
        if (wrapper.dataset.drawerTransitionSpeed) {
            this.drawerElement.style.setProperty(
                '--drawer-transition-speed',
                `${wrapper.dataset.drawerTransitionSpeed}s`
            );
        }
    }
}

const DRAWER_WRAPPER_SELECTOR = '[data-component="drawer"]';

export default function _drawer() {
    const drawers = document.querySelectorAll(DRAWER_WRAPPER_SELECTOR);

    drawers.forEach((drawer) => {
        const id = drawer.getAttribute('id');
        const triggers = document.querySelectorAll(`[aria-controls="${id}"]`);

        const DOMItem = drawer;

        if (DOMItem.drawer) {
            // Update props if drawer already exists.
            DOMItem.drawer.overrideProps(drawer);
            return;
        }

        DOMItem.drawer = new Drawer(drawer);

        triggers.forEach((trigger) => {
            trigger.addEventListener(
                'click',
                DOMItem.drawer.activateTriggerListener
            );
        });
    });
}

_drawer();
