import React from 'react';

export default function FileIcon({ document }) {
    const { customIcon, customIconLabel, fileType } = document;

    let svgLabel = '';
    let svgRef = 'file-type=default';

    if (fileType) {
        switch (fileType) {
            case 'doc':
            case 'docx':
            case 'document':
                svgLabel = 'Document';
                svgRef = 'file-type=docs';
                break;

            case 'xls':
            case 'xlsx':
            case 'spreadsheet':
                svgLabel = 'Spreadsheet';
                svgRef = 'file-type=sheets';
                break;

            case 'ppt':
            case 'pptx':
            case 'presentation':
                svgLabel = 'Presentation';
                svgRef = 'file-type=presentations';
                break;

            case 'pdf':
                svgLabel = 'PDF';
                svgRef = 'file-type=pdf';
                break;

            default:
                svgLabel = fileType;
        }
    }

    if (customIcon) {
        return (
            <img
                src={customIcon}
                alt={customIconLabel || svgLabel}
                className="personal-document-listing-item__icon-image"
            />
        );
    }

    return (
        <svg aria-label={svgLabel} className="svg-icon svg-icon--small">
            <use href={`#${svgRef}`} />
        </svg>
    );
}
