const _win = typeof window === 'undefined' ? {} : window;

/**
 * Helper to check if document is available.
 *
 * @param {object} win Mock Window object.
 */
function hasDocument(win = _win) {
    return win.document !== undefined;
}

/**
 * Gets value of cookie.
 *
 * @param {string} key Cookie key we are trying to get.
 * @param {object} win Mock Window object.
 *
 * @returns {null|string}
 */
export function getCookie(key, win = _win) {
    if (!hasDocument(win)) {
        return null;
    }

    const { document } = win;
    const { cookie } = document;

    if (typeof cookie !== 'string') {
        return null;
    }

    const cookies = cookie.split('; ');

    let cookieValue = null;
    cookies.find((element) => {
        const [_key, _value] = element.split('=');
        if (_key === key && _value !== '') {
            cookieValue = _value;
        }
        return null;
    });

    return cookieValue;
}

/**
 * Sets cookie with given name, value, expiry date and location.
 *
 * @param {string} name Cookie name.
 * @param {string} value Cookie value.
 * @param {string} daysToExpire Until when cookie will exist.
 * @param {string} path Location path.
 * @param {object} win Mock Window object.
 * @param {object} dateMock Mock date object.
 *
 * @returns {void}
 */
export const setCookie = (
    name,
    value = '',
    daysToExpire = '',
    path = '/',
    win = _win,
    dateMock
) => {
    if (!hasDocument(win)) {
        return;
    }

    const { document } = win;

    const {
        location: { hostname },
    } = document;

    let expires = '';
    if (daysToExpire) {
        const date = dateMock || new Date();
        date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=${path}; domain=${hostname}; secure;`;
};

/**
 * Removes cookie with specific name.
 *
 * @param {string} name Cookie name.
 * @param {string} path Location path.
 * @param {object} win Mock Window object.
 *
 * @returns {void}
 */
export function clearCookie(name, path = '/', win = _win) {
    if (!hasDocument(win)) {
        return;
    }

    const { document } = win;

    const {
        location: { hostname },
    } = document;

    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${path}; domain=${hostname}; secure;`;
}
