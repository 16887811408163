/**
 * Returns the student id from the auth endpoint, this is here so that anything that needs to happen with cogniot etc can be done here
 * @returns {Promise<string>}
 */
export default function getAuth({ datastore }) {
    // Get all of the cookies as an array of names and values
    const cookies = document.cookie.split('; ').map((cookie) => {
        const [name, value] = cookie.split('=');
        return {
            name,
            value,
        };
    });

    // From the array of cookies find any that match the name student id
    const studentIdCookie = cookies.find(({ name }) => name === 'studentId');

    // If we already had a student ID cookie then return it
    if (studentIdCookie) {
        return studentIdCookie.value;
    }

    return datastore.jwt.getAuthToken().then(() => {
        const studentId = datastore.getJWTPayload().sisStudentid;

        document.cookie = `studentId=${studentId}`;

        return studentId;
    });
}
