/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import CuratorIOSocialFeedProvider from './data/CuratorIOSocialFeedProvider';
import { SocialFeedLoadingState } from './socialFeed';

// Lazy load the main 'app'
const SocialFeed = React.lazy(() =>
    import(/* webpackChunkName: "SocialFeed" */ './socialFeed')
);

const appWrapper = document.querySelectorAll('[data-component="social-feed"]');
// Don't run on a non app page or when offline
if (appWrapper.length > 0 && navigator.onLine) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            const { dataset } = wrapper;
            const appConfiguration = JSON.parse(dataset.appConfiguration);

            let socialFeed = <SocialFeed {...appConfiguration} />;

            if (appConfiguration.datasource === 'curator') {
                socialFeed = (
                    <CuratorIOSocialFeedProvider
                        curatorUrl={appConfiguration.datasourceUrl}
                        apiKey={appConfiguration.datasourceApiKey}
                        feedId={appConfiguration.curatorFeed}
                    >
                        {socialFeed}
                    </CuratorIOSocialFeedProvider>
                );
            }

            ReactDOM.render(
                <Suspense fallback={<SocialFeedLoadingState />}>
                    {socialFeed}
                </Suspense>,
                wrapper
            );
        });
    });
}
