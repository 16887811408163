import { Modal } from '../../modal/js/global';
import initFormSubmissionHandler from './form-submission-handler';

export default function _contentModal() {
    /**
     * Content modal wrapper selector
     * @const {string}
     */
    const CONTENT_MODAL_WRAPPER_SELECTOR = '[data-component="content-modal"]';

    /**
     * Content modal header selector
     * @const {string}
     */
    const CONTENT_MODAL_HEADER_SELECTOR =
        '[data-component="content-modal-header"]';

    /**
     * Content modal footer selector
     * @const {string}
     */
    const CONTENT_MODAL_FOOTER_SELECTOR =
        '[data-component="content-modal-footer"]';

    /**
     * Create a new Content Modal
     * @param {HTMLElement} modalWrapper - The wrapping HTML element of the Content Modal
     * @class
     */
    class ContentModal extends Modal {
        constructor(modalWrapper) {
            super(modalWrapper);
            this.triggers = document.querySelectorAll(
                `a[href="#${modalWrapper.id}"]`
            );

            this.triggers.forEach((trigger) => {
                trigger.addEventListener('click', (e) => {
                    this.handleTriggerClick(e);
                });
            });

            /*
                The modal body has a scroll on it, but not the header so
                calculate the offset for the body height so it scrolls properly
            */
            const modalHeader = modalWrapper.querySelector(
                CONTENT_MODAL_HEADER_SELECTOR
            );
            if (modalHeader) {
                modalWrapper.style.setProperty(
                    '--content-modal-header-height',
                    `${modalHeader.scrollHeight}px`
                );
            }
            const modalFooter = modalWrapper.querySelector(
                CONTENT_MODAL_FOOTER_SELECTOR
            );
            if (modalFooter) {
                modalWrapper.style.setProperty(
                    '--content-modal-footer-height',
                    `${modalFooter.scrollHeight}px`
                );
            }

            this.initFormHandler();
        }

        /**
         * Initialises the AJAX form handler needed so a page reload is
         * not needed when a user submit the form within the modal.
         */
        initFormHandler() {
            const form = this.modalWrapper.querySelector('form');
            if (form) {
                // This uses a Marketplace Matrix Form Ajax handler to deal with submitting the form
                // https://app.marketplace.squiz.net/internal/matrix-ajax-forms
                initFormSubmissionHandler({
                    formElement: form,
                    loadingMessageMarkup: '',
                });
            }
        }

        /**
         * Handle the press on the trigger button, this will be set to an anchor
         * value so we need to prevent the default processing on it as its unwanted.
         * @param {Event} e click event
         */
        handleTriggerClick(e) {
            e.preventDefault();
            this.openModal();
        }

        /**
         * Watch the height of the modal and records it to a CSS variable that can
         * be used to adjust the max height of the modal container. Needed due to mobile devices
         * not accounting for address bars, 'notches' and other elements in 100vh.
         */
        openModal() {
            super.openModal();

            if (this.modal) {
                this.modalWrapper.style.setProperty(
                    '--content-modal-content-height',
                    `${this.modal.clientHeight}px`
                );
            }

            // create an Observer instance
            const contentModal = this;
            this.resizeObserver = new window.ResizeObserver(() => {
                contentModal.modalWrapper.style.setProperty(
                    '--content-modal-content-height',
                    `${contentModal.modal.clientHeight}px`
                );
            });

            // start observing a DOM node
            this.resizeObserver.observe(this.modal);
        }

        /**
         * Remove watcher on modal close
         */
        closeModal() {
            super.closeModal();
            this.resizeObserver.unobserve(this.modal);
        }
    }

    // Find all content-modals and created handler objects
    const modals = document.querySelectorAll(CONTENT_MODAL_WRAPPER_SELECTOR);
    modals.forEach((modal) => {
        // Create a new item to modify
        const DOMItem = modal;

        // Attach the modal instance to our DOM Item
        if (!DOMItem.modal) {
            DOMItem.modal = new ContentModal(modal);
        }
    });
}

_contentModal();
