import stringToBool from 'PlugAndPlay/_global/js/stringToBool';
import Timer from 'PlugAndPlay/_global/js/Timer';
import { Slider } from 'PlugAndPlay/slider/js/global';

export default function _carousel() {
    /**
     * Carousel wrapper selector
     * @const {string}
     */
    const CAROUSEL_SELECTOR = '[data-component="carousel"]';

    /**
     * Carousel slide selector
     * @const {string}
     */
    const CAROUSEL_ITEM_SELECTOR = '[data-component="carousel-item"]';

    /**
     * Carousel inner selector
     * @const {string}
     */
    const CAROUSEL_INNER_SELECTOR = '[data-component="carousel-inner"]';

    /**
     * Carousel previous button
     * @const {string}
     */
    const CAROUSEL_BUTTON_PREVIOUS_SELECTOR =
        '[data-component="carousel-button--previous"]';

    /**
     * Carousel previous button
     * @const {string}
     */
    const CAROUSEL_BUTTON_NEXT_SELECTOR =
        '[data-component="carousel-button--next"]';

    /**
     * Carousel previous button
     * @const {string}
     */
    const CAROUSEL_BUTTON_AUTOPLAY_STATE =
        '[data-component="carousel-button__autoplay-state"]';

    /**
     * Carousel navigation items selector
     * @const {string}
     */
    const CAROUSEL_NAVIGATION_ITEM_SELECTOR =
        '[data-component="carousel-navigation__item"]';

    /**
     * Styling class for the paused playing state
     * @const {string}
     */
    const CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_PAUSED =
        'carousel-button__autoplay-state--paused';

    /**
     * Styling class for the playing state
     * @const {string}
     */
    const CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_RUNNING =
        'carousel-button__autoplay-state--playing';

    /**
     * Class for SLIDE_ACTIVE
     * @const {string}
     */
    const SLIDE_ACTIVE = 'carousel-item--active';

    /**
     * Class for NAVIGATION_CLASSNAME_ACTIVE
     * @const {string}
     */
    const NAVIGATION_CLASSNAME_ACTIVE = 'carousel-navigation__item--active';

    /**
     * Class for SLIDER_TRANSITIONING
     * @const {string}
     */
    const SLIDER_TRANSITIONING = 'carousel__inner--transitioning';

    /**
     * Class for SLIDER_TRANSITIONING_ANY
     * @const {string}
     */
    const SLIDER_TRANSITIONING_ANY = 'carousel__inner--transitioning-any';

    /**
     * Class for SLIDER_TRANSITIONING_RIGHT
     * @const {string}
     */
    const SLIDER_TRANSITIONING_RIGHT = 'carousel__inner--transition-right';

    /**
     * Class for SLIDER_TRANSITIONING_LEFT
     * @const {string}
     */
    const SLIDER_TRANSITIONING_LEFT = 'carousel__inner--transition-left';

    /**
     * CSS Var for SLIDER_CSS_ANIMATION_DURATION
     * @const {string}
     */
    const SLIDER_CSS_ANIMATION_DURATION_VAR = '--slider-animation-duration';

    /**
     * CSS Var for SLIDER_CSS_SLIDE_DURATION
     * @const {string}
     */
    const SLIDER_CSS_SLIDE_DURATION_VAR = '--slider-slide-duration';

    /**
     * Create a new Carousel
     * @param {HTMLElement} carousel - The wrapping HTML element of the carousel
     * @class
     */
    class Carousel extends Slider {
        constructor(carousel) {
            super(carousel);
            // Define the carousel HTML element for use throughout the class
            this.carousel = carousel;

            // Define the carousel classes for the slider
            this.SLIDE_ACTIVE = SLIDE_ACTIVE;
            this.NAVIGATION_CLASSNAME_ACTIVE = NAVIGATION_CLASSNAME_ACTIVE;
            this.SLIDER_TRANSITIONING = SLIDER_TRANSITIONING;
            this.SLIDER_TRANSITIONING_ANY = SLIDER_TRANSITIONING_ANY;
            this.SLIDER_TRANSITIONING_RIGHT = SLIDER_TRANSITIONING_RIGHT;
            this.SLIDER_TRANSITIONING_LEFT = SLIDER_TRANSITIONING_LEFT;
            this.SLIDER_CSS_ANIMATION_DURATION_VAR =
                SLIDER_CSS_ANIMATION_DURATION_VAR;
            this.SLIDER_CSS_SLIDE_DURATION_VAR = SLIDER_CSS_SLIDE_DURATION_VAR;

            // Bind the function to this
            this.moveCarouselForwards = this.moveCarouselForwards.bind(this);
            this.moveCarouselBackwards = this.moveCarouselBackwards.bind(this);
            this.moveCarouselToSlide = this.moveCarouselToSlide.bind(this);
            this.moveSlideWithKeys = this.moveSlideWithKeys.bind(this);
            this.toggleAutoPlayState = this.toggleAutoPlayState.bind(this);
            this.pauseCarouselAutoplayOnHover =
                this.pauseCarouselAutoplayOnHover.bind(this);
            this.resumeCarouselAutoplayOnHover =
                this.resumeCarouselAutoplayOnHover.bind(this);

            // Initialize the carousel
            this.initializeCarousel(carousel);
        }

        /**
         * Initializes the carousel
         * @param {HTMLElement} carousel - The carousel HTML Item
         */
        initializeCarousel(carousel) {
            // Get the inner element
            this.inner = carousel.querySelector(CAROUSEL_INNER_SELECTOR);

            // Get the items within the carousel
            this.items = carousel.querySelectorAll(CAROUSEL_ITEM_SELECTOR);

            // Get the carousel navigation items
            this.navigationItems = carousel.querySelectorAll(
                CAROUSEL_NAVIGATION_ITEM_SELECTOR
            );

            // Get the previous and next buttons
            this.previousButton = carousel.querySelector(
                CAROUSEL_BUTTON_PREVIOUS_SELECTOR
            );
            this.nextButton = carousel.querySelector(
                CAROUSEL_BUTTON_NEXT_SELECTOR
            );

            // Get the defined animation duration (Default to 0.5s if unset)
            this.animationDuration =
                parseInt(carousel.dataset.carouselAnimationDuration, 10) || 500;

            // Get if the carousel should loop
            this.shouldLoop = stringToBool(
                carousel.dataset.carouselShouldLoop,
                true
            );

            // get the auto play state button
            this.autoplayStateButtons = carousel.querySelectorAll(
                CAROUSEL_BUTTON_AUTOPLAY_STATE
            );

            // Get if the carousel should enable auto play
            this.shouldAutoplay = stringToBool(
                carousel.dataset.carouselShouldAutoplay,
                true
            );

            if (this.shouldAutoplay) {
                this.initialAutoplayState = stringToBool(
                    carousel.dataset.carouselAutoplayInitial,
                    true
                );
            }

            // Get the slide interval
            this.sliderAnimationDuration =
                parseInt(carousel.dataset.carouselSlideInterval, 10) || 3000;

            const interval =
                this.sliderAnimationDuration + this.animationDuration;

            // Check if the timer has already been created
            if (this.autoplayTimer instanceof Timer) {
                // If the timer is already created, reset the interval to the newly calculated one & stop the timer
                this.autoplayTimer.reset(interval).stop();
            } else {
                // Create the autoplay timer (in the stopped state)
                this.autoplayTimer = new Timer(
                    () => this.moveSlide(),
                    interval
                ).stop();
            }

            // Check that we have at least 2 slides and that we haven't already done this
            if (
                this.items.length >= 2 &&
                !(carousel.carousel instanceof Carousel)
            ) {
                this.autoplayStateButtons.forEach((button) => {
                    button.classList.remove(
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_PAUSED,
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_RUNNING
                    );
                    button.classList.add(
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_RUNNING
                    );
                });
            }

            // Check that we have a previous or next button
            if (
                this.previousButton ||
                this.nextButton ||
                this.navigationItems
            ) {
                // Add the event listeners
                this.setEventListeners();
            }

            // Set the animation duration for the CSS
            this.setCSSAnimationDuration(carousel);

            // Set the animation duration for the CSS
            this.setCSSSlideDuration(carousel);

            // Start autoplay
            if (this.shouldAutoplay && this.initialAutoplayState) {
                this.startAutoplay();
                this.autoplayStateButtons.forEach((button) => {
                    button.setAttribute('aria-pressed', 'true');
                });
            } else {
                // Set the button state to paused if we aren't auto playing when we initially load
                this.autoplayStateButtons.forEach((button) => {
                    button.classList.remove(
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_PAUSED,
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_RUNNING
                    );
                    button.classList.add(
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_PAUSED
                    );
                });
            }

            // Set a variable for capturing the autoplay state on hover
            this.autoplayPreviousState = null;

            // Set the current hovered state
            this.carouselIsHovered = false;
        }

        /**
         * Re-Initialize the carousel by removing all event listeners, resetting settings based on html attributes then adding the event listeners again
         */
        reinitializeCarousel() {
            this.removeEventListeners();
            this.initializeCarousel(this.carousel);
        }

        /**
         * Moves carousel item backwards.
         */
        moveCarouselBackwards(event) {
            // Move the slide
            this.moveSlide('backwards', event);

            // Reset the timer if the user has moved the slide and autoplay is enabled
            if (this.shouldAutoplay && this.autoplayTimer.isRunning) {
                this.autoplayTimer.reset();
            }
        }

        /**
         * Moves carousel item backwards.
         */
        moveCarouselForwards(event) {
            // Move the slide
            this.moveSlide('forwards', event);
            // Reset the timer if the user has moved the slide and autoplay is enabled
            if (this.shouldAutoplay && this.autoplayTimer.isRunning) {
                this.autoplayTimer.reset();
            }
        }

        /**
         * Moves carousel item to given index.
         *
         * @param {Event} event - The click event
         * @param {Bool} shouldFocus - if the slide that we navigate to should be focuses
         */
        moveCarouselToSlide(event, shouldFocus) {
            // Get the defined index of the slide to move to
            const slideToIndex = parseInt(event.target.dataset.slideTo, 10);

            // Check that the slide is within the bounds
            if (slideToIndex >= 0 && slideToIndex <= this.numOfItems) {
                // Move the carousel to the specified slide
                this.moveToSlide(slideToIndex, event);
            }

            // Check if we should focus the slide that we are navigating to
            if (shouldFocus) {
                // Focus the slide with the tab order set at 1
                Array.from(this.slides)
                    // Slide 1 because we have offset the slides by one slide to allow for animation when sliding from the left
                    .filter((slide) => slide.dataset?.slideOrder === '1')
                    .at(0)
                    ?.focus();
            }
        }

        /**
         * Function that sets the event listeners on the carousel controls
         * @param {HTMLElement} nextButton - The Next Button DOM Element
         * @param {HTMLElement} previousButton - The Previous Button DOM Element
         * @param {HTMLElement} navigationItems - The navigation items DOM elements
         */
        setEventListeners(
            nextButton = this.nextButton,
            previousButton = this.previousButton,
            navigationItems = this.navigationItems
        ) {
            // Add the listener for the next button
            if (nextButton) {
                nextButton.addEventListener('click', this.moveCarouselForwards);
            }

            // Add the listener for the previous button
            if (previousButton) {
                previousButton.addEventListener(
                    'click',
                    this.moveCarouselBackwards
                );
            }

            // Add the event listener for the nav items
            if (navigationItems) {
                navigationItems.forEach((navItem) => {
                    navItem.addEventListener('click', (event) => {
                        // Move the carousel to the respective slide
                        this.moveCarouselToSlide(event, true);
                    });
                });
            }

            // Add event listener for keypress
            if (this.carousel) {
                this.carousel.addEventListener('keyup', this.moveSlideWithKeys);
            }

            if (this.shouldAutoplay) {
                // Add event listeners for the autoplay button
                if (this.autoplayStateButtons) {
                    this.autoplayStateButtons.forEach((button) => {
                        button.addEventListener(
                            'click',
                            this.toggleAutoPlayState
                        );
                    });
                }
                // Pause the carousel when a user hovers over it
                if (this.carousel) {
                    this.carousel.addEventListener(
                        'mouseenter',
                        this.pauseCarouselAutoplayOnHover
                    );
                }

                // restart the carousel when a user hovers leave it
                if (this.carousel) {
                    this.carousel.addEventListener(
                        'mouseout',
                        this.resumeCarouselAutoplayOnHover
                    );
                }
            }
        }

        /**
         * Toggles the state of the auto play (play / pause)
         */
        toggleAutoPlayState() {
            // Check that the timer is defined
            if (this.autoplayTimer) {
                // Initially remove the state classes from all buttons
                this.autoplayStateButtons.forEach((button) => {
                    button.classList.remove(
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_PAUSED,
                        CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_RUNNING
                    );
                });

                if (this.autoplayPreviousState === 'running') {
                    // If the slider is running, add the paused class to all buttons
                    this.autoplayStateButtons.forEach((button) => {
                        button.classList.add(
                            CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_PAUSED
                        );
                        button.setAttribute('aria-pressed', 'false');
                    });
                    // Stop the carousel auto play
                    this.autoplayTimer.stop();
                    // Change the previous state to paused
                    this.autoplayPreviousState = 'paused';
                } else {
                    // If the carousel is stopped add the playing class to the buttons
                    this.autoplayStateButtons.forEach((button) => {
                        button.classList.add(
                            CAROUSEL_BUTTON_AUTOPLAY_STATE_CLASS_RUNNING
                        );
                        button.setAttribute('aria-pressed', 'true');
                    });
                    // Start the carousel if not hovered
                    if (!this.carouselIsHovered) {
                        this.autoplayTimer.start();
                    }
                    // Change the previous state to paused
                    this.autoplayPreviousState = 'running';
                }
            }
        }

        /**
         * Pauses the carousel
         */
        pauseCarouselAutoplayOnHover() {
            // Set that the carousel is currently hovered
            this.carouselIsHovered = true;
            // Check that the timer is defined
            if (this.autoplayTimer) {
                // Set the previous state so that we can revert back to it after hover exit
                this.autoplayPreviousState = this.autoplayTimer.isRunning
                    ? 'running'
                    : 'paused';

                // Stop the carousel auto play
                this.autoplayTimer.stop();
            }
        }

        /**
         * Starts the carousel autoplay
         */
        resumeCarouselAutoplayOnHover() {
            // Set the carousel hovered state to false
            this.carouselIsHovered = false;
            // Check that the timer is defined
            if (
                this.autoplayTimer &&
                this.autoplayPreviousState === 'running'
            ) {
                // Start the carousel
                this.autoplayTimer.start();
            }
        }

        /**
         * When a key is pressed in the slide move the slide to the left or right
         * @param {Event} event - The Keyup event
         */
        moveSlideWithKeys(event) {
            const currentFocusedElement = document.activeElement;
            const currentActiveSlide =
                this.items[this.getIndexOfCurrentSlide()];

            if (event.keyCode === 37) {
                this.moveCarouselBackwards();
                if (currentActiveSlide.contains(currentFocusedElement)) {
                    this.moveFocusToCurrentSlide();
                }
            }

            if (event.keyCode === 39) {
                this.moveCarouselForwards();
                if (currentActiveSlide.contains(currentFocusedElement)) {
                    this.moveFocusToCurrentSlide();
                }
            }
        }

        /**
         * Moves the focus for the carousel to the current slide article container
         */
        moveFocusToCurrentSlide() {
            const currentSlide = this.getIndexOfCurrentSlide(this.items);
            setTimeout(() => {
                this.items[currentSlide].focus();
            }, this.animationDuration);
        }

        /**
         * Removes the event listeners from the carousel
         * @param {HTMLElement} nextButton - The next button for the carousel
         * @param {HTMLElement} previousButton - The previous button for the carousel
         * @param {HTMLElement} navigationItems - The navigation items for the carousel
         */
        removeEventListeners(
            nextButton = this.nextButton,
            previousButton = this.previousButton,
            navigationItems = this.navigationItems
        ) {
            // Add the listener for the next button
            if (nextButton) {
                nextButton.removeEventListener(
                    'click',
                    this.moveCarouselForwards
                );
            }

            // Add the listener for the previous button
            if (previousButton) {
                previousButton.addEventListener(
                    'click',
                    this.moveCarouselBackwards
                );
            }

            // Add the event listener for the nav items
            if (navigationItems) {
                navigationItems.forEach((navItem) => {
                    navItem.removeEventListener(
                        'click',
                        this.moveCarouselToSlide
                    );
                });
            }

            // Remove the keypress listener
            if (this.carousel) {
                this.carousel.removeEventListener(
                    'keyup',
                    this.moveSlideWithKeys
                );
            }

            // Remove event listeners for the autoplay button
            if (this.autoplayStateButtons) {
                this.autoplayStateButtons.forEach((button) => {
                    button.removeEventListener(
                        'click',
                        this.toggleAutoPlayState
                    );
                });
            }

            // Remove the hover over event listener
            if (this.carousel) {
                this.carousel.removeEventListener(
                    'mouseenter',
                    this.pauseCarouselAutoplayOnHover
                );
            }

            // Remove the mouse out event listener
            if (this.carousel) {
                this.carousel.removeEventListener(
                    'mouseout',
                    this.resumeCarouselAutoplayOnHover
                );
            }
        }

        /**
         * Starts the carousel auto play
         */
        startAutoplay() {
            this.autoplayTimer.start();
        }
    }

    /**
     * Function that initializes the carousels on the page
     */
    function initializeCarousels() {
        const carouselInstances = document.querySelectorAll(CAROUSEL_SELECTOR);

        // Go through each carousel instance and create a new class from that carousel
        carouselInstances.forEach((carousel) => {
            // Create a new item to modify
            const DOMItem = carousel;

            // Check that this carousel hasn't already be initialised
            if (!DOMItem.carousel) {
                // Attach the carousel instance to our DOM Item
                DOMItem.carousel = new Carousel(carousel);
            } else {
                // The carousel exists, but we need to update the settings based on the new data from storybook
                // Re-Initialize the carousel
                DOMItem.carousel.reinitializeCarousel();
            }
        });
    }

    /**
     * @const {boolean}
     */
    const carouselExists = !!document.querySelector(CAROUSEL_SELECTOR);

    // If the module exists, do whatever we need to
    if (carouselExists) {
        initializeCarousels();
    }
}

// Call our contained carousel function
_carousel();
