import React from 'react';
import { formatDate } from '@fullcalendar/react';

/**
 * BaseCalendarEvent which contains the generic event rendering templates used within the application
 * for the various Full Calendar view render hooks and the onClick modal view.
 */
export default class BaseCalendarEvent {
    constructor(event, fcEvent) {
        this.event = event; // This is always the full payload from the data source, NEVER the fcEvent
        this.fcEvent = fcEvent; // Optional
    }

    /**
     * Get the title to use in the event render
     * @returns {string} title to use in FullCalendar event displays
     */
    getCalendarItemTitle() {
        return this.event.title;
    }

    /**
     * Get the Full Calendar event content for the DayView
     * @returns {JSX Object} content
     */
    getDayViewContent() {
        const title = this.getCalendarItemTitle();
        let { start, end } = this.event;
        const {
            allDay,
            type,
            icon,
            iconAlt,
            description,
            duration,
            bannerHighlight, // True when a user clicks the top 'banner' to highlight counted events
            recurrence,
        } = this.event;

        const isRecurring = recurrence && recurrence.length !== 0;
        if (isRecurring) {
            ({ start, end } = this.fcEvent); // Just a destruction without a declaration (let/const)
        }

        // Get string formatted dates for screen reader sections of event details
        const startTime = formatDate(start, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
        });
        const endTime =
            end &&
            formatDate(end, {
                hour: 'numeric',
                minute: '2-digit',
                hour12: false,
            });

        return (
            <div
                className={`calendar-event ${
                    bannerHighlight ? 'calendar-event--highlightable' : ''
                } ${type ? `calendar-event--${type}` : ''}`}
            >
                <div className="calendar-event__header">
                    {icon && (
                        <svg className="svg-icon">
                            {iconAlt && <title>{iconAlt}</title>}
                            <use href={`#${icon}`} />
                        </svg>
                    )}
                    <span className="calendar-event__title">{title}</span>
                </div>

                {/* This section is hidden using 'visuallyhidden' */}
                <div className="calendar-event__time">
                    {allDay && `All day event`}
                    {!allDay && <time dateTime={startTime}>{startTime}</time>}
                    {!allDay && endTime && ` to `}
                    {!allDay && endTime && (
                        <time dateTime={endTime}>{endTime}</time>
                    )}
                </div>

                {(!duration || duration >= 900000) && ( // Briefer events dont have space to fit the description
                    <div className="calendar-event__description">
                        {description}
                    </div>
                )}
            </div>
        );
    }

    onScheduleViewContentKeyDown(e, onClick) {
        if (e.key === 'Enter') {
            onClick({ el: undefined, event: this.fcEvent });
        }
    }

    getScheduleViewContent({ onClick }) {
        const title = this.getCalendarItemTitle();
        let { start, end } = this.event;
        const { allDay, icon, iconAlt, recurrence } = this.event;

        const isRecurring = recurrence && recurrence.length !== 0;
        if (isRecurring) {
            ({ start, end } = this.fcEvent); // Just a destruction without a declaration (let/const)
        }

        // Get string formatted dates for screen reader sections of event details
        let startTime = formatDate(start, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
        });
        let endTime =
            end &&
            formatDate(end, {
                hour: 'numeric',
                minute: '2-digit',
                hour12: false,
            });

        // If the event spans multiple days include the day and month in the description
        const spansMultipleDays = false;
        if (
            end &&
            !isRecurring &&
            (start.getDate() !== end.getDate() ||
                start.getMonth() !== end.getMonth() ||
                start.getYear() !== end.getYear())
        ) {
            startTime = formatDate(start, {
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: '2-digit',
                hour12: false,
            });

            endTime =
                end &&
                formatDate(end, {
                    day: 'numeric',
                    month: 'long',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: false,
                });
        }

        return (
            <div
                role="link"
                onKeyDown={(e) => this.onScheduleViewContentKeyDown(e, onClick)}
                tabIndex="0"
                className="calendar-event"
            >
                <div className="calendar-event__header">
                    {icon && (
                        <svg className="svg-icon svg-icon--expand">
                            {iconAlt && <title>{iconAlt}</title>}
                            <use href={`#${icon}`} />
                        </svg>
                    )}
                    <span className="calendar-event__title">{title}</span>
                </div>
                {!allDay && !spansMultipleDays && (
                    <>
                        <time
                            dateTime={startTime}
                            className="calendar-event__time"
                        >
                            {startTime}
                        </time>

                        {endTime && ` to `}
                        {endTime && (
                            <time
                                dateTime={endTime}
                                className="calendar-event__time"
                            >
                                {endTime}
                            </time>
                        )}
                    </>
                )}
            </div>
        );
    }

    /**
     * Get the title to show in the modal popup's H1 tag
     * @returns {string} title
     */
    getModalTitle() {
        return this.event.title;
    }

    /**
     * Get the modal body content for this type of event
     * @returns {JSX Object} content
     */
    getModalContent() {
        let { start, end } = this.event;
        const { allDay, type, description, recurrence } = this.event;

        const isRecurring = recurrence && recurrence.length !== 0;
        if (isRecurring) {
            ({ start, end } = this.fcEvent); // Just a destruction without a declaration (let/const)
        }

        // Use FullCalendar utility to format the dates into the structure we need for display
        const startDate = formatDate(start, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }); // e.g. December 20, 2021

        const startTime = formatDate(start, {
            hour: 'numeric',
            minute: '2-digit',
        }); // e.g. 2:15 PM

        const endDate =
            end &&
            formatDate(end, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            }); // e.g. December 21, 2021
        const endTime =
            end &&
            formatDate(end, {
                hour: 'numeric',
                minute: '2-digit',
            }); // e.g. 12:15 AM

        // Check if the event spans across days (not necessarily lasts for more than 24 hours)
        let spansMultipleDays = false;
        if (
            end &&
            (start.getDate() !== end.getDate() ||
                start.getMonth() !== end.getMonth() ||
                start.getYear() !== end.getYear())
        ) {
            spansMultipleDays = true;
        }

        return (
            <div
                className={`calendar-event-details calendar-event-details--${type}`}
            >
                <div className="calendar-event-details__time">
                    {/*
                        Single day example:     December 21, 2021 from 2:15 PM to 3:15 PM
                        Multi day span example: August 10, 2022 from 12:00 PM to August 11, 2022 6:00 AM
                        All day example:        January 1, 2022 All day
                    */}
                    <time
                        dateTime={startDate}
                        className="calendar-event-details__start-date"
                    >
                        {startDate}
                    </time>
                    {allDay && (
                        <span className="calendar-event-details__all-day">
                            {` All day `}
                        </span>
                    )}
                    {!allDay && (
                        <>
                            {` from `}
                            <time
                                dateTime={startTime}
                                className="calendar-event-details__start-time"
                            >
                                {startTime}
                            </time>
                        </>
                    )}
                    {!allDay && endTime && ` to `}
                    {spansMultipleDays && endDate && (
                        <time
                            dateTime={endDate}
                            className="calendar-event-details__end-date"
                        >
                            {` ${endDate} `}
                        </time>
                    )}
                    {!allDay && endTime && (
                        <time
                            dateTime={endTime}
                            className="calendar-event-details__end-time"
                        >
                            {endTime}
                        </time>
                    )}
                </div>
                <div className="calendar-event-details__description">
                    {description}
                </div>
            </div>
        );
    }

    /**
     * Get the footer controls for this type of event
     * @returns {array} array of additional footer controls
     */
    getModalFooterControls() {
        return [];
    }
}
