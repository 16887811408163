/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

const { bool, shape, string, func } = PropTypes;

/**
 * The user details context
 * @export
 */
const UserDetailsContext = createContext({
    isLoading: false,
    userDetails: {},
    getUserDetails: null,
    hasError: false,
});

UserDetailsContext.Provider.propTypes = {
    value: shape({
        isLoading: bool,
        userDetails: shape({
            firstName: string,
            lastName: string,
            lmsStudentid: string,
        }),
        getUserDetails: func,
        hasError: bool,
    }),
};

export default UserDetailsContext;
