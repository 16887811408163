/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import BaseSocialCard from './BaseSocialCard';
import YoutubeSocialCard from './YoutubeSocialCard';

/**
 * Create a card based on the details of the card pulled from the datasource
 *
 * @param {Object} args data object containing card information from the apps data source
 * @returns {Object} card object
 */
export function createCard(args) {
    switch (args.source) {
        case 'youtube': {
            return (
                <YoutubeSocialCard
                    key={`${args.source} + ${args.createdDate.getTime()}`}
                    {...args}
                />
            );
        }

        default:
            return (
                <BaseSocialCard
                    key={`${args.source} + ${args.createdDate.getTime()}`}
                    {...args}
                />
            );
    }
}
