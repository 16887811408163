import React, { useState, useEffect, useContext } from 'react';

import useGoogleApi, {
    AuthenticationState,
    ErrorType as AuthErrorType,
} from 'PlugAndPlay/_global/jsx/integrations/google/useGoogleApi.jsx';

import GoogleDriveAuthScopeContext from 'PlugAndPlay/_global/jsx/integrations/google/GoogleDriveAuthScopeContext.jsx';

import PersonalDocumentListingContext, {
    PersonalDocumentListingContextErrorType as ErrorType,
} from './PersonalDocumentListingContext';

export const SESSION_STORAGE_ID = 'GDrivePDList';
export const LOCAL_SCOPE_STORAGE_ID = 'GDrivePDListScopeApproved';

export default ({ apiKey, clientId, children }) => {
    // In order to not show the 'please approve these scopes' popup each time we need to know
    // if a user has approved this before; this might be from localstorage, matrix, datastore
    const { hasScopeBeenApproved, setHasScopeBeenApproved } = useContext(
        GoogleDriveAuthScopeContext
    );

    const {
        authenticationState,
        authenticate,
        retryAuthentication,
        checkTokenState,
        loginButton,
        errorType: authErrorType,
    } = useGoogleApi({
        discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
        ],
        scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
        apiKey,
        clientId,
        tokenStorageId: SESSION_STORAGE_ID,
    });

    // Is loading data from backend API service
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);

    // Convert useGoogleApi error to PersonalDocumentListingContext error type
    useEffect(() => {
        if (authErrorType !== null && authErrorType) {
            if (authErrorType === AuthErrorType.AUTH_ERROR) {
                setError(ErrorType.AUTH_ERROR);
            } else if (authErrorType === AuthErrorType.SCOPE_ERROR) {
                setError(ErrorType.SCOPE_ERROR);
            } else {
                setError(ErrorType.ERROR);
            }
        }
    }, [authErrorType]);

    useEffect(() => {
        // Once Google API connector is ready to start run it
        if (
            authenticationState === AuthenticationState.READY_FOR_AUTH ||
            authenticationState === AuthenticationState.NEED_REFRESH
        ) {
            // Default is null, will be true / false once it has been retrieved
            if (hasScopeBeenApproved !== null) {
                authenticate({
                    userHasApprovedScope: hasScopeBeenApproved,
                });
            }
        }

        // Once authenticated update the scope approval if needed
        if (authenticationState === AuthenticationState.AUTHENTICATED) {
            if (!hasScopeBeenApproved) {
                setHasScopeBeenApproved(true);
            }
        }
    }, [hasScopeBeenApproved, authenticationState]);

    const getDocuments = ({ count }) => {
        setIsLoading(true);

        // Wrap request in auth token checker from common auth code
        checkTokenState(
            window.gapi.client.drive.files
                .list({
                    orderBy: 'modifiedByMeTime desc',
                    pageSize: count,
                    fields: 'nextPageToken, files(id, name, webViewLink, owners, lastModifyingUser, modifiedTime, modifiedByMeTime, size, mimeType, fileExtension, iconLink)',
                })
                .then((response) => {
                    setDocuments(
                        response.result.files.map((file) => {
                            const mimeSplit = file.mimeType.split('.');
                            return {
                                id: file.id,
                                title: file.name,
                                url: file.webViewLink,
                                createdBy: file.owners[0].displayName,
                                lastModifiedBy:
                                    file.modifiedTime === file.modifiedByMeTime
                                        ? 'me'
                                        : file.lastModifyingUser.displayName,
                                lastModifiedDate: new Date(file.modifiedTime),
                                fileSize: file.size
                                    ? parseInt(file.size, 10)
                                    : undefined,
                                fileType:
                                    file.fileExtension || mimeSplit.at(-1),
                                customIcon: file.iconLink,
                            };
                        })
                    );
                    setIsLoading(false);
                })
        );
    };

    const restartAuthProcess = () => {
        setError(null);
        retryAuthentication({
            userHasApprovedScope: hasScopeBeenApproved,
        });
    };

    return (
        <PersonalDocumentListingContext.Provider
            value={{
                isLoading:
                    authenticationState === AuthenticationState.NOT_STARTED ||
                    authenticationState === AuthenticationState.READY_FOR_AUTH
                        ? true
                        : isLoading,
                isReady:
                    authenticationState === AuthenticationState.AUTHENTICATED,
                isAuthNeeded:
                    authenticationState ===
                    AuthenticationState.NEEDS_LOGIN_CONSENT,
                signInLink: loginButton,
                error,
                documents,
                getDocuments,
                allDocumentsLink: 'https://drive.google.com/drive/my-drive',
                restartAuthProcess,
            }}
        >
            {children}
        </PersonalDocumentListingContext.Provider>
    );
};
