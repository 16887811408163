/* eslint-disable react/prefer-stateless-function */
import React, { useState, createRef } from 'react';
import { useFocusWithin, useKeyboard } from '@react-aria/interactions';

export default function ButtonMenu({
    uniqueId,
    icon,
    iconTitle,
    text,
    children,
    className,
}) {
    const buttonRef = createRef();
    const [isOpen, setIsOpen] = useState(false);

    // Watch the focus and blur on the menu and close if focus leaves the control
    const { focusWithinProps } = useFocusWithin({
        onBlurWithin: () => {
            setIsOpen(false);
        },
    });

    // Listen for Esc key within this element
    const { keyboardProps } = useKeyboard({
        onKeyDown: (e) => {
            if (isOpen && e.key === 'Escape') {
                setIsOpen(false);
                buttonRef.current.focus(); // Restore focus to the element which opened the menu
            }
        },
    });

    // Wrap the children onClick so the menu can be closed at the same time
    const modifiedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                onClick: () => {
                    setIsOpen(false);
                    buttonRef.current.focus(); // Restore focus to the element which opened the menu
                    child.props.onClick();
                },
            });
        }
        return child;
    });

    return (
        <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...focusWithinProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...keyboardProps}
            className={`button-menu ${className}`}
        >
            <button
                ref={buttonRef}
                type="button"
                aria-expanded={isOpen}
                aria-controls={`${uniqueId}-button-menu-menu`}
                onClick={() => setIsOpen(!isOpen)}
                className="button-menu__button"
            >
                {icon && (
                    <svg className="svg-icon button-menu__icon">
                        {iconTitle && <title>{iconTitle}</title>}
                        <use href={`#${icon}`} />
                    </svg>
                )}
                {text && <span className="button-menu__label">{text}</span>}
            </button>
            <ul
                id={`${uniqueId}-button-menu-menu`}
                className={`button-menu__menu ${
                    isOpen ? 'button-menu__menu--open' : ''
                }`}
            >
                {modifiedChildren}
            </ul>
        </div>
    );
}

export function ButtonMenuItem({ label, onClick }) {
    return (
        <li className="button-menu__item">
            <button
                type="button"
                onClick={onClick}
                className="button-menu__item-select"
            >
                <span className="button-menu__item-label">{label}</span>
            </button>
        </li>
    );
}
