import { useState, useLayoutEffect } from 'react';

/**
 * Creates a Ref and uses a resize observer to watch the width and height changes
 * that occurr to that ref. Returns changes to the sizes.
 *
 * @returns const [calendarRef, { width, height }] = useResizeObserver();
 */
export default function useResizeObserver() {
    // This creates its own ref, this helps prevent unnessasary re-render spam
    const [ref, setRef] = useState(null);
    // Record the width and height of the ref this creates
    const [size, setSize] = useState({ width: 0, height: 0 });

    function updateSize(entries) {
        // since we are observing only a single element, so we access the first element in entries array
        const rect = entries[0].contentRect;

        // current width & height
        const { width } = rect;
        const { height } = rect;

        setSize({ width, height });
    }

    // eslint-disable-next-line consistent-return
    useLayoutEffect(() => {
        // Wait until the ref has been set by the createRefCallback
        if (ref) {
            const resizeOb = new ResizeObserver(updateSize);
            resizeOb.observe(ref);

            // Cleanup function to stop observer once dead
            return () => {
                resizeOb.unobserve(ref);
            };
        }
    }, [ref]);

    return [setRef, size];
}
