/* eslint-disable react/jsx-props-no-spreading */
import getJWT from 'PlugAndPlay/edify/js/getJwt';
import React, { Suspense } from 'react';
import EdifyUserDetailsProvider from './data/EdifyUserDetailsProvider';

// Lazy load the main 'app'
const UserDetails = React.lazy(() =>
    import(/* webpackChunkName: "UserDetails" */ './UserDetails')
);

const appWrapper = document.querySelectorAll('[data-component="user-details"]');
// Don't run on a non app page
if (appWrapper.length > 0) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            // Get the dataset from the wrapper
            const { dataset } = wrapper;

            // Get the info we need to initialise the component form the CCT
            const { serviceUrl, JWTUrl, studentDataUrlPrefix } = JSON.parse(
                dataset.appConfiguration
            );

            // Initialise our datastore service
            const datastore = new Datastore({
                serviceURL: serviceUrl,
                jwtCallback: () => getJWT(JWTUrl),
            });

            // Render the component
            ReactDOM.render(
                <Suspense
                    fallback={
                        <div className="users-loader">
                            <svg className="svg-icon">
                                <title>Loading user details</title>
                                <use href="#user-avatar" />
                            </svg>
                        </div>
                    }
                >
                    <EdifyUserDetailsProvider datastore={datastore}>
                        <UserDetails
                            popover
                            studentDataUrlPrefix={studentDataUrlPrefix}
                        />
                    </EdifyUserDetailsProvider>
                </Suspense>,
                wrapper
            );
        });
    });
}
