import AccessibleCard from '../../_global/js/accessibleCard';

export default function _card() {
    /**
     * Component wrapper selector
     * @const {string}
     */
    const CARD_SELECTOR = '[data-component="card"]';

    /**
     * Card item selector
     * @const {string}
     */
    const CARD_ITEM_SELECTOR = '.card';

    /**
     * Card link selector
     * @const {string}
     */
    const CARD_LINK_SELECTOR = '.card__link';

    class Card {
        constructor(component) {
            this.cardWrapper = component;

            component.querySelectorAll(CARD_ITEM_SELECTOR).forEach((item) => {
                const DOMItem = item;
                const link = item.querySelector(CARD_LINK_SELECTOR);

                if (!DOMItem.component) {
                    DOMItem.component = new AccessibleCard(item, link);
                }
            });
        }
    }

    const components = document.querySelectorAll(CARD_SELECTOR);
    components.forEach((component) => {
        const DOMItem = component;

        // Attach the instance to the DOM once
        if (!DOMItem.component) {
            DOMItem.component = new Card(component);
        }
    });
}

_card();
