/**
 * This function is a callback for the datastore sdk where a JWT is needed to be fetched for each comeponent
 * @param {string} tokenEndpoint - The JWT token endpoint, to fetch the token from
 * @returns {string} returns the JWT as a string
 */
// eslint-disable-next-line consistent-return
export default async function getJWT(tokenEndpoint) {
    // If the user is offline then we should just return a dummy JWT
    if (!navigator.onLine) {
        return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21tZW50IjoidGhpcyBpcyBhIGR1bW15IEpXVCB1c2VkIGZvciBTREsgdmFsaWRhdGlvbiB3aGlsZSBvZmZsaW5lLiJ9.41wRTE4EiHkCVYztCDlpik_BmuuHKK_ZyNzIm4xcEb4';
    }

    // Get the endpoint as a string to be used for our cookie key
    const endpointName = JSON.stringify(tokenEndpoint);

    // Get all of the cookies as an array of names and values
    const cookies = document.cookie.split('; ').map((cookie) => {
        const [name, value] = cookie.split('=');
        return {
            name,
            value,
        };
    });

    // From the array of cookies find any with the name as the endpoint name
    const endpointCookie = cookies.find(({ name }) => name === endpointName);

    // If we found a cookie return it
    if (endpointCookie) {
        return endpointCookie.value;
    }

    // Get the JWT from the passed in endpoint
    const jwtResponse = await fetch(tokenEndpoint, {
        method: 'POST',
        credentials: 'include',
    });

    // Get the JWT contents from the response
    const jwtContents = await jwtResponse.text();

    // Get the max age from the response headers
    const [, maxAge] = jwtResponse.headers.get('cache-control')?.split(', ');

    // Set the response as a cookie
    document.cookie = `${endpointName}=${jwtContents};${maxAge}`;

    // Return the JWT
    return jwtContents;
}
