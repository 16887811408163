/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../../collapsible-card/jsx/Card';
import CircularProgress from '../../circular-progress/jsx/circular-progress';
import interpolate from '../../_global/js/interpolate';
import GpaContext from './data/GpaContext';

export function LoadingState() {
    return (
        <>
            <div className="analytics__progress-details analytics__progress-details--loading" />
            <div className="analytics__progress-details analytics__progress-details--loading" />
        </>
    );
}

function ErrorState() {
    return <p>Oops looks like there has been an error</p>;
}

export default function Analytics({
    progressHeading,
    progressAria,
    progressLabel,
    creditsHeading,
    creditsAria,
    creditsLabel,
    gpaScale,
    creditsScale,
    goodScoreMessage,
    strokeWidth,
}) {
    // Get our data from the context
    const { user, gpa, creditsCompleted, getData, isLoading, isReady, error } =
        useContext(GpaContext);

    // State for the title is contained internally
    const [title, setTitle] = useState('');

    const date = new Date();

    useEffect(() => {
        // Get the initial values
        getData();

        const hours = date.getHours();

        let newTitle = ``;

        if (hours < 13) {
            newTitle = `Good morning`;
        } else if (hours < 18) {
            newTitle = `Good afternoon`;
        } else {
            newTitle = `Good evening`;
        }

        if (gpa < 0.7 * gpaScale) {
            newTitle = `Welcome back`;
        } else if (gpa > 0.75 * gpaScale) {
            newTitle = goodScoreMessage;
        }

        if (date.getDay() === 5) {
            newTitle = `Happy Friday`;
        }

        setTitle(`${newTitle}, ${user}`);
    }, [user, gpa, creditsCompleted]);

    return (
        <Card
            isCollapsible={false}
            title={title}
            contentClasses="analytics__container"
            id={`card-${user}`}
        >
            {isLoading && <LoadingState />}
            {error && <ErrorState />}
            {isReady && (
                <>
                    <div className="analytics__progress-details">
                        <CircularProgress
                            animation="none"
                            percentage={gpa || '/'}
                            scale={gpaScale}
                            strokeWidth={strokeWidth}
                        />
                        <div className="analytics__progress-description">
                            <h4>{progressHeading}</h4>
                            <span className="sr-only">
                                {interpolate(progressAria, { gpa, gpaScale })}
                            </span>
                            <span aria-hidden>
                                {interpolate(progressLabel, { gpa, gpaScale })}
                            </span>
                        </div>
                    </div>

                    <div className="analytics__progress-details">
                        <CircularProgress
                            animation="none"
                            percentage={creditsCompleted || `/`}
                            scale={creditsScale}
                            strokeWidth={strokeWidth}
                        />
                        <div className="analytics__progress-description">
                            <h4>{creditsHeading}</h4>
                            <span className="sr-only">
                                {interpolate(creditsAria, { creditsCompleted })}
                            </span>
                            <span aria-hidden>
                                {interpolate(creditsLabel, {
                                    creditsCompleted,
                                })}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </Card>
    );
}

const { number, string } = PropTypes;

Analytics.propTypes = {
    gpaScale: number,
    creditsScale: number,
    progressHeading: string,
    progressAria: string,
    progressLabel: string,
    creditsHeading: string,
    creditsAria: string,
    creditsLabel: string,
    goodScoreMessage: string,
    strokeWidth: number,
};

Analytics.defaultProps = {
    gpaScale: 7,
    creditsScale: 96,
    progressHeading: 'Total GPA',
    progressAria: 'You have {gpa} of a possible {gpaScale}',
    progressLabel: '{gpa}/{gpaScale}',
    creditsHeading: 'Completed credits',
    creditsAria: 'You have completed {creditsCompleted} of your credits',
    creditsLabel: '{creditsCompleted} credits',
    goodScoreMessage: 'Great GPA! keep it up',
    strokeWidth: 3,
};
