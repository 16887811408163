/**
 * Replace strings in given mustache template.
 *
 * @param {string} template Template string.
 * @param {object} data Data to be replaced.
 *
 * @returns {string}
 */
export default function interpolate(template, data) {
    const interpolated = template.replace(/{([^{}]+)}/g, (keyExpr, key) =>
        data[key] !== undefined ? `${data[key]}` : ``
    );

    return interpolated;
}
