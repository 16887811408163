/* eslint-disable import/prefer-default-export */
import { useRef, useState } from 'react';
import { useButton } from '@react-aria/button';
/**
 * @returns Hooks to open and close the modal dialog from interacting with the a div rather than a button
 */
export default function useDivButtonModalOpen() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModalRef = useRef();

    let { buttonProps: modalOpenProps } = useButton(
        {
            onPress: () => setIsModalOpen(true),
        },
        openModalRef
    );

    // Add our own fields
    modalOpenProps = {
        ...modalOpenProps,
        role: 'button',
        tabIndex: '0',
        'aria-label': 'Open in modal',
    };
    delete modalOpenProps.type;

    return {
        openModalRef, // useRef
        modalOpenProps, // useKeyboard
        isModalOpen, // useState get
        setIsModalOpen, // useState set
    };
}
