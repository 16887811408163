/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

function CardHeading({
    children,
    toggleIsCollapsed,
    isCollapsed,
    isCollapsible,
    id,
}) {
    if (isCollapsible) {
        return (
            <button
                className="collapsible-card__header"
                type="button"
                onClick={toggleIsCollapsed}
                aria-expanded={!isCollapsed}
                aria-controls={`content-${id}`}
            >
                {children}
            </button>
        );
    }

    return <div className="collapsible-card__header">{children}</div>;
}

export default function CollapsibleCard(props) {
    const {
        wrapperClasses,
        contentClasses,
        children,
        id,
        headingIcon,
        isCollapsible,
        isInitialCollapsed,
        isScrollable,
        subTitle,
        title,
        ...rest
    } = props;

    let storageId = ``;

    if (id) {
        storageId = `ComponentCard-${id}`;
    }

    let storageIsCollapsed = isInitialCollapsed || false;
    if (storageId) {
        storageIsCollapsed = localStorage.getItem(storageId) === `collapsed`;
    }

    const [isCollapsed, setCollapsed] = useState(storageIsCollapsed);

    const wrapperClass = [
        'collapsible-card',
        'no-wysiwyg',
        wrapperClasses,
    ].join(' ');
    const contentClass = [
        'collapsible-card__contents',
        contentClasses,
        isScrollable && 'collapsible-card__contents--scrollable',
    ].join(' ');
    const collapseClass = [
        'svg-icon',
        isCollapsed === false && 'collapsible-card__collapsed',
    ].join(' ');

    const toggleIsCollapsed = useCallback(() => {
        setCollapsed(!isCollapsed);

        if (storageId) {
            localStorage.setItem(storageId, !isCollapsed ? `collapsed` : ``);
        }
    }, [storageId, isCollapsed]);

    return (
        <div className={wrapperClass} id={id} {...rest}>
            <CardHeading
                isCollapsible={isCollapsible}
                toggleIsCollapsed={toggleIsCollapsed}
                isCollapsed={isCollapsed}
                id={id}
            >
                <h2 className="collapsible-card__header-text">{title}</h2>
                {(subTitle || isCollapsible || headingIcon) && (
                    <div className="collapsible-card__header-right">
                        {subTitle && (
                            <h3 className="collapsible-card__header-text collapsible-card__header-text--sub-title">
                                {subTitle}
                            </h3>
                        )}
                        {isCollapsible && (
                            <svg
                                className={`${collapseClass} collapsible-card__more-menu-icon`}
                            >
                                <title>
                                    {isCollapsed
                                        ? `Expand panel`
                                        : `Collapse panel`}
                                </title>
                                <use href="#chevron" />
                            </svg>
                        )}
                        {headingIcon}
                    </div>
                )}
            </CardHeading>
            <div
                id={`content-${id}`}
                role="region"
                tabIndex={-1}
                className="collapsible-card__body"
            >
                {(isCollapsible !== true ||
                    (isCollapsible === true && isCollapsed === false)) && (
                    <div className={contentClass}>{children}</div>
                )}
            </div>
        </div>
    );
}

CollapsibleCard.propTypes = {
    wrapperClasses: PropTypes.string,
    contentClasses: PropTypes.string,
    id: PropTypes.string,
    headingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isCollapsible: PropTypes.bool,
    isInitialCollapsed: PropTypes.bool,
    isScrollable: PropTypes.bool,
    subTitle: PropTypes.string,
    title: PropTypes.string,
};

CollapsibleCard.defaultProps = {
    wrapperClasses: '',
    contentClasses: '',
    id: '',
    headingIcon: '',
    isCollapsible: false,
    isInitialCollapsed: false,
    isScrollable: false,
    subTitle: '',
    title: '',
};
