/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */
/* eslint-disable no-unused-vars */

// eslint-disable-next-line import/prefer-default-export
export class BaseStorageAdapter {
    delete(key) {
        throw new Error(`Method not implemented`);
    }

    deleteAll() {
        throw new Error(`Method not implemented`);
    }

    get(key) {
        throw new Error(`Method not implemented`);
    }

    getAll() {
        throw new Error(`Method not implemented`);
    }

    set(key, token) {
        throw new Error(`Method not implemented`);
    }

    setAll(tokens) {
        throw new Error(`Method not implemented`);
    }
}
