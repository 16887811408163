/* eslint-disable import/prefer-default-export */
import BaseCalendarEvent from './BaseCalendarEvent';
import AssignmentCalendarEvent from './AssignmentCalendarEvent';

/**
 * Get the event type customisation object which can be used to get specific rendering HTML/JSX objects based on the event type.
 *
 * @param {Object} event data object containing event information from the apps data source, NOT the fcEvent
 * @returns {Object} customisation object
 */
export function getEventTypeCustomisations(event, fcEvent) {
    if (event.type === 'assignment') {
        return new AssignmentCalendarEvent(event, fcEvent);
    }
    return new BaseCalendarEvent(event, fcEvent);
}
