import maintainDisabled from 'ally.js/src/maintain/disabled';
import maintainHidden from 'ally.js/src/maintain/hidden';
import whenKey from 'ally.js/src/when/key';
import visibleArea from 'ally.js/src/when/visible-area';
import firstTabbable from 'ally.js/src/query/first-tabbable';

import * as focusTrap from 'focus-trap';

const MODAL_WRAPPER_SELECTOR =
    '[data-component="modal"]:not([data-extended="true"])';
const MODAL_SELECTOR = '[role="dialog"]';
const MODAL_TRIGGER_SELECTOR = '[data-click="modalOpen"]';
const MODAL_CLOSE_SELECTOR = '[data-click="modalClose"]';
const MODAL_PREVENT_CLOSE = '[data-click="modalPreventClose"]';

/**
 * Class to freeze the main page content when the modal is active
 * @const {string}
 */
const MODAL_FREEZE_BACKGROUND = 'modal-active';

/**
 * This class contains the logic for working with a Modal like interface; primarily the accessability
 * concerns like focus trapping, escape keys, focus restore ect.
 *
 * It can be extended and used as a parent for tightly coupled components which are ALWAYS modal based
 * or used as a composite component for components which have optional modal features.
 */
export class Modal {
    constructor(modalWrapper) {
        this.modalWrapper = modalWrapper;

        // Events relay
        this.eventsRelay = modalWrapper;

        // Media watch utility
        this.mediaWatch = undefined;
        this.mediaWatchChangeHandler = undefined;

        // Find the actual modal
        this.modal = this.modalWrapper.querySelector(MODAL_SELECTOR);
        // Find the modal trigger action
        this.modalTriggers = this.modalWrapper.querySelectorAll(
            MODAL_TRIGGER_SELECTOR
        );

        // Areas on click that should close
        const modalCloses =
            this.modalWrapper.querySelectorAll(MODAL_CLOSE_SELECTOR);
        // Areas on click that should prevent click propagation to close
        const modalPreventCloses =
            this.modalWrapper.querySelectorAll(MODAL_PREVENT_CLOSE);

        // Ally.js accessibility handles - See https://allyjs.io/tutorials/accessible-dialog.html
        this.allyHandles = {
            originalFocus: undefined,
            disabled: undefined,
            tabFocus: undefined,
            hidden: undefined,
            keyHandle: undefined,
        };
        // Set a default closer handler than can be changed on modal open
        this.closeHandler = this.closeModal;

        // Sets public access to the method.
        this.openHandler = this.openModal;

        this.modalTriggers.forEach((modalTrigger) => {
            modalTrigger.addEventListener('click', () => {
                this.openModal();
            });
        });

        modalCloses.forEach((modalClose) => {
            modalClose.addEventListener('click', () => {
                this.closeHandler();
            });
        });

        modalPreventCloses.forEach((preventClose) => {
            preventClose.addEventListener('click', (e) => {
                this.preventClose(e);
            });
        });
    }

    /**
     * Opens the modal and configures the page appropriatly for WCAG standards.
     */
    openModal() {
        this.modal.hidden = false;

        this.activateAccessability();

        // Create and dispatch event to relay.
        const event = new CustomEvent('pnp-modal-event', {
            detail: { eventType: 'open' },
        });
        this.eventsRelay.dispatchEvent(event);
    }

    /**
     * Configures the element appropriatly for WCAG standards.
     *
     * Focus is trapped to the modal window. Other elements on the page are disabled and hidden from accessability tools.
     * Escape keys are setup and focus is set to the first valid item.
     *
     * @param {Element} targetElement - (optional) target element to use instead of the modal wrapper
     * @param {Function} closeCallback - (optional) callback for closing the modal
     */
    activateAccessability(targetElement, closeCallback) {
        this.allyHandles.originalFocus = document.activeElement;

        // Disable interaction outside of modal
        this.allyHandles.disabled = maintainDisabled({
            filter: targetElement || this.modal,
        });

        // Focus trap the user to the modal
        this.allyHandles.tabFocus = focusTrap.createFocusTrap(
            targetElement || this.modal,
            {
                escapeDeactivates: false, // Already handled below
            }
        );
        this.allyHandles.tabFocus.activate();

        // Hide other content from screen reader (like a css overlay but for screen readers)
        this.allyHandles.hidden = maintainHidden({
            filter: targetElement || this.modal,
        });

        // Handle escape key to close modal
        if (closeCallback) {
            this.closeHandler = closeCallback;
        }
        this.allyHandles.keyHandle = whenKey({
            escape: () => {
                setTimeout(this.closeHandler());
            },
        });

        // Wait until the dialog becomes visible and focus the first tabbable control
        visibleArea({
            context: targetElement || this.modal,
            callback: () => {
                const element = firstTabbable({
                    context: targetElement || this.modal,
                    defaultToContext: true,
                });
                element.focus();
            },
        });

        document.querySelector('body').classList.add(MODAL_FREEZE_BACKGROUND);
    }

    /**
     * This function closes the modal and reverses the WCAG functions setup oin the open.
     */
    closeModal() {
        if (this.modal.hidden === false) {
            this.modal.hidden = true;

            this.deactivateAccessability();

            // Create and dispatch event to relay.
            const event = new CustomEvent('pnp-modal-event', {
                detail: { eventType: 'close' },
            });
            this.eventsRelay.dispatchEvent(event);
        }
    }

    /**
     * Reverses the WCAG functions setup oin the open.
     */
    deactivateAccessability() {
        this.allyHandles.disabled.disengage();
        this.allyHandles.tabFocus.deactivate();
        this.allyHandles.hidden.disengage();
        this.allyHandles.keyHandle.disengage();
        this.allyHandles.originalFocus.focus();

        document
            .querySelector('body')
            .classList.remove(MODAL_FREEZE_BACKGROUND);

        // Restore default close handler
        this.closeHandler = this.closeModal;
    }

    /**
     * This function is an event handler to prevent the modal being closes when an item 'internal' to it is pressed.
     * @param {Event} e - event object
     */
    preventClose(e) {
        e.stopPropagation();
    }

    /**
     * Function to assist in controlling components when showing as a modal depends on the current
     * media query match e.g. modal on mobile but not on tablet and desktop.
     *
     * @param {String} mediaQuery - media query string. See src/modules/_global/js/screenSize.js
     * @param {Function} matchCallback - Callback when media change occurs and matches the query string
     * @param {Function} nomatchCallback - Callback when media change occurs and does not matche the query string
     */
    watchForMediaQueryChange(mediaQuery, matchCallback, nomatchCallback) {
        this.mediaWatch = window.matchMedia(`(${mediaQuery})`);

        // Have to save the handler function so it can be removed later
        this.mediaWatchChangeHandler = (e) => {
            if (e.matches) {
                matchCallback();
            } else {
                nomatchCallback();
            }
        };

        // Watch for a change and let change handler callback handle it
        this.mediaWatch.addEventListener(
            'change',
            this.mediaWatchChangeHandler
        );
    }

    /**
     * Function to detatch the media query watch
     */
    removeMediaQueryWatch() {
        if (this.mediaWatch) {
            this.mediaWatch.removeEventListener(
                'change',
                this.mediaWatchChangeHandler
            );
        }
    }
}

export default function _modal() {
    // Find all modals and created handler objects
    const modals = document.querySelectorAll(MODAL_WRAPPER_SELECTOR);
    modals.forEach((modal) => {
        // Create a new item to modify
        const DOMItem = modal;

        // Attach the modal instance to our DOM Item
        if (!DOMItem.modal) {
            DOMItem.modal = new Modal(modal);
        }
    });
}

_modal();
