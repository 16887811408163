import FeatureBranchSwitcher from './FeatureBranchSwitcher';
import FeatureBranchManager from './FeatureBranchManager';
import FeatureBranchPopOut from './FeatureBranchPopOut';

// eslint-disable-next-line no-underscore-dangle
export default function _featureBranchManager() {
    const FEATURE_BRANCH_ALERT_SELECTOR =
        '[data-component="featureBranchAlert"]';
    const FEATURE_BRANCH_MANAGER_SELECTOR =
        '[data-component="featureBranchManager"]';
    const FEATURE_BRANCH_POP_OUT_SELECTOR =
        '[data-component="featureBranchPopOut"]';

    const featureBranchAlert = document.querySelectorAll(
        FEATURE_BRANCH_ALERT_SELECTOR
    );
    featureBranchAlert.forEach((alert) => {
        const DOMItem = alert;
        if (!DOMItem.alert) {
            DOMItem.alert = new FeatureBranchSwitcher(alert);
        }
    });

    const featureBranchManager = document.querySelectorAll(
        FEATURE_BRANCH_MANAGER_SELECTOR
    );
    featureBranchManager.forEach((manager) => {
        const DOMItem = manager;
        if (!DOMItem.manager) {
            DOMItem.manager = new FeatureBranchManager(manager);
        }
    });

    const featureBranchPopOut = document.querySelectorAll(
        FEATURE_BRANCH_POP_OUT_SELECTOR
    );
    featureBranchPopOut.forEach((popOut) => {
        const DOMItem = popOut;
        if (!DOMItem.popOut) {
            DOMItem.popOut = new FeatureBranchPopOut(popOut);
        }
    });
}

_featureBranchManager();
