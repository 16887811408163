/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import uuidv4 from 'PlugAndPlay/_global/js/uuid';
import Modal from '../../modal/jsx/modal';
import Collapsible from '../../collapsible/jsx/Collapsible';
import splitArrayAtPoint from '../../_global/js/splitArrayAtPoint';
import formatBytes from '../../_global/js/formatBytes';

function AssessmentModal({
    onModalClose,
    courseName,
    url,
    due,
    description,
    name,
    submissionType,
}) {
    const titleId = uuidv4(); // Modal needs a id for the dialog described by
    const dateObj = new Date(due); // Make sure the data is a date object

    const cleanDescription = DOMPurify.sanitize(description);
    return (
        <Modal
            titleId={titleId}
            onClose={onModalClose}
            className="my-courses-assessment-modal"
        >
            <header className="my-courses-assessment-modal__header">
                <h1 id={titleId} className="my-courses-assessment-modal__title">
                    {courseName}
                </h1>
                <button
                    type="button"
                    onClick={onModalClose}
                    className="my-courses-assessment-modal__header-close"
                >
                    <svg className="svg-icon">
                        <title>Close modal</title>
                        <use href="#close" />
                    </svg>
                </button>
            </header>

            <div className="my-courses-assessment-modal__body">
                <div className="my-courses-assessment-modal__due">
                    <h2 className="my-courses-assessment-modal__due-title">
                        Assignment due
                    </h2>
                    {`Due `}
                    <span className="my-courses-assessment-modal__due-date">
                        {dateObj.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })}
                    </span>

                    <svg aria-hidden className="svg-icon">
                        <use href="#edit" />
                    </svg>
                </div>
                <h2 className="my-courses-assessment-modal__sub-title">
                    {name}
                </h2>
                <div
                    // eslint-disable-next-line react/no-danger -- sanitize using DOMPurify above
                    dangerouslySetInnerHTML={{ __html: cleanDescription }}
                    className="my-courses-assessment-modal__description"
                />
                {submissionType && (
                    <div className="my-courses-assessment-modal__submit-method">
                        <svg aria-hidden className="svg-icon">
                            <use href="#information" />
                        </svg>
                        {`Submit `}
                        {submissionType}
                    </div>
                )}
                {url && (
                    <div className="my-courses-assessment-modal__submit-note">
                        More details available in Canvas
                    </div>
                )}
            </div>

            <footer className="my-courses-assessment-modal__footer">
                <button
                    type="button"
                    onClick={onModalClose}
                    className="my-courses-assessment-modal__close"
                >
                    Close
                </button>

                <a
                    href={url}
                    target="_blank"
                    className="my-courses-assessment-modal__link"
                    rel="noreferrer"
                >
                    <svg className="svg-icon">
                        <use href="#external" />
                    </svg>
                    Canvas
                </a>
            </footer>
        </Modal>
    );
}
function Assessment(assessment) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { name, due } = assessment;
    const dateObj = new Date(due); // Make sure the data is a date object

    return (
        <>
            <div className="my-courses__assessment-item">
                <button
                    type="button"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    className="my-courses__assessment-name"
                >
                    {name}
                </button>
                <div className="my-courses__assessment-due">
                    {/* Display the due date as MMM D */}
                    {dateObj.toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                    })}
                </div>
            </div>

            {isModalOpen && (
                <AssessmentModal
                    {...assessment}
                    onModalClose={() => {
                        setIsModalOpen(false);
                    }}
                />
            )}
        </>
    );
}

Assessment.propTypes = {
    name: PropTypes.string.isRequired,
    due: PropTypes.string.isRequired,
};

function Update({ name, date, link, fileSize }) {
    // Make sure the data is a date object
    const dateObj = new Date(date);

    return (
        <div className="my-courses__update-item">
            <div className="my-courses__update-icon">
                <svg className="svg-icon">
                    <use href="#attachment" />
                    <title>Attachment icon</title>
                </svg>
            </div>
            <div className="my-courses__update-name">
                {name} ({formatBytes(fileSize)}){' '}
                <a className="my-courses__update-link" href={link}>
                    Download
                </a>
            </div>
            <div className="my-courses__update-date">
                {/* Display the date as MMM D */}
                {dateObj.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                })}
            </div>
        </div>
    );
}

Update.propTypes = {
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default function CourseContent({ assessments, updates }) {
    // Split the assessments into the first 4 and the rest
    let visibleAssessments = [];
    let restAssessments = [];
    if (Array.isArray(assessments)) {
        [visibleAssessments, restAssessments] = splitArrayAtPoint(
            assessments,
            4
        );
    }

    // Split the updates into the first 4 and the rest
    let visibleUpdates = [];
    let restUpdates = [];
    if (Array.isArray(updates)) {
        [visibleUpdates, restUpdates] = splitArrayAtPoint(updates, 4);
    }

    return (
        <>
            {Array.isArray(assessments) && assessments.length > 0 && (
                <div className="my-courses__assessments">
                    <div className="my-courses__assessments-title-wrapper">
                        <div className="my-courses__assessments-icon">
                            <svg className="svg-icon">
                                <use href="#edit" />
                                <title>Edit icon</title>
                            </svg>
                        </div>
                        <div className="my-courses__assessments-title">
                            Assignments & Assessments
                        </div>
                        <div className="my-courses__assessments-column-heading">
                            Due Date
                        </div>
                    </div>
                    {visibleAssessments.map((assessment) => {
                        const { id, name, due } = assessment;

                        return (
                            <Assessment
                                key={id}
                                name={name}
                                due={due}
                                {...assessment}
                            />
                        );
                    })}
                    {restAssessments.length > 0 && (
                        <Collapsible
                            displayChevron
                            collapsedTitle="Show more"
                            expandedTitle="Show less"
                            isOpen={false}
                            additionalButtonClasses="my-courses__show-more-button"
                            additionalContentClasses="my-courses__show-more-content"
                        >
                            {restAssessments.map((assessment) => {
                                const { id, name, due } = assessment;

                                return (
                                    <Assessment
                                        key={id}
                                        name={name}
                                        due={due}
                                    />
                                );
                            })}
                        </Collapsible>
                    )}
                </div>
            )}
            {Array.isArray(updates) && updates.length > 0 && (
                <div className="my-courses__updates">
                    <div className="my-courses__updates-title-wrapper">
                        <div className="my-courses__updates-title">
                            Course updates
                        </div>
                        <div className="my-courses__updates-column-heading">
                            Date
                        </div>
                    </div>
                    {visibleUpdates.map((update) => {
                        const { id, name, date, link, fileSize } = update;

                        return (
                            <Update
                                key={id}
                                name={name}
                                date={date}
                                link={link}
                                fileSize={fileSize}
                            />
                        );
                    })}
                    {restUpdates.length > 0 && (
                        <Collapsible
                            displayChevron
                            collapsedTitle="Show more"
                            expandedTitle="Show less"
                            isOpen={false}
                            additionalButtonClasses="my-courses__show-more-button"
                            additionalContentClasses="my-courses__show-more-content"
                        >
                            {restUpdates.map((update) => {
                                const { id, name, date, link, fileSize } =
                                    update;

                                return (
                                    <Update
                                        key={id}
                                        name={name}
                                        date={date}
                                        link={link}
                                        fileSize={fileSize}
                                    />
                                );
                            })}
                        </Collapsible>
                    )}
                </div>
            )}
            <div className="my-courses__footer">
                <a className="my-courses__footer-link" href="#">
                    Download syllabus
                </a>
                <a className="my-courses__footer-button--light" href="#">
                    <svg className="svg-icon">
                        <use href="#download" />
                        <title>Download icon</title>
                    </svg>
                    Syllabus
                </a>
                <a className="my-courses__footer-button" href="#">
                    <svg className="svg-icon">
                        <use href="#external" />
                        <title>External icon</title>
                    </svg>
                    Canvas
                </a>
            </div>
        </>
    );
}
