/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import MockEmailsProvider from './data/emails-listing-mock-provider';
import { LoadingState } from './emails-listing';

// Lazy load the main 'app'
const EmailsListing = React.lazy(() =>
    import(/* webpackChunkName: "EmailsListing" */ './emails-listing')
);

const MockProvider = (appConfiguration, emailsListing) => {
    const wrapper = (
        <MockEmailsProvider {...appConfiguration}>
            {emailsListing}
        </MockEmailsProvider>
    );

    return wrapper;
};

// Lazy load the main 'app'
const init = (MockProviderFn = MockProvider) => {
    const appWrapper = document.querySelectorAll(
        '[data-component="emails-listing"]'
    );

    // Don't run on a non app page
    if (appWrapper.length > 0) {
        // Only load ReactDOM from if its needed as its pretty large
        import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then(
            (ReactDOM) => {
                appWrapper.forEach((wrapper) => {
                    let emailsListingWrapper;
                    const { dataset } = wrapper;
                    const appConfiguration = JSON.parse(
                        dataset.appConfiguration
                    );

                    const emailsListing = (
                        <EmailsListing {...appConfiguration} />
                    );

                    if (appConfiguration.datasource === 'mock') {
                        emailsListingWrapper = MockProviderFn(
                            appConfiguration,
                            emailsListing
                        );
                    }

                    ReactDOM.render(
                        <Suspense fallback={<LoadingState />}>
                            {emailsListingWrapper}
                        </Suspense>,
                        wrapper
                    );
                });
            }
        );
    }
};

init();

// For unit test only
export { init, MockProvider };
