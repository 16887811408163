import axios from 'axios';

/**
 * Class to provide session storage caching for requests from sources which
 * send no-cache no-store headers back but you want to cache it anyway.
 */
export default class AjaxCache {
    /**
     * @param {String} cacheName - Name of the cache, used as key of session storage
     */
    constructor(cacheName) {
        this.cacheName = cacheName;
        window.sessionStorage.setItem(cacheName, JSON.stringify({}));
    }

    /**
     * Primary function used to send an AJAX request that should be cached for possible later
     * re-request without the need for a remote request.
     *
     * @param {String} url -  URL of the resource to load and store the response in cache.
     * @param {Function} transform -  Transform function to manipulate the response before being stored in cache and returned. Accepts a single 'response' input and should output desired cached output.
     * @returns {Promise}
     */
    sendRequest({ url, transform }) {
        return new Promise((resolve, reject) => {
            const cacheData = this._getFromCache(url);
            if (cacheData) {
                resolve(cacheData);
            } else {
                this._makeRequest(url, transform, resolve, reject);
            }
        });
    }

    /**
     * Makes actualy http request using Axios.
     */
    _makeRequest(url, transform, resolve, reject) {
        axios
            .get(url)
            .then((response) => {
                const data = transform ? transform(response) : response;
                this._addToCache(url, data);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    }

    /**
     * Helper to get a cached entry
     */
    _getFromCache(key) {
        const cache = JSON.parse(window.sessionStorage.getItem(this.cacheName));
        return cache[key];
    }

    /**
     * Helper to store a cached entry
     */
    _addToCache(key, value) {
        const cache = JSON.parse(window.sessionStorage.getItem(this.cacheName));
        cache[key] = value;
        window.sessionStorage.setItem(this.cacheName, JSON.stringify(cache));
    }
}
