/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';

import LocalStorageGoogleDriveAuthScopeProvider from 'PlugAndPlay/_global/jsx/integrations/google/LocalStorageGoogleDriveAuthScopeProvider.jsx';
import EdifyGoogleDriveAuthScopeProvider from 'PlugAndPlay/_global/jsx/integrations/google/EdifyGoogleDriveAuthScopeProvider.jsx';
import GoogleDrivePersonalDocumentListingProvider, {
    LOCAL_SCOPE_STORAGE_ID,
} from './data/GoogleDrivePersonalDocumentListingProvider';
import OneDrivePersonalDocumentListingProvider from './data/OneDrivePersonalDocumentListingProvider';

import { PersonalDocumentListingLoadingState } from './PersonalDocumentListing';

const appWrapper = document.querySelectorAll(
    '[data-component="personal-document-listing"]'
);

if (appWrapper.length > 0) {
    // Lazy load the main 'app'
    const PersonalDocumentListing = React.lazy(() =>
        import(
            /* webpackChunkName: "PersonalDocumentListing" */ './PersonalDocumentListing'
        )
    );

    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            const { dataset } = wrapper;
            const configuration = JSON.parse(dataset.configuration);

            // Create the base component
            let personalDocListing = (
                <PersonalDocumentListing {...configuration} />
            );

            // Google provider
            if (configuration.datasource === 'google') {
                personalDocListing = (
                    <GoogleDrivePersonalDocumentListingProvider
                        clientId={configuration.clientId}
                    >
                        {personalDocListing}
                    </GoogleDrivePersonalDocumentListingProvider>
                );

                // Where is the prior scope approval stored?
                if (configuration.scopeApprovalSource === 'edify') {
                    // Edify stores it against the student record
                    const datastore = new Datastore({
                        serviceURL: configuration.datastore.url,
                        jwtURL: configuration.datastore.jwt,
                    });

                    personalDocListing = (
                        <EdifyGoogleDriveAuthScopeProvider
                            datastore={datastore}
                        >
                            {personalDocListing}
                        </EdifyGoogleDriveAuthScopeProvider>
                    );
                } else {
                    // Otherwise assume its in the local storage
                    personalDocListing = (
                        <LocalStorageGoogleDriveAuthScopeProvider
                            localStorageKey={
                                configuration.localStorageKey ||
                                LOCAL_SCOPE_STORAGE_ID
                            }
                        >
                            {personalDocListing}
                        </LocalStorageGoogleDriveAuthScopeProvider>
                    );
                }
            } else if (configuration.datasource === 'onedrive') {
                // MS provider
                personalDocListing = (
                    <OneDrivePersonalDocumentListingProvider
                        clientId={configuration.clientId}
                    >
                        {personalDocListing}
                    </OneDrivePersonalDocumentListingProvider>
                );
            }

            ReactDOM.render(
                <Suspense fallback={<PersonalDocumentListingLoadingState />}>
                    {personalDocListing}
                </Suspense>,
                wrapper
            );
        });
    });
}
