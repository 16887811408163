/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Bookmarks Application Context
 * @export
 */
const BookmarkContext = createContext({
    isLoading: false,
    isReady: false,
    error: null,
    bookmarks: [],
    getBookmarks: null,
    updateBookmarks: null,
});

BookmarkContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        isReady: PropTypes.bool,
        error: PropTypes.symbol,
        bookmarks: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                url: PropTypes.string,
            })
        ),
        getBookmarks: PropTypes.func,
        updateBookmarks: PropTypes.func,
    }),
};

export const BookmarksContextErrorType = Object.freeze({
    ERROR: Symbol('ERROR'),
});

export default BookmarkContext;
