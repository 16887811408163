/**
 * Debounce functions for better performance
 * @module debounce
 * @param  {Function} fn The function to debounce
 * @example <caption>Example of how to use the debounce function</caption>
 * window.addEventListener('resize', debounce(functionToDebounce));
 */
export default function debounce(fn) {
    // Define a variable to store an animation frame
    let requestAnimationFrame;

    // Return a function
    return (...args) => {
        // If the animation frame is defined dont do anything (the window hasn't finished animating yet)
        if (requestAnimationFrame) {
            return;
        }

        // If the animation frame isn't defined define it
        requestAnimationFrame = window.requestAnimationFrame(() => {
            // Run our debounced function
            fn(...args);

            // Set the animation frame as undefined
            requestAnimationFrame = undefined;
        });
    };
}
