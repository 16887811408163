/* eslint-disable react/jsx-props-no-spreading */ // Disabled as prop spreading is the documented way to use the @react-aria framework
import React, { useEffect } from 'react';
import {
    useOverlay,
    usePreventScroll,
    useModal,
    OverlayContainer,
} from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope, useFocusManager } from '@react-aria/focus';

/**
 * React implementation of the Plug and Play Modal, uses the Adobe @react-aria package
 * to handle the modal / dialog related accessability concerns such as focus movement, focus traps,
 * ESC to close ect.
 *
 * Needs to be wrapped within a <OverlayProvider> to aria hide the application when the modal opens.
 *
 * Comments on @react-aria hooks come directly from their documentation.
 */
export default function Modal({
    titleId,
    title,
    onClose,
    children,
    className,
}) {
    return (
        <OverlayContainer>
            <div
                className={`no-wysiwyg modal-wrapper ${
                    className ? `${className}-wrapper` : ''
                }`}
            >
                <FocusScope contain restoreFocus autoFocus>
                    <ModalContent
                        titleId={titleId}
                        title={title}
                        onClose={onClose}
                        className={className}
                    >
                        {children}
                    </ModalContent>
                </FocusScope>
            </div>
        </OverlayContainer>
    );
}

function ModalContent({ titleId, title, onClose, children, className }) {
    // Base settings for @react-aria hooks
    const settings = {
        'aria-describedby': titleId,
        title,
        onClose,
        isDismissable: true,
        isOpen: true,
    };

    const modalRef = React.useRef();
    const modalContentRef = React.useRef();
    const focusManager = useFocusManager();

    /*
        Provides the behavior for overlays such as dialogs, popovers, and menus. Hides the overlay when
        the user interacts outside it, when the Escape key is pressed, or optionally, on blur.
    */
    const { overlayProps, underlayProps } = useOverlay(
        settings,
        modalContentRef
    );

    /*
        Prevents scrolling on the document body on mount, and restores it on unmount.
        Also ensures that content does not shift due to the scrollbars disappearing.
    */
    usePreventScroll();

    /*
        Hides content outside the current <OverlayContainer> from screen readers on mount and restores it on unmount.
    */
    const { modalProps } = useModal();

    /*
        Provides the behavior and accessibility implementation for a dialog component.
    */
    const { dialogProps, titleProps } = useDialog(settings, modalContentRef);

    /*
        Remove the hidden attribute as soon as its drawn to the DOM so it transitions open like
        the base ES6 modal in the framework.
    */
    useEffect(() => {
        modalRef.current.removeAttribute('hidden');
        focusManager.focusFirst(); // FocusScope autofocus property wont work due to being hidden so focus first now
    }, [modalRef]);

    return (
        <div
            ref={modalRef}
            {...underlayProps}
            hidden
            className={`modal ${className || ''}`}
        >
            <div
                ref={modalContentRef}
                {...overlayProps}
                {...dialogProps}
                {...modalProps}
                aria-modal="true"
                tabIndex="-1"
                className={`modal__content ${
                    className ? `${className}__content` : ''
                }`}
            >
                {titleId ? (
                    ''
                ) : (
                    <h2
                        {...titleProps}
                        className={`${className ? `${className}__title` : ''}`}
                    >
                        {title}
                    </h2>
                )}
                {children}
            </div>
        </div>
    );
}
