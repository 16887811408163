import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Collapsible({
    collapsedTitle,
    expandedTitle,
    isOpen,
    shouldAnimate,
    children,
    displayChevron,
    additionalButtonClasses,
    additionalContentClasses,
}) {
    // Is collapsed should be the oppisite of is Open prop
    const [isCollapsed, setIsCollapsed] = useState(!isOpen);
    const [isAnimating, setIsAnimating] = useState(false);

    // Handle the click and animation if the prop is set
    function handleClick() {
        if (!shouldAnimate) {
            setIsCollapsed(!isCollapsed);
        } else {
            setIsAnimating(true);
        }
    }

    // Handle the animation
    function handleAnimationEnd() {
        if (isAnimating) {
            setIsAnimating(false);
            setIsCollapsed(!isCollapsed);
        }
    }

    function getContentClasses() {
        const classes = ['react-collapse__content', additionalContentClasses];
        if (isCollapsed && isAnimating) {
            classes.push('react-collapse__content--expanding');
        }
        if (!isCollapsed && isAnimating) {
            classes.push('react-collapse__content--collapsing');
        }
        if (!isCollapsed) {
            classes.push('react-collapse__content--open');
        }
        return classes.join(' ');
    }

    return (
        <>
            <button
                type="button"
                onClick={handleClick}
                aria-expanded={!isCollapsed}
                className={`react-collapse__button${
                    additionalButtonClasses ? ` ${additionalButtonClasses}` : ''
                }
                ${!isCollapsed ? 'react-collapse__button--open' : ''}`}
            >
                {displayChevron && (
                    <span
                        className={`react-collapse__chevron ${
                            isCollapsed
                                ? 'react-collapse__chevron--up'
                                : 'react-collapse__chevron--down'
                        }`}
                    >
                        <svg className="svg-icon">
                            <use xlinkHref="#chevron" />
                            <title>Chevron</title>
                        </svg>
                    </span>
                )}
                {isCollapsed ? collapsedTitle : expandedTitle || collapsedTitle}
            </button>
            <div
                className={getContentClasses()}
                onTransitionEnd={handleAnimationEnd()}
            >
                {children}
            </div>
        </>
    );
}

Collapsible.propTypes = {
    collapsedTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    expandedTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isOpen: PropTypes.bool,
    shouldAnimate: PropTypes.bool,
    children: PropTypes.node,
};

Collapsible.defaultProps = {
    collapsedTitle: 'Title',
    expandedTitle: 'Title',
    isOpen: true,
    shouldAnimate: false,
    children:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde repudiandae distinctio voluptatum laborum quod, quisquam corrupti pariatur possimus totam adipisci est soluta, aut, quos magni. Aspernatur velit perferendis et incidunt.',
};
