import React, { useState, useEffect, useRef } from 'react';

import {
    PublicClientApplication,
    BrowserAuthError,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';

import './useMicrosoftAuth.scss';

export const AuthenticationState = Object.freeze({
    NOT_STARTED: Symbol('NOT_STARTED'),
    NEEDS_LOGIN_CONSENT: Symbol('NEEDS_LOGIN_CONSENT'),
    AUTHENTICATED: Symbol('AUTHENTICATED'),
    ERROR: Symbol('ERROR'),
});

export const ErrorType = Object.freeze({
    AUTH_ERROR: Symbol('AUTH_ERROR'),
    SCOPE_ERROR: Symbol('SCOPE_ERROR'),
    POPUP_ERROR: Symbol('POPUP_ERROR'),
    ERROR: Symbol('ERROR'),
});

export default function useMicrosoftAuth({ clientId, scopes }) {
    const [authenticationState, setAuthenticationState] = useState(
        AuthenticationState.NOT_STARTED
    );
    const [errorType, setErrorType] = useState(null);

    // Connector into Microsoft Authentication Library
    const msalInstance = useRef(
        new PublicClientApplication({
            auth: {
                clientId,
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: 'http://localhost:6006',
            },
            cache: {
                /// cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                cacheLocation: 'localStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
        })
    );

    const attemptSilentLogin = () => {
        msalInstance.current
            .acquireTokenSilent({ scopes })
            .then(() => {
                // Token was found / refreshed from storage
                setAuthenticationState(AuthenticationState.AUTHENTICATED);
            })
            .catch((tokenRequestError) => {
                // No token exists, need to login
                if (
                    tokenRequestError instanceof BrowserAuthError ||
                    tokenRequestError instanceof InteractionRequiredAuthError
                ) {
                    // Check if they are logged in via SSO and use its login / auth
                    msalInstance.current
                        .ssoSilent({ scopes })
                        .then((loginResponse) => {
                            // Set it the active account and state to AUTHENTICATED
                            msalInstance.current.setActiveAccount(
                                loginResponse.account
                            );
                            setAuthenticationState(
                                AuthenticationState.AUTHENTICATED
                            );
                        })
                        .catch((silentError) => {
                            // Nope; gotta get the user to interact with MSAL
                            if (
                                silentError instanceof
                                InteractionRequiredAuthError
                            ) {
                                setAuthenticationState(
                                    AuthenticationState.NEEDS_LOGIN_CONSENT
                                );
                            } else {
                                setErrorType(ErrorType.ERROR);
                                setAuthenticationState(
                                    AuthenticationState.ERROR
                                );
                            }
                        });
                } else {
                    setErrorType(ErrorType.ERROR);
                    setAuthenticationState(AuthenticationState.ERROR);
                }
            });
    };

    // Check if a token is alreay stored in local/session storage from a previous interaction
    useEffect(() => {
        attemptSilentLogin();
    }, []);

    const startPopupLogin = () => {
        // Trigger popup login / auth
        msalInstance.current
            .loginPopup({ scopes })
            .then((loginResponse) => {
                // Set it the active account and state to AUTHENTICATED
                msalInstance.current.setActiveAccount(loginResponse.account);
                setAuthenticationState(AuthenticationState.AUTHENTICATED);
            })
            .catch((popupError) => {
                // Check the cause of the error to set the errorType correctly
                if (popupError.errorCode === 'access_denied') {
                    // User has denied the scope access to the app
                    setErrorType(ErrorType.SCOPE_ERROR);
                } else if (
                    popupError.errorCode === 'user_cancelled' ||
                    popupError.errorCode === 'popup_window_error'
                ) {
                    // User closed the popup or it couldnt open due to browser settings
                    setErrorType(ErrorType.POPUP_ERROR);
                } else {
                    // Set basic auth error
                    setErrorType(ErrorType.AUTH_ERROR);
                }
                setAuthenticationState(AuthenticationState.ERROR);
            });
    };

    const getAccessToken = () => {
        return new Promise((resolve, reject) => {
            // Either re-use a valid token from the sessionStorage or silently request a new token
            msalInstance.current
                .acquireTokenSilent({ scopes })
                .then((tokenResponse) => {
                    // Resolve with silent token
                    resolve(tokenResponse.accessToken);
                })
                .catch((tokenRequestError) => {
                    // Silent request has failed, requires user interaction (possibly scope approvals)
                    if (
                        tokenRequestError instanceof
                        InteractionRequiredAuthError
                    ) {
                        setErrorType(ErrorType.AUTH_ERROR);
                        setAuthenticationState(
                            AuthenticationState.NEEDS_LOGIN_CONSENT
                        );
                    } else {
                        // Set general error
                        setErrorType(ErrorType.ERROR);
                        // Reject the promise
                        reject(errorType);
                    }
                });
        });
    };

    const retryAuthentication = () => {
        if (authenticationState === AuthenticationState.ERROR) {
            // Trigger auth process again
            setAuthenticationState(AuthenticationState.NOT_STARTED);
            setErrorType(null);
            attemptSilentLogin();
        }
    };

    return {
        authenticationState,
        retryAuthentication,
        errorType,
        getAccessToken,
        loginButton: (
            <div className="microsoft-auth">
                <button
                    aria-label="Sign in with Microsoft"
                    type="button"
                    onClick={startPopupLogin}
                    className="microsoft-auth__action"
                />
            </div>
        ),
        requestAuthorisationConsent: startPopupLogin,
    };
}
