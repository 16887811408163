/* eslint-disable max-classes-per-file */
export default function _iconViewer() {
    /**
     * Icon Viewer wrapper selector
     * @const {string}
     */
    const ICON_VIEWER_SELECTOR = '[data-component="icon-viewer"]';

    /**
     * Icon List selector
     * @const {string}
     */
    const ICON_VIEWER_LIST_SELECTOR = '[data-component="icon-viewer-list"]';

    /**
     * Custom Icon List selector
     * @const {string}
     */
    const ICON_VIEWER_CUSTOM_LIST_SELECTOR =
        '[data-component="icon-viewer-custom-list"]';

    /**
     * Selector for icon html template
     * @const {string}
     */
    const ICON_VIEWER_ICON_TEMPLATE = 'template[id="icon-viewer-icon"]';

    /**
     * Icon Name selector
     * @const {string}
     */
    const ICON_VIEWER_ICON_NAME_SELECTOR =
        '[data-component="icon-viewer-icon-name"]';

    /**
     * Icon copy button selector
     * @const {string}
     */
    const ICON_VIEWER_ICON_COPY_SELECTOR =
        '[data-component="icon-viewer-copy"]';

    /**
     * Icon copy button selector
     * @const {string}
     */
    const ICON_VIEWER_ICON_COPYED_MESSAGE_SELECTOR =
        '[data-component="icon-viewer-copied-message"]';

    /**
     * Create a new IconViewer
     * @param {HTMLElement} iconViewer - The wrapping HTML element of the IconViewer
     * @class
     */
    class IconViewer {
        constructor(iconViewer) {
            this.iconViewer = iconViewer;

            this.iconTemplate = iconViewer.querySelector(
                ICON_VIEWER_ICON_TEMPLATE
            ).content.firstElementChild;

            this.init();
        }

        /**
         * Initialises the icon list by fetching all loaded SVGs available to
         * be <use>'d in the page body and lists them to the user.
         */
        init() {
            const icons = this.getStandardIcons();
            const list = this.iconViewer.querySelector(
                ICON_VIEWER_LIST_SELECTOR
            );

            icons.forEach((icon) => {
                const id = icon.getAttribute('id');
                const iconDom = this.createIcon(id, id);

                list.appendChild(iconDom);
            });

            const custom = this.getCustomIcons();
            const customList = this.iconViewer.querySelector(
                ICON_VIEWER_CUSTOM_LIST_SELECTOR
            );

            custom.forEach((icon) => {
                const id = icon.getAttribute('id');
                const iconDom = this.createIcon(id, id);

                customList.appendChild(iconDom);
            });
        }

        /**
         * Get all icons that have been loaded into the page body for re-use using the
         * SVG <use> tag. In dev these are loaded via webpack into the body, im prod / Matrix
         * these are loaded from a sprite map generated by the build process and may have additional
         * customer added entries.
         */
        getStandardIcons() {
            // Manual upload method
            const iconMap = document.querySelectorAll(
                'div[data-component="icon-map"] symbol[id]'
            );
            if (iconMap.length > 0) {
                return iconMap;
            }
            // Webpack bundled upload method
            const iconSprites = document.querySelectorAll(
                '#__SVG_SPRITE_NODE__ symbol[id]'
            );
            return iconSprites;
        }

        /**
         * Gets any custom icons added by a client to the icon map
         *
         * @returns Array of custom icon nodes
         */
        getCustomIcons() {
            return document.querySelectorAll(
                'div[data-component="custom-icons"] svg[id]'
            );
        }

        /**
         * Create a new DOM element based on the supplied HTML template to create
         * each icon in the icon list.
         *
         * @param {String} name - name of the icon for <use>
         * @param {String} title  - title of the icon for <title>
         * @returns DOMElement
         */
        createIcon(name, title) {
            const icon = this.iconTemplate.cloneNode(true);

            icon.querySelector('use').setAttribute('href', `#${name}`);
            icon.querySelector('title').appendChild(
                document.createTextNode(title)
            );
            icon.querySelector(ICON_VIEWER_ICON_NAME_SELECTOR).appendChild(
                document.createTextNode(title)
            );
            icon.querySelector(ICON_VIEWER_ICON_COPY_SELECTOR).addEventListener(
                'click',
                (e) => this.copyIconId(e)
            );

            return icon;
        }

        /**
         * Click handler for clicking on an icon, copies the value from the icons
         * <use> tag into the clipboard.
         *
         * @param {Event} e - event object
         */
        copyIconId(e) {
            // To copy to clipboard you have to select then execute a copy command
            const { target } = e;
            const el = document.createElement('textarea');
            el.value = target
                .querySelector('use')
                .getAttribute('href')
                .substring(1);
            target.appendChild(el);
            el.select();
            document.execCommand('copy');
            target.removeChild(el);

            // Add class to copied message to show it
            this.iconViewer
                .querySelector(ICON_VIEWER_ICON_COPYED_MESSAGE_SELECTOR)
                .classList.add('icon-viewer__copied-message--active');

            // If any existing timeout is happening, kill it and reset the message to base state
            if (this.timeout) {
                this.iconViewer
                    .querySelector(ICON_VIEWER_ICON_COPYED_MESSAGE_SELECTOR)
                    .classList.remove('icon-viewer__copied-message--hiding');
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                // After 2 seconds start fading the message out
                this.iconViewer
                    .querySelector(ICON_VIEWER_ICON_COPYED_MESSAGE_SELECTOR)
                    .classList.add('icon-viewer__copied-message--hiding');

                this.timeout = setTimeout(() => {
                    // After one second hide the message from screen and screen readers
                    this.iconViewer
                        .querySelector(ICON_VIEWER_ICON_COPYED_MESSAGE_SELECTOR)
                        .classList.remove(
                            'icon-viewer__copied-message--active'
                        );
                    this.iconViewer
                        .querySelector(ICON_VIEWER_ICON_COPYED_MESSAGE_SELECTOR)
                        .classList.remove(
                            'icon-viewer__copied-message--hiding'
                        );
                    this.timeout = undefined;
                }, 1000);
            }, 2000);
        }
    }

    // Find all IconViewer and created handler objects
    const iconViewers = document.querySelectorAll(ICON_VIEWER_SELECTOR);
    iconViewers.forEach((iconViewer) => {
        // Create a new item to modify
        const DOMItem = iconViewer;
        if (!DOMItem.iconViewer) {
            DOMItem.iconViewer = new IconViewer(iconViewer);
        }
    });
}

_iconViewer();
