import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useScript from '../../useScript';
import './useGoogleApi.scss';

const GOOGLE_API_URL = 'https://apis.google.com/js/api.js';
const GOOLE_IDENTITY_URL = 'https://accounts.google.com/gsi/client';
export const AuthenticationState = Object.freeze({
    NOT_STARTED: Symbol('NOT_STARTED'),
    READY_FOR_AUTH: Symbol('READY_FOR_AUTH'),
    NEEDS_LOGIN_CONSENT: Symbol('NEEDS_LOGIN_CONSENT'),
    AUTHENTICATED: Symbol('AUTHENTICATED'),
    NEED_REFRESH: Symbol('NEED_REFRESH'),
    ERROR: Symbol('ERROR'),
});

export const ErrorType = Object.freeze({
    AUTH_ERROR: Symbol('AUTH_ERROR'),
    SCOPE_ERROR: Symbol('SCOPE_ERROR'),
    ERROR: Symbol('ERROR'),
});

/**
 * Authenticate into Google API services
 * @param { Array } discoveryDocs - Array of Google discovery api paths
 * @param { String } scope - String of Google api scopes, comma delimited
 * @param { String } apiKey - (optional) Google api key
 * @param { String } clientId - Client id of Google App using the services
 * @param { String } tokenStorageId - Sets the token storage id
 */
export default function useGoogleApi({
    discoveryDocs,
    scope,
    apiKey,
    clientId,
    tokenStorageId,
}) {
    const [isAuthenticated, setIsAuthenticated] = useState(
        AuthenticationState.NOT_STARTED
    );
    const [errorType, setErrorType] = useState(null);
    const tokenClient = useRef(null);

    // Load the needed google APIs
    const googleApi = useScript(GOOGLE_API_URL);
    const googleIdentifyApi = useScript(GOOLE_IDENTITY_URL);

    // Wait for the needed Google APIs to be loaded then start the auth process
    useEffect(() => {
        // Once both google api and identitfy api are loaded
        if (googleApi === 'ready' && googleIdentifyApi === 'ready') {
            // Load the 'client' sub function of the gapi
            window.gapi.load('client', async () => {
                // Initalise the google api with our key and resources we need access to
                await window.gapi.client.init({
                    apiKey,
                    discoveryDocs,
                });

                setIsAuthenticated(AuthenticationState.READY_FOR_AUTH);
            });
        }
    }, [googleApi, googleIdentifyApi]);

    /**
     * This is split out into another function to be called just to make testing easier
     * @param { Boolean } userHasApprovedScope - Indication if user has approved scope before (skips login button step); recorded in an external resource
     */
    const authenticate = ({ userHasApprovedScope = false }) => {
        // If APIs are ready to start auth process or we need to do a refresh
        if (
            isAuthenticated === AuthenticationState.READY_FOR_AUTH ||
            isAuthenticated === AuthenticationState.NEED_REFRESH
        ) {
            // Grab an existing token to set
            const accessToken = window.sessionStorage.getItem(tokenStorageId);
            if (accessToken) {
                // Use existing token
                window.gapi.auth.setToken({ access_token: accessToken });
                // Assume its valid until it fails
                setIsAuthenticated(AuthenticationState.AUTHENTICATED);
            } else {
                // Get an authorisation token client to request access
                tokenClient.current =
                    window.google.accounts.oauth2.initTokenClient({
                        client_id: clientId,
                        scope,
                        callback: (tokenResponse) => {
                            // If an error occurs set component into error state
                            if (tokenResponse.error !== undefined) {
                                setErrorType(ErrorType.AUTH_ERROR);
                                setIsAuthenticated(AuthenticationState.ERROR);
                                return;
                            }

                            // 'hasGrantedAllScopes' function fails if you provide it an empty third argument so we need to
                            // run it differently based on the number of scopes we are checking,
                            let areScopesApproved = false;
                            if (scope.indexOf(',') === -1) {
                                areScopesApproved =
                                    window.google.accounts.oauth2.hasGrantedAllScopes(
                                        tokenResponse,
                                        scope
                                    );
                            } else {
                                const scopes = scope.split(',');
                                const firstScope = scopes[0]; // First scope is passed on its own
                                scopes.shift(); // Drop the first item from the array

                                areScopesApproved =
                                    window.google.accounts.oauth2.hasGrantedAllScopes(
                                        tokenResponse,
                                        firstScope,
                                        scopes
                                    );
                            }

                            // Check if we have been granted all the requested scopes
                            if (areScopesApproved) {
                                window.sessionStorage.setItem(
                                    tokenStorageId,
                                    tokenResponse.access_token
                                );

                                setIsAuthenticated(
                                    AuthenticationState.AUTHENTICATED
                                );
                            } else {
                                setErrorType(ErrorType.SCOPE_ERROR);
                                setIsAuthenticated(AuthenticationState.ERROR);
                            }
                        },
                    });

                // If we only need a refresh as there was an old token we can load without requesting consent again
                if (
                    userHasApprovedScope ||
                    isAuthenticated === AuthenticationState.NEED_REFRESH
                ) {
                    // This will automatically add the credentials into the gapi once done
                    tokenClient.current.requestAccessToken({ prompt: '' });
                } else {
                    // otherwise we need to prompt the user to authenticate the app and approve the needed resources
                    setIsAuthenticated(AuthenticationState.NEEDS_LOGIN_CONSENT);
                }
            }
        }
    };

    const retryAuthentication = ({ userHasApprovedScope }) => {
        if (isAuthenticated === AuthenticationState.ERROR) {
            // Trigger auth process again
            setIsAuthenticated(AuthenticationState.READY_FOR_AUTH);
            setErrorType(null);
            authenticate({ userHasApprovedScope });
        }
    };

    const requestAuthorisationConsent = (...args) => {
        // If a user has popups blocked this will fail at this point
        try {
            // Prompt the user to select a Google Account and ask for consent to share their data when establishing a new session.
            // This will automatically add the credentials into the gapi once done
            tokenClient.current.requestAccessToken(...args);
        } catch (e) {
            // If an error occurs set component into error state
            setErrorType(ErrorType.ERROR);
            setIsAuthenticated(AuthenticationState.ERROR);
        }
    };

    /**
     * Check the response from a gapi.client request for token expiration errors
     * @param { Promise } promise - gapi.client request promise
     */
    const checkTokenState = (promise) => {
        return promise.catch((error) => {
            // Check if token is expired
            if (error.status === 401) {
                // Clear token from storage
                window.sessionStorage.removeItem(tokenStorageId);
                // Set state to reload the token without prompt
                setIsAuthenticated(AuthenticationState.NEED_REFRESH);
            } else {
                throw error;
            }
        });
    };

    return {
        authenticationState: isAuthenticated,
        authenticate,
        retryAuthentication,
        checkTokenState,
        loginButton: (
            <div className="google-api-auth">
                <button
                    type="button"
                    onClick={requestAuthorisationConsent}
                    className="google-api-auth__action"
                    aria-label="Sign in with Google"
                />
            </div>
        ),
        requestAuthorisationConsent,
        errorMessage: (
            <div className="google-api-auth">
                <div className="google-api-auth__error">
                    Something has gone wrong. Most likely popups have been
                    blocked or access permissions denied, please allow to enable
                    login to Google Drive recent documents.
                </div>
            </div>
        ),
        errorType,
    };
}

useGoogleApi.propTypes = {
    authenticateState: PropTypes.symbol, // Current authentication status
    authenticate: PropTypes.func, // Function to start authentication process once ready
    checkTokenState: PropTypes.func, // Wrapper for API requests to check for expired / invalid tokens
    loginButton: PropTypes.element, // Default login button to request consent
    requestAuthorisationConsent: PropTypes.func, // Function to trigger consent process if 'login button' is custom implementation
    errorMessage: PropTypes.element, // Error message to show on error
    errorType: PropTypes.symbol, // Error type indicating cause of error state
};
