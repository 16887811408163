import whenKey from 'ally.js/src/when/key';
import {
    NAV_ITEM_ACTIVE,
    MENU_EXPANDER_SELECTOR,
} from '../../primary-nav/js/global';

export default function _megaMenu() {
    /**
     * Mega menu selector
     * @const {string}
     */
    const MEGA_MENU_SELECTOR = '[data-component="mega-menu"]';

    /**
     * Mega menu list selector
     * @const {string}
     */
    const MEGA_MENU_LIST_SELECTOR = '.mega-menu__nav-list';

    /**
     * Mega menu item selector
     * @const {string}
     */
    const MEGA_MENU_ITEM_SELECTOR = '.mega-menu__nav-item';

    /**
     * Mega menu item has child class
     * @const {string}
     */
    const MEGA_MENU_ITEM_HAS_CHILD = 'mega-menu__nav-item--has-children';

    /**
     * Mega menu element active class
     * @const {string}
     */
    const MEGA_MENU_ACTIVE = 'mega-menu-wrapper--menu-active';

    /**
     * Mega menu nav item active class
     * @const {string}
     */
    const MEGA_MENU_NAV_ITEM_ACTIVE = 'mega-menu__nav-item--active';

    /**
     * Mega menu expand selector
     * @const {string}
     */
    const MEGA_MENU_EXPAND_SELECTOR = '.mega-menu__nav-expand';

    /**
     * Mega menu double teir element selector
     * @const {string}
     */
    const MEGA_MENU_TEIR_1_LEVEL_SELECTOR = '.mega-menu__nav-list--level-1';

    /**
     * Mega menu first level list element class
     * @const {string}
     */
    const MEGA_MENU_LEVEL_ONE = 'mega-menu__nav-item--level-1';

    /**
     * Mega menu second level list element selector
     * @const {string}
     */
    const MEGA_MENU_TEIR_2_LEVEL_SELECTOR = '.mega-menu__nav-list--level-2';

    /**
     * Mega menu second level list element class
     * @const {string}
     */
    const MEGA_MENU_LEVEL_TWO = 'mega-menu__nav-item--level-2';

    /**
     * Mega menu second level empty class
     * @const {string}
     */
    const MEGA_MENU_TEIR_2_EMPTY = 'mega-menu-wrapper--level-2-empty';

    /**
     * Create a new Mega Menu
     * @param {HTMLElement} MegaMenu - The HTML element of the mega menu
     * @class
     */
    class MegaMenu {
        constructor(megaMenu) {
            this.megaMenu = megaMenu;

            this.allyHandles = {
                keyHandle: undefined,
            };

            this.allyHandles.keyHandle = whenKey({
                escape: () => {
                    setTimeout(this.closeMenu());
                },
            });

            // Attach expantion handlers
            const menuExpanders = megaMenu.querySelectorAll(
                MEGA_MENU_EXPAND_SELECTOR
            );

            // Check if the second teir is empty so the width can be set correctly
            if (menuExpanders.length === 0) {
                megaMenu.classList.add(MEGA_MENU_TEIR_2_EMPTY);
            }

            menuExpanders.forEach((expander) => {
                const navItem = expander.closest(MEGA_MENU_ITEM_SELECTOR);
                expander.addEventListener('click', () => {
                    this._expandMenu(navItem);
                    this._toggleAria(navItem);
                });

                navItem.addEventListener('mouseover', (e) => {
                    e.stopPropagation();
                    this._clearAriaExpand();
                    this._expandMenu(navItem);
                });

                navItem.addEventListener('mouseleave', () => {
                    this._clearAriaExpand();
                    this._removeHoverActive(navItem);
                });
            });
        }

        closeMenu() {
            // Is the current mega menu open
            const openMegaMenu = this.megaMenu.closest(`.${NAV_ITEM_ACTIVE}`);
            this._clearAriaExpand();

            if (openMegaMenu) {
                // Does the currently open mega menu have focus within
                const currentFocus = document.activeElement;
                const isMenuInFocus = this.megaMenu.contains(currentFocus);

                // Close the menu
                openMegaMenu.classList.remove(NAV_ITEM_ACTIVE);

                if (isMenuInFocus) {
                    // Reset the focus to the menu expander button
                    openMegaMenu.querySelector(MENU_EXPANDER_SELECTOR).focus();
                }
            }
        }

        /**
         * Function to open the sub navigation when item is hovered or actioned.
         * Dynamically adjusts the height of the parent container to fit the full body
         * of the third menu tier if it is larger than the default
         *
         * @param {Element} navigationItem - Navigation item that was hovered or the closed nav item to the button that was clicked
         * @param {boolean} clickedOpen - If this function is being called from a button slick to open (true) or a hover (false)
         */
        _expandMenu(navigationItem) {
            const navItem = navigationItem;
            const isActive = navItem.classList.contains(
                MEGA_MENU_NAV_ITEM_ACTIVE
            );

            // Close other siblings when opening a new menu
            if (!navItem.classList.contains(MEGA_MENU_NAV_ITEM_ACTIVE)) {
                // Remove active from any siblings
                const allItems = navItem.classList.contains(MEGA_MENU_LEVEL_ONE)
                    ? navigationItem
                          .closest(MEGA_MENU_TEIR_1_LEVEL_SELECTOR)
                          .querySelectorAll(MEGA_MENU_ITEM_SELECTOR)
                    : navigationItem
                          .closest(MEGA_MENU_TEIR_2_LEVEL_SELECTOR)
                          .querySelectorAll(MEGA_MENU_ITEM_SELECTOR);

                allItems.forEach((item) => {
                    item.classList.remove(MEGA_MENU_NAV_ITEM_ACTIVE);
                });
            }

            // Add active class
            if (isActive) {
                navItem.classList.remove(MEGA_MENU_NAV_ITEM_ACTIVE);
            } else {
                navItem.classList.add(MEGA_MENU_NAV_ITEM_ACTIVE);
            }

            // Calculate largest height of active lists
            const currentMegaMenu = navItem.closest(MEGA_MENU_SELECTOR);
            const secondLevelHeight = navItem.classList.contains(
                MEGA_MENU_LEVEL_TWO
            )
                ? navItem.closest(MEGA_MENU_LIST_SELECTOR).clientHeight
                : 0;

            const megaMenuHeight = Math.max(
                navItem.querySelector(MEGA_MENU_LIST_SELECTOR).clientHeight,
                secondLevelHeight
            );

            currentMegaMenu.style.setProperty(
                '--subNavHeightNeeded',
                `${megaMenuHeight}px`
            );

            // Add active class to container
            if (navItem.classList.contains(MEGA_MENU_ITEM_HAS_CHILD)) {
                currentMegaMenu.classList.add(MEGA_MENU_ACTIVE);
            }
        }

        _toggleAria(navigationItem) {
            const buttonExpander = navigationItem.querySelector(
                MEGA_MENU_EXPAND_SELECTOR
            );

            // If escape was pressed we will set expanded to false.
            const expanded =
                buttonExpander.getAttribute('aria-expanded') === 'false';

            // Clear aria elsewhere, except things in the parent chain
            this._clearAriaExpand({
                excludeParents: true,
                current: navigationItem,
            });

            buttonExpander.setAttribute('aria-expanded', `${expanded}`);
        }

        _clearAriaExpand(
            { excludeParents, current } = { excludeParents: false }
        ) {
            let expanded = this.megaMenu.querySelectorAll(
                '[aria-expanded="true"]'
            );
            if (excludeParents && expanded.length > 0) {
                expanded = [...expanded].filter((element) => {
                    const parentItem = element.closest(MEGA_MENU_ITEM_SELECTOR);
                    return !parentItem.contains(current);
                });
            }
            expanded.forEach((expand) => {
                expand.setAttribute('aria-expanded', false);
            });
        }

        /**
         * Function handles removing active class added on hover when hover is lost
         *
         * @param {Element} navItem - Navigation item that has lost hover
         */
        _removeHoverActive(navItem) {
            const activeMegaMenu = navItem.closest(MEGA_MENU_SELECTOR);
            activeMegaMenu.classList.remove(MEGA_MENU_ACTIVE);
            navItem.classList.remove(MEGA_MENU_NAV_ITEM_ACTIVE);
        }
    }

    // Finall all mega menus and created handler objects
    const megaMenus = document.querySelectorAll(MEGA_MENU_SELECTOR);
    megaMenus.forEach((megaMenu) => {
        // Create a new item to modify
        const DOMItem = megaMenu;

        // Attach the megamenu instance to our DOM Item
        if (!DOMItem.megaMenu) {
            DOMItem.megaMenu = new MegaMenu(megaMenu);
        }
    });
}

_megaMenu();
