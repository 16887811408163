/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */
import { BaseUserAdapter } from '../BaseUserAdapter/BaseUserAdapter';

// eslint-disable-next-line import/prefer-default-export
export class DatastoreUserAdapter extends BaseUserAdapter {
    constructor(dto) {
        super();
        if (!dto) {
            throw new Error(
                `dto is required when instantiating a new DatastoreUserAdapter object`
            );
        }

        [`datastore`].forEach((name) => {
            if (!dto[name]) {
                throw new Error(
                    `${name} property is required when instantiating a new UserService object`
                );
            }
        });

        this.collectionName = `student`;
        this.datastore = dto.datastore;
    }

    /**
     * Get user object.
     *
     * @param {string} userId User id.
     *
     * @returns {Promise<object>} The result of the datastore query for a user.
     */
    get(userId) {
        return this.datastore
            .collection(this.collectionName)
            .doc(userId)
            .get()
            .then((response) => response);
    }

    /**
     * Update an user with new values.
     *
     * @param {object} user The user object.
     * @param {string} documentId The user document id.
     *
     * @returns {Promise<object>}The result of the datastore update query.
     */
    setAll(user, documentId) {
        return this.datastore
            .collection(this.collectionName)
            .doc(documentId)
            .update(JSON.stringify(user));
    }
}
