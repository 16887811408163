/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import React, { useState, useCallback } from 'react';
import getStudent from 'Edify/js/getStudentMemoized';
import UserDetailsContext from './UserDetailsContext';

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [hasError, setHasError] = useState(false);

    /**
     * Async function that gets the user details and sets them as the context
     * @returns {Object} Returns the student object
     */
    const getUserDetails = useCallback(async () => {
        setIsLoading(true);

        try {
            const studentQuery = await getStudent(datastore);

            const { lmsStudentid, firstName, lastName } = studentQuery;

            setUserDetails({
                lmsStudentid,
                firstName,
                lastName,
            });
        } catch (e) {
            setHasError(true);
            throw new Error(e);
        }

        setIsLoading(false);
    }, []);

    return (
        <UserDetailsContext.Provider
            value={{
                isLoading,
                userDetails,
                getUserDetails,
                hasError,
            }}
        >
            {children}
        </UserDetailsContext.Provider>
    );
};
