import { setCookie, getCookie } from 'PlugAndPlay/_global/js/cookieHelper';

export default function _cookieBanner() {
    /**
     * Banner wrapper selector.
     * @const {string}
     */
    const BANNER_SELECTOR = '[data-component="cookie-banner"]';

    /**
     * Banner close button.
     * @const {string}
     */
    const CLOSE_BTN = '[data-pnp-element="close-btn"]';

    /**
     * How many days dismissal will be stored in cookie.
     * @const {number}
     */
    const DAYS_TO_EXPIRY = 7;

    const closeBtn = document.querySelector(CLOSE_BTN);
    const banner = document.querySelector(BANNER_SELECTOR);

    const bannerDismissed = getCookie('cookie_banner_dismissed');

    if (!closeBtn || !banner) {
        return;
    }

    if (bannerDismissed === null) {
        banner.hidden = false;
    }

    /* Hide banner and sets cookie when user clicks on close button */
    closeBtn.addEventListener('click', () => {
        banner.hidden = true;

        setCookie('cookie_banner_dismissed', 'true', DAYS_TO_EXPIRY);
    });
}

_cookieBanner();
