/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Credits Application context.
 * @export
 */
const CreditContext = createContext({
    isLoading: false,
    balances: {},
    transactions: {},
    getBalances: null,
    getTransactions: null,
});

CreditContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        balances: PropTypes.objectOf(PropTypes.string),
        transactions: PropTypes.objectOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                    amount: PropTypes.string.isRequired,
                    date: PropTypes.instanceOf(Date),
                })
            )
        ),
        getBalances: PropTypes.func,
        getTransactions: PropTypes.func,
    }),
};

export default CreditContext;
