/* eslint-disable no-unused-expressions */
import { setCookie, getCookie } from '../../_global/js/cookieHelper';
import { Modal } from '../../modal/js/global';

export default function _addToHomeModal() {
    /**
     * Banner wrapper selector.
     * @const {string}
     */
    const MODAL_SELECTOR = '[data-component="add-to-home-modal"]';

    /**
     * How many days dismissal will be stored in cookie.
     * @const {number}
     */
    const DAYS_TO_EXPIRY = 7;

    // Modal selector.
    const modal = document.querySelector(MODAL_SELECTOR);

    // Dismissal cookie
    const modalDismissed = getCookie('pwa_install_modal_dismissed');

    // No modal no go.
    if (!modal) {
        return;
    }

    // Delay to display modal.
    const DELAY_TIME = modal.dataset.popupDelay || 5000;

    // Detects if device is on iOS
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    // Detect if iPad pro
    const isIpadOS = () =>
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform);

    // Detects if device is already in standalone mode
    const isInStandaloneMode = () =>
        'standalone' in window.navigator && window.navigator.standalone;

    // Checks if we should display install modal.
    if (
        (isIos() || isIpadOS()) &&
        !isInStandaloneMode() &&
        modalDismissed === null
    ) {
        modal.modalInstance = modal.modalInstance || new Modal(modal);
        // Give a little time to look around before attacking with modal.
        setTimeout(() => modal.modalInstance.openHandler(), DELAY_TIME * 1);

        // Listens for modal event, and fire our action when close event occur.
        modal.modalInstance &&
            modal.modalInstance.eventsRelay.addEventListener(
                'pnp-modal-event',
                (e) => {
                    const {
                        detail: { eventType },
                    } = e;

                    if (eventType !== 'close') {
                        return;
                    }

                    setCookie(
                        'pwa_install_modal_dismissed',
                        'true',
                        DAYS_TO_EXPIRY
                    );
                }
            );
    }
}

_addToHomeModal();
