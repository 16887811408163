import getAuth from './getAuth';

const getStudentFromResults = (students) => students[0];

/**
 * Memoized version of getStudents so that as long as we are getting the same student we aren't hitting datastore multiple times
 */
export const student = {
    cache: {},
    clearMemoization() {
        this.cache = {};
    },
    getStudentMemoized() {
        return async (datastore) => {
            const sisStudentid = await getAuth({ datastore });

            // If there is no sisStudentid in the payload then we won't get a student back from a datastore call so skip straight to resolving the empty array
            if (!sisStudentid) {
                return Promise.resolve({});
            }

            // Get the student sis id in a format that we can store the result agains
            const argsString = JSON.stringify(sisStudentid);

            // If this already exists in the cache let's return that
            if (this.cache[argsString]) {
                return this.cache[argsString].then(getStudentFromResults);
            }

            // actually get the student here
            const result = datastore
                .collection(`students`)
                .where(`sisStudentid`, `===`, sisStudentid)
                .get();

            // Store the result in the cache
            this.cache[argsString] = result;

            // Return the result
            return result.then(getStudentFromResults);
        };
    },
};

const getStudent = student.getStudentMemoized();

export default getStudent;
