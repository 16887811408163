import React, { useState, useEffect, useCallback } from 'react';

import GoogleDriveAuthScopeContext from './GoogleDriveAuthScopeContext';

export default ({ localStorageKey, children }) => {
    const [hasScopeBeenApproved, setHasScopeBeenApproved] = useState(null);

    useEffect(() => {
        setHasScopeBeenApproved(
            Boolean(window.localStorage.getItem(localStorageKey))
        );
    }, []);

    const handleSetHasScopeBeenApproved = useCallback((scopeApproved) => {
        window.localStorage.setItem(localStorageKey, scopeApproved);
        setHasScopeBeenApproved(scopeApproved);
    }, []);

    return (
        <GoogleDriveAuthScopeContext.Provider
            value={{
                isLoading: false,
                hasScopeBeenApproved,
                setHasScopeBeenApproved: handleSetHasScopeBeenApproved,
            }}
        >
            {children}
        </GoogleDriveAuthScopeContext.Provider>
    );
};
