import React, { useState, useEffect, useCallback } from 'react';

import getStudent from 'Edify/js/getStudentMemoized';
import GoogleDriveAuthScopeContext from './GoogleDriveAuthScopeContext';

export default ({ datastore, children }) => {
    const [hasScopeBeenApproved, setHasScopeBeenApproved] = useState(null);

    useEffect(() => {
        getStudent(datastore).then((student) => {
            setHasScopeBeenApproved(
                student.hasAuthorisedGoogleDocuments === true
            );
        });
    }, []);

    const handleSetHasScopeBeenApproved = useCallback((scopeApproved) => {
        getStudent(datastore).then((student) => {
            datastore
                .collection(`students`)
                .doc(student.studentid)
                .update({
                    hasAuthorisedGoogleDocuments: scopeApproved,
                })
                .then((updatedStudent) => {
                    setHasScopeBeenApproved(
                        updatedStudent.hasAuthorisedGoogleDocuments
                    );
                });
        });
    }, []);

    return (
        <GoogleDriveAuthScopeContext.Provider
            value={{
                isLoading: false,
                hasScopeBeenApproved,
                setHasScopeBeenApproved: handleSetHasScopeBeenApproved,
            }}
        >
            {children}
        </GoogleDriveAuthScopeContext.Provider>
    );
};
