/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { DatastoreUserAdapter } from 'PlugAndPlay/_data-layer/js/Adapters/UserAdapters/DatastoreUserAdapter/DatastoreUserAdapter';
import getJWT from 'Edify/js/getJwt';

// Lazy load the main Notifications 'app'
const NotificationSettings = React.lazy(() =>
    import(
        /* webpackChunkName: "NotificationSettings" */ './notification-settings'
    )
);

const appWrapper = document.querySelectorAll(
    '[data-component="notifications-settings"]'
);
if (appWrapper.length > 0) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            let datastore;
            const { dataset } = wrapper;
            const configuration = JSON.parse(
                dataset.notificationsConfiguration
            );

            let adapter;

            if (configuration.datasource === 'datastore') {
                datastore = new Datastore({
                    serviceURL: configuration.datasourceUrl,
                    jwtURL: configuration.datasourceJWT,
                });
            }

            if (configuration.datasource === 'edify') {
                datastore = new Datastore({
                    serviceURL: configuration.datasourceUrl,
                    jwtCallback: () => getJWT(configuration.datasourceJWT),
                });
            }

            if (datastore) {
                adapter = new DatastoreUserAdapter({ datastore });
            }

            ReactDOM.render(
                <Suspense
                    fallback={
                        <div className="notifications__skeleton">
                            Loading...
                        </div>
                    }
                >
                    {adapter ? (
                        <NotificationSettings
                            {...configuration}
                            userAdapter={adapter}
                        />
                    ) : (
                        <>
                            Adapter is not provided, please pass
                            datasource:datastore|mock into your component
                            configuration
                        </>
                    )}
                </Suspense>,
                wrapper
            );
        });
    });
}
