/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { useState } from 'react';

import { formatISO, formatDistance } from 'date-fns';

import uuidv4 from 'PlugAndPlay/_global/js/uuid';
import Modal from '../../../modal/jsx/modal';
import { useSocialCardModal } from './SocialCardCommon';

const SOCIAL_CARD_MAX_DESCRIPTION_LENGTH = 100;

export default function YoutubeSocialCard(args) {
    const {
        accountName,
        accountImage,
        createdDate,
        source,
        text,
        url,
        image,
        lazyLoadImages,
    } = args;

    const [isExpanded, setIsExpanded] = useState(false);
    const { openModalRef, modalOpenProps, isModalOpen, setIsModalOpen } =
        useSocialCardModal();

    let isTrimmed = false;
    let bodyText = text;
    if (bodyText.length > SOCIAL_CARD_MAX_DESCRIPTION_LENGTH && !isExpanded) {
        isTrimmed = true;
        bodyText = bodyText.slice(0, SOCIAL_CARD_MAX_DESCRIPTION_LENGTH);
    }

    const icon = 'external';

    return (
        <article className="social-card">
            <div
                {...modalOpenProps}
                role="button"
                tabIndex="0"
                aria-label="Open in modal"
                ref={openModalRef}
                className="social-card__open-modal"
            >
                <header className="social-card__header">
                    <div className="social-card__header-image">
                        <img
                            alt=""
                            src={accountImage}
                            loading={`${lazyLoadImages ? 'lazy' : ''}`}
                            className="social-card__account-image"
                        />
                    </div>
                    <div className="social-card__title">
                        <time
                            dateTime={formatISO(createdDate, {
                                representation: 'date',
                            })}
                            className="social-card__post-created"
                        >
                            {formatDistance(createdDate, new Date(), {
                                addSuffix: true,
                            })}
                        </time>
                        <div className="social-card__account-name">
                            {accountName}
                        </div>
                    </div>
                    <a
                        href={url}
                        target="_blank"
                        aria-label={`View at ${source} (Opens in new window)`}
                        rel="noopener noreferrer"
                        onClick={(event) => event.stopPropagation()}
                        className={`social-card__view-external social-card__view-external--${icon}`}
                    >
                        <svg className="svg-icon">
                            <use href={`#${icon}`} />
                        </svg>
                    </a>
                </header>
                <div className="social-card__body">
                    {bodyText}
                    {isTrimmed && (
                        <>
                            <span>...</span>
                            <button
                                type="button"
                                className="social-card__show-more"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsExpanded(true);
                                }}
                            >
                                show more
                            </button>
                        </>
                    )}

                    {image && (
                        <img
                            alt=""
                            src={image}
                            loading={`${lazyLoadImages ? 'lazy' : ''}`}
                            className="social-card__body-image"
                        />
                    )}
                </div>
            </div>
            {isModalOpen && (
                <YoutubeSocialCardModal
                    {...args}
                    handleEventModalClose={() => {
                        setIsModalOpen(false);
                    }}
                />
            )}
        </article>
    );
}

function YoutubeSocialCardModal({
    accountName,
    accountImage,
    createdDate,
    source,
    text,
    url,
    video,
    handleEventModalClose,
}) {
    const titleId = uuidv4(); // Modal needs a id for the dialog described by
    return (
        <Modal
            titleId={titleId}
            onClose={handleEventModalClose}
            className="social-card-modal"
        >
            <header className="social-card-modal__header">
                <div className="social-card-modal__header-image">
                    <img
                        alt=""
                        src={accountImage}
                        loading="lazy"
                        className="social-card-modal__account-image"
                    />
                </div>
                <div id={titleId} className="social-card-modal__title">
                    <time
                        dateTime={formatISO(createdDate, {
                            representation: 'date',
                        })}
                        className="social-card__post-created"
                    >
                        {formatDistance(createdDate, new Date(), {
                            addSuffix: true,
                        })}
                    </time>
                    <div className="social-card-modal__account-name">
                        {accountName}
                    </div>
                </div>
                <button
                    type="button"
                    onClick={handleEventModalClose}
                    aria-label="close modal"
                    className="social-card-modal__close-icon"
                >
                    <svg className="svg-icon">
                        <use href="#close" />
                    </svg>
                </button>
            </header>

            <div className="social-card-modal__body">
                {text}
                <div className="social-card-modal__video-embed-wrapper">
                    <iframe
                        width="853"
                        height="480"
                        src={video}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        className="social-card-modal__video-embed"
                    />
                </div>
            </div>

            <footer className="social-card-modal__footer">
                <a
                    href={url}
                    target="_blank"
                    className="social-card-modal__open-external-button"
                    rel="noreferrer"
                >
                    View on {source}
                </a>
            </footer>
        </Modal>
    );
}
