import { Collapse } from '../../collapsible/js/global';

export default function _accordion() {
    /**
     * Accordion wrapper selector
     * @const {string}
     */
    const ACCORDION_SELECTOR = '[data-component="accordion"]';

    /**
     * Accordion toggle all control
     * @const {string}
     */
    const ACCORDION_TOGGLE_ALL_EVENT = '[data-click="accordionToggleAll"]';

    /**
     * Accordion item selector
     * @const {string}
     */
    const ACCORDION_ITEM_SELECTOR = '.accordion-item';

    /**
     * Accordion item control selector
     * @const {string}
     */
    const ACCORDION_ITEM_CONTROL_SELECTOR = '.accordion-item__control';

    /**
     * Accordion item content selector
     * @const {string}
     */
    const ACCORDION_ITEM_CONTENT_SELECTOR = '.accordion-item__content';

    /**
     * Accordion item control selector event
     * @const {string}
     */
    const ACCORDION_ITEM_CONTROL_EVENT = '[data-click="accordionItemControl"]';

    /**
     * Accordion active/open class
     * @const {string}
     */
    const ACCORDION_ITEM_ACTIVE = 'accordion-item--active';

    /**
     * Accordion transition class when in collapsing state
     * @const {string}
     */
    const ACCORDION_ITEM_COLLAPSING = 'accordion-item__content--collapsing';

    /**
     * Accordion transition class when in expanding state
     * @const {string}
     */
    const ACCORDION_ITEM_EXPANDING = 'accordion-item__content--expanding';

    /**
     * Accordion item content open class
     * @const {string}
     */
    const ACCORDION_ITEM_OPEN = 'accordion-item__content--open';

    /**
     * Accordion item button open class
     * @const {string}
     */
    const ACCORDION_BUTTON_OPEN = 'accordion-item__control--open';

    /**
     * Create a new Accordion
     * @param {HTMLElement} accordion - The wrapping HTML element of the accordion
     * @class
     */
    class Accordion {
        constructor(accordion) {
            // Find the accordion up the DOM tree
            this.accordion = accordion;
            this.options = this.accordion.dataset;
            this.accordionItems =
                accordion.querySelectorAll(ACCORDION_ITEM_SELECTOR) || [];

            this.accordionItems.forEach((accordionItem) => {
                const control = accordionItem.querySelector(
                    ACCORDION_ITEM_CONTROL_EVENT
                );
                if (control) {
                    // Set the item as a collapse
                    // eslint-disable-next-line no-param-reassign
                    accordionItem.collapse = new Collapse();

                    // Set a colapse variable to make the rest of the setup a little tidier
                    const { collapse } = accordionItem;

                    // Define the collapse wrapper
                    collapse.collapseWrapper = accordionItem;

                    // Sets that this item should animate
                    collapse.shouldAnimate = true;

                    // Set the elements of the collapse
                    collapse.collapseButton = accordionItem.querySelector(
                        ACCORDION_ITEM_CONTROL_SELECTOR
                    );

                    // Set the collapse content
                    collapse.collapseContent = accordionItem.querySelector(
                        ACCORDION_ITEM_CONTENT_SELECTOR
                    );

                    // Set our collapse classes
                    collapse.buttonActiveClass = ACCORDION_BUTTON_OPEN;
                    collapse.contentOpenClass = ACCORDION_ITEM_OPEN;
                    collapse.contentExpandingClass = ACCORDION_ITEM_EXPANDING;
                    collapse.contentCollapsingClass = ACCORDION_ITEM_COLLAPSING;

                    // Add the event listener to the collapse
                    collapse.collapseButton.addEventListener('click', () => {
                        this.handleItemClick(collapse);
                    });
                }
            });

            // Open any items with the active class on them
            this.openActiveItems();

            // Attach toggle all click handler
            const accordionToggleAll = accordion.querySelector(
                ACCORDION_TOGGLE_ALL_EVENT
            );
            if (accordionToggleAll) {
                accordionToggleAll.addEventListener('click', () => {
                    this.handleToggleAllClick();
                });

                this.toggleAll = this.accordion.querySelector(
                    '.accordion__toggle-all'
                );
            }
        }

        /**
         * Event handler for accordion item click
         * @param {HTMLElement} item - The accordion item DOM Element\
         */
        handleItemClick(item) {
            // Dont run if an transition is current occuring on that item as it will cause an conflict in transitions
            if (item.isTransitionRunning) {
                return;
            }

            // Get the current item state so we dont toggle it open again if the user is trying to close it
            const currentItemState = item.collapseIsOpen;

            if (this.options.autoclose === 'true') {
                // Close everything except this element, so we dont multiple transitions at once
                this.closeAllItems(item.collapseWrapper);
            }

            // If the item is closed we can open it after closing all other items
            if (!currentItemState) {
                // Toggle the accordion open
                item.transitionItemOpen();
            } else {
                // Close the accordion
                item.transitionItemClosed();
            }

            // Toggle the active state on the wrapper
            if (item.collapseIsOpen) {
                item.collapseWrapper.classList.add(ACCORDION_ITEM_ACTIVE);
            } else {
                item.collapseWrapper.classList.remove(ACCORDION_ITEM_ACTIVE);
            }

            // If a toggle all button is enabled for this Accordion set its new state
            this.setToggleAllStatus(this.calculateNextToggleState());
        }

        /**
         * Handler for the opening and closing of all accordion items
         */
        handleToggleAllClick() {
            const toggleState = this.toggleAll.getAttribute('aria-checked');

            // Dont run if any transition is current occuring as it will cause an conflict in transitions
            if (
                Array.from(this.accordionItems).find(
                    (item) => item.collapse.isTransitionRunning
                )
            ) {
                return;
            }

            // If everything is closed, trigger an open
            if (toggleState === 'false') {
                this.openAllItems();
            } else {
                // Mixed or true state will both cause a full collapse
                this.closeAllItems();
            }
        }

        /**
         * Helper funtion to determine if the accordion state is all open, all closed or mixed
         */
        calculateNextToggleState() {
            // Get all controls in the accordion
            const accordionItems = Array.from(this.accordionItems).map(
                (item) => item.collapse
            );

            // Check if all the accordions are open
            const allOpen = accordionItems.every(
                (item) => item.isOpen === true
            );

            // Check if all the accordions are closed
            const allClosed = accordionItems.every(
                (item) => item.isOpen === false
            );

            // If all the accordions are open return allOpen
            if (allOpen) {
                return 'allOpen';
            }

            // If all the accordions are closed return allClosed
            if (allClosed) {
                return 'allClosed';
            }

            // The default return if neither of the above conditions are matched the accordions must be in a mixed state
            return 'mixed';
        }

        /**
         * Helper method to open all accordion item in the accordion
         */
        openAllItems() {
            this.accordionItems.forEach((item) => {
                item.collapse.transitionItemOpen();
                item.collapse.collapseWrapper.classList.add(
                    ACCORDION_ITEM_ACTIVE
                );
            });

            this.setToggleAllStatus('allOpen'); // Set toggle all to 'Collapse all'
        }

        /**
         * Helper method to open all accordion item in the accordion except the (optionally) provided item
         * @param {HTMLElement} exception - the element to be excluded from closing
         */
        closeAllItems(exception) {
            this.accordionItems.forEach((item) => {
                if (item.collapse.collapseWrapper !== exception) {
                    item.collapse.transitionItemClosed();
                    item.collapse.collapseWrapper.classList.remove(
                        ACCORDION_ITEM_ACTIVE
                    );
                }
            });

            if (!exception) {
                this.setToggleAllStatus('allClosed'); // Set toggle all to 'Expand all'
            } else {
                this.setToggleAllStatus('mixed'); // Set toggle all to 'mixed' if we have excluded an item from closing
            }
        }

        /**
         * Helper method to change the toggle all state: three states 'true', 'false' and 'mixed'
         * @param {string} state - The new state
         */
        setToggleAllStatus(toggleState) {
            // Check that the accordion has been setup with a toggle all button
            if (this.toggleAll) {
                // If the toggle state is all open
                if (toggleState === 'allOpen') {
                    // Set the toggle all button as aria checked value as true
                    this.toggleAll.setAttribute('aria-checked', true);
                    // Set the button text
                    this.toggleAll.textContent =
                        this.toggleAll.dataset.collapsetext;
                } else if (toggleState === 'mixed') {
                    // Mixed state will default to a collapse as the next action
                    this.toggleAll.setAttribute('aria-checked', 'mixed');
                    this.toggleAll.textContent =
                        this.toggleAll.dataset.collapsetext;
                } else {
                    // True state will both cause a full collapse
                    this.toggleAll.setAttribute('aria-checked', false);
                    this.toggleAll.textContent =
                        this.toggleAll.dataset.expandtext;
                }
            }
        }

        /**
         * Open the active elements
         * @method
         */
        openActiveItems() {
            this.accordionItems.forEach((item) => {
                // If the collapse has the active class the collapse should be open
                if (item.classList.contains(ACCORDION_ITEM_ACTIVE)) {
                    item.collapse.openElement();
                } else {
                    item.collapse.closeElement();
                }
            });
        }
    }

    // Find all accordions and created handler objects
    const accordions = document.querySelectorAll(ACCORDION_SELECTOR);
    accordions.forEach((accordion) => {
        // Create a new item to modify
        const DOMItem = accordion;

        // Attach the accordion instance to our DOM Item
        if (!DOMItem.accordion) {
            DOMItem.accordion = new Accordion(accordion);
        } else {
            DOMItem.accordion.openActiveItems();
        }
    });
}

_accordion();
