/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import React, { useState, useCallback } from 'react';
import CreditContext from './CreditContext';

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [balances, setBalances] = useState({});
    const [transactions, setTransactions] = useState({});

    /*
        Because of the datastore storage structure the id's returned from the getBalances
        call are required before a getTransactions request can be processed.

        By default getBalances is always called first in the application but this cannot
        be guaranteed (once modified by services) so this promise will allow the getTransactions
        call to 'wait' for the following getBalances call to happen first.
    */
    let balancePromiseResolve;
    const balancePromise = new Promise((resolve) => {
        balancePromiseResolve = resolve;
    });

    /**
     * Get all balance data.
     * @param {array[string]} [types] Specific balance types to retrieve
     * @returns {Promise} Object keyed to balance types
     */
    const getBalances = useCallback((types = []) => {
        setIsLoading(true);

        let query = datastore.collection(`balances`);

        if (types) {
            types.forEach((type, index) => {
                if (index === 0) {
                    query = query.where('type', '===', type);
                } else {
                    query = query.or('type', '===', type);
                }
            });
        }

        return query.get().then((results) => {
            // Save the results from Datastore for later as we need the id references
            setBalances(
                // Convert the response into the format the CreditContext expects
                results.reduce((accumulator, value) => {
                    accumulator[value.type] = `${value.amount}`;
                    return accumulator;
                }, {})
            );
            setIsLoading(false);

            // Resolve the balancePromise so if getTransactions is waiting it can run
            balancePromiseResolve(results);
        });
    }, []);

    /**
     * Get past transactions.
     * @param {array[string]} [types] Specific balance types to retrieve
     * @returns {Promise} Object keyed to balance types
     */
    const getTransactions = useCallback((types = []) => {
        // Wait for the balance request to be finished as we need the datastore ids
        balancePromise.then((balanceResults) => {
            setIsLoading(true);

            // Use Promise.all to wait for all the transaction type requests to be completed before setting the transactions all at once
            Promise.all(
                balanceResults
                    .filter((balance) => types.includes(balance.type)) // Filter down to those matching types
                    .map((balance) => {
                        const query = datastore
                            .collection(`balances`)
                            .doc(balance.id)
                            .collection(`transactions`);

                        // Return the promise for this balance type transactions requests, these will be collated by the Promise.all
                        return query.get().then((transactionResults) => {
                            // Process the results from datasore before returning the data to the Promise.all
                            return {
                                type: balance.type, // Store this as its needed for the CreditContext structure
                                results: transactionResults.map(
                                    // Convert the datastore string based dates in JS Date objects
                                    (transaction) => {
                                        return {
                                            ...transaction,
                                            amount: `${transaction.amount}`,
                                            date: new Date(transaction.date),
                                        };
                                    }
                                ),
                            };
                        });
                    })
            ).then((allTransactionResults) => {
                // Promise.all has completed here and we have an array of promise results
                setTransactions(
                    // Convert the response into the format the CreditContext expects
                    allTransactionResults.reduce((accumulator, value) => {
                        accumulator[value.type] = value.results;
                        return accumulator;
                    }, {})
                );
                setIsLoading(false);
            });
        });
    }, []);

    return (
        <CreditContext.Provider
            value={{
                isLoading,
                balances,
                getBalances,
                transactions,
                getTransactions,
            }}
        >
            {children}
        </CreditContext.Provider>
    );
};
