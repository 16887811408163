/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Calendar Application context.
 * @export
 */
const CalendarContext = createContext({
    isLoading: false,
    isError: false,
    events: [],
    getEvents: null,
});

CalendarContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        events: PropTypes.arrayOf(
            PropTypes.shape({
                // Base properties
                type: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                description: PropTypes.string,
                start: PropTypes.instanceOf(Date).isRequired,
                end: PropTypes.instanceOf(Date),
                duration: PropTypes.number,
                recurrence: PropTypes.arrayOf(PropTypes.string),
                url: PropTypes.string,

                // Assignment specific properties
                courseCode: PropTypes.string,
                courseName: PropTypes.string,
                submissionType: PropTypes.string,
            })
        ).isRequired,
        getEvents: PropTypes.func,
    }),
};

export default CalendarContext;
