/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';
import getJWT from 'Edify/js/getJwt';
import DatastoreEventProvider from './data/DatastoreEventProvider';
import EdifyEventProvider from './data/EdifyEventProvider';
import { CalendarViews, EventActions } from './enums';
import { CalendarLoadingState } from './calendar';

const Calendar = React.lazy(() =>
    import(/* webpackChunkName: "Calendar" */ './calendar')
);

const CalendarWrapper = (calendarConfiguration) => {
    let defaultView = CalendarViews.Day;

    if (calendarConfiguration.defaultView === 'Schedule') {
        defaultView = CalendarViews.Schedule;
    }

    let eventAction = EventActions.OpenInModal;

    if (calendarConfiguration.eventAction === 'link') {
        eventAction = EventActions.OpenAsLink;
    }

    if (calendarConfiguration.eventAction === 'page') {
        eventAction = EventActions.OpenInNewPage;
    }

    const calendar = (
        <Calendar
            {...calendarConfiguration}
            defaultView={defaultView}
            eventAction={eventAction}
        />
    );

    return calendar;
};

const DatastoreProvider = (calendarConfiguration, calendar) => {
    const { datasourceUrl, datasourceJWT } = calendarConfiguration;

    const datastore = new Datastore({
        serviceURL: datasourceUrl,
        jwtURL: datasourceJWT,
    });

    const provider = (
        <DatastoreEventProvider datastore={datastore}>
            {calendar}
        </DatastoreEventProvider>
    );

    return provider;
};

const EdifyProvider = (calendarConfiguration, calendar) => {
    const { datasourceUrl, datasourceJWT, edifyUrl, edifyJWT } =
        calendarConfiguration;

    const datastore = new Datastore({
        serviceURL: datasourceUrl,
        jwtCallback: () => getJWT(datasourceJWT),
    });

    // Edify has another datasource which the initial student is loaded from to get enrollment information
    const edifyDatastore = new Datastore({
        serviceURL: edifyUrl,
        jwtURL: edifyJWT,
    });

    const provider = (
        <EdifyEventProvider
            edifyDatastore={edifyDatastore}
            eventsDatastore={datastore}
        >
            {calendar}
        </EdifyEventProvider>
    );

    return provider;
};

const getComponent = ({
    CalendarWrapperFn = CalendarWrapper,
    DatastoreProviderFn = DatastoreProvider,
    EdifyProviderFn = EdifyProvider,
    wrapper,
}) => {
    const { dataset } = wrapper;
    const calendarConfiguration = JSON.parse(dataset.calendarConfiguration);

    let calendar = CalendarWrapperFn(calendarConfiguration);

    const { datasource } = calendarConfiguration;

    if (datasource === 'datastore') {
        calendar = DatastoreProviderFn(calendarConfiguration, calendar);
    }

    if (datasource === 'edify') {
        calendar = EdifyProviderFn(calendarConfiguration, calendar);
    }

    return (
        <Suspense
            fallback={<CalendarLoadingState {...calendarConfiguration} />}
        >
            {calendar}
        </Suspense>
    );
};

// Lazy load the main 'app'
const init = (args) => {
    const appWrapper = document.querySelectorAll('[data-component="calendar"]');

    // Don't run on a non app page
    if (appWrapper.length > 0) {
        // Only load ReactDOM from if its needed as its pretty large
        import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then(
            (ReactDOM) => {
                appWrapper.forEach((wrapper) => {
                    const component = getComponent({
                        ...args,
                        wrapper,
                    });

                    ReactDOM.render(component, wrapper);
                });
            }
        );
    }
};

init();

// Export for test purposes only
export {
    CalendarWrapper,
    DatastoreProvider,
    EdifyProvider,
    init,
    getComponent,
};
