/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The PersonalDocumentListing Application Context
 * @export
 */
const PersonalDocumentListingContext = createContext({
    isLoading: false,
    isReady: false,
    isAuthNeeded: false,
    signInLink: null,
    error: null,
    documents: [],
    getDocuments: null,
    allDocumentsLink: '',
    restartAuthProcess: null,
});

PersonalDocumentListingContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool, // Should a loading state in the app apply?
        isReady: PropTypes.bool, // Is the context ready for data requests
        isAuthNeeded: PropTypes.bool, // Does the user need to login to 3rd party
        signInLink: PropTypes.element,
        error: PropTypes.symbol,
        documents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
                createdBy: PropTypes.string,
                lastModifiedBy: PropTypes.string,
                lastModifiedDate: PropTypes.instanceOf(Date),
                fileSize: PropTypes.number,
                fileType: PropTypes.string,
                customIcon: PropTypes.string,
                customIconLabel: PropTypes.string,
            })
        ),
        getDocuments: PropTypes.func,
        allDocumentsLink: PropTypes.string,
        restartAuthProcess: PropTypes.func, // Function to allow user to re-invoke after fixing an error e.g. closed popup blocker
    }),
};

export const PersonalDocumentListingContextErrorType = Object.freeze({
    AUTH_ERROR: Symbol('AUTH_ERROR'),
    SCOPE_ERROR: Symbol('SCOPE_ERROR'),
    POPUP_ERROR: Symbol('POPUP_ERROR'),
    ERROR: Symbol('ERROR'),
});
export default PersonalDocumentListingContext;
