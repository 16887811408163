/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { LocalStorageAdapter } from 'PlugAndPlay/_data-layer/js/Adapters/StorageAdapters/LocalStorageAdapter/LocalStorageAdapter';

class Tracker {
    constructor({
        wrapper,
        storageAdapter,
        titleSelector,
        linkSelector,
        formSelector,
        historySize,
        delay,
        searchTerm,
        resultsCount,
    }) {
        this.historySize = historySize;
        this.defaultDelay = delay;
        this.titleSelector = titleSelector;
        this.linkSelector = linkSelector;
        this.formSelector = formSelector;
        this.searchTerm = searchTerm;
        this.resultsCount = resultsCount;

        this.storageAdapter = storageAdapter;

        const history = this.storageAdapter.getAll();

        this.clickHistory = Array.from(history.clickHistory || {});
        this.searchHistory = Array.from(history.searchHistory || {});

        this.trackLink(wrapper);
        this.trackedSubmit(wrapper);
        this.refreshStorage(wrapper);
    }

    /**
     * Gathers all tracking information and stores them in local storage, latter execute supplied callback.
     *
     * @param {object} trackingEvent Object with tracking information.
     * @param {function} cb Callback function.
     *
     * @returns void
     */
    trackedEventWithCb(trackingEvent, cb) {
        let eventFired = false;

        trackingEvent.time = Date.now();

        const history =
            trackingEvent.TYPE === 'CLICK'
                ? this.clickHistory
                : this.searchHistory;

        // Lets check if we have tracked link in history already
        const index = history.findIndex((key) => key.url === trackingEvent.url);

        // If we already have item in array we will remove it.
        if (index > -1) {
            history.splice(index, 1);
        }

        // Check if array is below our threshold, if yes than we are removing last item from array.
        if (history.length >= this.historySize) {
            history.pop();
        }

        // Adding new tracking link.
        history.unshift(trackingEvent);

        // Sets new array of links in storage.
        if (trackingEvent.TYPE === 'CLICK') {
            this.storageAdapter.setAll({
                clickHistory: history,
                searchHistory: this.searchHistory,
            });
        }

        if (trackingEvent.TYPE === 'SUBMIT') {
            this.storageAdapter.setAll({
                clickHistory: this.clickHistory,
                searchHistory: history,
            });
        }

        setTimeout(() => {
            if (eventFired) {
                return false;
            }
            eventFired = true;
            cb();
            return true;
        }, this.defaultDelay);
    }

    /**
     * Actual click handler.
     *
     * @param {string} url Link location.
     * @param {object} trackingEvent Object with tracking information.
     * @param {string} target If target supplied we will not follow.
     * @param {bool} clickOnce Flag for tracking if already clicked.
     *
     * @return void
     */
    trackedClick(url, trackingEvent, target, clickOnce = false) {
        let clicked = false;

        function _click() {
            if (!url || (url && target) || clicked) {
                return;
            }
            clicked = clickOnce;

            window.location.href = url;
        }

        this.trackedEventWithCb(trackingEvent, _click);
    }

    /**
     * Creates link handler.
     *
     * @param {object} trackingEvent Object with tracking information.
     * @param {HTMLElement} element Element where event will be added.
     *
     * @return {(function(*): void)|*}
     */
    trackingHandler(trackingEvent, element) {
        return (evt) => {
            evt.preventDefault();

            trackingEvent.title = this.titleSelector(element);
            trackingEvent.url =
                element.dataset.liveUrl || evt.currentTarget.href;

            this.trackedClick(evt.currentTarget.href, trackingEvent);
        };
    }

    /**
     * Creates submit handler.
     *
     * @param {object} trackingEvent Object with tracking information.
     * @param {HTMLElement} element Element where event will be added.
     *
     * @return {(function(*): void)|*}
     */
    trackingSubmitHandler(trackingEvent, element) {
        function _submit() {
            if (typeof element.submit !== 'function') {
                return;
            }

            element.submit();
        }

        return (evt) => {
            evt.preventDefault();
            trackingEvent.url = window.location.href;
            trackingEvent.time = Date.now();

            this.trackedEventWithCb(trackingEvent, _submit);
        };
    }

    /**
     * Adds event lister to element.
     *
     * @param {string} type Type of the event
     * @param {function} handler Function to be attached.
     * @param {HTMLElement} element Element where event will be added.
     *
     * @return {null|*}
     */
    attachHandler(type, handler, element) {
        if (!element) {
            return null;
        }

        element.addEventListener(type, handler);

        return element;
    }

    /**
     * Find search links and start attaching events to each.
     *
     * @param {HTMLElement} doc Wrapper element.
     *
     * @returns void
     */
    trackLink(doc) {
        const trackingEvent = {
            TYPE: 'CLICK',
            query: this.searchTerm,
        };

        const searchLinks = [...doc.querySelectorAll(this.linkSelector)];

        if (!searchLinks) {
            console.warn(
                `There are no links with given selector: ${this.linkSelector} in DOM, please fix data-search-form attribute with proper form selector`
            );
            return;
        }

        searchLinks.forEach((searchLink) => {
            this.attachHandler(
                'click',
                this.trackingHandler(trackingEvent, searchLink),
                searchLink
            );
            // Middle click
            this.attachHandler(
                'auxclick',
                this.trackingHandler(trackingEvent, searchLink),
                searchLink
            );
        });
    }

    /**
     *  Find search forms and start attaching events to each.
     *
     * @param {HTMLElement} doc Wrapper element.
     *
     * @returns void
     */
    trackedSubmit(doc) {
        const trackingEvent = {
            TYPE: 'SUBMIT',
            title: 'NEEDS_REFRESH',
        };

        const forms = [...doc.querySelectorAll(this.formSelector)];

        if (!forms) {
            console.warn(
                `There is no form with given selector:${this.formSelector} in DOM, please fix data-search-link-query attribute with proper form selector`
            );
            return;
        }

        forms.forEach((form) => {
            this.attachHandler(
                'submit',
                this.trackingSubmitHandler(trackingEvent, form),
                form
            );
        });
    }

    /**
     * When submit happens we have no way to gather info before link is being executed.
     * Instead we are marking data which needs rebuild with NEEDS_REFRESH key.
     * This way on page load (after submitting search) we are updating key with correct information.
     *
     * @returns void
     */
    refreshStorage() {
        const index = this.searchHistory.findIndex(
            (key) => key.title === 'NEEDS_REFRESH'
        );

        if (index === -1) {
            return;
        }

        this.searchHistory[index] = {
            ...this.searchHistory[index],
            title: this.searchTerm,
            count: this.resultsCount,
        };

        this.storageAdapter.setAll({
            clickHistory: this.clickHistory,
            searchHistory: this.searchHistory,
        });
    }
}

export default function _tracker() {
    /**
     * Component selector.
     * @const {string}
     */
    const INIT_SELECTOR = `[data-pnp-component="link-tracker"]`;

    /**
     * Local storage key id.
     * @const {string}
     */
    const STORAGE_KEY = `PNP-SEARCH-HISTORY`;

    /**
     * waiting time before callbacks are executed.
     * @const {string}
     */
    const DEFAULT_DELAY = 500;

    /**
     * Size of local storage.
     * @const {string}
     */
    const HISTORY_SIZE = 10;

    const _trackers = [...document.querySelectorAll(INIT_SELECTOR)];

    const storageAdapter = new LocalStorageAdapter({ storageKey: STORAGE_KEY });

    _trackers.forEach((wrapper) => {
        const DOMItem = wrapper;
        // Moving parts now.
        const { dataset } = wrapper;
        // This is to let tracker know what links we want to track.
        const linkSelector = dataset.linkQuery || `.listing-item__title-link`;
        // This is to let know where is our search form.
        const formSelector = dataset.searchForm || `.hero-banner-search__form`;
        // This is to store term we have been searching.
        const searchTerm = dataset.searchTerm || ``;
        // This is to store search results count.
        const resultsCount = dataset.resultsCount || 0;
        // This is to set delay of click execution.
        const delay = dataset.delay || DEFAULT_DELAY;
        // This is to specify how big history should be.
        const historySize = dataset.historySize || HISTORY_SIZE;

        const titleSelector = (element) => {
            // This it to let tracker know what link title we want to use.
            let title;
            try {
                title = element.getAttribute('title');
            } catch (e) {
                console.warn(
                    `Missing title attribute on ${linkSelector} selector.`
                );
            }
            return title;
        };

        if (!DOMItem.tracker) {
            DOMItem.tracker = new Tracker({
                wrapper: document,
                storageAdapter,
                titleSelector,
                formSelector,
                linkSelector,
                searchTerm,
                resultsCount,
                historySize,
                delay,
            });
        }
    });
}

_tracker();
