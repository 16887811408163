import FeatureBranchSwitcher from './FeatureBranchSwitcher';

const FEATURE_BRANCH_POP_OUT_EXPAND_SELECTOR =
    '[data-click="featureBranchPopOut"]';

const FEATURE_BRANCH_POPOUT_ACTIVE = 'feature-branch-pop-out--active';

export default class FeatureBranchPopOut extends FeatureBranchSwitcher {
    constructor(control) {
        super(control);

        const expand = control.querySelector(
            FEATURE_BRANCH_POP_OUT_EXPAND_SELECTOR
        );
        if (expand) {
            expand.addEventListener('click', () => {
                this.expandPanel(expand);
            });
        }
    }

    expandPanel(button) {
        if (button.getAttribute('aria-expanded') === 'true') {
            this.component.classList.remove(FEATURE_BRANCH_POPOUT_ACTIVE);
            button.setAttribute('aria-expanded', false);
        } else {
            this.component.classList.add(FEATURE_BRANCH_POPOUT_ACTIVE);
            button.setAttribute('aria-expanded', true);
        }
    }
}
