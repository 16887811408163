/**
 * Convert value into proper JS object.
 *
 * @param {*} value Any value we want to parse.
 *
 * @returns {string|any}
 */
function parseValue(value) {
    try {
        return JSON.parse(value);
    } catch (err) {
        return value || '';
    }
}

/**
 * Parses html string and build DOM element.
 *
 * @param {object} doc     Document object.
 * @param {string} htmlStr html string to be transformed into DOM.
 *
 * @returns {object}
 */
function parseHTML(doc, htmlStr) {
    const div = doc.createElement('div');
    div.innerHTML = htmlStr;
    return div.children;
}

/**
 * This is a hook, where we build array of DOM elements.
 * It is being used to handle key navigation in combobox.
 *
 * @returns {(*[]|(function(*=)))[]}
 */
function useArrayRef() {
    const childRefs = [];
    return [childRefs, (el) => el && childRefs.push(el)];
}

/**
 * Map for Html to React elements.
 *
 * @type {{*}}
 */
const domToRect = {
    autocomplete: 'autoComplete',
    class: 'className',
    maxlength: 'maxLength',
    minlength: 'minLength',
    placeholder: 'placeholder',
    spellcheck: 'spellCheck',
    autocorrect: 'autoCorrect',
    size: 'size',
    id: 'id',
    type: 'type',
    autofocus: 'autoFocus',
};

/**
 * Get parameter from URL.
 *
 * @param  {string}  queryString   Full query string.
 * @param  {string}  param         Parameter name.
 *
 * @return {string} Parameter value.
 */
function getParam(urlString, param) {
    const url = new URL(urlString);
    try {
        return url.searchParams.get(param);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return '';
    }
}

/**
 * Gets tracker object.
 *
 * @return {Tracker|null}
 */
function getTracker() {
    try {
        // eslint-disable-next-line prefer-destructuring
        const tracker = document.querySelectorAll(
            '[data-pnp-component="link-tracker"]'
        )[0].tracker;

        return tracker;
    } catch (e) {
        return null;
    }
}

export { parseValue, parseHTML, useArrayRef, domToRect, getParam, getTracker };
