import uuidv4 from 'PlugAndPlay/_global/js/uuid';

export default function _customForm() {
    const CUSTOM_MATRIX_FORM = 'form.custom-form';
    const FORM_QUESTION = '.sq-form-question';

    const REQUIRED_FIELD = '.sq-form-required-field';
    const REQUIRED_FIELD_DETECT =
        'input:not([required]):not([aria-required]):not([type="hidden"]), select:not([required]):not([aria-required]):not([type="hidden"])';

    const FILE_UPLOAD_CLEAR =
        '.sq-form-question-file-upload input[type="checkbox"]';

    const ERROR_MESSAGES = '.sq-form-question-error .sq-form-error';
    const ERROR_MESSAGES_DETECT =
        'input:not([aria-invalid]):not([type="hidden"]), select:not([aria-invalid]):not([type="hidden"])';

    const EMAIL_FIELDS = '.sq-form-question-email-address input';
    const EMAIL_FIELDS_DETECT = 'input:not([autocomplete])';

    const NOTE_FIELDS = '.sq-form-question-note';
    const NOTE_FIELDS_DETECT = '.sq-form-question-note:not([id])';
    const ANSWER_INPUT = '.sq-form-field';

    /**
     * Apply aria fixes for Matrix Custom Forms based on WCAG review done on the template
     * @param {HTMLElement} form - The wrapping HTML element of the form
     * @class
     */
    class AccessibleCustomForm {
        constructor(form) {
            this.form = form;

            // Personal info fields should be tagged
            this.detectAutoCompleteEmailFields(); // SQUIZ-58

            // Matrix doesnt focus error messages on a failed for submission
            this.detectErrorMessageFocus(); // SQUIZ-69

            // Matrix does not link error messages to the inputs in error
            this.detectErrorMessageLinks(); // SQUIZ-59

            // Matrix doesnt associated the file upload clear checkbox with the form field
            this.detectFileUploadClearLabel(); // SQUIZ-64

            // Required fields markers are not associated with the form fields in the DOM correctly, this adds aria-required to the fields.
            this.detectRequiredField(); // SQUIZ-94

            // Matrix does not link notes to descriptions
            this.detectNoteFields(); // SQUIZ-62
        }

        detectAutoCompleteEmailFields() {
            this.form.querySelectorAll(EMAIL_FIELDS).forEach((emailField) => {
                if (emailField.matches(EMAIL_FIELDS_DETECT)) {
                    this.enableAutoCompleteEmailFields({ emailField });
                }
            });
        }

        detectNoteFields() {
            this.form.querySelectorAll(NOTE_FIELDS).forEach((noteField) => {
                if (noteField.matches(NOTE_FIELDS_DETECT)) {
                    this.linkNoteFieldsToInputs({ noteField });
                }
            });
        }

        enableAutoCompleteEmailFields({ emailField }) {
            emailField.setAttribute('autocomplete', 'email');
        }

        detectErrorMessageFocus() {
            const errorMessages = this.form.querySelectorAll(ERROR_MESSAGES);

            // Check if error messages appear on the page
            if (errorMessages.length > 0) {
                // Wait a moment to see if something else corrects the focus
                setTimeout(() => {
                    // Check if an invalid element has focus
                    if (!document.activeElement.getAttribute('aria-invalid')) {
                        this.enableErrorMessageFocus({ errorMessages });
                    }
                }, 500);
            }
        }

        enableErrorMessageFocus({ errorMessages }) {
            const firstErrorMessage = errorMessages[0];

            // Grab the associated question
            const question = firstErrorMessage.closest(FORM_QUESTION);
            question.querySelector('input, select').focus();
        }

        detectErrorMessageLinks() {
            this.form
                .querySelectorAll(ERROR_MESSAGES)
                .forEach((errorMessage) => {
                    // Grab the associated question
                    const question = errorMessage.closest(FORM_QUESTION);
                    // Find all inputs without an error state
                    question
                        .querySelectorAll(ERROR_MESSAGES_DETECT)
                        .forEach((errorInput) => {
                            this.enableErrorMessageLinks({
                                errorInput,
                                errorMessage,
                            });
                        });
                });
        }

        enableErrorMessageLinks({ errorInput, errorMessage }) {
            errorInput.setAttribute('aria-invalid', true);
            errorInput.setAttribute('aria-describedby', errorMessage.id);
        }

        detectFileUploadClearLabel() {
            this.form
                .querySelectorAll(FILE_UPLOAD_CLEAR)
                .forEach((fileUploadClear) => {
                    // Check if the checkbox has an aria label
                    if (
                        !fileUploadClear.getAttribute('aria-label') &&
                        !fileUploadClear.getAttribute('aria-labelledby')
                    ) {
                        let hasLabel = false;
                        // Grab the associated question
                        const question = fileUploadClear.closest(FORM_QUESTION);

                        // Check if there is a label 'for', input must have an id for that to work
                        if (fileUploadClear.id) {
                            const labels =
                                question.getElementsByTagName('label');
                            for (let i = 0; i < labels.length; i++) {
                                if (labels[i].htmlFor === fileUploadClear.id) {
                                    hasLabel = true;
                                }
                            }
                        }

                        // If the input direct parent is a label as this counts as an implicit 'for'
                        const parentTag =
                            fileUploadClear.parentElement.tagName.toLowerCase();
                        if (parentTag === 'label') {
                            hasLabel = true;
                        }

                        if (!hasLabel) {
                            // Has no label, modify it to have one
                            this.enableFileUploadClearLabel({
                                fileUploadClear,
                            });
                        }
                    }
                });
        }

        enableFileUploadClearLabel({ fileUploadClear }) {
            let siblingClear;
            Array.from(fileUploadClear.parentElement.childNodes).forEach(
                (sibling) => {
                    if (sibling.textContent.toLowerCase() === 'clear?') {
                        siblingClear = sibling;
                    }
                }
            );

            if (siblingClear) {
                // Create an id
                const newId = uuidv4();

                // Create a new span
                const newSpan = document.createElement('span');
                newSpan.setAttribute('id', newId);
                newSpan.appendChild(
                    document.createTextNode(siblingClear.textContent)
                );

                // Replace the text node with the span
                siblingClear.parentNode.insertBefore(newSpan, siblingClear);
                siblingClear.parentNode.removeChild(siblingClear);

                // Link the span to the file upload clear
                fileUploadClear.setAttribute('aria-labelledby', newId);
            } else {
                fileUploadClear.setAttribute('aria-label', 'clear?');
            }
        }

        detectRequiredField() {
            // Find the required tag HTML in the form
            this.form
                .querySelectorAll(REQUIRED_FIELD)
                .forEach((requiredTag) => {
                    // Grab the associated question
                    const question = requiredTag.closest(FORM_QUESTION);
                    if (question) {
                        // Check if the field already has a required or aria-required attribute
                        question
                            .querySelectorAll(REQUIRED_FIELD_DETECT)
                            .forEach((requiredField) => {
                                // Set aria-required
                                this.enableRequiredField({ requiredField });
                            });
                    }
                });
        }

        enableRequiredField({ requiredField }) {
            requiredField.setAttribute('aria-required', true);
        }

        linkNoteFieldsToInputs({ noteField }) {
            // Get the input that is associated with the note field by the structure
            const inputField = noteField.parentNode.querySelector(ANSWER_INPUT);

            // Get the id from the input field
            const inputFieldId = inputField?.id;

            // Early return if we don't have an input id for some reason
            if (!inputFieldId) {
                return;
            }

            // Get the id for the note field
            const noteFieldId = `${inputFieldId}__note-field`;

            // Append this id to the note field
            noteField.setAttribute('id', noteFieldId);

            // Add the aria attribute to the input field
            const currentAttribute =
                inputField.getAttribute('aria-describedby');
            // Append the aria attribute to the input field if some already exists
            const ariaDescribedBy = currentAttribute
                ? `${noteFieldId} ${currentAttribute}`
                : noteFieldId;
            inputField.setAttribute('aria-describedby', ariaDescribedBy);
        }
    }

    // Find all custom forms
    const forms = document.querySelectorAll(CUSTOM_MATRIX_FORM);
    forms.forEach((form) => {
        // Create a new item to modify
        const DOMItem = form;

        // Attach to our DOM Item
        if (!DOMItem.accessibleCustomForm) {
            DOMItem.accessibleCustomForm = new AccessibleCustomForm(form);
        }
    });
}

_customForm();
