/**
 * This module handles clicks outside elements and adds / removes classes
 * @module clickOutside
 * @param {HTMLElement} element - The element we want to hide if the click is outside it
 * @param {function} functionToCall - The function that should be called if a click is outside of the defined element
 */
export default function hideOnClickOutside(element, functionToCall) {
    // Define out event listener to remove
    function removeClickListener() {
        /* eslint-disable no-use-before-define */
        document.removeEventListener('click', outsideClickListener);
    }

    // Define out click listener
    function outsideClickListener(event) {
        // If the click wasn't inside our element and our element is visible
        if (!element.contains(event.target) && isVisible(element)) {
            functionToCall();

            // Tidy up and remove the event listener
            removeClickListener();
        }
    }

    // Add an event listener to the document for this item
    document.addEventListener('click', outsideClickListener);
}

// Check if the element is visible
const isVisible = (element) =>
    !!element &&
    !!(
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length
    ); // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js
