import stringToBool from '../../_global/js/stringToBool';

/**
 * This class fix Matrix form imperfections.
 * Add missing attributes to input fields (autocomplete and placeholder)
 * Handles inline input clear button.
 *
 * @private
 */
export default function _searchForm() {
    /**
     * Main component selector.
     * @const {string}
     */
    const SEARCH_FORM_COMPONENT = '[data-pnp-component="search-form"]';

    /**
     * Search input field selector.
     * @const {string}
     */
    const SEARCH_INPUT_SELECTOR = '.sq-form-field:first-child';

    /**
     * Clear action button selector.
     * @const {string}
     */
    const SEARCH_CLEAR_ACTION_SELECTOR = '[data-click="clear-search"]';

    /**
     * Class flag indicating if input has value.
     * @const {string}
     */
    const SEARCH_HAS_INPUT_CLASS = 'search-form--has-input';

    /**
     * Search placeholder text.
     * @const {string}
     */
    const PLACEHOLDER_TEXT = 'Search this website';

    /**
     * Since the form is not wrapped inside our component, we use submit button to trigger click.
     * @type {string}
     */
    const SUBMIT_BUTTON = '[type="submit"]';

    /**
     * Sort dropdown selector, we add listener, to listen on changes and trigger submit on change.
     * @type {string}
     */
    const SORT_DROPDOWN = '.sq-form-field:nth-child(2)';

    class SearchForm {
        constructor(searchFormWrapper) {
            this.searchFormWrapper = searchFormWrapper;

            const input = searchFormWrapper.querySelector(
                SEARCH_INPUT_SELECTOR
            );
            const submitButton = searchFormWrapper.querySelector(SUBMIT_BUTTON);
            const sortDropdown = searchFormWrapper.querySelector(SORT_DROPDOWN);
            const isEmptySearchOn = stringToBool(
                searchFormWrapper.dataset.pnpEmptySearchEnabled
            );

            // Attach event handlers to clear
            searchFormWrapper
                .querySelectorAll(SEARCH_CLEAR_ACTION_SELECTOR)
                .forEach((element) => {
                    element.addEventListener('click', () => {
                        this.clearSearch(
                            input,
                            this.searchFormWrapper,
                            SEARCH_HAS_INPUT_CLASS
                        );
                    });
                });

            if (input) {
                this.handleAria(input);

                input.addEventListener('input', (e) => {
                    this.handleInputChange(
                        e,
                        this.searchFormWrapper,
                        SEARCH_HAS_INPUT_CLASS
                    );
                });
                input.addEventListener('change', (e) => {
                    this.handleInputChange(
                        e,
                        this.searchFormWrapper,
                        SEARCH_HAS_INPUT_CLASS
                    );
                });
                // Add attributes not available in matrix

                input.setAttribute('autocomplete', 'off');
                input.setAttribute('placeholder', PLACEHOLDER_TEXT);
            }

            if (sortDropdown && input) {
                sortDropdown.addEventListener('change', () => {
                    if (input.value !== '' || isEmptySearchOn) {
                        submitButton.click();
                    }
                });
            }
        }

        /**
         * Clears value and toggle class on supplied DOM element.
         *
         * @param {object} inputField DOM Input field we will clear value.
         * @param {object} element    DOM Element where we toggling flag.
         * @param {string} className  Class name being used as flag indicator.
         */
        clearSearch(inputField, element, className) {
            // eslint-disable-next-line no-param-reassign
            inputField.value = '';
            inputField.dispatchEvent(new window.Event('change'));

            // Remove the 'has input' class which makes the X show
            element.classList.remove(className);

            // Reset focus to the input as the X will now hide
            inputField.focus();
        }

        /**
         * Adds the appropriate aria attributes to the search field
         */
        handleAria(inputField) {
            // Set the place holder as the aria label
            inputField.setAttribute('aria-label', PLACEHOLDER_TEXT);
        }

        /**
         * Toggle class on supplied DOM element.
         *
         * @param {object} e         Element firing event.
         * @param {object} element   DOM Element where we toggling flag.
         * @param {string} className Class name being used as flag indicator.
         */
        handleInputChange(e, element, className) {
            if (!element) {
                return;
            }
            const {
                target: { value },
            } = e;

            // eslint-disable-next-line no-unused-expressions
            value !== ''
                ? element.classList.add(className)
                : element.classList.remove(className);
        }
    }

    const SearchComponents = document.querySelectorAll(SEARCH_FORM_COMPONENT);

    SearchComponents.forEach((item) => {
        // Create a new item to modify
        const DOMItem = item;

        // Attach the class instance to our DOMItem
        if (!DOMItem.searchForm) {
            DOMItem.searchForm = new SearchForm(item);
        }
    });
}

_searchForm();
