import React from 'react';
import PropTypes from 'prop-types';

export default function StatusMessage({
    icon,
    customIcon,
    title,
    children,
    className,
}) {
    return (
        <div className={`status-message ${className}`}>
            <div className="status-message__body">
                <div className="status-message__icon">
                    {customIcon}
                    {!customIcon && (
                        <svg
                            aria-label={`${icon} icon`}
                            className="svg-icon svg-icon--xlarge"
                        >
                            <use href={`#${icon}`} />
                        </svg>
                    )}
                </div>

                <div className="status-message__content">
                    {title && (
                        <div className="status-message__title">{title}</div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
}

StatusMessage.propTypes = {
    icon: PropTypes.string,
    customIcon: PropTypes.element,
    children: PropTypes.element,
    className: PropTypes.string,
};

StatusMessage.defaultProps = {
    icon: 'warning',
    customIcon: null,
    children: null,
    className: '',
};
