/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OverlayProvider } from '@react-aria/overlays';

import uuidv4 from 'PlugAndPlay/_global/js/uuid';
import CourseContext from './data/CourseContext';

import Card from '../../collapsible-card/jsx/Card';
import CourseContent from './CourseContent';

export function LoadingState({ numberOfCourses = 4 }) {
    // Generate the unique id's for keys
    const uniqueIds = [...Array(numberOfCourses)].map(() => uuidv4());
    return (
        <div className="my-courses my-courses--loading">
            <div className="my-courses__heading">
                <div className="my-courses__title">My Courses</div>
            </div>
            <div className="my-courses__container">
                {[...Array(numberOfCourses)].map((course, index) => {
                    return (
                        <div
                            key={uniqueIds[index]}
                            className="my-courses__card my-courses__card--loading"
                        />
                    );
                })}
            </div>
        </div>
    );
}

function ErrorState() {
    return (
        <div className="my-courses">
            <div className="my-courses__heading">
                <div className="my-courses__title">My Courses</div>
            </div>
            <div className="my-courses__container">
                <p>Oops looks like something went wrong.</p>
            </div>
        </div>
    );
}

export default function Courses({ title }) {
    const { courses, getCourses, isLoading, isReady, error } =
        useContext(CourseContext);

    // Initial data load
    useEffect(() => {
        getCourses();
    }, []);

    return (
        <>
            {isLoading && <LoadingState />}
            {error && <ErrorState />}
            {courses.length === 0 && isReady && (
                <div className="my-courses">
                    <div className="my-courses__heading">
                        <div className="my-courses__title">{title}</div>
                    </div>
                    <div className="my-courses__empty-container">
                        <div className="my-courses__empty-content">
                            <div className="my-courses__svg-wrapper">
                                <svg className="svg-icon">
                                    <use href="#no-courses" />
                                    <title>Empty box icon</title>
                                </svg>
                            </div>
                            <div className="my-courses__empty-text">
                                <b>No courses added</b>
                                <br />
                                Contact your administrator
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {courses.length > 0 && isReady && (
                <OverlayProvider className="my-courses">
                    <div className="my-courses__heading">
                        <div className="my-courses__title">My Courses</div>
                    </div>
                    <div className="my-courses__container">
                        {
                            // Loop through each of the courses and create a card for each
                            courses &&
                                courses.map((course, index) => {
                                    const {
                                        id,
                                        code,
                                        name,
                                        assessments,
                                        updates,
                                    } = course;

                                    return (
                                        <Card
                                            isCollapsible
                                            isInitialCollapsed={index !== 0}
                                            key={id}
                                            title={`${code} | ${name}`}
                                            wrapperClasses="my-courses__card"
                                            contentClasses="my-courses__content"
                                        >
                                            <CourseContent
                                                assessments={assessments || []}
                                                updates={updates || []}
                                            />
                                        </Card>
                                    );
                                })
                        }
                    </div>
                </OverlayProvider>
            )}
        </>
    );
}

Courses.propTypes = {
    title: PropTypes.string,
};

Courses.defaultProps = {
    title: 'My Courses',
};
