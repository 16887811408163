/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Google Drive auth scope context
 * @export
 */
const GoogleDriveAuthScopeContext = createContext({
    isLoading: false,
    hasScopeBeenApproved: null,
    setHasScopeBeenApproved: null,
});

GoogleDriveAuthScopeContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        hasScopeBeenApproved: PropTypes.bool,
        setHasScopeBeenApproved: PropTypes.func,
    }),
};

export default GoogleDriveAuthScopeContext;
