/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import getJWT from 'Edify/js/getJwt';
import EdifyBookmarkProvider from './data/EdifyBookmarkProvider';
import LocalStorageBookmarkProvider from './data/LocalStorageBookmarkProvider';
import { LoadingState } from './bookmarks';

// Lazy load the main Bookmarks 'app'
const Bookmarks = React.lazy(() =>
    import(/* webpackChunkName: "Bookmarks" */ './bookmarks')
);

const appWrapper = document.querySelectorAll('[data-component="bookmarks"]');
// Don't run if wrapper doesn't exist
if (appWrapper.length > 0) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            const { dataset } = wrapper;
            const bookmarksConfiguration = JSON.parse(
                dataset.bookmarksConfiguration
            );

            let bookmarks = <Bookmarks {...bookmarksConfiguration} />;

            if (bookmarksConfiguration.datasource === 'edify') {
                const datastore = new Datastore({
                    serviceURL: bookmarksConfiguration.datasourceUrl,
                    jwtCallback: () =>
                        getJWT(bookmarksConfiguration.datasourceJWT),
                });

                bookmarks = (
                    <EdifyBookmarkProvider datastore={datastore}>
                        {bookmarks}
                    </EdifyBookmarkProvider>
                );
            } else {
                bookmarks = (
                    <LocalStorageBookmarkProvider
                        storageKey={bookmarksConfiguration.storageKey}
                    >
                        {bookmarks}
                    </LocalStorageBookmarkProvider>
                );
            }

            ReactDOM.render(
                <Suspense fallback={<LoadingState />}>{bookmarks}</Suspense>,
                wrapper
            );
        });
    });
}
