import React, { useState, useCallback } from 'react';
import getStudent from 'Edify/js/getStudentMemoized';
import CourseContext, { CoursesContextErrorType } from './CourseContext';

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState(null);
    const [courses, setCourses] = useState([]);

    // Function that sets the correct state when loding starts
    function beginLoading() {
        setIsLoading(true);
        setIsReady(false);
    }

    // Function that sets the correct state when loding ends
    function endLoading() {
        setIsLoading(false);
        setIsReady(true);
    }

    /**
     * Create query to get student's courses.
     *
     * @param {object} query
     *
     * @return {Promise<[]|*>}
     */
    const studentCourses = () => {
        // eslint-disable-next-line no-shadow
        return getStudent(datastore).then(({ courses }) => {
            const query = datastore.collection(`courses`);

            for (let i = 0; i < courses.length; i++) {
                const courseId = courses[i];
                if (i === 0) {
                    query.where(`courseid`, `===`, courseId);
                } else {
                    query.or(`courseid`, `===`, courseId);
                }
            }

            return query.get();
        });
    };

    /**
     * Gets the assignments for the course
     *
     * @param {string} courseId - The course ID as a string
     */
    const getAssignments = useCallback((courseId) => {
        const startDate = new Date();
        // Set the time to midnight
        startDate.setHours(24, 0, 0, 0);

        const assignmentsQuery = datastore
            .collection(`assignments`)
            .where(`dueDate`, `>=`, startDate.toISOString())
            .and(`courseid`, `===`, courseId)
            .or(`courseidAssignee`, `===`, courseId)
            .or(`courseSectionidAssignee`, `===`, courseId)
            .sortBy(`dueDate`)
            .get()
            .catch((sdkError) => {
                // If we got an error because of something other than a 404
                if (sdkError?.status === 404) {
                    // Return an empty array when the endpoint doesnt exist
                    return [];
                }
                setError(CoursesContextErrorType.ERROR);
                return null;
            });

        return assignmentsQuery;
    }, []);

    /**
     * Gets the updates for the course
     *
     * @param {string} courseId - The course ID as a string
     *
     * @return {Promise<[]|*>}
     */
    const getFiles = useCallback((courseId) => {
        const filesQuery = datastore
            .collection(`courses`)
            .doc(courseId)
            .collection(`files`)
            .sortBy(`updated`)
            .get()
            .catch((sdkError) => {
                // If we got an error because of something other than a 404
                if (sdkError?.status === 404) {
                    // Return an empty array when the endpoint doesnt exist
                    return [];
                }
                setError(CoursesContextErrorType.ERROR);
                return null;
            });

        return filesQuery;
    }, []);

    /**
     * Get all of the courses.
     *
     * @returns {Promise<object>} The result of the datastore query for all courses.
     */
    const getCourses = useCallback(() => {
        beginLoading();

        const addAssessmentsAndUpdates = (course) => {
            const courseId = course.courseid;
            const assignmentsQuery = getAssignments(courseId);
            const filesQuery = getFiles(courseId);

            const courseObject = { ...course };

            return Promise.all([assignmentsQuery, filesQuery])
                .then(([assignments, files]) => {
                    // Remap to shape we are expecting in the component.
                    courseObject.assessments = assignments.map((item) => ({
                        name: item.title,
                        due: item.dueDate,
                        ...item,
                    }));

                    // Remap to shape we are expecting in the component.
                    courseObject.updates = files.map((item) => ({
                        date: item.updated,
                        fileSize: item.size,
                        link: item.url,
                        ...item,
                    }));

                    return courseObject;
                })
                .catch(() => {
                    setError(CoursesContextErrorType.ERROR);
                });
        };

        studentCourses()
            // eslint-disable-next-line no-shadow
            .then((courses) => {
                const promises = [];
                courses.forEach((course) => {
                    promises.push(addAssessmentsAndUpdates(course));
                });
                return Promise.all(promises);
            })
            .then((courseResults) => {
                setCourses(courseResults);
                endLoading();
            })
            .catch(() => {
                setError(CoursesContextErrorType.ERROR);
            });
    }, []);

    return (
        <CourseContext.Provider
            value={{ isLoading, isReady, error, courses, getCourses }}
        >
            {children}
        </CourseContext.Provider>
    );
};
