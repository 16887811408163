/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Card from '../../collapsible-card/jsx/Card';
import Collapsible from '../../collapsible/jsx/Collapsible';
import StatusMessage from '../../status-message/jsx/StatusMessage';
import EmailsListingContext, {
    EmailsListingContextErrorType,
} from './data/emails-listing-context';

/**
 * Loading partial.
 * @return {JSX.Element}
 * @constructor
 */
export function LoadingState() {
    return (
        <>
            <span className="sr-only">Loading...</span>
            <ol className="emails-list">
                <li className="emails-list__item emails-list--loading" />
                <li className="emails-list__item emails-list--loading" />
                <li className="emails-list__item emails-list--loading" />
                <li className="emails-list__item emails-list--loading" />
            </ol>
        </>
    );
}

/**
 * Handle error messages.
 *
 * @param {object} error Error object.
 * @return {JSX.Element|null}
 * @constructor
 */
function ErrorState({ error }) {
    if (error === EmailsListingContextErrorType.ERROR) {
        const message = 'Oops looks like there has been an error';
        return <StatusMessage title={message} />;
    }
    return null;
}

/**
 * Email content renderer.
 *
 * @param {string} content email content we want to sanitise.
 * @return {JSX.Element}
 * @constructor
 */
function EmailContent({ content }) {
    const cleanHtml = DOMPurify.sanitize(content);
    return (
        <div
            className="emails-listing__email-content"
            dangerouslySetInnerHTML={{ __html: cleanHtml }}
        />
    );
}

/**
 * Emails listing.
 *
 * @param {array} emails Array of email objects.
 *
 * @return {JSX.Element}
 * @constructor
 */
function EmailsList({ emails }) {
    if (!emails || !emails.length) {
        return <StatusMessage title="Your inbox is empty" icon="email" />;
    }

    const titlePartial = ({ email, showSubtitle, showLink }) => {
        const wrapperClasses = [
            'email-heading',
            email.isRead ? 'email-heading--not-read' : '',
        ].join(' ');

        return (
            <>
                <div className={wrapperClasses}>
                    <div className="email-heading__from">{email.from}</div>
                    <div className="email-heading__title">{email.title}</div>
                    {showLink && email.link && (
                        <a className="email-heading__link" href={email.link}>
                            <svg className="svg-icon">
                                <use href="#external" />
                                <title>External icon</title>
                            </svg>
                            <span className="sr-only">
                                Link to email content
                            </span>
                        </a>
                    )}
                    {showSubtitle && (
                        <div className="email-heading__subtitle">
                            {email.subtitle}
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <ol className="emails-list no-wysiwyg">
            {emails.map((email, idx) => (
                <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={`email-${idx}`}
                    className="emails-list__item"
                >
                    {!email.content ? (
                        <>
                            {titlePartial({
                                email,
                                showSubtitle: true,
                                showLink: true,
                            })}
                        </>
                    ) : (
                        <Collapsible
                            displayChevron
                            collapsedTitle={titlePartial({
                                email,
                                showSubtitle: true,
                            })}
                            expandedTitle={titlePartial({ email })}
                            isOpen={false}
                            additionalButtonClasses="emails-list__item--show-more-button"
                            additionalContentClasses="email-content"
                        >
                            <EmailContent content={email.content} />
                        </Collapsible>
                    )}
                </li>
            ))}
        </ol>
    );
}

/**
 * Email listing entry point.
 *
 * @param {string} title Box title.
 * @param {string} emailClientUrl Link to email client.
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function EmailsListing({ title, emailClientUrl }) {
    const { isLoading, error, isReady, emails, getEmails } =
        useContext(EmailsListingContext);

    useEffect(() => getEmails(), []);

    return (
        <Card
            isCollapsible
            title={title}
            contentClasses="emails-listing"
            id="emails-listing"
        >
            <h2 className="emails-listing__heading">Recent Emails</h2>
            {isLoading && <LoadingState />}
            {error && <ErrorState error={error} />}
            {isReady && <EmailsList emails={emails} />}
            <a className="emails-listing__footer-button" href={emailClientUrl}>
                <svg className="svg-icon">
                    <use href="#external" />
                    <title>External icon</title>
                </svg>
                Check your inbox
            </a>
        </Card>
    );
}

const { string } = PropTypes;

EmailsListing.propTypes = {
    title: string,
    emailClientUrl: string,
};

EmailsListing.defaultProps = {
    title: 'Emails',
    emailClientUrl: '#',
};
