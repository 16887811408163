/* eslint-disable */ // This file is imported from Marketplace and to make easier to maintain will use its existing style and formatting

/**
 * Hijack the form submission action so that we can perform additional validation, use AJAX to submit the form and display the response
 *
 * @param {Object} handlerOptions
 * {HTML Element} formElement The form to attach the submission handlers to. Required.
 * {String} loadingMessageMarkup Markup to insert into the loading indicator. Optional.
 * {Boolean} onLoadCallback This will be called upon completion of the request (success or fail). It will receive the request XHR event and the form's handler options. Optional.
 * {Boolean} scrollOnLoad Whether or not to scroll to the top of the page after the form submission (this is useful for long forms). Default = true. Optional.
 * {Function} validationFunction This will be called before the form is submitted. It will receive the form element and should return a Boolean. Optional.
 *
 * @returns void
 */
export default function initFormSubmissionHandler(handlerOptions) {
    'use strict';

    const formOptions = {
        "formElement": null,
        "loadingMessageMarkup": '<p class="loading-message"><em>Loading form...</em></p>',
        "onLoadCallback": {},
        "scrollOnLoad": false,
        "validationFunction": function(){return true;}
    };
    let queryString = [];
    let parameterId = '';
    let submitButtons = {};

    // Update the default form options
    for (let prop in handlerOptions) {
        formOptions[prop] = handlerOptions[prop];
    }

    // Check that at least a form has been provided
    if (formOptions.formElement === null || formOptions.formElement === undefined) {
        console.log('Error: Unable to initialise initFormSubmissionHandler(). No form provided.', formOptions);
        return;
    }

    // Insert the loading animation/message into the page
    formOptions.formElement.insertAdjacentHTML('beforeend', creatingLoadingMarkup(formOptions.loadingMessageMarkup));

    // Add a listener to each of the form's submit buttons so that we can track which button is submitting the form
    submitButtons = formOptions.formElement.querySelectorAll('[type=submit]');

    for (var i = 0, j = submitButtons.length; i < j; ++i) {
        submitButtons[i].addEventListener('click', function(evt){
            hijackFormSubmission(evt, formOptions);
        });
    }

    // Check if the user is continuing a saved submission
    queryString = window.location.href.split('?');
    parameterId = '';

    // Check if: there are query parameters
    if (queryString[1]) {
        // Extract the FORM ID from the query string
        parameterId = queryString[1].match(/SQ_FORM_(.*?)(?=_SUBMISSION)/);

        // Check if: The parameters are for continuing a submission. The ID in the parameters matches the current form
        if (queryString[1].indexOf('_SUBMISSION') !== -1 && formOptions.formElement.getAttribute("id").indexOf(parameterId[1]) !== -1) {

            // The user is continuing a form submission
            // Remove the query from the URL to prevent an infinite loop once we load the saved form progress
            window.history.replaceState({}, document.title, location.protocol + "//" + location.host + location.pathname);

            // Make a GET request to retrieve the form submission
            continueFormSubmission(formOptions, queryString[1] + '&SQ_ASSET_CONTENTS_RAW');
        }
    }

    /**
     * Respond to attempts to submit the form
     *
     * @param {MouseEvent} evt The event that triggered the submission
     * @param {Object} handlerOptions The handler options for the form (as set in initFormSubmissionHandler())
     *
     * @returns void
     */
    function hijackFormSubmission(evt, handlerOptions) {
        const XHR = new XMLHttpRequest();
        let form = handlerOptions.formElement; // Capture all the form data
        let formData = new FormData(form); // Convert the form fields into FormData
        let formField = {};
        let submitBtn = {};
        let formSubmitter = evt.currentTarget;

        evt.preventDefault();

        // Show the loading message
        form.getElementsByClassName('js-loading-message')[0].classList.add('is-active');

        // Check if the form passes validation (and/or other pre-submit actions)
        if (isFunction(handlerOptions.validationFunction && !handlerOptions.validationFunction(form))) {
            // Hide the loading message
            form.getElementsByClassName('js-loading-message')[0].classList.remove('is-active');
            return;
        }

        // Perform some additional operations on the form data
        for (let i = 0, j = form.elements.length; i < j; ++i) {

            formField = form.elements[i];

            if (formField.name === '' || formField.disabled) {
                // Skip the undefined and disabled elements
                continue;
            }

            // Matrix requires the submit button to be part of the submission data
            // Find the submit button in the form inputs
            // Match the submit buttons found in the form to the button that initiated the form submission
            if (formField.type === 'submit') {

                // Check if the user is submitting the form or saving form
                if (formSubmitter.getAttribute("name").indexOf('_save') !== -1 && formField.getAttribute("name").indexOf('_save') !== -1) {
                    // Capture the save button element
                    submitBtn = formField;
                    break;
                }

                if (formSubmitter.getAttribute("name").indexOf('_previous_page') !== -1 && formField.getAttribute("name").indexOf('_previous_page') !== -1) {
                    // Capture the previous button element
                    submitBtn = formField;
                    break;
                }

                if (formSubmitter.getAttribute("name").indexOf('_submit') !== -1 && formField.getAttribute("name").indexOf('_submit') !== -1) {
                    // Capture the submit button element (standard form submit)
                    submitBtn = formField;
                    break;
                }

                if (formSubmitter.getAttribute("name").indexOf('_public_auth') !== -1 && formField.getAttribute("name").indexOf('_public_auth') !== -1) {
                    // Capture the submit button element (for the continue submission password form)
                    submitBtn = formField;
                    break;
                }
            }
        }

        // Disable the submit button
        submitBtn.setAttribute("disabled", "");

        // Append the submit button to the form data
        formData.append(submitBtn.name, submitBtn.value);

        // Respond to requests to POST the form
        XHR.addEventListener('load', function(evt) {
            onFormRequestSuccess(evt, handlerOptions);
        });

        // Respond to request errors
        XHR.addEventListener('error', function(evt) {
            onFormRequestError(evt, handlerOptions, submitBtn);
        });

        // Callback function
        if (isFunction(handlerOptions.onLoadCallback)) {

            XHR.addEventListener('loadend', function(evt) {
                handlerOptions.onLoadCallback(evt, handlerOptions);
            });
        }

        // Set up our request
        XHR.open("POST", generatePostURL(form.getAttribute('action')));

        // Make the request
        XHR.send(formData);
    }

    /**
     * Respond to the form submission LOAD event
     *
     * @param {ProgressEvent} evt The event as part of the XHR request
     * @param {Object} handlerOptions The handler options for the form (as set in initFormSubmissionHandler())
     *
     * @returns void
     */
    function onFormRequestSuccess(evt, handlerOptions) {
        const responseElementId = uuidv4();
        const replacePreviousResponse = handlerOptions.formElement.parentNode.classList.contains('js-ajax-form-response-wrapper');
        let responseElement = document.createElement('div');
        let responseElementWrapper = document.createElement('div');
        let responseElementScripts = null;

        // Set the ID on our wrapper so we can target it later (there may be multiple AJAX forms on one page)
        responseElementWrapper.setAttribute('id', responseElementId);

        // Set a hook Class on our wrapper so we can identify it later
        responseElementWrapper.setAttribute('class', 'js-ajax-form-response-wrapper');

        // Set the response in our new <div>
        responseElementWrapper.innerHTML = evt.currentTarget.response;

        // Check if there are any scripts that will need to be executed
        responseElementScripts = responseElementWrapper.getElementsByTagName('script');

        // Set our new <div> in the container so that we can keep our wrapper markup when inserting it into the DOM
        responseElement.appendChild(responseElementWrapper);

        // Insert the wrapper and response into the page
        if (replacePreviousResponse) {
            // There has already been a response, so insert the new response before that wrapper
            handlerOptions.formElement.parentNode.insertAdjacentHTML('beforebegin', responseElement.innerHTML);
        } else {
            // Insert the response before the original form
            handlerOptions.formElement.insertAdjacentHTML('beforebegin', responseElement.innerHTML);
        }

        // Check if the response contains another form (as a direct child)
        // E.g. for multi-page forms or when server-side validation has failed
        for (var i = 0, j = responseElementWrapper.childNodes.length; i < j; ++i) {
            if (responseElementWrapper.childNodes[i].nodeName.toLowerCase() === 'form') {

                // Intialise the new form using the previous configuration options
                const formOptions = {};

                // Copy the previous form handler options
                for (let prop in handlerOptions) {
                    formOptions[prop] = handlerOptions[prop];
                }

                // Set the new form as the target form
                formOptions.formElement = document.getElementById(responseElementId).childNodes[i];

                initFormSubmissionHandler(formOptions);
                break;
            }
        }

        // Remove the current form
        if (replacePreviousResponse) {
            // There has already been a response, so remove that wrapper
            handlerOptions.formElement.parentNode.parentNode.removeChild(handlerOptions.formElement.parentNode);
        } else {
            // Delete the original form (which won't have our wrapper)
            handlerOptions.formElement.parentNode.removeChild(handlerOptions.formElement);
        }

        // Run any scripts that are included with the response
        if (responseElementScripts.length > 0) {
            for (var n = 0, b = responseElementScripts.length; n < b; ++n) {
                if (responseElementScripts[n].getAttribute('src') !== null) {
                    // The JavaScript is loaded into the page from another source

                    // Create a new <script> node
                    let newScript = document.createElement('script');

                    // Set the script source to match the original
                    newScript.setAttribute('src', responseElementScripts[n].getAttribute('src'));

                    // Traverse the DOM to get the original script node
                    let originalScript = document.getElementById(responseElementId).getElementsByTagName('script')[n];

                    // Insert our new script directly before the original
                    originalScript.parentNode.insertBefore(newScript, originalScript);
                } else {
                    // The JavaScript is inline, so try to run it directly
                    try {
                        eval(responseElementScripts[n].innerHTML);
                    } catch (error) {
                        console.error('Error parsing form inline JavaScript. Error: ', error);
                    }
                }
            }
        }

        // Scroll to the top of the page (if enabled)
        // This works well in Firefox and Chrome.
        // For Safari, Edge and IE11, it just snaps to the top of the page.
        if (handlerOptions.scrollOnLoad) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
        }
    }

    /**
     * Respond to the form submission ERROR event
     *
     * @param {ProgressEvent} evt The event as part of the XHR request
     * @param {Object} handlerOptions The handler options for the form (as set in initFormSubmissionHandler())
     * @param {HTMLElement} submitButton The button that initiated the form submission. Optional.
     *
     * @returns void
     */
    function onFormRequestError(evt, handlerOptions, submitButton) {
        console.log('Oops! Something went wrong.', evt);

        // Re-enable the submit button
        if (submitButton) {
            submitButton.removeAttribute("disabled");
        }

        // Hide the loading message
        handlerOptions.formElement.getElementsByClassName('js-loading-message')[0].classList.remove('is-active');
    }

    /**
     * Continue a previously saved form submission
     * Retrieve the resume form contents from Matrix
     *
     * @param {Object} handlerOptions The handler options for the form (as set in initFormSubmissionHandler())
     * @param {String} queryString The query string to use to continue the form submission. e.g. SQ_FORM_12345_SUBMISSION=123&SQ_FORM_12345_PAGE=1&SQ_FORM_12345_ACCESSID=123cd321cd347
     *
     * @returns void
     */
    function continueFormSubmission(handlerOptions, queryString) {
        const XHR = new XMLHttpRequest();

        // Show the loading message
        handlerOptions.formElement.getElementsByClassName('js-loading-message')[0].classList.add('is-active');

        // Respond to requests to GET the form
        XHR.addEventListener('load', function(evt) {
            onFormRequestSuccess(evt, handlerOptions);
        });

        // Respond to request errors
        XHR.addEventListener('error', function(evt) {
            onFormRequestError(evt, handlerOptions);
        });

        // Callback function
        if (Object.keys(handlerOptions.onLoadCallback).length !== 0 && isFunction(handlerOptions.onLoadCallback)) {

            XHR.addEventListener('loadend', function(evt) {
                handlerOptions.onLoadCallback(evt, handlerOptions);
            });
        }

        // Set up our request
        XHR.open("GET", handlerOptions.formElement.getAttribute('action') + '?' + queryString);

        // Make the request
        XHR.send();
    }

    /**
     * Add the Matrix URL suffix to the URL that will remove all Designs and Paint Layouts
     *
     * @param {String} formAction The form request action (action attribute)
     *
     * @returns Form URL as a String
     */
    function generatePostURL(formAction) {
        const urlSuffix = 'SQ_ASSET_CONTENTS_RAW';

        // Check if there are already query parameters
        if (formAction.indexOf('?') !== -1) {
            return formAction + '&' + urlSuffix;
        } else {
            return formAction + '?' + urlSuffix;
        }
    }

    /**
     * Create a new loading message
     *
     * @returns HTML as a String
     */
    function creatingLoadingMarkup(loadingMessage) {
        return '<div class="js-loading-message loading-container"><div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div>' + loadingMessage + '</div>';
    }

    /**
     * Check if an Object is a function
     *
     * @param {Object} functionToCheck The Object to check
     *
     * @returns Boolean
     */
    function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    /**
     * Generate a (mostly) unique ID in an RFC4122 version 4 compliant format
     *
     * @returns String
     *
     * @source https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
     */
    function uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, function(c) {
        return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
      });
    }
}