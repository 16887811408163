/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

// eslint-disable-next-line import/prefer-default-export
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export class FunnelbackAutocompleteAdapter {
    constructor(adapter) {
        if (!adapter) {
            throw new Error(
                `adapter is required when instantiating a new FunnelbackAutocompleteAdapter object`
            );
        }

        [`funnelback`].forEach((name) => {
            if (!adapter[name]) {
                throw new Error(
                    `${name} property is required when instantiating a new FunnelbackAutocompleteService object`
                );
            }
        });

        this.autocomplete = adapter.funnelback;
    }

    /**
     * Prepare ajax call to service provider.
     *
     * @param {string} query Query string.
     * @param {function} fakeFetch Unit test mock.
     *
     * @returns {*}
     */
    getResults(query, fakeFetch) {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json' },
        };

        const queryParam = this.autocomplete.queryParam
            ? `${this.autocomplete.queryParam}=${query}`
            : `partial_query=${query}`;

        const params =
            (this.autocomplete.params && this.autocomplete.params.split('&')) ||
            [];

        params.push(queryParam);

        const fetchUrl = `${this.autocomplete.serviceUrl}?${params.join('&')}`;

        if (fakeFetch) {
            return fakeFetch(fetchUrl, requestOptions);
        }

        return axios.get(fetchUrl, requestOptions);
    }
}
