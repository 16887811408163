/**
 * Defines the valid view types the calendar component can be placed in
 */
export const CalendarViews = Object.freeze({
    Day: Symbol('Day'),
    Week: Symbol('Week'),
    Month: Symbol('Month'),
    Schedule: Symbol('Schedule'),
});

/**
 * Defines the valid event action types, calendar will fallback to using
 * OpenInModal if no option is chosen or a link is not provided.
 */
export const EventActions = Object.freeze({
    OpenAsLink: Symbol('OpenAsLink'),
    OpenInNewPage: Symbol('OpenInNewPage'),
    OpenInModal: Symbol('OpenInModal'),
});
