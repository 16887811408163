import React, { useState, useCallback } from 'react';
import BookmarkContext from './BookmarkContext';

export default ({ storageKey, children }) => {
    const [isLoading] = useState(false);
    const [isReady] = useState(true);
    const [bookmarks, setBookmarks] = useState([]);

    const getBookmarks = useCallback(() => {
        const localBookmarks = window.localStorage.getItem(storageKey);
        if (localBookmarks) {
            setBookmarks(Array.from(JSON.parse(localBookmarks)));
        }
    }, []);

    const updateBookmarks = useCallback((newBookmarks) => {
        window.localStorage.setItem(storageKey, JSON.stringify(newBookmarks));
        setBookmarks(newBookmarks);
    }, []);

    return (
        <BookmarkContext.Provider
            value={{
                isLoading,
                isReady,
                bookmarks,
                getBookmarks,
                updateBookmarks,
            }}
        >
            {children}
        </BookmarkContext.Provider>
    );
};
