/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { Collapse } from '../../collapsible/js/global';

export default function _aside() {
    /**
     * Main wrapper selector.
     * @type {string}
     */
    const ASIDE_SELECTOR = '[data-component="aside-nav"]';

    /**
     * Elements we will be hiding or showing.
     * @type {string}
     */
    const ASIDE_GROUP_SECTION = '[data-component="aside-section"]';

    /**
     * Button firing actions to hide/show elements.
     * @type {string}
     */
    const ASIDE_TOGGLE_BUTTON = '[data-component="aside-toggle"]';

    /**
     * Content class selector.
     * @type {string}
     */
    const ASIDE_CONTENT = '.aside-nav__list';

    /**
     * Class name indicating element is expanded.
     * @type {string}
     */
    const OPEN_CLASS = 'is-expanded';

    /**
     * Create a new Aside navigation functionality.
     * @param {HTMLElement} asideNavigation - The wrapping HTML element of the aside navigation.
     * @class
     */
    class Aside {
        constructor(asideNavigation) {
            // Outer wrapper for aside menu.
            this.asideSelection = asideNavigation;
            // Gets all menu sections.
            this.asideSections =
                this.asideSelection.querySelectorAll(ASIDE_GROUP_SECTION);
            // Setup collapse to all sections.
            this.setupCollapse(this.asideSections);
        }

        /**
         * Attach instance of Collapse class to each of aside menu section.
         *
         * @param {object} asideSections List of DOM nodes matching menu sections.
         *
         * @returns void
         */
        setupCollapse(asideSections) {
            asideSections.forEach((asideGroup) => {
                // Get toggle button.
                const button = asideGroup.querySelector(ASIDE_TOGGLE_BUTTON);
                const content = asideGroup.querySelector(ASIDE_CONTENT);

                // Quit if collapse already attached or we are missing toggle  button.
                if (asideGroup.collapse || !button) {
                    return;
                }
                // Attach instance of Collapse to menu section.
                asideGroup.collapse = new Collapse();

                const { collapse } = asideGroup;

                // Let the collapse whats the name of expanding class.
                collapse.contentOpenClass = OPEN_CLASS;
                collapse.buttonActiveClassString = OPEN_CLASS;

                // Let the collapse know what are the toggle button and content.
                collapse.collapseButton = button;
                collapse.collapseContent = content;
                collapse.collapseWrapper = asideGroup;

                // Attach click listener and callback to toggle button.
                collapse.collapseButton.addEventListener(
                    'click',
                    collapse.toggleOpenState
                );
                const isOpen =
                    collapse.collapseButton.classList.contains(OPEN_CLASS);

                isOpen ? collapse.openElement() : collapse.closeElement();
            });
        }
    }

    const aside = document.querySelector(ASIDE_SELECTOR);

    if (!aside || aside.asideSelection) {
        return;
    }

    aside.asideSelection = new Aside(aside);
}

_aside();
