/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Notifications Application context.
 * @export
 */
const NotificationsContext = createContext({
    isLoading: false,
    notifications: [],
    getNotifications: null,
    isMoreNotifications: false,

    setRead: null,
    unreadNotifications: 0,
    getUnreadNotificationsCount: null,

    // Optional
    pinnedNotifications: [],
    getPinnedNotifications: null,
    setPinned: null,
});

// Share is used for both 'notifications' and 'pinnedNotifications'
const notificationShape = PropTypes.arrayOf(
    PropTypes.shape({
        // Base properties
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        title: PropTypes.string.isRequired,
        body: PropTypes.string,
        createdDate: PropTypes.instanceOf(Date).isRequired,
        pinned: PropTypes.bool,

        // Assignment
        course: PropTypes.string,
        dueDate: PropTypes.instanceOf(Date),
        externalLink: PropTypes.string,
    })
);

NotificationsContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        notifications: notificationShape.isRequired,
        getNotifications: PropTypes.func,
        isMoreNotifications: PropTypes.bool.isRequired,

        setRead: PropTypes.func,
        unreadNotifications: PropTypes.number,
        getUnreadNotificationsCount: PropTypes.func,

        pinnedNotifications: notificationShape,
        getPinnedNotifications: PropTypes.func,
        setPinned: PropTypes.func,
    }),
};

export default NotificationsContext;
