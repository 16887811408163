/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, {
    useState,
    useCallback,
    useEffect,
    useRef,
    createRef,
    useContext,
} from 'react';
import { OverlayProvider } from '@react-aria/overlays';

import uuidv4 from 'PlugAndPlay/_global/js/uuid';
import BookmarkContext from './data/BookmarkContext';

import Modal from '../../modal/jsx/modal';
import Card from '../../collapsible-card/jsx/Card';

/**
 * @param url string
 *
 * return true/false if the URL is a valid HTTP or HTTPS URL
 */
const isValidURL = (url) => {
    const res = url.match(
        /^(((H|h)(T|t)(T|t)(P|p)(S|s)?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,100}\.[a-zA-Z]{2,10}(\/([-a-zA-Z0-9@:%_+.~#?&//=]*))?/
    );

    return res !== null;
};

/**
 * Returns the possible favicon URL for the specified URL.
 *
 * @param {string} url The URL.
 * @param {number} size The icon size.
 *
 * @returns {string} The favicon URL.
 */
const getFaviconURL = (url, size = 24) => {
    let faviconURL = ``;
    try {
        const linkURL = new URL(url);

        // Use the Google service to retrieve the favicon. Optional param = &sz=64.
        faviconURL = `https://www.google.com/s2/favicons?domain=${linkURL.hostname}&sz=${size}`;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`invalid URL: ${url}`);
    }

    return faviconURL;
};

/**
 * Add protocol to URL.
 *
 * @param {string} url The URL to test.
 *
 * @returns {string} URL with protocol.
 */
const addProtocol = (url) => {
    let _url = url;
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        _url = `https://${url}`;
    }
    return _url;
};

export function QuickLinksDialog({
    currentLinks,
    initialView,
    maxLinks,
    onDialogClose,
    initialTitle,
    initialSubtitle,
    onSave,
    formSpec,
}) {
    const urlTimer = useRef(null);
    const urlRef = createRef(null);

    const [view, setView] = useState(initialView);
    const [title, setTitle] = useState(initialTitle);
    const [subtitle, setSubtitle] = useState(initialSubtitle);
    const [links, setLinks] = useState(currentLinks);

    const [editIndex, setEditIndex] = useState(null);
    const [urlValue, setURLValue] = useState(``);
    const [URLFieldError, setURLFieldError] = useState(``);
    const [titleValue, setTitleValue] = useState(``);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const editBookmark = (e, link, index) => {
        setURLValue(link.url);
        setTitleValue(link.title);
        setView(`edit`);
        setTitle(`Update Bookmark`);
        setSubtitle(``);
        setDisableSaveButton(false);
        setEditIndex(index);
    };

    const addBookmark = () => {
        setView(`add`);
        setTitle(`Add bookmark`);
        setSubtitle(``);
        setURLValue(``);
        setTitleValue(``);
        setDisableSaveButton(true);
    };

    const handleGoBack = () => {
        setView(`list`);
        setTitle(initialTitle);
        setSubtitle(initialSubtitle);
    };

    const handleDeleteBookmark = (e, index) => {
        const newLinks = [...links];
        newLinks.splice(index, 1);
        setLinks(newLinks);
        setDisableSaveButton(false);

        if (onSave) {
            onSave(newLinks);
        }

        if (newLinks.length <= 0) {
            addBookmark();
        }
    };

    const handleSaveBookmark = () => {
        const newLinks = [...links];
        if (editIndex !== null && view === `edit`) {
            newLinks[editIndex].title = titleValue;
            newLinks[editIndex].url = addProtocol(urlValue);
        }
        if (view === `add` || view === `add-only`) {
            if (urlValue) {
                newLinks.push({
                    title: titleValue,
                    url: addProtocol(urlValue),
                });
            }
        }

        setLinks(newLinks);
        onSave(newLinks);
        if (view === `add-only`) {
            onDialogClose();
            return;
        }
        setView(`list`);
    };

    useEffect(() => {
        if (urlRef && urlRef.current) {
            urlRef.current.focus();
        }
    }, [view]);

    const handleURLChange = useCallback((event) => {
        const { value } = event.target;
        setURLValue(value);
        clearTimeout(urlTimer.current);
        setDisableSaveButton(true);
        urlTimer.current = setTimeout(() => {
            if (value.trim().length === 0) {
                setDisableSaveButton(true);
                setURLFieldError(`URL link is required`);
            } else if (isValidURL(value) === false) {
                setDisableSaveButton(true);
                setURLFieldError(`The given URL link is invalid`);
            } else {
                setDisableSaveButton(false);
                setURLFieldError(``);
            }
        }, 1000);
    }, []);

    const handleTitleChange = useCallback(
        (event) => {
            setTitleValue(event.currentTarget.value);

            if (urlValue.length > 0 && URLFieldError !== ``) {
                setDisableSaveButton(false);
            }
        },
        [urlValue]
    );

    const handleEnterPress = useCallback(
        (event) => {
            if (event.keyCode === 13 && URLFieldError === ``) {
                handleSaveBookmark();
            }
        },
        [URLFieldError, titleValue, urlValue]
    );

    function customFooter(canSave) {
        return (
            <footer
                className={[
                    `quicklinks-modal__footer`,
                    view !== 'list' && `quicklinks-modal__footer-right-aligned`,
                ].join(' ')}
            >
                {view === 'list' ? (
                    <>
                        {links && links.length === maxLinks ? (
                            <p className="quicklinks__dialog-limit-message">
                                You have reached your bookmark limit.
                            </p>
                        ) : (
                            <div className="quicklinks-modal__footer-buttons-container">
                                <button
                                    aria-label="Add new bookmark"
                                    type="button"
                                    className="quicklinks-modal__footer-button quicklinks-modal__footer-button--dashed"
                                    onClick={(e) => addBookmark(e)}
                                >
                                    <svg className="svg-icon">
                                        <use href="#add" />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="quicklinks-modal__footer-buttons-container">
                        {links && links.length > 0 && (
                            <button
                                type="button"
                                className="quicklinks-modal__footer-button"
                                aria-label="Go back"
                                onClick={(e) => handleGoBack(e)}
                            >
                                Go Back
                            </button>
                        )}
                        <button
                            type="button"
                            aria-label="Save new bookmark"
                            disabled={canSave}
                            className="quicklinks-modal__footer-button quicklinks-modal__footer-button--save"
                            onClick={(e) => handleSaveBookmark(e)}
                        >
                            Save
                        </button>
                    </div>
                )}
            </footer>
        );
    }

    const titleId = uuidv4(); // Modal needs a id for the dialog described by
    return (
        <OverlayProvider>
            <Modal
                titleId={titleId}
                className="quicklinks-modal"
                onClose={onDialogClose}
            >
                <header className="quicklinks-modal__header">
                    <h1 id={titleId} className="quicklinks-modal__title">
                        {title}
                    </h1>
                    {subtitle && (
                        <h2 className="quicklinks-modal__subtitle">
                            {subtitle}
                        </h2>
                    )}
                    <button
                        type="button"
                        onClick={onDialogClose}
                        className="quicklinks-modal__header-close"
                    >
                        <svg className="svg-icon svg-icon-">
                            <title>Close modal</title>
                            <use href="#close" />
                        </svg>
                    </button>
                </header>

                <div className="quicklinks-modal__body">
                    {view === `list` && (
                        <>
                            {links &&
                                links.map((link, index) => {
                                    return (
                                        <div
                                            className="quicklinks-modal__container"
                                            key={index}
                                        >
                                            <button
                                                type="button"
                                                aria-label={`Edit bookmark ${link.title}`}
                                                className="quicklinks-modal__edit-button"
                                                onClick={(e) =>
                                                    editBookmark(e, link, index)
                                                }
                                            >
                                                <div className="bookmark-icon">
                                                    <img
                                                        alt="link icon"
                                                        className="bookmark-icon-image"
                                                        height={24}
                                                        src={getFaviconURL(
                                                            link.url
                                                        )}
                                                        loading="lazy"
                                                        width={24}
                                                    />
                                                </div>
                                                <div className="quicklinks-modal__edit-button-title">
                                                    {link.title}
                                                </div>
                                                <svg className="svg-icon">
                                                    <use href="#edit" />
                                                </svg>
                                            </button>

                                            <button
                                                type="button"
                                                aria-label={`Delete bookmark ${link.title}`}
                                                className="quicklinks-modal__delete-button"
                                                onClick={(e) =>
                                                    handleDeleteBookmark(
                                                        e,
                                                        index
                                                    )
                                                }
                                            >
                                                <svg className="svg-icon">
                                                    <use href="#subtract-alt" />
                                                </svg>
                                            </button>
                                        </div>
                                    );
                                })}
                        </>
                    )}
                    {(view === `add` ||
                        view === `add-only` ||
                        view === `edit`) && (
                        <div className="form custom-form custom-form--color-grey70">
                            <div
                                className="sq-form-question sq-form-question-text"
                                id="sq_form_field_wrapper_bookmark-url"
                            >
                                <label
                                    className="sq-form-question-title"
                                    htmlFor="bookmark-url-input"
                                >
                                    Link
                                    <abbr
                                        className="sq-form-required-field"
                                        title="required"
                                    >
                                        *
                                    </abbr>
                                </label>
                                <div className="sq-form-question-answer">
                                    <input
                                        ref={urlRef}
                                        autoComplete="off"
                                        autoCapitalize="off"
                                        type="text"
                                        name="bookmark-url"
                                        size="30"
                                        maxLength="300"
                                        placeholder={
                                            formSpec.linkUrlPlaceholder
                                        }
                                        id="bookmark-url-input"
                                        className="sq-form-field"
                                        value={urlValue}
                                        onChange={handleURLChange}
                                        onKeyDown={handleEnterPress}
                                    />
                                </div>
                                <span className="sq-form-error">
                                    {URLFieldError}
                                </span>
                            </div>
                            <div
                                className="sq-form-question sq-form-question-text"
                                id="sq_form_field_wrapper_bookmark-title"
                            >
                                <label
                                    className="sq-form-question-title"
                                    htmlFor="bookmark-tile-input"
                                >
                                    Name of bookmark
                                </label>
                                <div className="sq-form-question-answer">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="bookmark-title"
                                        size="30"
                                        maxLength="300"
                                        placeholder={
                                            formSpec.linkTitlePlaceholder
                                        }
                                        id="bookmark-tile-input"
                                        className="sq-form-field"
                                        value={titleValue}
                                        onChange={handleTitleChange}
                                        onKeyDown={handleEnterPress}
                                    />
                                </div>
                                <em className="sq-form-question-note">
                                    Try to keep it as short as possible.
                                </em>
                            </div>
                        </div>
                    )}
                </div>
                {customFooter(disableSaveButton)}
            </Modal>
        </OverlayProvider>
    );
}

function QuickLinksList({
    links,
    maxLinks,
    dialogView,
    addBookmarks,
    onDialogClose,
    onSave,
    formSpec,
    listSpec,
}) {
    const handleClick = (e, location) => {
        if (listSpec.openLinkTarget === '_blank') {
            window.open(location, '_blank');
        } else {
            window.location.href = location;
        }
    };

    return (
        <div className="quicklinks__list">
            {links &&
                links.map((link, index) => {
                    return (
                        <div className="quicklinks__link" key={index}>
                            <button
                                type="button"
                                aria-label={`Open bookmark ${link.title}`}
                                className="quicklinks__button"
                                onClick={(e) => handleClick(e, link.url)}
                            >
                                <div className="quicklinks__link-icon">
                                    <img
                                        alt="link icon"
                                        className="quicklinks__link-icon-image"
                                        height={24}
                                        src={getFaviconURL(link.url)}
                                        loading="lazy"
                                        width={24}
                                    />
                                </div>
                                <div className="quicklinks__link-title">
                                    {link.title}
                                </div>
                            </button>
                        </div>
                    );
                })}
            {(!maxLinks || links.length < maxLinks) && (
                <div className="quicklinks__link">
                    <button
                        type="button"
                        aria-label="Add new bookmark"
                        className="quicklinks__button quicklinks__button--dashed"
                        onClick={addBookmarks}
                    >
                        <svg className="svg-icon">
                            <use href="#add" />
                        </svg>
                    </button>
                </div>
            )}
            {dialogView === `list` && (
                <QuickLinksDialog
                    currentLinks={links}
                    maxLinks={maxLinks}
                    onDialogClose={onDialogClose}
                    initialView={dialogView}
                    initialTitle="Bookmarks"
                    initialSubtitle="Add up to four bookmarks."
                    onSave={onSave}
                    formSpec={formSpec}
                />
            )}
            {dialogView === `add-only` && (
                <QuickLinksDialog
                    currentLinks={links}
                    maxLinks={maxLinks}
                    onDialogClose={onDialogClose}
                    initialView={dialogView}
                    initialTitle="New bookmark"
                    onSave={onSave}
                    formSpec={formSpec}
                />
            )}
        </div>
    );
}

export function LoadingState({ numOfItems = 4 }) {
    return (
        <div className="quicklinks__list quicklinks__list--loading">
            {[...Array(numOfItems)].map((item, index) => (
                <div className="quicklinks-modal__container" key={index} />
            ))}
        </div>
    );
}

function ErrorState() {
    return (
        <div className="quicklinks__list">
            <p>Oops looks like something went wrong</p>
        </div>
    );
}

export default function Bookmarks({ title, maxLinks, formSpec, listSpec }) {
    const [showEditBookmarks, setShowEditBookmarks] = useState(false);
    const [dialogView, setDialogView] = useState('list');
    const {
        bookmarks,
        getBookmarks,
        updateBookmarks,
        isLoading,
        isReady,
        error,
    } = useContext(BookmarkContext);

    useEffect(() => {
        (async () => {
            await getBookmarks();
            if (bookmarks.length <= 0) {
                setDialogView('add-only');
            } else {
                setDialogView('list');
            }
        })();
    }, []);

    const editBookmarks = useCallback(() => {
        if (bookmarks.length <= 0) {
            setDialogView('add-only');
        } else {
            setDialogView('list');
        }
        setShowEditBookmarks(true);
    }, [bookmarks]);

    const addBookmarks = useCallback(() => {
        setDialogView('add-only');
        setShowEditBookmarks(true);
    }, []);

    const handleDialogClose = useCallback(() => {
        setShowEditBookmarks(false);
    }, []);

    const handleSave = useCallback((newBookmarks) => {
        (async () => {
            await updateBookmarks(newBookmarks);
        })();
    }, []);

    const headingIcon = () => {
        return (
            <button
                type="button"
                aria-label="Edit bookmarks"
                onClick={editBookmarks}
                className="heading-icon"
            >
                <svg className="svg-icon">
                    <use href="#edit" />
                </svg>
            </button>
        );
    };

    return (
        <Card
            headingIcon={headingIcon()}
            title={title}
            wrapperClasses="quicklinks"
            contentClasses="quicklinks__container"
        >
            {isLoading && <LoadingState numOfItems={maxLinks} />}
            {error && <ErrorState />}
            {isReady && (
                <QuickLinksList
                    dialogView={
                        showEditBookmarks === true ? dialogView : undefined
                    }
                    links={bookmarks}
                    maxLinks={maxLinks}
                    onDialogClose={handleDialogClose}
                    onSave={handleSave}
                    formSpec={formSpec}
                    listSpec={listSpec}
                    addBookmarks={addBookmarks}
                />
            )}
        </Card>
    );
}

Bookmarks.defaultProps = {
    maxLinks: 4,
    title: ``,
    documentId: 666,
    listSpec: {
        openLinkTarget: `_blank`,
    },
    formSpec: {
        linkUrlPlaceholder: `Enter your link url`,
        linkTitlePlaceholder: `Enter your link title`,
    },
};
