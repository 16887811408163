import React from 'react';
import DOMPurify from 'dompurify';
import { formatDate } from '@fullcalendar/react';

import BaseCalendarEvent from './BaseCalendarEvent';

/**
 * Assignment type event rendering templates.
 */
export default class AssignmentCalendarEvent extends BaseCalendarEvent {
    /**
     * Assignments use the courseCode instead of the event title
     * @returns {string} title to use in FullCalendar event displays
     */
    getCalendarItemTitle() {
        return this.event.courseCode;
    }

    /**
     * Get the title to show in the modal popup's H1 tag
     * @returns {string} title
     */
    getModalTitle() {
        return this.event.courseName;
    }

    /**
     * Get the modal body content for this type of event
     * @returns {JSX Object} content
     */
    getModalContent() {
        let { start } = this.event;
        const { type, title, description, submissionType, recurrence, url } =
            this.event;

        const isRecurring = recurrence && recurrence.length !== 0;
        if (isRecurring) {
            ({ start } = this.fcEvent); // Just a destruction without a declaration (let/const)
        }

        // Use FullCalendar utility to format the dates into the structure we need for display
        const dueDate = formatDate(start, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
        }); // e.g. December 23, 2021, 2:15 PM

        const cleanDescription = DOMPurify.sanitize(description);

        return (
            <div
                className={`calendar-event-details calendar-event-details--${type}`}
            >
                <div className="calendar-event-details__due">
                    <h2 className="calendar-event-details__due-title">
                        Assignment due
                    </h2>
                    {`Due `}
                    <time
                        dateTime={dueDate}
                        className="calendar-event-details__due-date"
                    >
                        {dueDate}
                    </time>

                    <svg aria-hidden className="svg-icon">
                        <use href="#edit" />
                    </svg>
                </div>
                <h2 className="calendar-event-details__sub-title">{title}</h2>
                <div
                    // eslint-disable-next-line react/no-danger -- sanitize using DOMPurify above
                    dangerouslySetInnerHTML={{ __html: cleanDescription }}
                    className="calendar-event-details__description"
                />
                {submissionType && (
                    <div className="calendar-event-details__submit-method">
                        <svg aria-hidden className="svg-icon">
                            <use href="#information" />
                        </svg>
                        {`Submit `}
                        {submissionType}
                    </div>
                )}
                {url && (
                    <div className="calendar-event-details__submit-note">
                        More details available in Canvas
                    </div>
                )}
            </div>
        );
    }

    /**
     * Get the footer controls for this type of event
     * @returns {array} array of additional footer controls
     */
    getModalFooterControls() {
        const additionalControls = [];

        if (this.event.url) {
            additionalControls.push({
                url: this.event.url,
                icon: 'external',
                label: 'Canvas',
            });
        }

        return additionalControls;
    }
}
