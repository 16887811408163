export default function _funnelbackSearchResults() {
    /**
     * Main wrapper selector.
     * @type {string}
     */
    const FORM_SELECTOR = '[data-component="funnelback-search-results"]';

    /**
     * Sorting hidden field.
     * @type {string}
     */
    const HIDDEN_SORT = 'input[name="sort"]';

    /**
     * Sorting hidden num ranks field.
     * @type {string}
     */
    const HIDDEN_NUM_RANKS = 'input[name="num_ranks"]';

    /**
     * Create a new Form handler functionality.
     * @param {HTMLElement} funnelback form - The wrapping HTML element of the form.
     * @class
     */
    class FunnelbackSearchResults {
        constructor(formWrapper) {
            // Outer wrapper for aside menu.
            this.formWrapper = formWrapper;
            // Gets hidden sort field.
            this.hiddenSort = this.formWrapper.querySelector(HIDDEN_SORT);
            // Gets hidden num_ranks field.
            this.hiddenNumRanks =
                this.formWrapper.querySelector(HIDDEN_NUM_RANKS);

            // Setup event listeners.
            this.attachChangeEvents();
        }

        /**
         * Attach event listeners to select fields.
         *
         * @returns void
         */
        attachChangeEvents() {
            const sortDropdown = document.querySelector('#sort');

            if (sortDropdown && this.hiddenSort) {
                sortDropdown.addEventListener('change', (event) => {
                    this.hiddenSort.value = event.target.value;
                });
            }

            const numRanksDropdown = document.querySelector('#per_page');

            if (numRanksDropdown && this.hiddenNumRanks) {
                numRanksDropdown.addEventListener('change', (event) => {
                    this.hiddenNumRanks.value = event.target.value;
                });
            }
        }
    }

    const form = document.querySelector(FORM_SELECTOR);

    if (!form || form.handler) {
        return;
    }

    form.handler = new FunnelbackSearchResults(form);
}

_funnelbackSearchResults();
