/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import getJWT from 'Edify/js/getJwt';
import EdifyGpaProvider from './data/EdifyGpaProvider';
import { LoadingState } from './analytics';

const appWrapper = document.querySelectorAll('[data-component="analytics"]');

if (appWrapper.length > 0) {
    // Lazy load the main Analytics 'app'
    const Analytics = React.lazy(() =>
        import(/* webpackChunkName: "AnalyticsProvider" */ './analytics')
    );

    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            const { dataset } = wrapper;
            const configuration = JSON.parse(dataset.analyticsConfiguration);

            const datastore = new Datastore({
                serviceURL: configuration.datasourceUrl,
                jwtCallback: () => getJWT(configuration.datasourceJWT),
            });

            ReactDOM.render(
                <Suspense fallback={<LoadingState />}>
                    <EdifyGpaProvider datastore={datastore}>
                        <Analytics {...configuration} />
                    </EdifyGpaProvider>
                </Suspense>,
                wrapper
            );
        });
    });
}
