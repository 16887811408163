/* eslint-disable no-bitwise */
/**
 * Generate a (mostly) unique ID in an RFC4122 version 4 compliant format
 * Function ported from initFormSubmissionHandler.
 *
 * @returns String
 *
 * @source https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
 */
export default function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}
