/* eslint-disable no-unused-expressions */
import { Collapse } from '../../collapsible/js/global';

const FACET_COMPONENT_SELECTOR = '[data-component="facet"]';

const FACET_GROUP_SELECTOR = '[data-component="facet-group"]';

const FACET_GROUP_CONTROL_SELECTOR = '[data-component="facet-group-control"]';

const FACET_GROUP_CONTENT_SELECTOR = '[data-component="facet-group-content"]';

const FACET_GROUP_CONTROL_OPEN_CLASS = 'facet-group__title--open';

const FACET_GROUP_CONTENT_OPEN_CLASS = 'facet-group__list--open';

const FACET_GROUP_CONTENT_EXPANDING_CLASS = 'facet-group__list--expanding';

const FACET_GROUP_CONTENT_COLLAPSING_CLASS = 'facet-group__list--collapsing';

const FACET_GROUP_SHOW_MORE_BUTTON_SELECTOR =
    '[data-component="facet-group-show-more-button"]';

const FACET_GROUP_HIDDEN_ITEM_CLASS = 'facet-group__list-item--hidden';

const FACET_GROUP_ITEM_SELECTOR = '[data-component="facet-group__list-item"]';

const COLLAPSE_ALL_FACETS = '[data-component="collapse-all"]';

const ALL_FACETS_SECTION = 'facet-groups';

class FacetSection {
    constructor(facetSection) {
        this.facetSection = facetSection;
        this.facetGroups =
            this.facetSection.querySelectorAll(FACET_GROUP_SELECTOR);
        this.showMoreItems = this.showMoreItems.bind(this);

        this.setupFacetSectionCollapse();
        this.setupFacetGroupCollapse();
        this.initialiseShowMoreButtons();
    }

    setupFacetSectionCollapse() {
        if (!this.facetSection) {
            return;
        }

        // eslint-disable-next-line no-param-reassign
        this.facetSection.collapse = new Collapse();

        // setup a collapse variable to make setting properties a little more readable
        const { collapse } = this.facetSection;

        // Define the collapse wrapper
        collapse.collapseWrapper = this.facetSection;

        // Sets that this item should animate
        collapse.shouldAnimate = true;

        // Set the elements of the collapse
        collapse.collapseButton =
            this.facetSection.querySelector(COLLAPSE_ALL_FACETS);

        // Set the collapse content
        collapse.collapseContent = this.facetSection.querySelector(
            `.${ALL_FACETS_SECTION}`
        );

        // Set our collapse classes
        collapse.buttonActiveClass = FACET_GROUP_CONTROL_OPEN_CLASS;

        collapse.contentOpenClass = `${ALL_FACETS_SECTION}--open`;
        collapse.contentExpandingClass = `${ALL_FACETS_SECTION}--expanding`;
        collapse.contentCollapsingClass = `${ALL_FACETS_SECTION}--collapsing`;

        // Add an event listener to toggle the facet group
        if (!collapse.collapseButton) {
            return;
        }

        collapse.collapseButton.addEventListener(
            'click',
            collapse.toggleOpenState
        );

        // If the collapse button has the open class, make sure it is open
        const isOpen = collapse.collapseButton.classList.contains(
            FACET_GROUP_CONTROL_OPEN_CLASS
        );

        isOpen ? collapse.openElement() : collapse.closeElement();
    }

    // Setup each facet group as a collapsible item
    setupFacetGroupCollapse() {
        this.facetGroups.forEach((facetGroup) => {
            // Check that we havent initilised these before
            if (!facetGroup.collapse) {
                // eslint-disable-next-line no-param-reassign
                facetGroup.collapse = new Collapse();

                // setup a collapse variable to make setting properties a little more redable
                const { collapse } = facetGroup;

                // Define the collapse wrapper
                collapse.collapseWrapper = facetGroup;

                // Sets that this item should animate
                collapse.shouldAnimate = true;

                // Set the elements of the collapse
                collapse.collapseButton = facetGroup.querySelector(
                    FACET_GROUP_CONTROL_SELECTOR
                );

                // Set the collapse content
                collapse.collapseContent = facetGroup.querySelector(
                    FACET_GROUP_CONTENT_SELECTOR
                );

                // Set our collapse classes
                collapse.buttonActiveClass = FACET_GROUP_CONTROL_OPEN_CLASS;
                collapse.contentOpenClass = FACET_GROUP_CONTENT_OPEN_CLASS;
                collapse.contentExpandingClass =
                    FACET_GROUP_CONTENT_EXPANDING_CLASS;
                collapse.contentCollapsingClass =
                    FACET_GROUP_CONTENT_COLLAPSING_CLASS;

                // Add an event listener to toggle the facet group
                collapse.collapseButton.addEventListener(
                    'click',
                    collapse.toggleOpenState
                );

                const isOpen = collapse.collapseButton.classList.contains(
                    FACET_GROUP_CONTROL_OPEN_CLASS
                );

                isOpen ? collapse.openElement() : collapse.closeElement();
            }
        });
    }

    initialiseShowMoreButtons() {
        this.facetGroups.forEach((facetGroup) => {
            // check for a show more button
            const showMoreButtonExists = !!facetGroup.querySelector(
                FACET_GROUP_SHOW_MORE_BUTTON_SELECTOR
            );

            // If there is a show more button
            if (showMoreButtonExists) {
                // Get the show more button
                const showMoreButton = facetGroup.querySelector(
                    FACET_GROUP_SHOW_MORE_BUTTON_SELECTOR
                );

                // Add an on click event listener to the show more button
                showMoreButton.addEventListener('click', this.showMoreItems);
            }
        });
    }

    showMoreItems(event) {
        // Get the facet group by finding the facet group in the triggering events path
        const facetGroup = event.path.find((element) => element.collapse);

        // Get all the list items for the facet group
        const listItems = facetGroup.querySelectorAll(
            FACET_GROUP_ITEM_SELECTOR
        );

        // For each item remove the hidden class
        listItems.forEach((listItem) => {
            listItem.classList.remove(FACET_GROUP_HIDDEN_ITEM_CLASS);
        });

        // Remove the show more button from the facet group
        facetGroup
            .querySelector(FACET_GROUP_SHOW_MORE_BUTTON_SELECTOR)
            .remove();
    }
}

export default function _facets() {
    // Check if the facets exist on the page
    const facetsExist = !!document.querySelector(FACET_COMPONENT_SELECTOR);

    function initialiseFacets() {
        // Check if the facets exist
        if (facetsExist) {
            // Get each of the facets from the page
            const facets = document.querySelectorAll(FACET_COMPONENT_SELECTOR);

            // For each facet on the page initialize the facet group
            facets.forEach((facet) => {
                // If the facet group hasn't be initialised before initialize it
                if (!facet.facetSection) {
                    // eslint-disable-next-line no-param-reassign
                    facet.facetSection = new FacetSection(facet);
                }
            });
        }
    }

    initialiseFacets();
}

_facets();
