/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */
import { BaseStorageAdapter } from '../BaseStorageAdapter/BaseStorageAdapter';

// eslint-disable-next-line import/prefer-default-export
export class LocalStorageAdapter extends BaseStorageAdapter {
    constructor(dto = {}) {
        super();
        this.store = dto.store || window.localStorage;
        this.storageKey = dto.storageKey || `@@values@@`;
    }

    /**
     * Delete a specific key.
     * @param {string} key The key.
     * @returns {void}
     */
    delete(key) {
        const allValues = this.getAll();
        if (allValues[key]) {
            delete allValues[key];
        }
        this.setAll(allValues);
    }

    /**
     * Delete all values.
     * @returns {void}
     */
    deleteAll() {
        this.setAll({});
    }

    /**
     * Get a specific value.
     * @param {string} key The key.
     * @returns {any}
     */
    get(key) {
        const allValues = this.getAll();
        return allValues[key] || null;
    }

    /**
     * Get all values.
     * @returns {object}
     */
    getAll() {
        const valuesStr = this.store.getItem(this.storageKey);
        if (valuesStr) {
            return JSON.parse(valuesStr);
        }
        return {};
    }

    /**
     * Set a specific value.
     * @param {string} key The key.
     * @returns {any}
     */
    set(key, value) {
        const values = this.getAll();
        values[key] = value;
        this.setAll(values);
    }

    /**
     * Set all values.
     * @returns {object}
     */
    setAll(values) {
        this.store.clear();
        this.store.setItem(this.storageKey, JSON.stringify(values));
    }
}
