/* eslint-disable max-classes-per-file */
/* eslint-disable no-console */
import { minMediaQueryXL } from 'PlugAndPlay/_global/js/screenSize';
import { HEADER_SEARCH_SELECTOR } from '../../header-search/js/global';

import { Modal } from '../../modal/js/global';
import PrimaryNav from '../../primary-nav/js/global';

/**
 * Hamburger selector
 * @const {string}
 */
const HAMBURGER_MENU_SELECTOR = '[data-component="hamburger-menu"]';

/**
 * Hamburger active/open class
 * @const {string}
 */
const HAMBURGER_MENU_ACTIVE = 'hamburger-menu-wrapper--active';

/**
 * Hamburger submenu toggle control
 * @const {string}
 */
const HAMBURGER_SUB_MENU_TOGGLE =
    '[data-click="hamburger-menu-toggle-submenu"]';

/**
 * Hamburger menu link selector
 * @const {string}
 */
const HAMBURGER_MENU_LINK_SELECTOR = 'a';

/**
 * Hamburger submenu active/open class
 * @const {string}
 */
const HAMBURGER_SUB_MENU_ACTIVE = 'hamburger-menu-item__list--active';

/**
 * Hamburger open control
 * @const {string}
 */
const HAMBURGER_MENU_OPEN = '[data-click="hamburger-menu-open"]';

/**
 * Hamburger close control
 * @const {string}
 */
const HAMBURGER_MENU_CLOSE = '[data-click="hamburger-menu-close"]';

/**
 * Hamburger search control
 * @const {string}
 */
const HAMBURGER_MENU_SEARCH = '[data-click="hamburger-menu-search"]';

/**
 * Create a new Hamburger Menu
 * @param {HTMLElement} Hamburger - The HTML element of the hamburger
 * @class
 */
class HamburgerMenuImpl extends Modal {
    constructor(hamburgerMenu) {
        super(hamburgerMenu);
        this.hamburgerMenu = hamburgerMenu;
        this.hamburgerDialog =
            this.hamburgerMenu.querySelector('[role="dialog"]');
        // Get the links in the menu
        this.hamburgerLinks = this.hamburgerMenu.querySelectorAll(
            HAMBURGER_MENU_LINK_SELECTOR
        );

        const primaryNavInstance = PrimaryNav.getInstance();
        if (primaryNavInstance) {
            this.isDesktopHamburger = primaryNavInstance.isDesktopHamburger();
        }

        this.hamburgerOpen = hamburgerMenu.querySelector(HAMBURGER_MENU_OPEN);
        if (this.hamburgerOpen) {
            this.hamburgerOpen.addEventListener('click', () => {
                this.openMenu();
            });
        }

        // Attach on item click handler
        const subMenuToggles = hamburgerMenu.querySelectorAll(
            HAMBURGER_SUB_MENU_TOGGLE
        );
        subMenuToggles.forEach((subMenuToggle) => {
            subMenuToggle.addEventListener('click', () => {
                this._handleToggleSubMenu(subMenuToggle);
            });
        });

        // Hamburgers menus own close button
        const hamburgerClose =
            hamburgerMenu.querySelector(HAMBURGER_MENU_CLOSE);
        if (hamburgerClose) {
            hamburgerClose.addEventListener('click', () => {
                this.closeMenu();
            });
        }

        // Hamburger menus own search button
        const searchButton = hamburgerMenu.querySelector(HAMBURGER_MENU_SEARCH);
        if (searchButton) {
            searchButton.addEventListener('click', () => {
                this._handleShowSearch();
            });
        }

        // Close the menu if the user clicks on a link
        this.hamburgerLinks.forEach((hamburgerLink) => {
            hamburgerLink.addEventListener('click', () => {
                this.closeMenu();
            });
        });
    }

    /**
     * Public function to open the hamburger menu
     */
    openMenu() {
        // If header search exists and is open, close it.
        document.querySelectorAll(HEADER_SEARCH_SELECTOR).forEach((element) => {
            if (element.headerSearch.isOpen()) {
                element.headerSearch.closeSearch();
            }
        });

        // Open first so internal elements can be focused
        this._transitionMenuOpen();
        // Focus trap the user to the dialog
        super.openModal();

        this._listenForMediaQueryChange();
    }

    /**
     * Public function to close the hamburger menu
     */
    closeMenu() {
        this._stopListeningFoMediaQueryChange();

        // Then run menu close (otherwise focus cant return to the hamburger button)
        this._transitionMenuClosed();

        // Release user from focus trap
        super.closeModal();
    }

    closeModal() {
        this.closeMenu();
    }

    /**
     * Event handler for sub menu item click
     * @param {HTMLElement} subMenu - The sub menu item DOM Element
     */
    _handleToggleSubMenu(subMenuToggle) {
        const subMenu = document.getElementById(
            subMenuToggle.getAttribute('aria-controls')
        );

        if (subMenuToggle.getAttribute('aria-expanded') === 'false') {
            subMenuToggle.setAttribute('aria-expanded', true);
            this._transitionItemMenuOpen(subMenu);
        } else {
            subMenuToggle.setAttribute('aria-expanded', false);
            this._transitionItemMenuClosed(subMenu);
        }
    }

    /**
     * Event handler for search button click
     */
    _handleShowSearch() {}

    // Transition handlers
    _transitionMenuOpen() {
        this.hamburgerOpen.setAttribute('aria-expanded', true);
        this.hamburgerDialog.classList.add(HAMBURGER_MENU_ACTIVE);
    }

    _transitionMenuClosed() {
        this.hamburgerOpen.setAttribute('aria-expanded', false);
        this.hamburgerDialog.classList.remove(HAMBURGER_MENU_ACTIVE);
    }

    _transitionItemMenuOpen(subMenu) {
        subMenu.classList.add(HAMBURGER_SUB_MENU_ACTIVE);
        subMenu.parentNode.classList.add(HAMBURGER_SUB_MENU_ACTIVE);
    }

    _transitionItemMenuClosed(subMenu) {
        subMenu.classList.remove(HAMBURGER_SUB_MENU_ACTIVE);
        subMenu.parentNode.classList.remove(HAMBURGER_SUB_MENU_ACTIVE);
    }

    /**
     * Function to listen to changes to the media query and deactivate the modal ally code
     * when it changes from mobile to tablet/desktop. Does not run if desktop hamburger is enabled.
     */
    _listenForMediaQueryChange() {
        if (!this.isDesktopHamburger) {
            this.watchForMediaQueryChange(
                minMediaQueryXL,
                () => {
                    console.log('change to desktop, undo ally');
                    this.deactivateAccessability();
                },
                () => {
                    console.log('change to desktop, redo ally');
                    this.activateAccessability();
                }
            );
        }
    }

    /**
     * Function to stop listening to the media query when the menu is closed.
     */
    _stopListeningFoMediaQueryChange() {
        this.removeMediaQueryWatch();
    }
}

/**
 * Singelton wrapper for hamburger menu to give other areas of the template
 * access to the menu without having to pull it from the DOM directly.
 */
export default class HamburgerMenu {
    /**
     * Access method to get a hold of the template hamburger menu
     * @returns {HamburgerMenuImpl} - Hamburger menu object
     */
    static getInstance() {
        const hamburgerMenu = document.querySelector(HAMBURGER_MENU_SELECTOR);
        // Hamburger doesnt exist, return null
        if (!hamburgerMenu) {
            return null;
        }

        // Doesnt exist yet, create new menu object
        if (!hamburgerMenu._hamburgerMenu) {
            // Store the actual class against the DOM, as if this is destroyed for any reason it will need to be re-instantiated
            hamburgerMenu._hamburgerMenu = new HamburgerMenuImpl(hamburgerMenu);
        }

        // Return existing menu object
        return hamburgerMenu._hamburgerMenu;
    }
}

// Bootstrap the hamburger menu
HamburgerMenu.getInstance();
