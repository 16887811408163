/**
 * Provides the logic in a dropdown which navigates the user to the
 * corresponding URL.
 *
 * @private
 */
export default function _searchTools() {
    /**
     * Main component selector.
     * @const {string}
     */
    const SEARCH_TOOLS_COMPONENT =
        '[data-pnp-component="stencils-search-tools"]';

    /**
     * Find the submit button to trigger click event when the dropdown is changed.
     * @type {string}
     */
    const SUBMIT_BUTTON = '[type="submit"]';

    /**
     * Specifies the selector for the dropdowns which we want to trigger the click
     * event whent he value is changed.
     * @type {string}
     */
    const DROPDOWN = '.sq-form-question-answer select';

    class SearchTools {
        constructor(searchToolsFormWrapper) {
            this.searchToolsFormWrapper = searchToolsFormWrapper;

            const submitButton =
                searchToolsFormWrapper.querySelector(SUBMIT_BUTTON);

            // When the user selects a new value on any of the drop downs,
            // fire the form so that the new value is reflected in the search.
            const dropdowns = searchToolsFormWrapper.querySelectorAll(DROPDOWN);
            dropdowns.forEach((dropdown) => {
                dropdown.addEventListener('change', () => {
                    submitButton.click();
                });
            });
        }
    }

    const searchComponents = document.querySelectorAll(SEARCH_TOOLS_COMPONENT);

    searchComponents.forEach((item) => {
        // Create a new item to modify
        const DOMItem = item;

        // Attach the class instance to our DOMItem
        if (!DOMItem.searchTools) {
            DOMItem.searchTools = new SearchTools(item);
        }
    });
}

_searchTools();
