/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import React, { useState, useCallback } from 'react';
import { parseJSON } from 'date-fns';
import SocialFeedContext from './SocialFeedContext';

export default ({ curatorUrl, feedId, apiKey, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [feedData, setFeedData] = useState([]);

    /**
     * Get all events.
     * @param {Integer} [maxItems] Number of results to fetch
     */
    const getFeedData = useCallback(({ maxItems } = {}) => {
        setIsLoading(true);

        const url = `${curatorUrl}/feeds/${feedId}/posts?api_key=${apiKey}&limit=${
            maxItems || 25
        }`;

        fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                return result.posts || [];
            })
            .then((posts) => {
                return posts.map((curatorItem) => {
                    const result = {
                        accountImage: curatorItem.user_image,
                        createdDate: parseJSON(curatorItem.source_created_at),
                        text: curatorItem.text,
                        image: curatorItem.image,
                        accountName: curatorItem.user_full_name,
                        source: curatorItem.network_name.toLowerCase(),
                        url: curatorItem.url,
                    };

                    if (curatorItem.video) {
                        result.video = curatorItem.video;
                    }

                    return result;
                });
            })
            .then((results) => {
                setFeedData(results);
            })
            .catch((e) => {
                setIsError(true);
                throw e;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <SocialFeedContext.Provider
            value={{
                isLoading,
                isError,
                feedData,
                getFeedData,
            }}
        >
            {children}
        </SocialFeedContext.Provider>
    );
};
