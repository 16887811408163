import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { format as formatDate } from 'date-fns';

import formatBytes from 'PlugAndPlay/_global/js/formatBytes';
import Card from '../../collapsible-card/jsx/Card';
import StatusMessage from '../../status-message/jsx/StatusMessage';

import PersonalDocumentListingContext, {
    PersonalDocumentListingContextErrorType as ErrorType,
} from './data/PersonalDocumentListingContext';
import FileIcon from './FileIcon';

/**
 * Components loading state for internal use & React.lazy loading
 */
export function PersonalDocumentListingLoadingState() {
    return (
        <div className="personal-document-listing-loading">
            <div className="personal-document-listing-loading__label">
                Loading...
            </div>
            <div className="personal-document-listing-loading__shadow" />
        </div>
    );
}

export default function PersonalDocumentListing({
    title,
    numberOfDocuments,
    allDocumentsLabel,
    allDocumentsLinkOverride,
}) {
    // Get our data from the context
    const {
        isLoading,
        isAuthNeeded,
        signInLink,
        isReady,
        error,
        documents,
        getDocuments,
        allDocumentsLink,
        restartAuthProcess,
    } = useContext(PersonalDocumentListingContext);

    useEffect(() => {
        if (isReady) {
            getDocuments({ count: numberOfDocuments });
        }
    }, [isReady]);

    let errorType = '';
    let errorMessage;
    if (error) {
        if (error === ErrorType.AUTH_ERROR) {
            errorType = 'auth';
            errorMessage =
                'An error has occurred in authenticating your user account.';
        } else if (error === ErrorType.POPUP_ERROR) {
            errorType = 'popup';
            errorMessage =
                'An error has occurred in opening the authentication popup. Ensure popups are allowed and you are not closing the popup window.';
        } else if (error === ErrorType.SCOPE_ERROR) {
            errorType = 'scope';
            errorMessage =
                'An error has occurred loading your recent documents, ensure you have approved the required permissions when prompted.';
        } else if (error === ErrorType.ERROR) {
            errorType = 'error';
            errorMessage =
                'An error has occurred loading your recent documents.';
        }

        errorMessage = (
            <>
                {errorMessage}
                {errorType !== 'error' && (
                    <button
                        type="button"
                        onClick={() => {
                            restartAuthProcess();
                        }}
                        className="personal-document-listing-message__error-retry"
                    >
                        Retry login
                    </button>
                )}
            </>
        );
    }

    // Custom icon used by all status messages
    const messageCustomIcon = (
        <>
            <svg className="svg-icon svg-icon--xlarge personal-document-listing-message__icon-file">
                <use href="#file-anyfile" />
            </svg>
            <svg className="svg-icon personal-document-listing-message__icon-block">
                <use href="#error" />
            </svg>
        </>
    );

    return (
        <Card
            id="personalDocumentListing"
            isCollapsible
            title={title}
            contentClasses="personal-document-listing"
        >
            {isLoading && <PersonalDocumentListingLoadingState />}

            {isAuthNeeded && (
                <StatusMessage
                    title="No files available"
                    customIcon={messageCustomIcon}
                    className="personal-document-listing-sign-in"
                >
                    <div className="personal-document-listing-sign-in__message">
                        {signInLink}
                    </div>
                </StatusMessage>
            )}

            {error && (
                <StatusMessage
                    title="Error - no content loaded"
                    customIcon={messageCustomIcon}
                    className={`personal-document-listing-message personal-document-listing-message--${errorType}`}
                >
                    {errorMessage}
                </StatusMessage>
            )}

            {isReady && (
                <>
                    <ol className="personal-document-listing__list">
                        {documents.map((document) => {
                            return (
                                <li
                                    key={document.id}
                                    className="personal-document-listing-item"
                                >
                                    <div className="personal-document-listing-item__icon">
                                        <FileIcon document={document} />
                                    </div>
                                    <a
                                        href={document.url}
                                        className="personal-document-listing-item__title"
                                    >
                                        {document.title}
                                    </a>
                                    <div className="personal-document-listing-item__last-modified">
                                        <div className="personal-document-listing-item__last-modified-date">
                                            {document.lastModifiedDate && (
                                                <>
                                                    <span className="sr-only">
                                                        Modified on
                                                    </span>
                                                    {formatDate(
                                                        document.lastModifiedDate,
                                                        'd MMM yyyy'
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {document.lastModifiedBy && (
                                            <div className="personal-document-listing-item__last-modified-by">
                                                Modified by{' '}
                                                {document.lastModifiedBy}
                                            </div>
                                        )}
                                    </div>
                                    <div className="personal-document-listing-item__size">
                                        {document.fileSize &&
                                            formatBytes(document.fileSize)}

                                        {!document.fileSize && <>&ndash;</>}
                                    </div>
                                </li>
                            );
                        })}
                    </ol>
                    <div className="personal-document-listing__footer">
                        <a
                            href={allDocumentsLinkOverride || allDocumentsLink}
                            target="_blank"
                            className="personal-document-listing__all-documents"
                            rel="noreferrer"
                        >
                            <svg
                                aria-label="Opens in new window"
                                className="svg-icon svg-icon--small"
                            >
                                <use href="#external" />
                            </svg>
                            {allDocumentsLabel}
                        </a>
                    </div>
                </>
            )}
        </Card>
    );
}

PersonalDocumentListing.propTypes = {
    title: PropTypes.string,
    numberOfDocuments: PropTypes.number,
    allDocumentsLabel: PropTypes.string,
    allDocumentsLinkOverride: PropTypes.string,
};

PersonalDocumentListing.defaultProps = {
    title: 'Personal Document Listing',
    numberOfDocuments: 5,
    allDocumentsLabel: 'Check all documents on cloud',
    allDocumentsLinkOverride: '',
};
