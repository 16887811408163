/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export class BaseUserAdapter {
    /**
     * Get user.
     *
     * @param {string} userId The user id.
     *
     * @returns void
     */
    get(userId) {
        throw new Error(`Method not implemented`);
    }

    /**
     * Update an user with new values.
     *
     * @param {object} user The user object.
     * @param {string} documentId The user document id.
     *
     * @returns void
     */
    setAll(user, documentId) {
        throw new Error(`Method not implemented`);
    }

    /**
     * Method will toggle given key in array.
     *
     * @param {string} notificationKey Notification key we need to toggle.
     * @param {object} user User object.
     *
     * @return {*}
     */
    updateNotifications(notificationKey, user) {
        throw new Error(`Method not implemented`);
    }

    /**
     * Method toggle device push notifications.
     *
     * @param {bool} toggle Flag determining should it be on or off.
     *
     * @return {Promise<unknown>}
     */
    toggleNotifications(toggle) {
        throw new Error(`Method not implemented`);
    }
}
