import AccessibleCard from '../../_global/js/accessibleCard';

export default function _card() {
    /**
     * Component wrapper selector
     * @const {string}
     */
    const NEWS_LISTING_SELECTOR = '[data-component="featured-news-listing"]';

    /**
     * Card item selector
     * @const {string}
     */
    const NEWS_LISTING_ITEM_SELECTOR = '.featured-news-listing-item';

    /**
     * Card link selector
     * @const {string}
     */
    const NEWS_LISTING_LINK_SELECTOR = '.featured-news-listing-item__link';

    class FeaturedNewsListing {
        constructor(component) {
            this.cardWrapper = component;

            component
                .querySelectorAll(NEWS_LISTING_ITEM_SELECTOR)
                .forEach((item) => {
                    const DOMItem = item;
                    const link = item.querySelector(NEWS_LISTING_LINK_SELECTOR);

                    if (!DOMItem.component) {
                        DOMItem.component = new AccessibleCard(item, link);
                    }
                });
        }
    }

    const components = document.querySelectorAll(NEWS_LISTING_SELECTOR);
    components.forEach((component) => {
        const DOMItem = component;

        // Attach the instance to the DOM once
        if (!DOMItem.component) {
            DOMItem.component = new FeaturedNewsListing(component);
        }
    });
}

_card();
