const KEYCODES = {
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    SPACE: 32,
    UP: 38,
    DOWN: 40,
};

/**
 * Selector for combobox listbox status area
 * @const {string}
 */
const COMBOBOX_RESULT_STATS_SELECTOR =
    '[data-component="combobox-result-status"]';

/**
 * Selector for combobox listbox status area count
 * @const {string}
 */
const COMBOBOX_RESULT_COUNT_SELECTOR =
    '[data-component="combobox-result-count"]';

/**
 * Create a new Combobox.
 * Methodology is based on https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html and https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA22.html
 *
 * @param {HTMLElement} combobox - The wrapping HTML element of the combobox
 * @class
 */
export default class Combobox {
    constructor(combobox) {
        // Find the combobox up the DOM tree
        this.component = combobox;
        this.input = combobox.querySelector('input');
        this.combobox = combobox.querySelector('[role="combobox"');
        this.listbox = combobox.querySelector('[role="listbox"');
        this.resultStatus = combobox.querySelector(
            COMBOBOX_RESULT_STATS_SELECTOR
        );
        this.resultStatusCount = combobox.querySelector(
            COMBOBOX_RESULT_COUNT_SELECTOR
        );

        this.shown = false;
        this.activeIndex = -1;

        // Save this function bind so it can be added and removed as the combobox is shown and hidden
        this.checkShouldHideAutocomplete =
            this.handleCheckShouldHideAutocomplete.bind(this);

        // Listen for text changes on the input
        this.input.addEventListener(
            'keyup',
            this.handleTextKeyInput.bind(this)
        );
        // Listen for aria/wcag pattern keypresses to interact with the listbox
        this.input.addEventListener(
            'keydown',
            this.handleListBoxInteractionKeyInput.bind(this)
        );

        this.input.addEventListener(
            'change',
            this.handleInputCheckForExternalChange.bind(this)
        );

        this.input.addEventListener(
            'focus',
            this.showAutocompleteList.bind(this)
        );
        this.input.addEventListener(
            'blur',
            this.selectActiveIndexItem.bind(this)
        );
        this.listbox.addEventListener(
            'click',
            this.handleAutoCompleteItemClick.bind(this)
        );
    }

    /**
     * Update the DOM to show the autocomplete listbox
     */
    showAutocompleteList() {
        if (this.listbox.childElementCount > 0) {
            this.shown = true;

            // Start listener for closing the listbox
            document.addEventListener(
                'click',
                this.checkShouldHideAutocomplete
            );
            // Show the autocomplete list
            this.listbox.dataset.active = true;
            // Flag the combobox as expanded
            this.combobox.setAttribute('aria-expanded', 'true');
        }
    }

    /**
     * Handle the (mouse / tap) click of an item in the listbox
     * @param {Event} event
     */
    handleAutoCompleteItemClick(event) {
        if (event.target) {
            // Find the closest li and pass that to the selectItem function (common between keyboard and house)
            this.selectItem(event.target.closest('li'));
        }
    }

    /**
     * Common funtion between keyboard and mouse operations, handles the selection of the item from the listbox
     * @param {Element} item - List item to be selected
     */
    selectItem(item) {
        if (item) {
            this.input.value = item.innerText;
            this.input.dispatchEvent(new window.Event('change'));
            this.hideAutocompleteList();
        }
    }

    /**
     * Selects the auto complete item at the currently active index
     */
    selectActiveIndexItem() {
        if (this.activeIndex < 0) {
            return;
        }
        this.selectItem(this.getItem(this.activeIndex));
    }

    /**
     * Handler for clicking on the body while the listbox is active, determine if the listbox should be hidden
     * @param {Event} event
     */
    handleCheckShouldHideAutocomplete(event) {
        if (
            event.target !== this.input &&
            !this.combobox.contains(event.target)
        ) {
            this.hideAutocompleteList();
        }
    }

    /**
     * Update the DOM to hide the listbox
     */
    hideAutocompleteList() {
        // Remove click to close watcher
        document.removeEventListener('click', this.checkShouldHideAutocomplete);

        this.shown = false;
        this.activeIndex = -1;
        // this.listbox.innerHTML = '';
        this.listbox.dataset.active = false;
        this.combobox.setAttribute('aria-expanded', 'false');
        // this.resultsCount = 0;
        this.input.setAttribute('aria-activedescendant', '');
    }

    /**
     * One of two key event handlers for the input box. This handles the general text being typed
     * into the input box and non-aria pattern based key input.
     * @param {Event} event
     */
    handleTextKeyInput(event) {
        const key = event.which || event.keyCode;

        switch (key) {
            case KEYCODES.UP:
            case KEYCODES.DOWN:
            case KEYCODES.ESC:
            case KEYCODES.RETURN:
                event.preventDefault();
                return;
            default:
                this.updateResults(false);
        }
    }

    /**
     * Extending classes should override to populate the listbox data. MUST be called at the end
     * of listbox data population to do finialisation tasks.
     */
    updateResults() {
        this.activeIndex = -1;
        this.input.setAttribute('aria-activedescendant', '');

        if (this.listbox.childElementCount > 0) {
            this.showAutocompleteList();

            // Update the area live region result count area
            if (this.resultStatus && this.resultStatusCount) {
                this.resultStatusCount.textContent =
                    this.listbox.childElementCount;

                if (this.resultStatus.dataset.active === 'false') {
                    this.resultStatus.dataset.active = true;
                }
            }
        } else {
            this.hideAutocompleteList();
        }
    }

    /**
     * Clear the listbox of all entries so it can be rebuilt
     */
    clearResults() {
        while (this.listbox.lastElementChild) {
            this.listbox.removeChild(this.listbox.lastElementChild);
        }
    }

    /**
     * One of two key event handlers for the input box. This handles keypresses intended
     * to interact with the listbox based on the aria/wcag pattern.
     * @param {Event} event
     */
    handleListBoxInteractionKeyInput(event) {
        const key = event.which || event.keyCode;
        let { activeIndex } = this;
        const resultsCount = this.listbox.children.length;

        // ESC key is used in aria / wcag patterns to close modals and other interactive overlays
        if (this.shown && key === KEYCODES.ESC) {
            this.hideAutocompleteList();
            /* setTimeout(() => {
                // On Firefox, input does not get cleared here unless wrapped in a setTimeout
                this.input.value = '';
                this.input.dispatchEvent(new window.Event('change'));
            }, 1); */
        }

        // No results in the list, so exit out now
        if (resultsCount < 1) {
            return;
        }

        switch (key) {
            case KEYCODES.UP:
                if (activeIndex <= 0) {
                    activeIndex = resultsCount - 1;
                } else {
                    activeIndex -= 1;
                }
                break;

            case KEYCODES.DOWN:
                if (activeIndex === -1 || activeIndex >= resultsCount - 1) {
                    activeIndex = 0;
                } else {
                    activeIndex += 1;
                }
                break;

            case KEYCODES.RETURN:
                // Dont submit the form if the listbox is open
                if (activeIndex !== -1 && this.shown) {
                    event.preventDefault();
                }
                this.selectItem(this.getItem(activeIndex));
                return;

            case KEYCODES.TAB:
                this.selectActiveIndexItem();
                this.hideAutocompleteList();
                return;

            default:
                return;
        }

        if (!this.shown) {
            this.showAutocompleteList();
        }

        // If we are still processing a UP or DOWN press, prevent any other processing
        event.preventDefault();

        const activeItem = this.getItem(activeIndex);
        // const prevActive = this.getItem(previousIndex);
        this.activeIndex = activeIndex; // Update current index tracking

        // Remove selection from previous item row
        this.listbox
            .querySelectorAll(`[aria-selected='true']`)
            .forEach((listItem) => {
                const item = listItem;
                item.setAttribute('aria-selected', 'false');
            });

        // Select current row
        if (activeItem) {
            // Activate link between input and current listbox active
            this.input.setAttribute(
                'aria-activedescendant',
                `result-item-${activeIndex}`
            );

            // Set active class and select attribute
            activeItem.setAttribute('aria-selected', 'true');
        } else {
            // If something has gone wrong unassociate the input from the current item
            this.input.setAttribute('aria-activedescendant', '');
        }
    }

    /**
     * Handler to check if an external interaction has changed the input value and update
     * as nessasary for that.
     * @param {Event} event
     */
    handleInputCheckForExternalChange(event) {
        const input = event.target;

        // Input has been cleared, hide listbox and remove entries
        if (input.value === '') {
            this.clearResults();
            this.hideAutocompleteList();
        }
    }

    getItem(index) {
        return this.listbox.children[index];
    }
}
