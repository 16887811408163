/**
 * Component selector.
 *
 * @type {string}
 */
const PROGRESS_WRAPPER = '[data-component="CircularProgress"]';

/**
 * Chart wrapper selector.
 *
 * @type {string}
 */
const CHART_WRAPPER = '.circular-progress-circle__chart';

/**
 * Text description selector.
 *
 * @type {string}
 */
const CHART_DESCRIPTION_WRAPPER = '.circular-progress-circle__description';

/**
 * Builds circle progress object.
 *
 */
class CircularAnimation {
    constructor(wrapper) {
        this.percentage = parseInt(wrapper.dataset.percentage, 10);
        this.stroke = parseInt(wrapper.dataset.stroke, 10);
        this.scale = parseInt(wrapper.dataset.scale, 10) || 100;

        // We have to change the radius of the circle so that the formula (Circumference / scale) * Percentage to fill equals Percentage to fill.
        this.radius = this.scale / (Math.PI * 2);
        this.coordinates = this.radius + this.stroke;
        this.viewBoxSize = this.coordinates * 2;

        this.setViewBox(wrapper);
        this.setDescription(wrapper);
        this.setCircleStroke(wrapper);
        this.setCompleted(wrapper);
        this.setCirclesProperties(wrapper);
    }

    /**
     * Sets size of svg viewbox.
     *
     * @param {HTMLElement} wrapper
     */
    setViewBox(wrapper) {
        wrapper.querySelectorAll(CHART_WRAPPER).forEach((circle) => {
            circle.setAttribute(
                'viewBox',
                `0 0 ${this.viewBoxSize} ${this.viewBoxSize}`
            );
        });
    }

    /**
     * Sets amount of progress text.
     *
     * @param {HTMLElement} wrapper
     */
    setDescription(wrapper) {
        wrapper.querySelectorAll(CHART_DESCRIPTION_WRAPPER).forEach((text) => {
            // eslint-disable-next-line no-param-reassign
            text.innerHTML = this.percentage;
        });
    }

    /**
     * Sets circles attributes such as offset x, y and radius.
     *
     * @param {HTMLElement} wrapper
     */
    setCirclesProperties(wrapper) {
        wrapper.querySelectorAll('.circle').forEach((circle) => {
            circle.setAttribute('r', this.radius);
            circle.setAttribute('cy', this.coordinates);
            circle.setAttribute('cx', this.coordinates);
        });
    }

    /**
     * Setting css variables, used for dash-array.
     *
     * @param {HTMLElement} wrapper
     */
    setCompleted(wrapper) {
        wrapper.style.setProperty('--circle-percentage', `${this.percentage}`);
    }

    /**
     * Sets the size of progress circle stroke.
     *
     * @param {HTMLElement} wrapper
     */
    setCircleStroke(wrapper) {
        wrapper.style.setProperty('--circle-stroke', `${this.stroke}`);
    }
}

export default function _initCircularAnimation() {
    const progressExist = !!document.querySelector(PROGRESS_WRAPPER);

    function initialiseProgressBars() {
        if (progressExist) {
            const progressBars = document.querySelectorAll(PROGRESS_WRAPPER);

            progressBars.forEach((progressBar) => {
                // eslint-disable-next-line no-param-reassign
                progressBar.animator = new CircularAnimation(progressBar);
            });
        }
    }

    initialiseProgressBars();
}

_initCircularAnimation();
