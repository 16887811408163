/**
 * Takes a string and converts it to boolean, with a default option if the string isn't true/false or undefined
 * @module stringToBool
 * @param {string} string - The string to be converted to boolean
 * @param {boolean} defaultOption - The default to be returned if the string isn't correct
 * @returns {boolean}
 */
export default function stringToBool(string, defaultOption) {
    // Check that the string is defined
    if (string) {
        // Check that we are getting back a valid boolean
        if (
            string.toLowerCase() === 'true' ||
            string.toLowerCase() === 'false'
        ) {
            return JSON.parse(string.toLowerCase());
        }
    }

    return defaultOption;
}
