/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import React, { useState, useCallback } from 'react';
import CalendarContext from './CalendarContext';

/**
 * Events which have started prior to the 'start' date can be needed, these can be events which cross day
 * boundaries or repeating events with an RRULE which means they should be displayed.
 *
 * filters:document.start<2022-01-12T13:30:00.000Z;document.end>2022-01-12T13:30:00.000Z
 */
const getPriorStartEvents = ({ datastore, start }) => {
    let query = datastore.collection(`events`);

    if (start) {
        query = query.where(`start`, `<`, start.toISOString());
        query = query.and(`end`, `>`, start.toISOString());
    }

    return query;
};

/**
 * Events which start at some point within the range, these may continue past the range or start and end within it. These can be
 * individual events or repeating events which start within the range.
 *
 * filters:document.start>=2022-01-12T13:30:00.000Z;document.start<2022-01-13T13:30:00.000Z
 *
 */
const getInRangeStartEvents = ({ datastore, start, end }) => {
    let query = datastore.collection(`events`);

    if (start) {
        // Datastore simulator has a bug with the >= so minus one and use >
        const startDateFix = new Date(start.getTime() - 1);
        query = query.where(`start`, `>`, startDateFix.toISOString());

        // If there is an end date to the range, use that as the last point in time an event can start to be included
        if (end) {
            // Datastore simulator has a bug with the <= so add one and use <
            const endDateFix = new Date(end.getTime() + 1);
            query = query.and(`start`, `<`, endDateFix.toISOString());
        }
    }

    return query;
};

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [events, setEvents] = useState([]);

    /**
     * Get all events.
     * @param {Date} [start] Start date to get events from
     * @param {Date} [end] End date to get events to
     */
    const getEvents = useCallback(({ start, end } = {}) => {
        setIsLoading(true);

        /*
            Datastore doesn't have anything fancy for events storage, we have to handle things like RRules, past events
            flowing into a day etc. There are 4 circumstances we are interested in for a given date range (start + end date):

            1: Recurring events that start before the date range and end after the start (so within it or after); while not guarantee as the RRule is not parsed its possible it will need to be included.
            2: Simple events that started before the range which end after the start (so within it or after).
            3: Simple and recurring events that start within the range and continue past it.
            4: Simple and recurring events that start and end within the range.

            These can be combined into two queries that need to be made.
            1: Find event that start before the range that have not ended before the range starts
            2: Find events that start after the begin of the range but that do not start past the end of the range
        */

        const priorEventsQuery = getPriorStartEvents({ datastore, start });
        const currentRangeEventsQuery = getInRangeStartEvents({
            datastore,
            start,
            end,
        });

        Promise.all([priorEventsQuery.get(), currentRangeEventsQuery.get()])
            .then((values) => {
                let mergedResults = [];
                values.forEach((results) => {
                    // Datastore accepts and returns dates in the ISO string format
                    const dataConversion = results.map((e) => {
                        let event = e;

                        // If a data payload exists
                        if (e.data) {
                            event = {
                                // Expand out all the properties into the event data
                                ...JSON.parse(event.data),
                                // But let the base event information override any duplication
                                ...e,
                            };
                        }

                        event.start = new Date(event.start);
                        event.end = new Date(event.end);
                        return event;
                    });

                    mergedResults = mergedResults.concat(dataConversion);
                });

                setEvents(mergedResults);
            })
            .catch((e) => {
                setIsError(true);
                throw e;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <CalendarContext.Provider
            value={{
                isLoading,
                isError,
                events,
                getEvents,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};
