/**
 * Funtion that takes a size in bytes and formats it in a human redable way (e.g 200MB etc)
 * @param {int} bytes - The size in bytes
 * @returns {string} - The formatted size as a string
 */
function formatBytes(bytes) {
    // Check that bytes is a number
    if (bytes === undefined || typeof bytes !== 'number') {
        throw new Error('Please ensure that bytes is passed as a number');
    }

    // Define our sizes
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    // Catch statement
    if (bytes === 0) {
        return '0 Bytes';
    }

    // Check how many lots of 1024 bytes we are dealing with
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

    // Return our formatted string
    return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

export default formatBytes;
