/**
 * A function that gets the current screens breakpoint size as a string
 * @module getCurrentScreenSize
 * @returns {string} - Returns a string of the current breakpoint size
 */
export default function getCurrentScreenSize() {
    return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--screen-size');
}

export const screenSizeXS = 1;
export const minMediaQueryXS = 'min-width: 0px';

export const screenSizeSM = 2;
export const minMediaQuerySM = 'min-width: 576px';

export const screenSizeMD = 3;
export const minMediaQueryMD = 'min-width: 768px';

export const screenSizeLG = 4;
export const minMediaQueryLG = 'min-width: 992px';

export const screenSizeXL = 5;
export const minMediaQueryXL = 'min-width: 1200px';

export const screenSizeXXL = 6;
export const minMediaQueryXXL = 'min-width: 1400px';

/**
 * A function that gets the current screens breakpoint size as a integer
 * @module getCurrentScreenSizeInt
 * @returns {int} - Returns a int of the current breakpoint size
 */
export function getCurrentScreenSizeInt() {
    return parseInt(
        window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--screen-size-int'),
        10
    );
}
