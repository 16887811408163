import React, { useState, useCallback } from 'react';
import getStudent from 'Edify/js/getStudentMemoized';
import GpaContext, { GpaContextErrorType } from './GpaContext';

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [gpa, setGpa] = useState(0);
    const [creditsCompleted, setCreditsCompleted] = useState(0);
    const [user, setUser] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState(null);

    const getData = useCallback(() => {
        setIsLoading(true);

        // Get the first item from our datastore collection
        const dataQuery = getStudent(datastore);

        // Do the query
        dataQuery
            .then((studentData) => {
                if (studentData) {
                    // Set the state in the context
                    setGpa(studentData.GPA);
                    setCreditsCompleted(studentData.credits);
                    setUser(studentData.firstName);
                }

                // Set is loading to false
                setIsLoading(false);
                // Set is ready
                setIsReady(true);
            })
            .catch(() => {
                setError(GpaContextErrorType.ERROR);
            });
    }, []);

    return (
        <GpaContext.Provider
            value={{
                isLoading,
                isReady,
                error,
                gpa,
                creditsCompleted,
                user,
                getData,
            }}
        >
            {children}
        </GpaContext.Provider>
    );
};
