/* eslint-disable react/prefer-stateless-function */
import React, { useState, createRef } from 'react';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useFocusWithin, useKeyboard } from '@react-aria/interactions';
import { getDaysInMonth, addMonths } from 'date-fns';
import { CalendarViews } from './enums';

export default function DateControlHeader({ date, viewType, onDateChange }) {
    const datePickerButtonRef = createRef();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    let daySkipForward = 1;
    let daySkipBack = -1;

    if (viewType === CalendarViews.Week) {
        daySkipForward = 7;
        daySkipBack = -7;
    } else if (viewType === CalendarViews.Month) {
        daySkipForward = getDaysInMonth(date); // Going forward need to add this months days
        daySkipBack = getDaysInMonth(addMonths(date, -1)) * -1; // Going back need to subtract last months days
    }

    // Handlers for changing the date via the header buttons
    const addDaysToDate = ({ numberOfDays }) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + numberOfDays);
        onDateChange(newDate);
    };
    const handleSetDateToday = () => {
        onDateChange(new Date());
    };
    const handleDatePickerSelected = (selectedDate) => {
        onDateChange(selectedDate);
        setIsDatePickerOpen(false);
        // Restore focus to the element which opened the picker
        datePickerButtonRef.current.focus();
    };

    // Watch the focus and blur on the date picker and close if focus leaves the control
    const { focusWithinProps } = useFocusWithin({
        onBlurWithin: () => {
            setIsDatePickerOpen(false);
        },
    });
    // Listen for Esc key within the date picker
    const { keyboardProps } = useKeyboard({
        onKeyDown: (e) => {
            if (isDatePickerOpen && e.key === 'Escape') {
                setIsDatePickerOpen(false);
                // Restore focus to the element which opened the picker
                datePickerButtonRef.current.focus();
            }
        },
    });

    return (
        <div className="calendar-sub-header">
            <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...focusWithinProps}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...keyboardProps}
                className="calendar-date-picker"
            >
                <button
                    ref={datePickerButtonRef}
                    type="button"
                    onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                    className="calendar-date-picker__button"
                >
                    <span className="calendar-date-picker__button-aria">
                        Toggle date picker
                    </span>
                    {date.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })}
                    <svg aria-hidden className="svg-icon svg-icon--small">
                        <use href="#chevron" />
                    </svg>
                </button>
                <div
                    className={`calendar-date-picker__picker ${
                        isDatePickerOpen
                            ? 'calendar-date-picker__picker--open'
                            : ''
                    }`}
                >
                    <DayPicker onDayClick={handleDatePickerSelected} />
                </div>
            </div>
            <div className="calendar-sub-header__date-controls">
                <button
                    type="button"
                    onClick={handleSetDateToday}
                    className="calendar-sub-header__date-action"
                >
                    Today
                </button>

                <button
                    type="button"
                    onClick={() => addDaysToDate({ numberOfDays: daySkipBack })}
                    className="calendar-sub-header__date-action"
                >
                    <svg className="svg-icon svg-icon--small svg-icon--previous">
                        <title>Previous day</title>
                        <use href="#chevron" />
                    </svg>
                </button>
                <button
                    type="button"
                    onClick={() =>
                        addDaysToDate({ numberOfDays: daySkipForward })
                    }
                    className="calendar-sub-header__date-action"
                >
                    <svg className="svg-icon svg-icon--small svg-icon--next">
                        <title>Next day</title>
                        <use href="#chevron" />
                    </svg>
                </button>
            </div>
        </div>
    );
}
