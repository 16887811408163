/* eslint-disable react/prefer-stateless-function */
import React, { useState, createRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import DayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';

function WeekView({ date, events, onEventClick, returnCalendarApi }) {
    const calendarRef = createRef();
    const [calendarAPI, setCalendarAPI] = useState();

    // Once the Full Calendar has been created, keep it so its API can be referenced when needed.
    useEffect(() => {
        if (calendarRef && calendarRef.current) {
            setCalendarAPI(calendarRef.current.getApi());

            if (returnCalendarApi) {
                returnCalendarApi(calendarAPI);
            }
        }
    }, [calendarRef]);

    // If the date changes, instruct Full Calendar to change via its API
    useEffect(() => {
        if (calendarAPI) {
            calendarAPI.gotoDate(date);
        }
    }, [calendarAPI, date]);

    return (
        <div className="calendar-week-view">
            <FullCalendar
                height="100%"
                ref={calendarRef}
                plugins={[DayGridPlugin, rrulePlugin]}
                initialView="dayGridWeek"
                headerToolbar={false}
                titleFormat={false}
                events={events}
                eventClick={onEventClick}
            />
        </div>
    );
}
export default React.memo(WeekView);
