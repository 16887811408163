export default function _scrollbutton() {
    /**
     * Scroll button selector
     * @const {string}
     */
    const SCROLL_BTN_SELECTOR = '.hero-banner__js-scroll-btn';

    // Add event listener to the scroll button
    function smpScrollTopBtn(selector) {
        // On click of the scroll button, scroll banner out of the way to expose main content
        selector.addEventListener('click', () => {
            selector.scrollIntoView({
                behavior: 'smooth',
            });
        });
    }

    /**
     * @const {boolean}
     */
    const scrollBtnExists = !!document.querySelector(SCROLL_BTN_SELECTOR);
    // If the scollBtn Exists
    if (scrollBtnExists) {
        smpScrollTopBtn(document.querySelector(SCROLL_BTN_SELECTOR));
    }
}

_scrollbutton();
