import {
    getCurrentScreenSizeInt,
    screenSizeMD,
    minMediaQueryMD,
} from 'PlugAndPlay/_global/js/screenSize';
import { Modal } from '../../modal/js/global';

/**
 * Header search component selector
 * @const {string}
 */
export const HEADER_SEARCH_SELECTOR = '[data-component="header-search"]';

export default function _headerSearch() {
    /**
     * Main search body selector
     * @const {string}
     */
    const HEADER_SEARCH_BODY_SELECTOR = '.header-search';

    /**
     * Open action selector
     * @const {string}
     */
    const HEADER_SEARCH_INPUT_SELECTOR = '.header-search__input';

    /**
     * Open action selector
     * @const {string}
     */
    const HEADER_SEARCH_OPEN_ACTION_SELECTOR =
        '[data-click="header-search-open"]';

    /**
     * Open action selector
     * @const {string}
     */
    const HEADER_SEARCH_TOGGLE_ACTION_SELECTOR =
        '[data-click="header-search-toggle"]';

    /**
     * Close action selector
     * @const {string}
     */
    const HEADER_SEARCH_CLOSE_ACTION_SELECTOR =
        '[data-click="header-search-close"]';

    /**
     * Clear action selector
     * @const {string}
     */
    const HEADER_SEARCH_CLEAR_ACTION_SELECTOR =
        '[data-click="header-search-clear"]';

    /**
     * Header search form submit button selector
     */
    const HEADER_SEARCH_SUBMIT_SELECTOR = '[type="submit"]';

    /**
     * Auto focus option indicator
     * @const {string}
     */
    const HEADER_SEARCH_AUTO_FOCUS = '[data-autofocus="true"]';

    /**
     * Header search open/active class
     * @const {string}
     */
    const HEADER_SEARCH_ACTIVE_CLASS = 'header-search-wrapper--active';

    /**
     * Header search has input class
     * @const {string}
     */
    const HEADER_SEARCH_HAS_INPUT_CLASS = 'header-search-wrapper--has-input';

    /**
     * Header search modal mode selector
     * @const {string}
     */
    const HEADER_SEARCH_MODE_MODAL_SELECTOR = '.header-search-wrapper--modal';

    /**
     * Header search fadein mode selector
     * @const {string}
     */
    const HEADER_SEARCH_MODE_FADEIN_SELECTOR = '.header-search-wrapper--fadein';

    /**
     * Create a new HeaderSearch
     * @param {HTMLElement} headerSearch - The wrapping HTML element of the headerSearch
     * @class
     */
    class HeaderSearch {
        constructor(headerSearchWrapper) {
            this.headerSearchWrapper = headerSearchWrapper;
            this.headerSearch = headerSearchWrapper.querySelector(
                HEADER_SEARCH_BODY_SELECTOR
            );

            // HeaderSearch isnt always a Modal so use it as a composite rather than as a parent
            this.modalHandler = new Modal(headerSearchWrapper);

            // Attach event handlers to open
            headerSearchWrapper
                .querySelectorAll(HEADER_SEARCH_OPEN_ACTION_SELECTOR)
                .forEach((element) => {
                    element.addEventListener('click', () => {
                        this.openSearch();
                    });
                });

            // Attach event handlers to open
            headerSearchWrapper
                .querySelectorAll(HEADER_SEARCH_TOGGLE_ACTION_SELECTOR)
                .forEach((element) => {
                    element.addEventListener('click', () => {
                        this.toggleSearch();
                    });
                });

            // Attach event handlers to close
            headerSearchWrapper
                .querySelectorAll(HEADER_SEARCH_CLOSE_ACTION_SELECTOR)
                .forEach((element) => {
                    element.addEventListener('click', () => {
                        this.closeSearch();
                    });
                });

            // Attach event handlers to clear
            headerSearchWrapper
                .querySelectorAll(HEADER_SEARCH_CLEAR_ACTION_SELECTOR)
                .forEach((element) => {
                    element.addEventListener('click', () => {
                        this.clearSearch();
                    });
                    if (this._isFadeIn()) {
                        element.addEventListener('blur', (e) => {
                            if (e.currentTarget.contains(e.relatedTarget)) {
                                this._handleFadeInBlur(e);
                            }
                        });
                    }
                });

            const primaryInput = headerSearchWrapper.querySelector(
                HEADER_SEARCH_INPUT_SELECTOR
            );

            this.primaryInput = primaryInput;

            if (primaryInput) {
                primaryInput.addEventListener('input', (e) => {
                    this._handleInputChange(e);
                });
                primaryInput.addEventListener('change', (e) => {
                    this._handleInputChange(e);
                });
                if (this._isFadeIn()) {
                    primaryInput.addEventListener('blur', (e) => {
                        if (e.currentTarget.contains(e.relatedTarget)) {
                            this._handleFadeInBlur(e);
                        }
                    });
                }
            }

            const submitButton = headerSearchWrapper.querySelector(
                HEADER_SEARCH_SUBMIT_SELECTOR
            );

            if (submitButton) {
                if (this._isFadeIn()) {
                    submitButton.addEventListener('blur', (e) => {
                        if (e.currentTarget.contains(e.relatedTarget)) {
                            this._handleFadeInBlur(e);
                        }
                    });
                }
            }
        }

        /**
         * Open the search and handles extras like setting up modal ally and focus.
         */
        openSearch() {
            if (!this.isOpen()) {
                // Add CSS class to activate styling
                this.headerSearchWrapper.classList.add(
                    HEADER_SEARCH_ACTIVE_CLASS
                );

                // If the search content should render as a modal, activate the accessibility features
                if (this._isModal()) {
                    this._activateModal();
                } else if (this._isFadeIn()) {
                    this._activateFadeIn();
                }

                // Listen for media query changes while open in case it switches to mobile (modal) which has extra processing (see above)
                this._listenForMediaQueryChange();

                // If the search is configured to auto focus do it - must be after the ally setup otherwise focus restore wont function correctly
                if (
                    this.headerSearchWrapper.matches(HEADER_SEARCH_AUTO_FOCUS)
                ) {
                    this.headerSearchWrapper
                        .querySelector(HEADER_SEARCH_INPUT_SELECTOR)
                        .focus();
                }
            }
        }

        /**
         * Helper function to activate the ally modal features + markup
         */
        _activateModal() {
            this.headerSearch.setAttribute('role', 'dialog');
            this.headerSearch.setAttribute(
                'aria-label',
                this.headerSearch.dataset.modalLabel
            );

            this.modalHandler.activateAccessability(this.headerSearch, () => {
                this.closeSearch();
            });
        }

        _activateFadeIn() {
            // Tag the toggle button as expanded
            this.headerSearchWrapper
                .querySelectorAll(HEADER_SEARCH_TOGGLE_ACTION_SELECTOR)
                .forEach((element) => {
                    element.setAttribute('aria-expanded', true);
                });

            function listener(event) {
                if (!this.headerSearchWrapper.contains(event.target)) {
                    this.closeSearch();
                }
            }
            this.fadeInDocListener = listener.bind(this);

            document.addEventListener('click', this.fadeInDocListener);
        }

        /**
         * Function to open or close the search depending on if open or not already
         */
        toggleSearch() {
            if (this.isOpen()) {
                this.closeSearch();
            } else {
                this.openSearch();
            }
        }

        /**
         * Closes the modal and deactivates any modal ally and media watch
         */
        closeSearch() {
            if (this.isOpen()) {
                // Remove CSS class to deactivate styling
                this.headerSearchWrapper.classList.remove(
                    HEADER_SEARCH_ACTIVE_CLASS
                );

                // Stop listening to media when closed to not waste resources
                this._stopListeningFoMediaQueryChange();

                // If the search content was modal based disable the accessability features
                if (this._isModal()) {
                    this._deactivateModal();
                } else if (this._isFadeIn()) {
                    this._deactivateFadeIn();
                }
            }
        }

        /**
         * Helper function to deactivate the modal ally + markup.
         */
        _deactivateModal() {
            this.headerSearch.setAttribute('role', '');
            this.headerSearch.setAttribute('aria-label', '');
            this.modalHandler.deactivateAccessability();
        }

        _deactivateFadeIn() {
            // Tag the toggle button as not expanded
            this.headerSearchWrapper
                .querySelectorAll(HEADER_SEARCH_TOGGLE_ACTION_SELECTOR)
                .forEach((element) => {
                    element.setAttribute('aria-expanded', false);
                });

            document.removeEventListener('click', this.fadeInDocListener);
            this.fadeInDocListener = undefined;
        }

        clearSearch() {
            // Clear the input field
            const input = this.headerSearchWrapper.querySelector(
                HEADER_SEARCH_INPUT_SELECTOR
            );
            input.value = '';
            input.dispatchEvent(new window.Event('change'));

            // Remove the 'has input' class which makes the X show
            this.headerSearchWrapper.classList.remove(
                HEADER_SEARCH_HAS_INPUT_CLASS
            );

            // Reset focus to the input as the X will now hide
            this.headerSearchWrapper
                .querySelector(HEADER_SEARCH_INPUT_SELECTOR)
                .focus();
        }

        _handleFadeInBlur() {
            if (!this.headerSearchWrapper.matches(':focus-within')) {
                this.closeSearch();
            }
        }

        /**
         * Function to check if the search is currently open
         */
        isOpen() {
            return this.headerSearchWrapper.matches(
                `.${HEADER_SEARCH_ACTIVE_CLASS}`
            );
        }

        /**
         * Function to determine if the search content is going to render as a modal,
         * this can be caused by the 'mode' of the search being set to 'modal' or by
         * the screen size being under a set dimension.
         *
         * @returns {bool} - is the search content rendering as a modal
         */
        _isModal() {
            return (
                this.headerSearchWrapper.matches(
                    HEADER_SEARCH_MODE_MODAL_SELECTOR
                ) || getCurrentScreenSizeInt() < screenSizeMD
            );
        }

        /**
         * Function to check if search mode is fadein
         * @returns {bool} - is the search mode fadein
         */
        _isFadeIn() {
            return this.headerSearchWrapper.matches(
                HEADER_SEARCH_MODE_FADEIN_SELECTOR
            );
        }

        /**
         * Function to listen to changes to the media query and deactivate the modal ally code
         * when it changes from mobile to tablet/desktop. Does not run if search is always a modal.
         */
        _listenForMediaQueryChange() {
            // If the mode is always modal we dont need to watch for changes
            if (
                !this.headerSearchWrapper.matches(
                    HEADER_SEARCH_MODE_MODAL_SELECTOR
                )
            ) {
                this.modalHandler.watchForMediaQueryChange(
                    minMediaQueryMD,
                    () => {
                        this._deactivateModal();
                    },
                    () => {
                        this._activateModal();
                    }
                );
            }
        }

        /**
         * Function to stop listening to the media query when the search or modal is closed.
         */
        _stopListeningFoMediaQueryChange() {
            this.modalHandler.removeMediaQueryWatch();
        }

        /**
         * Function to handle the input change, adds a class to root to identify if text content exists or not
         */
        _handleInputChange(e) {
            const { target } = e;
            if (target.value !== '') {
                this.headerSearchWrapper.classList.add(
                    HEADER_SEARCH_HAS_INPUT_CLASS
                );

                this.primaryInput.focus();
            } else {
                this.headerSearchWrapper.classList.remove(
                    HEADER_SEARCH_HAS_INPUT_CLASS
                );
            }
        }
    }

    // Find all components (should only be one) and created handler objects
    const headerSearch = document.querySelectorAll(HEADER_SEARCH_SELECTOR);
    headerSearch.forEach((item) => {
        // Create a new item to modify
        const DOMItem = item;

        // Attach the class instance to our DOMItem
        if (!DOMItem.headerSearch) {
            DOMItem.headerSearch = new HeaderSearch(item);
        }
    });
}

_headerSearch();
