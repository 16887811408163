/*
    "Standard" card design of a <li> with an <a> tag wrapping the contents of the
    card to make it clickable isnt accessible as it causes the screen reader the announce
    the entire card at once.

    However, UX wise we still want the whole card surface to be clickable. This blog goes
    into further details https://inclusive-components.design/cards/ but has a hacky conclusion
    that we dont use.
*/
export default class AccessibleCard {
    constructor(card, link) {
        // setup an array to determine the buttons that we should be handling, click events from other mouse buttons should be ignored
        this.buttonsToHandle = [0]; // 0 = Left mouse

        if (card && link) {
            this.card = card;
            this.link = link;

            // Determine if a click or a drag is happening
            this.drag = false;

            card.addEventListener('mousedown', (event) => {
                // check if we care about the action based on button used
                this.ignoreUnwantedEvents(event, () => {
                    // start listening for movement
                    this.addMoveListener();
                    // reset drag to no
                    this.drag = false;
                });
            });

            this.moveHandler = (event) => {
                // check if we care about the action based on button used
                this.ignoreUnwantedEvents(event, () => {
                    // if the mouse moves we are dragging
                    this.drag = true;
                });
            };

            card.addEventListener('mouseup', (event) => {
                // check if we care about the action based on button used
                this.ignoreUnwantedEvents(event, () => {
                    // Check if a drag has happened, if not
                    if (!this.drag) {
                        // dispatch a new click event based on the existing event (in order to capture modifier keys held)
                        link.dispatchEvent(new MouseEvent('click', event));
                    }

                    // stop listening for movement
                    this.removeMoveListner();

                    // either way, reset the drag to false
                    this.drag = false;
                });
            });
        }
    }

    /**
     * Check if we want to ignore the event for some reason before processing it.
     *
     * @param {Event} event - JS event object
     * @param {Function} callback - function to call if even isnt ignored
     */
    ignoreUnwantedEvents(event, callback) {
        if (!this.buttonsToHandle.includes(event.button)) {
            return;
        }

        callback(event);
    }

    addMoveListener() {
        this.card.addEventListener('mousemove', this.moveHandler);
    }

    removeMoveListner() {
        this.card.removeEventListener('mousemove', this.moveHandler);
    }
}
