import AjaxCache from 'PlugAndPlay/_global/js/ajaxCache';
import Combobox from '../../combobox/js/global';

export default function _autocompleteSearch() {
    /**
     * Autocomplete wrapper selector
     * @const {string}
     */
    const AUTOCOMPLETE_SELECTOR = '[data-component="autocomplete"]';

    /**
     * Selector for autocomplete listbox status area
     * const {string}
     */
    const AUTOCOMPLETE_RESULT_STATS_SELECTOR =
        '[data-component="autocomplete-result-status"]';
    /**
     * Selector for autocomplete listbox status area count
     * @const {string}
     */
    const AUTOCOMPLETE_RESULT_COUNT_SELECTOR =
        '[data-component="autocomplete-result-count"]';

    const AUTOCOMPLETE_RESULT_TEMPLATE =
        'template[data-template-id="autocomplete-result"]';
    const AUTOCOMPLETE_RESULT_QUERY_TEMPLATE =
        'template[data-template-id="autocomplete-result-query"]';

    /**
     * Create a new Autocomplete.
     *
     * @param {HTMLElement} autocomplete - The wrapping HTML element of the autocomplete
     * @class
     */
    class Autocomplete extends Combobox {
        constructor(autocomplete) {
            super(autocomplete);
            this.autocomplete = autocomplete;
            this.resultStatus = autocomplete.querySelector(
                AUTOCOMPLETE_RESULT_STATS_SELECTOR
            );
            this.resultStatusCount = autocomplete.querySelector(
                AUTOCOMPLETE_RESULT_COUNT_SELECTOR
            );
            this.emphasis = autocomplete.dataset.emphasis;

            // Get the templates for the results list from the DOM
            this.resultItemTemplate = autocomplete.querySelector(
                AUTOCOMPLETE_RESULT_TEMPLATE
            ).content.firstElementChild;
            this.resultItemQueryTemplate = autocomplete.querySelector(
                AUTOCOMPLETE_RESULT_QUERY_TEMPLATE
            ).content.firstElementChild;

            // Build the remote address URL string
            this.remoteAddress = `${this.autocomplete.dataset.suggestSource}?collection=${this.autocomplete.dataset.suggestCollection}&${this.autocomplete.dataset.suggestAdditionalParams}&fmt=json++`;

            // Create an ajax request cache
            this.ajaxCache = new AjaxCache('autocomplete');
        }

        /**
         * Query funnelback suggest.json endpoint and render results to the listbox
         */
        updateResults() {
            const query = this.input.value;
            this.ajaxCache
                .sendRequest({
                    url: `${this.remoteAddress}&partial_query=${query}`,
                    transform: (response) => {
                        return response.data;
                    },
                })
                .then((response) => {
                    // Remove all child nodes
                    this.clearResults();

                    // Create new child nodes
                    response.forEach((result, index) => {
                        this.listbox.appendChild(
                            this.createResultItemFromTemplate(
                                result,
                                index,
                                query
                            )
                        );
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                })
                .finally(() => {
                    // Post update call the super to re-setup the listbox
                    super.updateResults();
                });
        }

        /**
         * Creates a new results item from the template loaded onto the page.
         *
         * @param {Object} result - response from Funnelback request
         * @param {int} index - index of result
         * @returns {Element} - new <li> element for listbox
         */
        createResultItemFromTemplate(result, index, query) {
            const resultItem = this.resultItemTemplate.cloneNode(true);
            // Append the index to the id
            resultItem.setAttribute(
                'id',
                `${resultItem.getAttribute('id')}${index}`
            );

            // Add the result text
            if (this.emphasis && this.emphasis !== 'none') {
                const queryItem = this.resultItemQueryTemplate.cloneNode(true);
                queryItem.textContent = query;

                // Split the result display based on the query
                const querySplit = result.disp.split(query);
                querySplit.forEach((partWord, partIndex) => {
                    // If the part word isnt blank, print it
                    if (partWord) {
                        resultItem.appendChild(
                            document.createTextNode(partWord)
                        );
                    }
                    /*
                        If the partWord isnt the last item, print the 'query' span. A word like 'dedicated' will when split on 'd'
                        break into ["", "e", "icate", ""], the last partWord it doesnt need to be printed for both the end results.
                    */
                    if (partIndex !== querySplit.length - 1) {
                        resultItem.appendChild(queryItem.cloneNode(true));
                    }
                });
            } else {
                resultItem.textContent = result.disp;
            }

            return resultItem;
        }

        /**
         * Example of how to do on a system which does not support html templates
         *
         * @param {Object} result - response from Funnelback request
         * @param {int} index - index of result
         * @returns {Element} - new <li> element for listbox
         */
        createResultItem(result, index) {
            const resultItem = document.createElement('li');
            resultItem.textContent = result.disp;
            resultItem.setAttribute('id', `result-item-${index}`);
            resultItem.setAttribute('role', 'option');
            resultItem.setAttribute('aria-selected', 'false');
            resultItem.classList.add('autocomplete-search-listbox__item');
            return resultItem;
        }
    }

    // Finall all autocomplete and created handler objects
    const autocompletes = document.querySelectorAll(AUTOCOMPLETE_SELECTOR);
    autocompletes.forEach((autocomplete) => {
        // Create a new item to modify
        const DOMItem = autocomplete;

        // Attach the carousel instance to our DOM Item
        if (!DOMItem.autocomplete) {
            DOMItem.autocomplete = new Autocomplete(autocomplete);
        }
    });
}

_autocompleteSearch();
