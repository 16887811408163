/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { LocalStorageAdapter } from 'PlugAndPlay/_data-layer/js/Adapters/StorageAdapters/LocalStorageAdapter/LocalStorageAdapter';

// Lazy load the main SearchHistory 'ui'
const SearchHistory = React.lazy(() =>
    import(/* webpackChunkName: "SearchHistory" */ './search-history')
);

const appWrapper = document.querySelectorAll(
    '[data-component="search-history"]'
);
// Don't run if wrapper doesn't exist
if (appWrapper.length > 0) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            const storageAdapter = new LocalStorageAdapter({
                storageKey: 'PNP-SEARCH-HISTORY',
            });

            const { dataset } = wrapper;
            const configuration = JSON.parse(
                dataset.searchHistoryConfiguration
            );

            ReactDOM.render(
                <Suspense
                    fallback={
                        <div className="search-history__skeleton">
                            Loading...
                        </div>
                    }
                >
                    <SearchHistory
                        {...configuration}
                        storageAdapter={storageAdapter}
                    />
                </Suspense>,
                wrapper
            );
        });
    });
}
