/* eslint-disable react/prefer-stateless-function */
import React, { useState, createRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import TimeGridPlugin from '@fullcalendar/timegrid';
import rrulePlugin from '@fullcalendar/rrule';

import { EventActions } from './enums';
import { getEventTypeCustomisations } from './event-types/EventTypes';

function DayView({
    date,
    allDaySlot,
    nowIndicator,
    events,
    onEventClick,
    returnCalendarApi,
}) {
    const calendarRef = createRef();
    const [calendarAPI, setCalendarAPI] = useState();

    // Once the Full Calendar has been created, keep it so its API can be referenced when needed.
    useEffect(() => {
        if (calendarRef && calendarRef.current) {
            setCalendarAPI(calendarRef.current.getApi());

            returnCalendarApi(calendarAPI);
        }
    }, [calendarRef]);

    // If the date changes, instruct Full Calendar to change via its API
    useEffect(() => {
        if (calendarAPI) {
            calendarAPI.gotoDate(date);
        }
    }, [calendarAPI, date]);

    /*
        This function is an extension point of Full Calendar to allow custom
        rendering of the event content within this view type.
    */
    const printEventContent = ({ event }) => {
        const eventCustomisation = getEventTypeCustomisations(
            event.extendedProps, // Pass the full event data payload not only the fcEvent data
            event
        );
        return eventCustomisation.getDayViewContent();
    };

    /*
        Add some extra ARIA bits to the FullCalendar HTML based on how the application is
        configured.
    */
    const adjustDomAfterRender = (arg) => {
        const interactionType = arg.event.extendedProps._eventInteractionType;

        // Inject a button/link role to override the inherited 'presentation' role so screen readers pick it up easier
        arg.el.setAttribute(
            'role',
            interactionType === EventActions.OpenInModal ? 'button' : 'link'
        );

        // If new window override link with target=_blank
        if (interactionType === EventActions.OpenInNewPage) {
            arg.el.setAttribute('target', '_blank');
        }
    };

    return (
        <div className="calendar-day-view">
            <FullCalendar
                height="100%"
                ref={calendarRef}
                plugins={[TimeGridPlugin, rrulePlugin]}
                initialView="timeGridDay"
                scrollTime="08:00:00" // Default time to scroll the calendar to
                headerToolbar={false}
                titleFormat={false}
                allDaySlot={allDaySlot}
                nowIndicator={nowIndicator}
                events={events}
                eventClick={onEventClick}
                eventContent={printEventContent}
                eventDidMount={adjustDomAfterRender}
                eventMinHeight={48} // SCSS .fc-timegrid-slot needs to be changed to match (minus margins)
            />
        </div>
    );
}
export default React.memo(DayView);
