/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import getJWT from 'Edify/js/getJwt';
import DatastoreNotificationsProvider from './data/DatastoreNotificationsProvider';

// Lazy load the main 'app'
const Notifications = React.lazy(() =>
    import(/* webpackChunkName: "Notifications" */ './notifications')
);

const DatastoreProvider = (appConfiguration, notifications) => {
    const { datasourceUrl, datasourceJWT } = appConfiguration;

    const datastore = new Datastore({
        serviceURL: datasourceUrl,
        jwtURL: datasourceJWT,
    });

    const wrapper = (
        <DatastoreNotificationsProvider datastore={datastore}>
            {notifications}
        </DatastoreNotificationsProvider>
    );

    return wrapper;
};

const EdifyProvider = (appConfiguration, notifications) => {
    const { datasourceUrl, datasourceJWT } = appConfiguration;

    const datastore = new Datastore({
        serviceURL: datasourceUrl,
        jwtCallback: () => getJWT(datasourceJWT),
    });

    const wrapper = (
        <DatastoreNotificationsProvider datastore={datastore}>
            {notifications}
        </DatastoreNotificationsProvider>
    );

    return wrapper;
};

// Lazy load the main 'app'
const init = (
    DatastoreProviderFn = DatastoreProvider,
    EdifyProviderFn = EdifyProvider
) => {
    const appWrapper = document.querySelectorAll(
        '[data-component="notifications"]'
    );

    // Don't run on a non app page
    if (appWrapper.length > 0) {
        // Only load ReactDOM from if its needed as its pretty large
        import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then(
            (ReactDOM) => {
                appWrapper.forEach((wrapper) => {
                    let notificationsWrapper;
                    const { dataset } = wrapper;
                    const appConfiguration = JSON.parse(
                        dataset.appConfiguration
                    );

                    const notifications = (
                        <Notifications {...appConfiguration} popover />
                    );

                    if (appConfiguration.datasource === 'datastore') {
                        notificationsWrapper = DatastoreProviderFn(
                            appConfiguration,
                            notifications
                        );
                    }

                    if (appConfiguration.datasource === 'edify') {
                        notificationsWrapper = EdifyProviderFn(
                            appConfiguration,
                            notifications
                        );
                    }

                    ReactDOM.render(
                        <Suspense
                            fallback={
                                <div className="notifications-loader">
                                    <svg className="svg-icon">
                                        <title>Loading notifications</title>
                                        <use href="#edify-notification" />
                                    </svg>
                                </div>
                            }
                        >
                            {notificationsWrapper}
                        </Suspense>,
                        wrapper
                    );
                });
            }
        );
    }
};

init();

// For unit test only
export { init, EdifyProvider, DatastoreProvider };
