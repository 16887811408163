import {
    setCookie,
    clearCookie,
    getCookie,
} from '../../../_global/js/cookieHelper';

const FEATURE_BRANCH_MANAGER_COOKIE_NAME = 'branch';
const FEATURE_BRANCH_MANAGER_EXPIRY_TIME = 0.0416; // 1 hour expressed as fraction of a day (1/24).

const FEATURE_BRANCH_HIDE_COOKIE_NAME = 'branch-alert-hide';
const FEATURE_BRANCH_HIDE_EXPIRY_TIME = 0.007; // About 10 mins
const FEATURE_BRANCH_SHOW_CLASS_NAME = 'feature-branch-alert--show';

const FEATURE_BRANCH_TOGGLE_COOKIE_NAME = 'branch-alert-toggle';
const FEATURE_BRANCH_TOGGLED_CLASS_NAME = 'feature-branch-alert--toggled';

const FEATURE_BRANCH_RESET_SELECTOR = '[data-click="featureBranchReset"]';
const FEATURE_BRANCH_SET_SELECTOR = '[data-click="featureBranchSet"]';
const FEATURE_BRANCH_HIDE_SELECTOR = '[data-click="featureBranchHide"]';
const FEATURE_BRANCH_TOGGLE_SELECTOR = '[data-click="featureBranchToggle"]';

export default class FeatureBranchSwitcher {
    constructor(control) {
        this.component = control;

        const reset = control.querySelector(FEATURE_BRANCH_RESET_SELECTOR);
        if (reset) {
            reset.addEventListener('click', () => {
                this.resetToDefaultBranch();
            });
        }

        const set = control.querySelectorAll(FEATURE_BRANCH_SET_SELECTOR);
        set.forEach((setControl) => {
            setControl.addEventListener('click', (event) => {
                this.setFeatureBranch(event.target.dataset.branch);
            });
        });

        const hide = control.querySelectorAll(FEATURE_BRANCH_HIDE_SELECTOR);
        hide.forEach((element) => {
            element.addEventListener('click', () => {
                this.hideBranchAlertTemp();
            });
        });

        const toggle = control.querySelectorAll(FEATURE_BRANCH_TOGGLE_SELECTOR);
        toggle.forEach((element) => {
            element.addEventListener('click', () => {
                this.toggleBranchAlertTemp();
            });
        });

        const hideBranchAlert = getCookie(FEATURE_BRANCH_HIDE_COOKIE_NAME);
        if (!hideBranchAlert) {
            this.component.classList.add(FEATURE_BRANCH_SHOW_CLASS_NAME);

            const toggledBranchAlert = getCookie(
                FEATURE_BRANCH_TOGGLE_COOKIE_NAME
            );
            if (toggledBranchAlert) {
                this.component.classList.remove(
                    FEATURE_BRANCH_TOGGLED_CLASS_NAME
                );
            }
        }
    }

    toggleBranchAlertTemp() {
        const isToggled = getCookie(FEATURE_BRANCH_TOGGLE_COOKIE_NAME);

        if (!isToggled) {
            setCookie(
                FEATURE_BRANCH_TOGGLE_COOKIE_NAME,
                !isToggled,
                FEATURE_BRANCH_MANAGER_EXPIRY_TIME
            );

            this.component.classList.remove(FEATURE_BRANCH_TOGGLED_CLASS_NAME);
        } else {
            clearCookie(FEATURE_BRANCH_TOGGLE_COOKIE_NAME);
            this.component.classList.add(FEATURE_BRANCH_TOGGLED_CLASS_NAME);
        }
    }

    hideBranchAlertTemp() {
        setCookie(
            FEATURE_BRANCH_HIDE_COOKIE_NAME,
            'true',
            FEATURE_BRANCH_HIDE_EXPIRY_TIME
        );

        window.location.reload();
    }

    setFeatureBranch(value) {
        setCookie(
            FEATURE_BRANCH_MANAGER_COOKIE_NAME,
            value,
            FEATURE_BRANCH_MANAGER_EXPIRY_TIME
        );
        window.location.reload();
    }

    resetToDefaultBranch() {
        clearCookie(FEATURE_BRANCH_MANAGER_COOKIE_NAME);
        window.location.reload();
    }
}
