import React, { useState, useCallback } from 'react';
import CourseContext, { CoursesContextErrorType } from './CourseContext';

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState(null);
    const [courses, setCourses] = useState([]);

    // Function that sets the correct state when loding starts
    function beginLoading() {
        setIsLoading(true);
        setIsReady(false);
    }

    // Function that sets the correct state when loding ends
    function endLoading() {
        setIsLoading(false);
        setIsReady(true);
    }

    /**
     * Gets the assessments for the course
     * @param {string} courseId - The course ID as a string
     */
    const getAssessments = useCallback((courseId) => {
        const assessmentsQuery = datastore
            .collection(`courses`)
            .doc(courseId)
            .collection(`assessments`)
            .get()
            .catch((sdkError) => {
                // If we got an error because of something other than a 404
                if (sdkError?.status === 404) {
                    // Return an empty array when the endpoint doesnt exist
                    return [];
                }
                setError(CoursesContextErrorType.ERROR);
                return null;
            });

        return assessmentsQuery;
    }, []);

    /**
     * Gets the updates for the course
     * @param {string} courseId - The course ID as a string
     */
    const getUpdates = useCallback((courseId) => {
        const updatesQuery = datastore
            .collection(`courses`)
            .doc(courseId)
            .collection(`updates`)
            .get()
            .catch((sdkError) => {
                // If we got an error because of something other than a 404
                if (sdkError?.status === 404) {
                    // Return an empty array when the endpoint doesnt exist
                    return [];
                }
                setError(CoursesContextErrorType.ERROR);
                return null;
            });

        return updatesQuery;
    }, []);

    /**
     * Get all of the courses.
     *
     * @returns {Promise<object>} The result of the datastore query for all courses.
     */
    const getCourses = useCallback(() => {
        beginLoading();

        const coursesQuery = datastore.collection(`courses`).get();

        // Add the assessments and updates to the course object.
        const addAssessmentsAndUpdates = (course) => {
            const courseId = course.id;
            const assessmentsQuery = getAssessments(courseId);
            const updatesQuery = getUpdates(courseId);

            const courseObject = { ...course };

            return Promise.all([assessmentsQuery, updatesQuery])
                .then(([assessments, updates]) => {
                    courseObject.assessments = assessments;
                    courseObject.updates = updates;

                    return courseObject;
                })
                .catch(() => {
                    setError(CoursesContextErrorType.ERROR);
                });
        };

        coursesQuery
            .then((coursesResults) => {
                const promises = [];

                coursesResults.forEach((course) => {
                    promises.push(addAssessmentsAndUpdates(course));
                });

                return Promise.all(promises);
            })
            .then((courseResults) => {
                setCourses(courseResults);
                endLoading();
            })
            .catch(() => {
                setError(CoursesContextErrorType.ERROR);
            });
    }, []);

    return (
        <CourseContext.Provider
            value={{ isLoading, isReady, error, courses, getCourses }}
        >
            {children}
        </CourseContext.Provider>
    );
};
