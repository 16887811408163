import { Modal } from '../../modal/js/global';

const VIDEO_MODAL_WRAPPER_SELECTOR = '[data-component="video-modal"]';

export default function _videoModal() {
    class VideoModal extends Modal {
        constructor(modalWrapper) {
            super(modalWrapper);
            this.videoIFrame = this.modal.querySelector('iframe');
        }

        /**
         * This function opens the modal for the video. It sets the src only on load so if
         * autoplay parameters are passed in the video does not start appearing before its shown.
         */
        openModal() {
            this.videoIFrame.setAttribute('src', this.modalWrapper.dataset.src);
            super.openModal();
        }

        /**
         * This function closes the modal and removes the source from the iFrame to stop the video
         * from playing in the background if it has been started.
         */
        closeModal() {
            this.videoIFrame.setAttribute('src', '');
            super.closeModal();
        }
    }

    // Find all video-modals and created handler objects
    const modals = document.querySelectorAll(VIDEO_MODAL_WRAPPER_SELECTOR);
    modals.forEach((modal) => {
        // Create a new item to modify
        const DOMItem = modal;

        // Attach the modal instance to our DOM Item
        if (!DOMItem.modal) {
            DOMItem.modal = new VideoModal(modal);
        }
    });
}

_videoModal();
