export default function _widgetGrid() {
    /**
     * Widget grid wrapper selector
     * @const {string}
     */
    const WIDGET_GRID_SELECTOR = '[data-component="widget-grid"]';

    /**
     * Open tab mobile nav bar
     * @const {string}
     */
    const WIDGET_GRID_OPEN_TAB = '[data-click="widget-grid-mobile-open-tab"]';

    /**
     * Open default mobile nav bar
     * @const {string}
     */
    const WIDGET_GRID_OPEN_DEFAULT =
        '[data-click="widget-grid-mobile-open-default"]';

    /**
     * Class to hide non open widget grid containers
     */
    const WIDGE_GRID_NON_DEFAULT_TAB_ACTIVE_CLASS =
        'widget-grid-wrapper--non-default-tab-active';

    /**
     * Class to show specific widget grid container
     */
    const WIDGET_GRID_CONTAINER_SHOW_TAB_CLASS =
        'widget-grid__container--show-mobile-tab';

    /**
     * Class to highlight the open mobile nav container
     */
    const WIDGET_GRID_NAV_ACTIVE_CLASS =
        'widget-grid-mobile-nav__item--current';

    /**
     * Create a new WidgetGrid
     * @param {HTMLElement} WidgetGrid - The wrapping HTML element of the WidgetGrid
     * @class
     */
    class WidgetGrid {
        constructor(widgetGrid) {
            this.widgetGrid = widgetGrid;

            this.mobileTabItems =
                widgetGrid.querySelectorAll(WIDGET_GRID_OPEN_TAB) || [];

            this.mobileTabDefault = widgetGrid.querySelector(
                WIDGET_GRID_OPEN_DEFAULT
            );

            this.mobileTabItems.forEach((mobileTabItem) => {
                mobileTabItem.addEventListener('click', () => {
                    this.handleOpenMobileTab(mobileTabItem);
                });
            });

            if (this.mobileTabDefault) {
                this.mobileTabDefault.addEventListener('click', () => {
                    this.handleOpenMobileDefault();
                });
            }
        }

        /**
         * Open the requested tab as the current grid 'page'
         * @param {HTMLElement} tab
         */
        handleOpenMobileTab(tab) {
            // Close everything so we can reopen what we need
            this.hideAllOpenTabs();

            // Get the widget container this button controls and show it
            const controls = tab.getAttribute('aria-controls');
            const widgetContainer = document.querySelector(`#${controls}`);
            widgetContainer.classList.add(WIDGET_GRID_CONTAINER_SHOW_TAB_CLASS);

            // Hide the 'default' widget grid tab
            this.widgetGrid.classList.add(
                WIDGE_GRID_NON_DEFAULT_TAB_ACTIVE_CLASS
            );
            // Remove active class on 'deault' button
            this.mobileTabDefault.classList.remove(
                WIDGET_GRID_NAV_ACTIVE_CLASS
            );

            // Add the 'current' class to the tab button
            tab.classList.add(WIDGET_GRID_NAV_ACTIVE_CLASS);
            tab.setAttribute('aria-selected', true);
        }

        /**
         * Revert the grid 'page' to the default grid list view
         */
        handleOpenMobileDefault() {
            // Close all 'tabs' and remove nav button active classes
            this.hideAllOpenTabs();

            // Remove hide class on the 'default' widget grid tab
            this.widgetGrid.classList.remove(
                WIDGE_GRID_NON_DEFAULT_TAB_ACTIVE_CLASS
            );

            // Add active class to 'deault' button
            this.mobileTabDefault.classList.add(WIDGET_GRID_NAV_ACTIVE_CLASS);
            this.mobileTabDefault.setAttribute('aria-selected', true);
        }

        hideAllOpenTabs() {
            // Remove active show class on all widget grid containers
            const openTabs = this.widgetGrid.querySelectorAll(
                `.${WIDGET_GRID_CONTAINER_SHOW_TAB_CLASS}`
            );
            openTabs.forEach((element) => {
                element.classList.remove(WIDGET_GRID_CONTAINER_SHOW_TAB_CLASS);
            });

            // Remove active current class on all mobile nav buttons
            const activeButtons = this.widgetGrid.querySelectorAll(
                `.${WIDGET_GRID_NAV_ACTIVE_CLASS}`
            );
            activeButtons.forEach((element) => {
                element.classList.remove(WIDGET_GRID_NAV_ACTIVE_CLASS);
            });

            // Remove aria-selected
            const ariaSelected = this.widgetGrid.querySelectorAll(
                '[aria-selected="true"]'
            );
            ariaSelected.forEach((element) => {
                element.setAttribute('aria-selected', false);
            });
        }
    }

    // Find all accordions and created handler objects
    const widgetGrids = document.querySelectorAll(WIDGET_GRID_SELECTOR);
    widgetGrids.forEach((widgetGrid) => {
        // Create a new item to modify
        const DOMItem = widgetGrid;

        // Attach the widgetGrid instance to our DOM Item
        if (!DOMItem.widgetGrid) {
            DOMItem.widgetGrid = new WidgetGrid(widgetGrid);
        }
    });
}

_widgetGrid();
