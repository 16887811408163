/* eslint-disable react/prefer-stateless-function */
import React, { useState, useRef, useEffect } from 'react';

import FullCalendar from '@fullcalendar/react';
import ListPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';

import { getEventTypeCustomisations } from './event-types/EventTypes';

export default function ScheduleView({
    totalDays,
    events,
    onEventClick,
    onLoadMore,
}) {
    const calendar = useRef(null);
    const [showLoadMore, setShowLoadMore] = useState(false);

    let monthPrimaryLabelCurrent = ''; // Used to determine if a new month section should start

    /* 
        This function is an extension point of Full Calendar to allow custom class names to be applied.

        If the month changes between this event and the last, add a class to this item so
        a new section can be started by not hiding the 'listDaySideFormat' configured above.
    */
    const showMonthLabelWhenMonthChanges = ({ sideText }) => {
        if (!monthPrimaryLabelCurrent) {
            monthPrimaryLabelCurrent = sideText;
        }
        if (monthPrimaryLabelCurrent !== sideText) {
            monthPrimaryLabelCurrent = sideText;
            return 'calendar-schedule-view-new-month';
        }
        return '';
    };

    /*
        This function is an extension point of Full Calendar to allow custom
        rendering of the event content within this view type.
    */
    const printEventContent = ({ event }) => {
        const eventCustomisation = getEventTypeCustomisations(
            event.extendedProps, // Pass the full event data payload not only the fcEvent data,
            event
        );
        return eventCustomisation.getScheduleViewContent({
            onClick: onEventClick,
        });
    };

    /*
        Track when the user has scroll to the bottom of the events and show a 'Load More' button
    */
    useEffect(() => {
        const scroller = calendar.current.querySelector('.fc-scroller');
        if (scroller && scroller.dataset && !scroller.dataset.scroller) {
            scroller.addEventListener('scroll', () => {
                const { scrollTop, scrollHeight, clientHeight } = scroller;
                if (scrollTop + clientHeight + 100 >= scrollHeight) {
                    setShowLoadMore(true);
                } else {
                    setShowLoadMore(false);
                }
            });
            scroller.dataset.scroller = true;
        }
    });

    const handleLoadMore = () => {
        setShowLoadMore(false);
        onLoadMore();
    };

    return (
        <div ref={calendar} className="calendar-schedule-view">
            <FullCalendar
                height="100%"
                plugins={[ListPlugin, rrulePlugin]}
                views={{
                    // Custom view type based on list so we get a whole 365 days, not just to the end of the current year
                    schedule: {
                        type: 'list',
                        duration: { days: totalDays },
                        buttonText: 'Schedule',
                    },
                }}
                initialView="schedule" // Set to custom view above
                headerToolbar={false}
                titleFormat={false}
                events={events}
                eventClick={onEventClick}
                listDayFormat={{
                    // e.g. Dec 21
                    month: 'short',
                    day: 'numeric',
                }}
                listDaySideFormat={{
                    // listDaySideFormat is used to title the new section when a month change occurs e.g. January 2022
                    month: 'long',
                    year: 'numeric',
                }}
                dayHeaderClassNames={showMonthLabelWhenMonthChanges}
                eventContent={printEventContent}
            />
            {showLoadMore && (
                <button
                    type="button"
                    onClick={handleLoadMore}
                    className="calendar-schedule-view__load-more"
                >
                    Load more
                </button>
            )}
        </div>
    );
}
