/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Emails Listing Context
 * @export
 */
const EmailsListingContext = createContext({
    isLoading: false,
    isReady: false,
    error: null,
});

EmailsListingContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        isReady: PropTypes.bool,
        error: PropTypes.symbol,
        emails: PropTypes.arrayOf(
            PropTypes.shape({
                from: PropTypes.string,
                title: PropTypes.string,
                link: PropTypes.string,
                subtitle: PropTypes.string,
                content: PropTypes.string,
                isRead: PropTypes.bool,
            })
        ),
    }),
};

export const EmailsListingContextErrorType = Object.freeze({
    ERROR: Symbol('ERROR'),
});

export default EmailsListingContext;
