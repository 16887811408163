/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The courses context
 * @exports
 */
const CourseContext = createContext({
    isLoading: false,
    isReady: false,
    error: null,
    courses: [],
    getCourses: null,
});

CourseContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        isReady: PropTypes.bool,
        error: PropTypes.symbol,
        courses: PropTypes.arrayOf(
            PropTypes.shape({
                assessments: PropTypes.arrayOf(
                    PropTypes.shape({
                        description: PropTypes.string,
                        due: PropTypes.string,
                        name: PropTypes.string,
                    })
                ),
                code: PropTypes.string,
                department: PropTypes.string,
                description: PropTypes.string,
                name: PropTypes.string,
                ssid: PropTypes.string,
                updates: PropTypes.arrayOf(
                    PropTypes.shape({
                        date: PropTypes.string,
                        description: PropTypes.string,
                        fileSize: PropTypes.number,
                        link: PropTypes.string,
                        name: PropTypes.string,
                    })
                ),
            })
        ).isRequired,
        getCourses: PropTypes.func,
    }),
};

export default CourseContext;

export const CoursesContextErrorType = Object.freeze({
    ERROR: Symbol('ERROR'),
});
