/**
 * Creates a new timer
 * @module Timer
 * @class
 * @param {function} fn - The function to be implemented every x seconds
 * @param {number} interval - The interval in ms that the function should be run
 * @property {Function} stop {@link module:Timer#stop}
 * @property {Function} start {@link module:Timer#start}
 * @property {Function} reset {@link module:Timer#reset}
 */
export default class Timer {
    constructor(fn, interval) {
        // Set our variables to be used in the class
        this.interval = interval;
        this.isRunning = false;
        this.fn = fn;

        // Create our timer object
        this.timerObject = setInterval(fn, interval);
    }

    /**
     * @method module:Timer#stop
     * @description Stops the timer from running
     * @returns {Timer}
     */
    stop() {
        if (this.timerObject) {
            this.isRunning = false;
            clearInterval(this.timerObject);
            this.timerObject = null;
        }
        return this;
    }

    /**
     * @method module:Timer#start
     * @description start timer using current settings (if it's not already running)
     * @returns {Timer}
     */
    start() {
        if (!this.timerObject) {
            this.isRunning = true;
            this.stop();
            this.timerObject = setInterval(this.fn, this.interval);
        }
        return this;
    }

    /**
     * @method module:Timer#reset
     * @description start with new or original interval, stop current interval
     * @returns {Timer}
     */
    reset(newT = this.interval) {
        this.interval = newT;
        return this.stop().start();
    }
}
