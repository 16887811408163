/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The GP Application Context
 * @export
 */
const GpaContext = createContext({
    isLoading: false,
    isReady: false,
    error: null,
    gpa: 0,
    creditsCompleted: 0,
    user: '',
    getData: null,
});

GpaContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        isReady: PropTypes.bool,
        error: PropTypes.symbol,
        gpa: PropTypes.number,
        creditsCompleted: PropTypes.number,
        user: PropTypes.string,
        getData: PropTypes.func,
    }),
};

export const GpaContextErrorType = Object.freeze({
    ERROR: Symbol('ERROR'),
});

export default GpaContext;
