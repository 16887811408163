import whenKey from 'ally.js/src/when/key';

import debounce from 'PlugAndPlay/_global/js/debounce';
import hideOnClickOutside from 'PlugAndPlay/_global/js/clickOutside';

// eslint-disable-next-line no-underscore-dangle
export default function _tabGroup() {
    /**
     * @const {string} - The selector used to find the tab group container
     */
    const TAB_GROUP_SELECTOR = '.tabs';

    /**
     * @const {string} - The selector to find the tab navigation items
     */
    const TAB_NAVIGATION_SELECTOR_BUTTON = '[data-tab-group-control]';

    /**
     * @const {string} - The selector to find content of the tab group
     */
    const TAB_CONTENT_SELECTOR = '[data-tab-group-controlled-by]';

    /**
     * @const {string} - The active class for the content container
     */
    const TAB_CONTENT_ACTIVE_CLASS = 'tab__content--active';

    /**
     * @const {string} - The active class for the tab button
     */
    const TAB_BUTTON_ACTIVE_CLASS = 'tab__button--active';

    /**
     * @const {string} - The selector for the tab group navigation container
     */
    const TAB_NAVIGATION_SELECTOR = '[data-tab-group-element="tab-list-nav"]';

    /**
     * @const {string} - The selector for the overflow menu button
     */
    const TAB_NAVIGATION_OVERFLOW_MENU_BUTTON_SELECTOR =
        '[data-tab-group-element="overflow-menu-button"]';

    /**
     * @const {string} - The overflow menu container selector
     */
    const TAB_NAVIGATION_OVERFLOW_MENU_CONTAINER_SELECTOR =
        '[data-tab-group-element="overflow-menu-container"]';

    /**
     * @const {string} - The selector for the overflow menu wrapper
     */
    const TAB_NAVIGATION_OVERFLOW_MENU_WRAPPER_SELECTOR =
        '[data-tab-group-element="overflow-menu-wrapper"]';

    /**
     * @const {string} - The overflow menu active class
     */
    const TAB_NAVIGATION_OVERFLOW_MENU_ACTIVE_CLASS =
        'tab-list-nav-overflow-menu__wrapper--visible';

    /**
     * @const {string} - Overflow menu open class
     */
    const TAB_NAVIGATION_OVERFLOW_MENU_OPEN_CLASS =
        'tab-list-nav__overflow-menu--open';
    /**
     * @const {string} - Overflow menu button open class
     */
    const TAB_NAVIGATION_OVERFLOW_MENU_BUTTON_OPEN_CLASS =
        'tab-list-nav-overflow-menu__button--open';

    /**
     * The Tab group class
     * @param {HTMLElement} - Passed the html element for a tab group
     * @class
     */
    class TabGroup {
        constructor(tabGroup) {
            // The tab group HTML element
            this.tabGroup = tabGroup;

            // Bind the required functions to the tabGroup class
            this.showContent = this.showContent.bind(this);
            this.recalculateMenuOnResize =
                this.recalculateMenuOnResize.bind(this);
            this.toggleOverflowMenuState =
                this.toggleOverflowMenuState.bind(this);

            // Get the tab group navigation buttons
            this.navigationButtons = this.tabGroup.querySelectorAll(
                TAB_NAVIGATION_SELECTOR_BUTTON
            );

            // Get the content areas for the tabs
            this.contentContainers =
                this.tabGroup.querySelectorAll(TAB_CONTENT_SELECTOR);

            // Get the tab navigation container
            this.navigationContainer = this.tabGroup.querySelector(
                TAB_NAVIGATION_SELECTOR
            );

            // Get the tab nav overflow button
            this.navigationOverflowMenuButton = this.tabGroup.querySelector(
                TAB_NAVIGATION_OVERFLOW_MENU_BUTTON_SELECTOR
            );

            // Get the container for the overflow menu
            this.navigationOverflowMenuContainer = this.tabGroup.querySelector(
                TAB_NAVIGATION_OVERFLOW_MENU_CONTAINER_SELECTOR
            );

            // Get the overflow menu wrapper
            this.navigationOverflowMenuWrapper = this.tabGroup.querySelector(
                TAB_NAVIGATION_OVERFLOW_MENU_WRAPPER_SELECTOR
            );

            // Define a variable if the overflow menu is active
            this.overflowMenuActive = false;

            // Destroy any previously set event listeners before initializing new ones
            this.removeEventListeners();

            // Add the event listeners
            this.addEventListeners();

            // Calculate the items that should be in the overflow menu
            this.addElementsToOverflowMenu();

            this.allyHandles = {
                keyHandle: undefined,
            };

            // Get the location hash
            const locationHash = window.location.hash.replace('#', '');

            // Check that we got something back from the url
            if (locationHash) {
                // Open the content
                this.openTab(locationHash);
            }
        }

        /**
         * Function that adds the event listeners when the tab group is initialized
         * @method
         */
        addEventListeners() {
            // Add the event listener to change the content when a button is clicked
            this.navigationButtons.forEach((navigationButton) => {
                navigationButton.addEventListener('click', this.showContent);
            });

            // Add an event listener that re-works the menu when the window is resized
            window.addEventListener(
                'resize',
                debounce(this.recalculateMenuOnResize)
            );

            // Add event listener to open and close the overflow menu
            this.navigationOverflowMenuButton.addEventListener(
                'click',
                this.toggleOverflowMenuState
            );
        }

        /**
         * Function that removes the event listeners that are setup in the add event listeners function
         * @method
         */
        removeEventListeners() {
            // Remove the event listener that shows content when a button is clicked
            this.navigationButtons.forEach((navigationButton) => {
                navigationButton.removeEventListener('click', this.showContent);
            });

            // remove the event listener that re-works the menu when the window is resized
            window.removeEventListener(
                'resize',
                debounce(this.recalculateMenuOnResize)
            );

            // Remove the event listener for the overflow menu
            this.navigationOverflowMenuButton.removeEventListener(
                'click',
                this.toggleOverflowMenuState
            );
        }

        /**
         * Removes the active class from all the content containers
         * @method
         */
        removeActiveClassFromContent() {
            this.contentContainers.forEach((contentContainer) => {
                contentContainer.classList.remove(TAB_CONTENT_ACTIVE_CLASS);
            });
        }

        /**
         * Removes the active class from all the navigation buttons
         * @method
         */
        removeActiveClassFromButtons() {
            this.navigationButtons.forEach((button) => {
                button.classList.remove(TAB_BUTTON_ACTIVE_CLASS);
            });
        }

        /**
         * Open a specific tab (Based on data controlled by attribute)
         * @method
         * @param {string} tabToOpen - The string to define the tab to open
         */
        openTab(tabToOpen) {
            // get the content to display based on the passed variable (note that the array from is needed as find is not a method on node lists)
            const contentToDisplay = Array.from(this.contentContainers).find(
                (contentContainer) =>
                    contentContainer.dataset.tabGroupControlledBy === tabToOpen
            );

            // Get the tab for the current active content
            const activeTab = Array.from(this.navigationButtons).find(
                (button) => button.dataset.tabGroupControl === tabToOpen
            );

            // If we found the content to display
            if (contentToDisplay && activeTab) {
                // Remove the active class from all content
                this.removeActiveClassFromContent();
                // Remove the active class from all buttons
                this.removeActiveClassFromButtons();

                // Add the active class to the content object
                contentToDisplay.classList.add(TAB_CONTENT_ACTIVE_CLASS);
                // Add the active class to the tab
                activeTab.classList.add(TAB_BUTTON_ACTIVE_CLASS);
            }
        }

        /**
         * Toggle the visibility state of the overflow menu
         * @method
         */
        toggleOverflowMenuState(event) {
            // Stop the propagation of the event so that we can attach the click outside event handler without it being called immediately
            event.stopPropagation();

            if (this.overflowMenuActive) {
                this.setTabIndexes(this.overflowMenuActive);

                this.navigationOverflowMenuContainer.classList.toggle(
                    TAB_NAVIGATION_OVERFLOW_MENU_OPEN_CLASS
                );
                this.navigationOverflowMenuButton.classList.toggle(
                    TAB_NAVIGATION_OVERFLOW_MENU_BUTTON_OPEN_CLASS
                );

                // Check if we have just opened the menu and if so add an event listener to close it if the user clicks outside it
                if (
                    this.navigationOverflowMenuContainer.classList.contains(
                        TAB_NAVIGATION_OVERFLOW_MENU_OPEN_CLASS
                    )
                ) {
                    hideOnClickOutside(
                        this.navigationOverflowMenuContainer,
                        () => this.closeOverflowMenu()
                    );

                    this.navigationOverflowMenuButton.setAttribute(
                        'aria-expanded',
                        true
                    );

                    this.allyHandles.keyHandle = whenKey({
                        escape: () => {
                            setTimeout(this.closeOverflowMenu());
                        },
                    });
                } else {
                    this.navigationOverflowMenuButton.setAttribute(
                        'aria-expanded',
                        false
                    );

                    if (this.allyHandles.keyHandle) {
                        this.allyHandles.keyHandle.disengage();
                    }
                }
            }
        }

        /**
         * Reworks tab index based on button visibility.
         *
         * @param {boolean} shouldBeTabbable Determines if the items are visible or hidden.
         */
        setTabIndexes(shouldBeTabbable) {
            this.navigationButtons.forEach((button) => {
                const shouldBeVisible =
                    button.dataset.hidden === 'true' && shouldBeTabbable;
                const shouldBeHidden =
                    button.dataset.hidden === 'true' && !shouldBeTabbable;

                if (shouldBeVisible) {
                    button.setAttribute('tabindex', '0');
                }
                if (shouldBeHidden) {
                    button.setAttribute('tabindex', '-1');
                }
            });
        }

        /**
         * Closes the overflow menu
         * @method
         */
        closeOverflowMenu() {
            if (this.overflowMenuActive) {
                this.setTabIndexes(!this.overflowMenuActive);

                this.navigationOverflowMenuContainer.classList.remove(
                    TAB_NAVIGATION_OVERFLOW_MENU_OPEN_CLASS
                );
                // Remove the open class from the navigation button
                this.navigationOverflowMenuButton.classList.remove(
                    TAB_NAVIGATION_OVERFLOW_MENU_BUTTON_OPEN_CLASS
                );

                this.navigationOverflowMenuButton.setAttribute(
                    'aria-expanded',
                    false
                );

                this.allyHandles.keyHandle.disengage();
            }
        }

        /**
         * Opens the overflow menu
         * @method
         */
        openOverflowMenu() {
            if (this.overflowMenuActive) {
                this.navigationOverflowMenuContainer.classList.add(
                    TAB_NAVIGATION_OVERFLOW_MENU_OPEN_CLASS
                );
            }
        }

        /**
         * Resize Event listener to call the recalculation when the window width has changed
         */
        recalculateMenuOnResize() {
            // Call the add elements to overflow menu which will rework the menu
            this.addElementsToOverflowMenu();
        }

        /**
         *
         * @param {*} event
         */
        addElementsToOverflowMenu() {
            // get the width of the containing div
            const containerWidth = this.tabGroup.offsetWidth;

            // Move all the buttons to the navigation container (not the dropdown) so we can caculate the widths correctly
            this.navigationButtons.forEach((button) => {
                this.navigationContainer.append(button);
            });

            // Move the dropdown container to be the last item after appending the buttons
            this.navigationContainer.append(this.navigationOverflowMenuWrapper);

            // Get the width of each element
            const navigationButtonWidths = Array.from(
                this.navigationButtons
            ).map((button) => {
                // Return the button width including the margin left and right
                return (
                    button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10)
                );
            });

            // get the navigation buttons total widths
            const navigationButtonsTotalWidths = navigationButtonWidths.reduce(
                (currentButtonWidthAccumulator, currentButtonWidth) =>
                    currentButtonWidthAccumulator + currentButtonWidth
            );

            // Get the width of the overflow menu button if the sum of the other buttons widths is greater than the container width
            if (navigationButtonsTotalWidths > containerWidth) {
                // Get the overflow menu button width including the margins
                const overflowMenuButtonWidth =
                    this.navigationOverflowMenuButton.offsetWidth +
                    parseInt(
                        window.getComputedStyle(
                            this.navigationOverflowMenuButton
                        ).marginLeft,
                        10
                    ) +
                    parseInt(
                        window.getComputedStyle(
                            this.navigationOverflowMenuButton
                        ).marginRight,
                        10
                    );

                // Get an array of the buttons that should be shown
                const buttonsToShow = [];
                // Get an array of the buttons that should be moved to the dropdown
                const buttonsToMoveToDropdown = [];
                // Variable to define if we can add more buttons
                let canAddMoreButtons = true;

                // Define the starting width of the sum of the buttons as 0
                let currentButtonsToShowWidth = 0;

                // Loop through the buttons
                this.navigationButtons.forEach((button) => {
                    // Check if the button being added would push the other buttons over the width
                    if (
                        currentButtonsToShowWidth +
                            button.offsetWidth +
                            parseInt(
                                window.getComputedStyle(button).marginLeft,
                                10
                            ) +
                            parseInt(
                                window.getComputedStyle(button).marginRight,
                                10
                            ) +
                            overflowMenuButtonWidth <
                            containerWidth &&
                        canAddMoreButtons
                    ) {
                        // If the width isn't going to push over the button over the width, add the button to the buttons to show list
                        buttonsToShow.push(button);

                        // add the button width to the current buttons to show width
                        currentButtonsToShowWidth =
                            currentButtonsToShowWidth +
                            button.offsetWidth +
                            parseInt(
                                window.getComputedStyle(button).marginLeft,
                                10
                            ) +
                            parseInt(
                                window.getComputedStyle(button).marginRight,
                                10
                            );
                    } else {
                        // If the button shouldn't be shown add it to the array that should be moved to the dropdown
                        buttonsToMoveToDropdown.push(button);
                        // Set the can add more buttons to false (preventing any more buttons being added)
                        canAddMoreButtons = false;
                    }
                });

                // Move the buttons to the dropdown
                buttonsToMoveToDropdown.forEach((button) => {
                    button.setAttribute('tabindex', '-1');
                    // eslint-disable-next-line no-param-reassign
                    button.dataset.hidden = 'true';
                    this.navigationOverflowMenuContainer.append(button);
                });

                buttonsToShow.forEach((button) => {
                    button.setAttribute('tabindex', '0');
                    // eslint-disable-next-line no-param-reassign
                    button.dataset.hidden = 'false';
                });

                // Display the overflow menu
                this.showOverflowMenu(true);

                // Set that the overflow menu is active
                this.overflowMenuActive = true;
            } else {
                // Don't display the overflow menu
                this.showOverflowMenu(false);
                // Set that the overflow menu is inactive
                this.overflowMenuActive = false;
            }
        }

        /**
         * Function that shows or hides the overflow menu
         * @param {bool} shouldShowOverflowMenu
         */
        showOverflowMenu(shouldShowOverflowMenu) {
            if (shouldShowOverflowMenu) {
                this.navigationOverflowMenuWrapper.classList.add(
                    TAB_NAVIGATION_OVERFLOW_MENU_ACTIVE_CLASS
                );
            } else {
                this.navigationOverflowMenuWrapper.classList.remove(
                    TAB_NAVIGATION_OVERFLOW_MENU_ACTIVE_CLASS
                );
            }
        }

        /**
         * Adds the active class to the content area
         * @param {event} event - The firing event (This should be fired by a element with the data attribute data-tab-group-control that controls )
         * @method
         */
        showContent(event) {
            this.navigationButtons.forEach((buttons) => {
                buttons.setAttribute('aria-selected', 'false');
            });

            event.srcElement.setAttribute('aria-selected', 'true');
            // Open the specific tab
            this.openTab(event.target.dataset.tabGroupControl);
            this.closeOverflowMenu();
        }
    }

    /**
     * Function that initializes the tab groups on the page
     */
    function initializeTabGroups() {
        const tabGroupInstances = document.querySelectorAll(TAB_GROUP_SELECTOR);

        // Go through each tab group instance and create a new class from that tabGroup
        tabGroupInstances.forEach((tabGroup) => {
            // Create a new item to modify
            const DOMItem = tabGroup;

            DOMItem.tabGroup = new TabGroup(tabGroup);
        });
    }

    /**
     * @const {boolean}
     */
    const tabGroupExists = !!document.querySelector(TAB_GROUP_SELECTOR);

    // If the module exists, do whatever we need to
    if (tabGroupExists) {
        initializeTabGroups();
    }
}

_tabGroup();
