/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useContext } from 'react';
import { OverlayProvider } from '@react-aria/overlays';
import { watchModals } from '@react-aria/aria-modal-polyfill';

import {
    getCurrentScreenSizeInt,
    screenSizeSM,
} from 'PlugAndPlay/_global/js/screenSize';

import Card from '../../collapsible-card/jsx/Card';
import StatusMessage from '../../status-message/jsx/StatusMessage';

import SocialFeedContext from './data/SocialFeedContext';
import { createCard } from './card-types/CardFactory';

export function SocialFeedLoadingState() {
    return (
        <div className="social-feed-loading">
            <div className="social-feed-loading__label">Loading...</div>
            <div className="social-feed-loading__card">
                <div className="social-feed-loading__shadow" />
            </div>
            <div className="social-feed-loading__card">
                <div className="social-feed-loading__shadow" />
            </div>
        </div>
    );
}

export default function SocialFeed({ title, numberOfCards }) {
    const { isLoading, isError, feedData, getFeedData } =
        useContext(SocialFeedContext);

    // If in mobile format lazy load all images
    const screenSize = getCurrentScreenSizeInt();
    const forceLazyLoadImage = screenSize <= screenSizeSM;

    watchModals();

    // Initial data load
    useEffect(() => {
        getFeedData({
            maxItems: numberOfCards,
        });
    }, [getFeedData]);

    return (
        <Card
            id="socialFeed"
            isCollapsible={false}
            title={title}
            contentClasses={`no-wysiwyg social-feed-wrapper ${
                isError && 'social-feed-wrapper--error'
            }`}
        >
            {isLoading && <SocialFeedLoadingState />}
            {isError && (
                <StatusMessage
                    title="Error - no content loaded"
                    icon="warning"
                    className="social-feed-error"
                >
                    <div className="social-feed-error__message">
                        Please check later
                    </div>
                </StatusMessage>
            )}

            {!isLoading && !isError && (
                <OverlayProvider className="social-feed-wrapper__overlay">
                    <div className="social-feed">
                        {feedData.map((cardDetails, i) => {
                            return createCard({
                                ...cardDetails,
                                lazyLoadImages: forceLazyLoadImage || i > 1,
                            });
                        })}
                    </div>
                </OverlayProvider>
            )}
        </Card>
    );
}
