/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, StrictMode, lazy } from 'react';
import { FunnelbackAutocompleteAdapter } from '../data/FunnelbackAutocompleteAdapter';
import { parseHTML, parseValue, domToRect } from './helpers';

const AutocompleteProvider = lazy(() =>
    import(/* webpackChunkName: "AutocompleteProvider" */ './autocomplete')
);

const ModalAutocompleteProvider = lazy(() =>
    import(
        /* webpackChunkName: "ModalAutocompleteProvider" */ './modal-autocomplete'
    )
);

const appWrapper = document.querySelectorAll(
    '[data-component="autocomplete-concierge"]'
);

if (appWrapper.length > 0) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            let root = wrapper;

            const { dataset } = wrapper;
            const config = dataset.autocompleteConfig
                ? JSON.parse(dataset.autocompleteConfig)
                : {};
            const { modalVersion } = config;
            const elements = parseHTML(document, wrapper.innerHTML);

            const parsedTemplates = Array.from(elements).map((el) => ({
                id: parseValue(el.dataset.id),
                label: parseValue(el.dataset.label),
                template: parseValue(el.dataset.template),
                serviceUrl: parseValue(el.dataset.serviceUrl),
                adapter: parseValue(el.dataset.adapter),
                params: parseValue(el.dataset.params),
                className: parseValue(el.classList.value),
                type: parseValue(el.nodeName),
                name: parseValue(el.name),
                value: parseValue(el.value),
            }));

            /*
                Following lines are running when user wants to 'hijack' existing input field and attach
                react version of concierge.
                Since input is not allowing to have children elements, we are taking attributes from
                existing input and replacing with div wrapper element.
                All except value (we are controlling inputs) attributes are copied and renamed to valid
                React attributes, latter they will be spread in our input field.
             */
            const portal = config.portal || null;
            const inputAttributes = [];
            const inputField = document.querySelector(portal);

            if (portal && inputField) {
                const replacement = document.createElement('div');
                for (let i = 0; i < inputField.attributes.length; i++) {
                    const attr = inputField.attributes[i];
                    if (attr.name === 'id') {
                        replacement.setAttribute(attr.name, attr.value);
                    }
                    if (attr.name !== 'value' && domToRect[attr.name]) {
                        inputAttributes[domToRect[attr.name]] =
                            attr.value.toString();
                    }
                }

                inputField.parentNode.replaceChild(replacement, inputField);
                root = document.querySelector(portal);
            }

            const props = {
                id: wrapper.id,
                html: wrapper.innerHTML,
                className: wrapper.className,
                templates: parsedTemplates.filter((el) => el.type === 'SPAN'),
                hiddenFields: parsedTemplates.filter(
                    (el) => el.type === 'INPUT'
                ),
                inputAttributes,
                ...config,
            };

            const adapters = [];
            props.templates.forEach((template) => {
                // If there is no adapter we will take default one which is Funnelback.
                if (!template.adapter || template.adapter === '') {
                    adapters.push(
                        new FunnelbackAutocompleteAdapter({
                            funnelback: {
                                serviceUrl: template.serviceUrl,
                                params: template.params,
                                queryParam: template.queryParam,
                            },
                        })
                    );
                }
            });

            const autocomplete = !modalVersion ? (
                <AutocompleteProvider {...props} adapters={adapters} />
            ) : (
                <ModalAutocompleteProvider {...props} adapters={adapters} />
            );

            ReactDOM.render(
                <StrictMode>
                    <Suspense
                        fallback={
                            <div className="autocomplete-concierge__skeleton">
                                Loading...
                            </div>
                        }
                    >
                        {autocomplete}
                    </Suspense>
                </StrictMode>,
                root
            );
        });
    });
}
