/**
 * Function that splits an array into an array of arrays at a given point.
 * @param {Array} array - The array to split.
 * @param {number} point - The point at which to split the array.
 * @returns {Array} The array of arrays.
 */
function splitArrayAtPoint(array, point) {
    // If the point isn't an integer, return an error.
    if (typeof point !== 'number' || !Number.isInteger(point)) {
        throw new Error('The point must be an integer.');
    }

    // If the array isn't an array, return an error.
    if (!Array.isArray(array)) {
        throw new Error('The array must be an array.');
    }

    // If the point is greater than the array lenth, return the array and an empty array.
    if (array.length === 0 || point >= array.length) {
        return [array, []];
    }

    // Split the array into two arrays.
    return [array.slice(0, point), array.slice(point)];
}

export default splitArrayAtPoint;
