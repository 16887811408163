/* eslint-disable import/prefer-default-export */
import { useRef, useState } from 'react';
import { useKeyboard } from '@react-aria/interactions';

/**
 * @returns Hooks to open and close the modal dialog from interacting with the social card
 */
export function useSocialCardModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModalRef = useRef();

    const { keyboardProps: modalOpenProps } = useKeyboard({
        onKeyUp: (e) => {
            // Check the target isnt another element inside the modal
            if (e.target === openModalRef.current) {
                // Enter
                if (e.keyCode === 13) {
                    setIsModalOpen(true);
                }

                // Space
                if (e.keyCode === 32) {
                    setIsModalOpen(true);
                }
            }
        },
    });
    modalOpenProps.onClick = () => setIsModalOpen(true);

    return {
        openModalRef, // useRef
        modalOpenProps, // useKeyboard
        isModalOpen, // useState get
        setIsModalOpen, // useState set
    };
}
