/* eslint-disable react/prefer-stateless-function */
import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
    useRef,
} from 'react';
import { OverlayProvider } from '@react-aria/overlays';
import {
    differenceInDays,
    addMonths,
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
} from 'date-fns';

import uuidv4 from 'PlugAndPlay/_global/js/uuid';
import ButtonMenu, { ButtonMenuItem } from '../../button-menu/jsx/button-menu';
import Modal from '../../modal/jsx/modal';
import StatusMessage from '../../status-message/jsx/StatusMessage';

// Internal module imports
import CalendarContext from './data/CalendarContext';
import { CalendarViews, EventActions } from './enums';
import DateControlHeader from './dateControlHeader';
import DayView from './dayView';
import WeekView from './weekView';
import MonthView from './monthView';
import ScheduleView from './scheduleView';
import { getEventTypeCustomisations } from './event-types/EventTypes';
import useResizeObserver from './useResizeObserver';

/**
 * This is only used on the initial webpack / React Lazy load of the component
 */
export function CalendarLoadingState({ title }) {
    return (
        <div className="calendar-wrapper__overlay">
            <div className="no-wysiwyg calendar">
                <div className="calendar__header">
                    <div className="calendar__title">{title}</div>
                </div>
                <div className="calendar-loading">
                    <div className="calendar-loading__label">Loading...</div>
                    <div className="calendar-loading__row calendar-loading__row--header">
                        <div className="calendar-loading__shadow-header" />
                        <div className="calendar-loading__shadow-header" />
                        <div className="calendar-loading__shadow-header" />
                        <div className="calendar-loading__shadow-header" />
                        <div className="calendar-loading__shadow-header" />
                    </div>
                    {[...Array(4)].map((junk, i) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={i} className="calendar-loading__row">
                                <div className="calendar-loading__shadow" />
                                <div className="calendar-loading__shadow" />
                                <div className="calendar-loading__shadow" />
                                <div className="calendar-loading__shadow" />
                                <div className="calendar-loading__shadow" />
                            </div>
                        );
                    })}
                    <div className="calendar-loading__row">
                        <div className="calendar-loading__shadow" />
                        <div className="calendar-loading__shadow" />
                        <div className="calendar-loading__shadow" />
                        <div className="calendar-loading__shadow calendar-loading__shadow--empty" />
                        <div className="calendar-loading__shadow calendar-loading__shadow--empty" />
                    </div>
                </div>
            </div>
        </div>
    );
}

/**
 * Calendar application
 */
export default function Calendar({
    defaultView,
    allDaySlot = CalendarViews.Day,
    nowIndicator,
    title,
    bannerEnabled,
    bannerText,
    bannerCountType,
    typeIconMap = {},
    eventAction = EventActions.OpenInModal,
}) {
    const [calendarAPI, setCalendarAPI] = useState();
    const [viewType, setViewType] = useState(defaultView);
    const { isLoading, isError, events, getEvents } =
        useContext(CalendarContext);
    const [allDaySlotEnabled, setAllDaySlotEnabled] = useState(allDaySlot);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(undefined);

    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const [scheduleEndDate, setScheduleEndDate] = useState(addMonths(today, 3));

    const [bannerCount, setBannerCount] = useState(0);
    const [highlightBanner, setHighlightBanner] = useState(false);
    const highlightBannerTimer = useRef(null);
    const highlightBannerLast = useRef(null);

    /*
        If the size of the calendar changes due to be hidden or made visible by an outside
        css effect this will detect the change in width and height and cause the DayView / ScheduleView
        to re-render as Full Calendar needs to have a stable height and width to render correctly.
    */
    const [calendarRef, { width, height }] = useResizeObserver();

    // When the 'currentDate' changes update the events in the EventsContext based on the new date.
    useEffect(() => {
        // Reset banner details
        setBannerCount(0);
        setHighlightBanner(false);
        highlightBannerLast.current = null;
        if (highlightBannerTimer.current) {
            clearTimeout(highlightBannerTimer.current);
            highlightBannerTimer.current = null;
        }

        // Fetch the data for the new view
        if (viewType === CalendarViews.Day) {
            getEvents({
                start: startOfDay(currentDate),
                end: endOfDay(currentDate),
            });
        } else if (viewType === CalendarViews.Week) {
            getEvents({
                start: startOfWeek(currentDate),
                end: endOfWeek(currentDate),
            });
        } else if (viewType === CalendarViews.Month) {
            getEvents({
                start: startOfMonth(currentDate),
                end: endOfMonth(currentDate),
            });
        }
    }, [currentDate, viewType]);

    // When the view type changes request update the events in the EventsContext based on the required date ranges for the view type
    useEffect(() => {
        if (viewType === CalendarViews.Schedule) {
            getEvents({
                start: today,
                end: scheduleEndDate,
            });
        }
    }, [viewType, scheduleEndDate]);

    // When the events from the EventsContent changes, re-calculate the number to show in the banner
    useEffect(() => {
        if (bannerEnabled) {
            let newCount = 0;

            // Need to check via the page API as only it knows whats being shown on the page after RRULE recurring events has been calculated
            if (calendarAPI) {
                newCount = calendarAPI.getEvents().reduce((count, event) => {
                    if (event.extendedProps.type === bannerCountType) {
                        return count + 1;
                    }
                    return count;
                }, 0);
            }

            setBannerCount(newCount);
        }
    }, [events, calendarAPI]);

    // When the events from the EventsContent changes, re-calculate all day slot validity
    useEffect(() => {
        if (allDaySlot) {
            if (events.find((event) => event.allDay)) {
                setAllDaySlotEnabled(true);
            } else {
                setAllDaySlotEnabled(false);
            }
        }
    }, [allDaySlot, events]);

    /*
        Transform from PNP Calendar data format into the FullCalendar format via clone. Also 
        applies any calculated fields which are needed in the event render hook.

        FullCalendar will discard any properties it does not know about so
        everything is also copied into the extension area 'extendedProps'.
    */
    const getFullCalendarEvents = useCallback(
        (fetchInfo, successCallback) => {
            const fcEvents = events.map((event, index) => {
                const fcEvent = {
                    ...event,
                    interactive: true,
                    extendedProps: {
                        ...event,
                        _calendarEventIndex: index, // Used to match the fcEvent to the internal state onClick
                        _eventInteractionType: eventAction, // Used to determine if the tag role should be button or link
                        duration: Math.floor(
                            Math.abs(event.end - event.start) / 1000 / 60
                        ), // Short events (< 30min) dont show the event description on Day view
                    },
                };

                // If open in modal is activated delete the URL from the payload as this causes FC to make the event a link on click
                if (eventAction === EventActions.OpenInModal && event.url) {
                    fcEvent.extendedProps.url = event.url;
                    delete fcEvent.url;
                }

                // If the type matches with a configuration in the icon map add it
                if (typeIconMap[event.type]) {
                    fcEvent.extendedProps.icon = typeIconMap[event.type];
                    fcEvent.extendedProps.iconAlt = event.type;
                }

                // If banner is enabled tag the event as highlightable via css
                if (bannerEnabled && event.type === bannerCountType) {
                    fcEvent.extendedProps.bannerHighlight = true;
                }

                if (event.recurrence && event.recurrence.length > 0) {
                    fcEvent.rrule = `${event.recurrence[0]}`;
                }

                return fcEvent;
            });
            successCallback(fcEvents);
        },
        [events]
    );

    // Handler for event being clicked
    const handleEventClick = useCallback(
        ({ el, event: fcEvent }) => {
            // If the eventAction isn't set to modal, try to resolve it first
            if (eventAction !== EventActions.OpenInModal) {
                // If there isn't a URL, fall back to the modal
                if (fcEvent.extendedProps.url) {
                    return;
                }
            }

            /* 
                Either the eventAction is OpenInModal or the event properties didn't contain a URL 
                so set the selected event for the next render to pickup and show a modal.
            */
            const calendarEvent =
                events[fcEvent.extendedProps._calendarEventIndex];
            setSelectedEvent({ event: calendarEvent, fcEvent, element: el });
        },
        [events]
    );

    const handleEventModalClose = useCallback(() => {
        /* if (e.type !== 'click') {
                selectedEvent.element.focus();
            } */
        setSelectedEvent(undefined);
    }, [selectedEvent]);

    const handleCalendarApiSet = useCallback((api) => {
        setCalendarAPI(api);
    }, []);

    const handleBannerClick = useCallback(() => {
        // Highlight the banner types in the calendar for a second
        setHighlightBanner(true);
        // Clear any current timeout
        if (highlightBannerTimer.current) {
            clearTimeout(highlightBannerTimer.current);
        }
        // 'fade out' the banner highlight
        highlightBannerTimer.current = setTimeout(() => {
            setHighlightBanner(false);
            highlightBannerTimer.current = null;
        }, 1000);

        const todaysEvents = calendarAPI.getEvents();
        if (todaysEvents.length > 0) {
            // Cycle though the events that match the banner type on the page
            let nextItem =
                highlightBannerLast.current !== null
                    ? highlightBannerLast.current + 1
                    : 0;
            if (nextItem >= todaysEvents.length) {
                nextItem = 0;
            }
            highlightBannerLast.current = nextItem;

            calendarAPI.scrollToTime(
                todaysEvents[nextItem].start.getHours() * 3600000 +
                    todaysEvents[nextItem].start.getMinutes() * 60000
            );
        }
    }, [highlightBanner, calendarAPI]);

    let selectedEventCustomisation;
    if (selectedEvent) {
        selectedEventCustomisation = getEventTypeCustomisations(
            selectedEvent.event,
            selectedEvent.fcEvent
        );
    }

    // const handleMoreSchedule = () => {
    //     setScheduleEndDate(addMonths(scheduleEndDate, 3));
    // };
    const handleMoreSchedule = useCallback(() => {
        setScheduleEndDate(addMonths(scheduleEndDate, 3));
    }, [scheduleEndDate]);

    const titleId = uuidv4(); // Modal needs a id for the dialog described by
    return (
        <div
            className={`calendar-wrapper ${
                isLoading ? 'calendar-wrapper--loading' : ''
            }`}
        >
            {isError && (
                <div className="calendar-wrapper__overlay">
                    <div className="no-wysiwyg calendar">
                        <div className="calendar__header">
                            <div className="calendar__title">{title}</div>
                        </div>
                        <div className="calendar__body">
                            <StatusMessage
                                title="Error - no content loaded"
                                icon="warning"
                                className="calendar-error"
                            >
                                <div className="calendar-error__message">
                                    Please check later
                                </div>
                            </StatusMessage>
                        </div>
                    </div>
                </div>
            )}

            {!isError && (
                <OverlayProvider className="calendar-wrapper__overlay">
                    <div
                        ref={calendarRef}
                        className={`no-wysiwyg calendar ${
                            viewType !== CalendarViews.Schedule
                                ? 'calendar--sub-header'
                                : ''
                        }`}
                    >
                        <div className="calendar__header">
                            <div className="calendar__title">{title}</div>
                            <ButtonMenu
                                uniqueId="calendar-view-switcher"
                                icon="overflow-menu"
                                iconTitle="Toggle view switcher menu"
                                className="calendar-view-select"
                            >
                                {viewType !== CalendarViews.Schedule && (
                                    <ButtonMenuItem
                                        label="Schedule"
                                        onClick={() =>
                                            setViewType(CalendarViews.Schedule)
                                        }
                                    />
                                )}
                                {viewType !== CalendarViews.Day && (
                                    <ButtonMenuItem
                                        label="Day"
                                        onClick={() =>
                                            setViewType(CalendarViews.Day)
                                        }
                                    />
                                )}
                                {viewType !== CalendarViews.Week && (
                                    <ButtonMenuItem
                                        label="Week"
                                        onClick={() =>
                                            setViewType(CalendarViews.Week)
                                        }
                                    />
                                )}
                                {viewType !== CalendarViews.Month && (
                                    <ButtonMenuItem
                                        label="Month"
                                        onClick={() =>
                                            setViewType(CalendarViews.Month)
                                        }
                                    />
                                )}
                            </ButtonMenu>
                        </div>

                        {viewType !== CalendarViews.Schedule && (
                            <DateControlHeader
                                date={currentDate}
                                viewType={viewType}
                                onDateChange={(date) => {
                                    setCurrentDate(date);
                                }}
                            />
                        )}

                        {viewType === CalendarViews.Day &&
                            bannerEnabled &&
                            bannerCount > 0 && (
                                <button
                                    type="button"
                                    aria-label={`Highlight ${bannerText}`}
                                    onClick={() => handleBannerClick()}
                                    className="calendar__banner"
                                >
                                    {bannerText}
                                    <span className="calendar__banner-count">
                                        {bannerCount}
                                    </span>
                                </button>
                            )}

                        <div
                            className={`calendar__body ${
                                highlightBanner
                                    ? 'calendar__body--highlight-types'
                                    : ''
                            }`}
                        >
                            {viewType === CalendarViews.Day && (
                                <DayView
                                    width={width}
                                    height={height}
                                    date={currentDate}
                                    allDaySlot={allDaySlotEnabled}
                                    nowIndicator={nowIndicator}
                                    events={getFullCalendarEvents}
                                    onEventClick={handleEventClick}
                                    returnCalendarApi={handleCalendarApiSet}
                                />
                            )}
                            {viewType === CalendarViews.Week && (
                                <WeekView
                                    width={width}
                                    height={height}
                                    date={currentDate}
                                    allDaySlot={allDaySlotEnabled}
                                    nowIndicator={nowIndicator}
                                    events={getFullCalendarEvents}
                                    onEventClick={handleEventClick}
                                />
                            )}
                            {viewType === CalendarViews.Month && (
                                <MonthView
                                    width={width}
                                    height={height}
                                    date={currentDate}
                                    allDaySlot={allDaySlotEnabled}
                                    nowIndicator={nowIndicator}
                                    events={getFullCalendarEvents}
                                    onEventClick={handleEventClick}
                                />
                            )}
                            {viewType === CalendarViews.Schedule && (
                                <ScheduleView
                                    width={width}
                                    height={height}
                                    totalDays={differenceInDays(
                                        scheduleEndDate,
                                        today
                                    )}
                                    events={getFullCalendarEvents}
                                    onEventClick={handleEventClick}
                                    onLoadMore={handleMoreSchedule}
                                />
                            )}
                        </div>
                    </div>
                    {selectedEvent && (
                        <Modal
                            titleId={titleId}
                            onClose={handleEventModalClose}
                            className="calendar-modal"
                        >
                            <header className="calendar-modal__header">
                                <h1
                                    id={titleId}
                                    className="calendar-modal__title"
                                >
                                    {selectedEventCustomisation.getModalTitle()}
                                </h1>
                                <button
                                    type="button"
                                    onClick={handleEventModalClose}
                                    className="calendar-modal__header-close"
                                >
                                    <svg className="svg-icon">
                                        <title>Close modal</title>
                                        <use href="#close" />
                                    </svg>
                                </button>
                            </header>

                            <div className="calendar-modal__body">
                                {selectedEventCustomisation.getModalContent()}
                            </div>

                            <footer className="calendar-modal__footer">
                                <button
                                    type="button"
                                    onClick={handleEventModalClose}
                                    className="calendar-modal__close"
                                >
                                    Close
                                </button>
                                {selectedEventCustomisation
                                    .getModalFooterControls()
                                    .map((config) => {
                                        return (
                                            <a
                                                key={config.url}
                                                href={config.url}
                                                target="_blank"
                                                className="calendar-modal__link"
                                                rel="noreferrer"
                                            >
                                                {config.icon ? (
                                                    <svg className="svg-icon">
                                                        <use
                                                            href={`#${config.icon}`}
                                                        />
                                                    </svg>
                                                ) : (
                                                    <></>
                                                )}
                                                {config.label}
                                            </a>
                                        );
                                    })}
                            </footer>
                        </Modal>
                    )}
                </OverlayProvider>
            )}
        </div>
    );
}
