/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import React, { useState, useCallback } from 'react';
import getStudent from 'Edify/js/getStudentMemoized';

import CalendarContext from './CalendarContext';

/*
    Datastore doesn't have anything fancy for events storage, we have to handle things like RRules, past events
    flowing into a day etc. There are 4 circumstances we are interested in for a given date range (start + end date):

    1: Recurring events that start before the date range and end after the start (so within it or after); while not guarantee as the RRule is not parsed its possible it will need to be included.
    2: Simple events that started before the range which end after the start (so within it or after).
    3: Simple and recurring events that start within the range and continue past it.
    4: Simple and recurring events that start and end within the range.

    These can be combined into two queries that need to be made.
    1: Find event that start before the range that have not ended before the range starts
    2: Find events that start after the begin of the range but that do not start past the end of the range
*/
const addPriorStartFilters = ({ query, start, fieldNames }) => {
    let modifiedQuery = query;
    if (start) {
        modifiedQuery = modifiedQuery.where(
            fieldNames?.start || `start`,
            `<`,
            start.toISOString()
        );
        modifiedQuery = modifiedQuery.and(
            fieldNames?.end || `end`,
            `>`,
            start.toISOString()
        );
    }

    return modifiedQuery;
};
const addInRangeStartFilters = ({ query, start, end, fieldNames }) => {
    let modifiedQuery = query;

    if (start) {
        modifiedQuery = modifiedQuery.where(
            fieldNames?.start || `start`,
            `>=`,
            start.toISOString()
        );

        // If there is an end date to the range, use that as the last point in time an event can start to be included
        if (end) {
            modifiedQuery = modifiedQuery.and(
                fieldNames?.start || `start`,
                `<=`,
                end.toISOString()
            );
        }
    }

    return modifiedQuery;
};

const processEvents = ({ results, defaultType, fieldNames }) => {
    return results.map((result) => {
        let event = result;
        if (event.data) {
            event = {
                // Expand out all the properties into the event data
                ...JSON.parse(event.data),
                // But let the base event information override any duplication
                ...event,
            };
        }

        // Use override start data prpery if provided
        event.start = new Date(event[fieldNames?.start || `start`]);

        if (fieldNames?.end && fieldNames?.end !== fieldNames?.start) {
            // Only if there is a real end override end date use it, if its a start duplicate skip
            event.end = new Date(event[fieldNames.end]);
        } else {
            // Otherwise use the event.end or default a end date of 30mins
            event.end = new Date(event.end || event.start.getTime() + 1800000);
        }

        // If no type is provided default one
        if (!event.type) {
            event.type = defaultType;
        }

        if (event.submissionType === 'none') {
            delete event.submissionType;
        }

        return event;
    });
};

export default ({ edifyDatastore, eventsDatastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [events, setEvents] = useState([]);

    const getSectionEvents = useCallback(({ student, start, end } = {}) => {
        // Get the students enrolled courses and sections
        const enrolment = JSON.parse(student.enrolments); // Looks like: "{“c1236” => [“s1”, “s2”],“c5334” => [“s11”, “s22”]}"
        const sectionEventPromises = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const [course, sections] of Object.entries(enrolment)) {
            sections.forEach((section) => {
                // Get events that start before the day that could effect the day (FullCalendar will decide if it needs to show it)
                const recurringEvents = addPriorStartFilters({
                    query: eventsDatastore
                        .collection('courses')
                        .doc(course)
                        .collection('sections')
                        .doc(section)
                        .collection('events'),
                    start,
                }).get();
                sectionEventPromises.push(recurringEvents);

                // Get events that happen only on the day
                const currentDayEvents = addInRangeStartFilters({
                    query: eventsDatastore
                        .collection('courses')
                        .doc(course)
                        .collection('sections')
                        .doc(section)
                        .collection('events'),
                    start,
                    end,
                }).get();
                sectionEventPromises.push(currentDayEvents);
            });
        }

        return Promise.all(sectionEventPromises).then((allSectionEvents) =>
            processEvents({
                results: allSectionEvents.flat(),
                defaultType: 'timetable',
            })
        );
    }, []);

    const getPersonalEvents = useCallback(({ student, start, end } = {}) => {
        const personalEventPromises = [];
        // Get events that start before the day that could effect the day (FullCalendar will decide if it needs to show it)
        const recurringEvents = addPriorStartFilters({
            query: eventsDatastore
                .collection('students')
                .doc(student.studentid)
                .collection('events'),
            start,
        }).get();
        personalEventPromises.push(recurringEvents);

        // Get events that happen only on the day
        const currentDayEvents = addInRangeStartFilters({
            query: eventsDatastore
                .collection('students')
                .doc(student.studentid)
                .collection('events'),
            start,
            end,
        }).get();
        personalEventPromises.push(currentDayEvents);

        return Promise.all(personalEventPromises).then((allSectionEvents) =>
            processEvents({
                results: allSectionEvents.flat(),
                defaultType: 'personal',
            })
        );
    }, []);

    /**
     * Get all events.
     * @param {Date} [start] Start date to get events from
     * @param {Date} [end] End date to get events to
     */
    const getEvents = useCallback(({ start, end } = {}) => {
        setIsLoading(true);

        getStudent(edifyDatastore)
            .then((student) => {
                // Get student course timestable
                const sectionEvents = getSectionEvents({ student, start, end });
                // Get students personal events
                const personalEvents = getPersonalEvents({
                    student,
                    start,
                    end,
                });

                // Once all events are back we can set save them all and end the load
                Promise.all([sectionEvents, personalEvents])
                    .then((allEvents) => {
                        setEvents(allEvents.flat());
                    })
                    .catch((e) => {
                        setIsError(true);
                        throw e;
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            })
            .catch((e) => {
                setIsError(true);
                throw e;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <CalendarContext.Provider
            value={{
                isLoading,
                isError,
                events,
                getEvents,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};
