import React, { Suspense } from 'react';
import getJWT from 'Edify/js/getJwt';
import DatastoreCoursesProvider from './data/DatastoreCoursesProvider';
import EdifyCoursesProvider from './data/EdifyCoursesProvider';
import { LoadingState } from './Courses';

// Get all the component wrappers
const componentWrapper = document.querySelectorAll('[data-component="courses"');

// Run if there are no components
if (componentWrapper.length > 0) {
    // Lazy load the main component
    const Courses = React.lazy(() => import('./Courses'));

    // Load in react dom
    import('react-dom').then((ReactDOM) => {
        componentWrapper.forEach((wrapper) => {
            let coursesProvider;

            // Get the attributes set on the wrapper (these will come from the CCT)
            const { dataset } = wrapper;

            // Get the config from the dataset
            const appConfiguration = JSON.parse(dataset.appConfiguration);

            // Get the type of provider from config
            const type = appConfiguration.provider || 'datastore';

            // Define our datastore instance
            const datastore = new Datastore({
                serviceURL: appConfiguration.datasourceUrl,
                jwtCallback: () => getJWT(appConfiguration.datasourceJWT),
            });

            if (type === 'datastore') {
                coursesProvider = (
                    <DatastoreCoursesProvider datastore={datastore}>
                        <Courses title={appConfiguration.title} />
                    </DatastoreCoursesProvider>
                );
            }

            if (type === 'edify') {
                coursesProvider = (
                    <EdifyCoursesProvider datastore={datastore}>
                        <Courses title={appConfiguration.title} />
                    </EdifyCoursesProvider>
                );
            }
            // Render our component
            ReactDOM.render(
                <Suspense fallback={<LoadingState />}>
                    {coursesProvider}
                </Suspense>,
                wrapper
            );
        });
    });
}
