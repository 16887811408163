import React from 'react';
import PropTypes from 'prop-types';
import interpolate from '../../_global/js/interpolate';

export default function CircularProgress({
    strokeWidth,
    percentage,
    scale,
    showProgress,
    wrapperClasses,
    descriptionTemplate,
    animation,
    children,
}) {
    const wrapperClass = [
        'circular-progress-circle',
        'circular-progress-circle__container',
        wrapperClasses,
    ].join(' ');

    // We use 100 here so that the circumfrance of the circle is 100 making it easier to work with the stroke dash array
    const radius = 100 / (Math.PI * 2);
    const diameter = radius * 2;
    // Get the size by finding the diameter plus both stroke widths (Left and Right / Top and Bottom)
    const viewBoxSize = diameter + 2 * strokeWidth;
    // Put the circles in the middle of the viewbox
    const coordinates = viewBoxSize / 2;
    // Set our viewbox
    const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;
    // Caculate the actual percentage
    let percentageCaculated;
    // Percentage can be a string on initial load of a component
    if (typeof percentage !== 'number') {
        percentageCaculated = 0;
    } else {
        percentageCaculated = (percentage / scale) * 100;
    }

    return (
        <section className={wrapperClass}>
            <svg
                className="circular-progress-circle__chart"
                viewBox={viewBox}
                style={{
                    '--circle-percentage': percentageCaculated,
                    '--circle-stroke': strokeWidth,
                    '--circle-scale': 100,
                    '--circle-animation': animation,
                }}
            >
                <circle
                    className="circle circular-progress-circle__chart-background"
                    cy={coordinates}
                    cx={coordinates}
                    r={radius}
                />
                {showProgress && (
                    <circle
                        className="circle circular-progress-circle__chart-progress"
                        cy={coordinates}
                        cx={coordinates}
                        r={radius}
                    />
                )}
            </svg>
            <h2 className="circular-progress-circle__description">
                {interpolate(descriptionTemplate, {
                    percentage,
                    scale,
                })}
            </h2>
            {children}
        </section>
    );
}

const { number, bool, string, oneOfType } = PropTypes;

CircularProgress.propTypes = {
    strokeWidth: number,
    percentage: oneOfType([number, string]),
    scale: number,
    showProgress: bool,
    descriptionTemplate: string,
    animation: string,
};

CircularProgress.defaultProps = {
    strokeWidth: 1,
    percentage: 0,
    scale: 100,
    showProgress: true,
    descriptionTemplate: '{percentage}',
    animation: '',
};
