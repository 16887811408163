import FeatureBranchSwitcher from './FeatureBranchSwitcher';

const FEATURE_BRANCH_CREATE_LINK_SELECTOR =
    '[data-submit="featureBranchCreateLink"]';
const FEATURE_BRANCH_COPY_LINK_SELECTOR =
    '[data-click="featureBranchLinkCopy"]';

export default class FeatureBranchManager extends FeatureBranchSwitcher {
    constructor(control) {
        super(control);

        control
            .querySelector(FEATURE_BRANCH_CREATE_LINK_SELECTOR)
            .addEventListener('submit', (event) => {
                this.createAutoRedirectString(event);
            });

        control
            .querySelector(FEATURE_BRANCH_COPY_LINK_SELECTOR)
            .addEventListener('click', (event) => {
                this.copyRedirectString(event);
            });

        this.checkAndActiveRedirect();
    }

    checkAndActiveRedirect() {
        /*
            Check if an auto redirect string has been provided
        */
        const urlParams = new URLSearchParams(window.location.search);
        const redirectPage = urlParams.get('redirectPage');
        const branch = urlParams.get('branch');

        if (redirectPage && branch) {
            this.setFeatureBranch(branch);
            window.location = redirectPage;
        }
    }

    createAutoRedirectString(event) {
        /* 
            Create a URL with query params which will be picked up by the checkAndActiveRedirect() 
            function when shared with a customer to redirect them to the correct page and bridge
            */
        const { target } = event;
        event.preventDefault();

        const url = target.querySelector(
            '.feature-branch-manager-link-creator__url'
        ).value;
        const branch = target.querySelector(
            '.feature-branch-manager-link-creator__branch'
        );
        const selectedBranch = branch.options[branch.selectedIndex].value;
        const seperator =
            window.location.toString().indexOf('?') !== -1 ? '&' : '?';

        this.component.querySelector(
            '.feature-branch-manager-output__link'
        ).value = `${window.location}${seperator}redirectPage=${url}&branch=${selectedBranch}`;
    }

    copyRedirectString() {
        // https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
        const copyText = this.component.querySelector(
            '.feature-branch-manager-output__link'
        );
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }
}
