import React, { useState, useCallback } from 'react';
import getStudent from 'Edify/js/getStudentMemoized';
import BookmarkContext, { BookmarksContextErrorType } from './BookmarkContext';

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState(null);
    const [bookmarks, setBookmarks] = useState([]);

    // Function to set the state correctly when loading starts
    function beginLoading() {
        setIsReady(false);
        setIsLoading(true);
    }

    // Function to set the state correctly when loading ends
    function endLoading() {
        setIsLoading(false);
        setIsReady(true);
    }

    // Get the bookmarks initially from datastore
    const getBookmarks = useCallback(() => {
        beginLoading();
        getStudent(datastore)
            .then((student) => {
                const bookmarksJSON = student.bookmarks;
                setBookmarks(Array.from(JSON.parse(bookmarksJSON)));
                endLoading();
            })
            .catch(() => {
                setError(BookmarksContextErrorType.ERROR);
            });
    }, []);

    // Updated the bookmarks in datastore
    const updateBookmarks = useCallback((newBookmarks) => {
        beginLoading();
        getStudent(datastore)
            .then((student) => {
                datastore
                    .collection(`students`)
                    .doc(student.studentid)
                    .update({ bookmarks: JSON.stringify(newBookmarks) })
                    .then((result) => {
                        setBookmarks(Array.from(JSON.parse(result.bookmarks)));
                        endLoading();
                    });
            })
            .catch(() => {
                setError(BookmarksContextErrorType.ERROR);
            });
    }, []);

    return (
        <BookmarkContext.Provider
            value={{
                isLoading,
                isReady,
                error,
                bookmarks,
                getBookmarks,
                updateBookmarks,
            }}
        >
            {children}
        </BookmarkContext.Provider>
    );
};
