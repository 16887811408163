/* eslint-disable max-classes-per-file */

/**
 * Primary nav selector
 * @const {string}
 */
const PRIMARY_NAV_SELECTOR = '[data-component="primary-nav"]';

/**
 * Primary nav item selector
 * @const {string}
 */
const NAV_ITEM_SELECTOR = '.primary-nav-item';

/**
 * Primary nav item active
 * @const {string}
 */
export const NAV_ITEM_ACTIVE = 'primary-nav-item--active';

/**
 * Menu expander button control
 * @const {string}
 */
export const MENU_EXPANDER_SELECTOR = '.primary-nav-item__expander';

/**
 * Desktop hamburger selector
 */

const PRIMARY_NAV_DESKTOP_HAMBURGER = '.primary-nav--desktop-burger';

/**
 * Create a new PrimaryNav
 * @param {HTMLElement} PrimaryNav - The HTML element of the primary nav
 * @class
 */
class PrimaryNavImpl {
    constructor(primaryNav) {
        this.primaryNav = primaryNav;

        const menuExpanders = primaryNav.querySelectorAll(
            MENU_EXPANDER_SELECTOR
        );

        menuExpanders.forEach((expander) => {
            const navItem = expander.closest(NAV_ITEM_SELECTOR);
            expander.addEventListener('click', () => {
                this._expandMenu(navItem);
                this._toggleAria(navItem);
            });

            expander.addEventListener('keyup', (event) => {
                const key = event.which || event.keyCode;
                // Call toggle only when escape was pressed.
                // eslint-disable-next-line no-unused-expressions
                key === 27 && this._toggleAria(navItem, key);
            });

            navItem.addEventListener('mouseover', () => {
                this._setMenuOffset(navItem);
            });
        });
    }

    isDesktopHamburger() {
        return this.primaryNav.matches(PRIMARY_NAV_DESKTOP_HAMBURGER);
    }

    _toggleAria(primaryNavItem, escapeKey) {
        const buttonExpander = primaryNavItem.querySelector(
            MENU_EXPANDER_SELECTOR
        );
        // If escape was pressed we will set expanded to false.
        const expanded = escapeKey
            ? false
            : buttonExpander.getAttribute('aria-expanded') === 'false';

        buttonExpander.setAttribute('aria-expanded', `${expanded}`);
    }

    _expandMenu(primaryNavItem) {
        // Get all siblings.
        const allItems = document.querySelectorAll(
            `${PRIMARY_NAV_SELECTOR} ${NAV_ITEM_SELECTOR}`
        );
        // Remove active class from all siblings except current one which will be toggled.
        allItems.forEach((item) => {
            // eslint-disable-next-line no-unused-expressions
            item === primaryNavItem
                ? item.classList.toggle(NAV_ITEM_ACTIVE)
                : item.classList.remove(NAV_ITEM_ACTIVE);
        });

        this._setMenuOffset(primaryNavItem);
    }

    _setMenuOffset(primaryNavItem) {
        const navItem = primaryNavItem;

        // Measure if the menu is going to be off screen
        const navBarWidth = navItem.offsetParent.offsetWidth; // Width of the navbar element
        const navItemOffset = navItem.offsetLeft; // How far along the navbar the item selected is
        const menuWidth =
            navItem.querySelector('.mega-menu-wrapper').offsetWidth; // THe width of the mega menu once shown

        const widthDiff = navBarWidth - (navItemOffset + menuWidth);
        if (widthDiff < 0) {
            navItem.dataset.menuOffsetNeeded = 'true';
            navItem.style.setProperty('--menuOffsetNeeded', `${widthDiff}px`);
        }
    }
}

/**
 * Singleton wrapper for Primary Nav to allow control from other areas of the template.
 * @class
 */
export default class PrimaryNav {
    static getInstance() {
        const primaryNav = document.querySelector(PRIMARY_NAV_SELECTOR);
        // primaryNav doesnt exist, return null
        if (!primaryNav) {
            return null;
        }

        // Doesnt exist yet, create new menu object
        if (!primaryNav._primaryNav) {
            // Store the actual class against the DOM, as if this is destroyed for any reason it will need to be re-instantiated
            primaryNav._primaryNav = new PrimaryNavImpl(primaryNav);
        }

        // Return existing primaryNav object
        return primaryNav._primaryNav;
    }
}

// Export for external access and run intial pass
PrimaryNav.getInstance();
