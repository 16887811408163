/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import getJWT from 'PlugAndPlay/edify/js/getJwt';
import DatastoreCreditProvider from './data/DatastoreCreditProvider';
import EdifyCreditProvider from './data/EdifyCreditProvider';

// Lazy load the main 'app'
const Credits = React.lazy(() =>
    import(/* webpackChunkName: "Credits" */ './credits')
);

const appWrapper = document.querySelectorAll('[data-component="credits"]');
// Don't run on a non app page
if (appWrapper.length > 0) {
    // Only load ReactDOM from if its needed as its pretty large
    import(/* webpackChunkName: "ReactDOM" */ 'react-dom').then((ReactDOM) => {
        appWrapper.forEach((wrapper) => {
            let creditsProvider;

            // Get the dataset from the wrapper
            const { dataset } = wrapper;

            // Get the config from Matrix
            const appConfiguration = JSON.parse(dataset.appConfiguration);

            // Get the provider of component
            const provider = appConfiguration.provider || 'datastore';

            // Get if the component is in menu or stand alone
            const isInMenu = appConfiguration.componentType === 'inMenu';

            const datastore = new Datastore({
                serviceURL: appConfiguration.datasourceUrl,
                jwtCallback: () => getJWT(appConfiguration.datasourceJWT),
            });

            if (provider === 'datastore') {
                creditsProvider = (
                    <DatastoreCreditProvider datastore={datastore}>
                        <Credits {...appConfiguration} popover={isInMenu} />
                    </DatastoreCreditProvider>
                );
            }

            if (provider === 'edify') {
                creditsProvider = (
                    <EdifyCreditProvider datastore={datastore}>
                        <Credits {...appConfiguration} popover={isInMenu} />
                    </EdifyCreditProvider>
                );
            }

            ReactDOM.render(
                <Suspense
                    fallback={
                        <>
                            {appConfiguration.balanceTypes.map(
                                (balanceType) => {
                                    return (
                                        <div className="credits-loader">
                                            <svg className="svg-icon">
                                                <title>
                                                    Loading {balanceType.label}
                                                </title>
                                                <use
                                                    href={`#${balanceType.icon}`}
                                                />
                                            </svg>
                                        </div>
                                    );
                                }
                            )}
                        </>
                    }
                >
                    {creditsProvider}
                </Suspense>,
                wrapper
            );
        });
    });
}
