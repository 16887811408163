import React, { useState, useCallback, useEffect } from 'react';
import EmailsListingContext, {
    EmailsListingContextErrorType,
} from './emails-listing-context';

export default ({ mockData, mockState, children }) => {
    const [emails, setEmails] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState(null);

    const getEmails = useCallback(() => {
        if (mockState === undefined) {
            setLoading(true);
            setTimeout(() => {
                // Set the state in the context
                setEmails(mockData);
                setLoading(false);
                setIsReady(true);
            }, 2000);
        }
    }, [mockData]);

    useEffect(() => {
        if (mockState === 'Ready') {
            setIsReady(true);
        }
        if (mockState === 'Error') {
            setError(EmailsListingContextErrorType.ERROR);
        }
        if (mockState === 'Loading') {
            setLoading(true);
        }
    }, [mockState]);

    return (
        <EmailsListingContext.Provider
            value={{
                isLoading,
                isReady,
                error,
                getEmails,
                emails,
            }}
        >
            {children}
        </EmailsListingContext.Provider>
    );
};
