/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * The Calendar Application context.
 * @export
 */
const SocialFeedContext = createContext({
    isLoading: false,
    isError: false,
    feedData: [],
    getFeedData: null,
});

SocialFeedContext.Provider.propTypes = {
    value: PropTypes.shape({
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        feedData: PropTypes.arrayOf(
            PropTypes.shape({
                accountName: PropTypes.string.isRequired,
                accountImage: PropTypes.string,
                createdDate: PropTypes.instanceOf(Date).isRequired,
                source: PropTypes.string.isRequired,
                text: PropTypes.string,
                url: PropTypes.string.isRequired,
                image: PropTypes.string,
                video: PropTypes.string,
            })
        ).isRequired,
        getFeedData: PropTypes.func,
    }),
};

export default SocialFeedContext;
