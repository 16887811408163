/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { useState } from 'react';

import { formatISO, formatDistance } from 'date-fns';

import uuidv4 from 'PlugAndPlay/_global/js/uuid';
import Modal from '../../../modal/jsx/modal';
import useDivModalOpen from '../../../modal/jsx/useDivButtonModalOpen';

const SOCIAL_CARD_MAX_DESCRIPTION_LENGTH = 100;

export default function BaseSocialCard(args) {
    const {
        accountName,
        accountImage,
        createdDate,
        source,
        text,
        url,
        image,
        lazyLoadImages,
    } = args;

    const [isExpanded, setIsExpanded] = useState(false);
    const { openModalRef, modalOpenProps, isModalOpen, setIsModalOpen } =
        useDivModalOpen();

    let isTrimmed = false;
    let bodyText = text;
    if (bodyText.length > SOCIAL_CARD_MAX_DESCRIPTION_LENGTH && !isExpanded) {
        isTrimmed = true;
        bodyText = bodyText.slice(0, SOCIAL_CARD_MAX_DESCRIPTION_LENGTH);
    }

    let icon;
    switch (source) {
        case 'twitter': {
            icon = 'logo-twitter';
            break;
        }
        case 'facebook': {
            icon = 'logo-facebook';
            break;
        }

        default:
            icon = 'external';
    }

    return (
        <article className="social-card">
            <div
                {...modalOpenProps}
                ref={openModalRef}
                className="social-card__open-modal"
            >
                <header className="social-card__header">
                    <div className="social-card__header-image">
                        <img
                            alt=""
                            src={accountImage}
                            loading={`${lazyLoadImages ? 'lazy' : ''}`}
                            className="social-card__account-image"
                        />
                    </div>
                    <div className="social-card__title">
                        <time
                            dateTime={formatISO(createdDate, {
                                representation: 'date',
                            })}
                            className="social-card__post-created"
                        >
                            {formatDistance(createdDate, new Date(), {
                                addSuffix: true,
                            })}
                        </time>
                        <div className="social-card__account-name">
                            {accountName}
                        </div>
                    </div>
                </header>
                <div className="social-card__body">
                    {bodyText}
                    {isTrimmed && (
                        <>
                            <span>...</span>
                            <button
                                type="button"
                                className="social-card__show-more"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsExpanded(true);
                                }}
                            >
                                show more
                            </button>
                        </>
                    )}

                    {image && (
                        <img
                            alt=""
                            src={image}
                            loading={`${lazyLoadImages ? 'lazy' : ''}`}
                            className="social-card__body-image"
                        />
                    )}
                </div>
            </div>
            <a
                href={url}
                target="_blank"
                aria-label={`View at ${source} (Opens in new window)`}
                rel="noopener noreferrer"
                onClick={(event) => event.stopPropagation()}
                className={`social-card__view-external social-card__view-external--${icon}`}
            >
                <svg className="svg-icon">
                    <use href={`#${icon}`} />
                </svg>
            </a>
            {isModalOpen && (
                <BaseSocialCardModal
                    {...args}
                    handleModalClose={() => {
                        setIsModalOpen(false);
                    }}
                />
            )}
        </article>
    );
}

function BaseSocialCardModal({
    accountName,
    accountImage,
    createdDate,
    source,
    text,
    url,
    image,
    video,
    videoCaptions,
    handleModalClose,
}) {
    let bodyAdditionalContent;
    if (video) {
        bodyAdditionalContent = (
            <div className="social-card-modal__video-embed-wrapper">
                <video src={video} className="social-card-modal__video-embed">
                    {videoCaptions && (
                        <track kind="captions" src={videoCaptions} />
                    )}
                </video>
            </div>
        );
    } else if (image) {
        bodyAdditionalContent = (
            <img
                alt=""
                loading="lazy"
                src={image}
                className="social-card-modal__body-image"
            />
        );
    }

    const titleId = uuidv4(); // Modal needs a id for the dialog described by
    return (
        <Modal
            titleId={titleId}
            onClose={handleModalClose}
            className="social-card-modal"
        >
            <header className="social-card-modal__header">
                <div className="social-card-modal__header-image">
                    <img
                        alt=""
                        src={accountImage}
                        loading="lazy"
                        className="social-card-modal__account-image"
                    />
                </div>
                <div id={titleId} className="social-card-modal__title">
                    <time
                        dateTime={formatISO(createdDate, {
                            representation: 'date',
                        })}
                        className="social-card__post-created"
                    >
                        {formatDistance(createdDate, new Date(), {
                            addSuffix: true,
                        })}
                    </time>
                    <div className="social-card-modal__account-name">
                        {accountName}
                    </div>
                </div>
                <button
                    type="button"
                    onClick={handleModalClose}
                    aria-label="close modal"
                    className="social-card-modal__close-icon"
                >
                    <svg className="svg-icon">
                        <use href="#close" />
                    </svg>
                </button>
            </header>

            <div className="social-card-modal__body">
                {text}
                {bodyAdditionalContent}
            </div>

            <footer className="social-card-modal__footer">
                <a
                    href={url}
                    target="_blank"
                    className="social-card-modal__open-external-button"
                    rel="noreferrer"
                >
                    View on {source}
                </a>
            </footer>
        </Modal>
    );
}
