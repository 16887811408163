import React, { useState, useEffect } from 'react';

import useMicrosoftAuth, {
    AuthenticationState,
    ErrorType as AuthErrorType,
} from 'PlugAndPlay/_global/jsx/integrations/microsoft/useMicrosoftAuth.jsx';

import PersonalDocumentListingContext, {
    PersonalDocumentListingContextErrorType as ErrorType,
} from './PersonalDocumentListingContext';

export default function OneDrivePersonalDocumentListingProvider({
    clientId,
    children,
}) {
    const scopes = ['Files.Read.All'];
    const {
        authenticationState,
        retryAuthentication,
        errorType: authErrorType,
        getAccessToken,
        loginButton,
    } = useMicrosoftAuth({
        clientId,
        scopes,
    });

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);

    // Convert useMicrosoftAuth error to PersonalDocumentListingContext error type
    useEffect(() => {
        if (authErrorType !== null) {
            if (authErrorType === AuthErrorType.AUTH_ERROR) {
                setError(ErrorType.AUTH_ERROR);
            } else if (authErrorType === AuthErrorType.SCOPE_ERROR) {
                setError(ErrorType.SCOPE_ERROR);
            } else if (authErrorType === AuthErrorType.POPUP_ERROR) {
                setError(ErrorType.POPUP_ERROR);
            } else {
                setError(ErrorType.ERROR);
            }
        }
    }, [authErrorType]);

    const getDocuments = ({ count }) => {
        setIsLoading(true);
        getAccessToken().then((accessToken) => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;
            headers.append('Authorization', bearer);
            return fetch(
                `https://graph.microsoft.com/v1.0/me/drive/recent?$top=${count}`,
                {
                    method: 'GET',
                    headers,
                }
            )
                .then((response) => response.json())
                .then((response) => {
                    setDocuments(
                        response.value.map((document) => {
                            let fileName = document.name;
                            let fileExtension = '';

                            // If it has a file extension
                            if (fileName.indexOf('.') !== -1) {
                                // Basic assumption that anything after the first '.' is the extension
                                fileName = document.name.substr(
                                    0,
                                    document.name.indexOf('.')
                                );
                                fileExtension = document.name.substr(
                                    document.name.indexOf('.') + 1
                                );
                            }

                            return {
                                id: document.id,
                                title: fileName,
                                url: document.webUrl,
                                createdBy: document.createdBy.user.displayName,
                                lastModifiedBy:
                                    document.lastModifiedBy.user.displayName,
                                lastModifiedDate:
                                    document.lastModifiedDateTime &&
                                    new Date(document.lastModifiedDateTime),
                                fileSize: document.remoteItem.size,
                                fileType: fileExtension,
                            };
                        })
                    );
                    setIsLoading(false);
                })
                .catch(() => {
                    // Set general error
                    setError(ErrorType.ERROR);
                });
        });
    };

    const restartAuthProcess = () => {
        setError(null);
        retryAuthentication();
    };

    return (
        <PersonalDocumentListingContext.Provider
            value={{
                isLoading:
                    authenticationState === AuthenticationState.NOT_STARTED
                        ? true
                        : isLoading,
                isReady:
                    authenticationState === AuthenticationState.AUTHENTICATED,
                isAuthNeeded:
                    authenticationState ===
                    AuthenticationState.NEEDS_LOGIN_CONSENT,
                signInLink: loginButton,
                error,
                documents,
                getDocuments,
                allDocumentsLink: 'https://onedrive.live.com/',
                restartAuthProcess,
            }}
        >
            {children}
        </PersonalDocumentListingContext.Provider>
    );
}
