/*!
 * @license
 * Copyright Squiz Australia Pty Ltd. All Rights Reserved.
 */

import React, { useState, useCallback, useRef } from 'react';
import NotificationsContext from './NotificationsContext';

const convertFromDatastoreToJS = (notification) => {
    // Perform conversions needed to get data from the datastore storage format
    const data = JSON.parse(notification.data);

    return {
        ...notification,
        ...data, // Expand the datastore extras 'data' property
        createdDate: new Date(notification.createdDate),
        dueDate: data.dueDate ? new Date(data.dueDate) : undefined,
    };
};

const convertArrayFromDatastoreToJS = (notifications) => {
    return notifications.map((notification) => {
        return convertFromDatastoreToJS(notification);
    });
};

export default ({ datastore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [pinnedNotifications, setPinnedNotifications] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [isMoreNotifications, setIsMoreNotifications] = useState(false);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

    // Need to persist the datastore query object used by getNotifications between request for notification pagination
    const pagedCollection = useRef();

    /**
     * Get unread notification count.
     */
    const getUnreadNotificationsCount = useCallback(() => {
        setIsLoading(true);

        datastore
            .collection(`notifications`)
            .where('read', '===', false)
            .count()
            .get()
            .then((count) => {
                setUnreadNotificationsCount(count);
                setIsLoading(false);
            });
    }, []);

    /**
     * Get pinned notifications.
     * @param {boolean} [immediate] mock only input to force no timeout on request
     */
    const getPinnedNotifications = useCallback(() => {
        setIsLoading(true);

        datastore
            .collection(`notifications`)
            .sortBy('createdDate', 'desc')
            .where('pinned', '===', true)
            .get()
            .then((results) => convertArrayFromDatastoreToJS(results))
            .then((results) => {
                setPinnedNotifications(results);
                setIsLoading(false);
            });
    }, []);

    /**
     * Get notifications.
     *
     * Will load results in a truncation form, loading the first X results then neededing
     * another call to this function to load the next 'set' of X results.
     *
     * @param {integer} [max] max items to load at once to allow result truncation functionality
     * @param {integer} [start] start point to load another 'set' of items
     * @param {boolean} [reload] do a fresh load of the data ignoreing previous loaded 'sets'
     * @param {boolean} [includePinned] include pinned results in the list
     */
    const getNotifications = useCallback(
        ({ max, start, reload, includePinned }) => {
            setIsLoading(true);

            // If a reload is happening or its the first request (no start position), restart the request object
            let query;
            if (!start || start === 0) {
                query = datastore
                    .collection(`notifications`)
                    .sortBy('createdDate', 'desc');

                // Reset the paged collection
                pagedCollection.current = undefined;
            } else {
                /*
                    Datastore saves the 'limit' and the position in the paged collection in the request object
                    so it needs to be built then reused
                */
                query = pagedCollection.current;
            }

            if (!pagedCollection.current && max) {
                query = query.limit(max);

                if (!includePinned) {
                    query = query.where('pinned', '===', false);
                }

                // Save the query object for re-use for the 'get more' requests.
                pagedCollection.current = query;
            }

            // If the max changes, reset the limit
            if (
                pagedCollection.current &&
                pagedCollection.current.size !== max
            ) {
                query = query.limit(max);
            }

            // Different datastore method is used depending on if its a new request or a next page
            let queryResult;
            if (!start || start === 0) {
                queryResult = query.get();
            } else {
                queryResult = query.getNextPage();
            }

            queryResult
                .then((results) => convertArrayFromDatastoreToJS(results))
                .then((result) => {
                    // If there is no start position its a fresh request
                    if (!start || reload) {
                        setNotifications(result);
                    } else {
                        // Otherwise its an additional page so add it to the internal data
                        setNotifications([...notifications].concat(result));
                    }

                    setIsMoreNotifications(
                        pagedCollection.current.hasNextPage()
                    );
                    setIsLoading(false);
                });
        },
        [notifications]
    );

    /**
     * Set read indicator on a notification.
     * @param {string} id id of the notification to update
     * @param {string} title datastore required field required for patch call
     * @param {string} createdDate id of the notification to update
     */
    const setRead = useCallback(
        ({ id, title, createdDate } = {}) => {
            setIsLoading(true);

            datastore
                .collection(`notifications`)
                .doc(id)
                .update({ title, createdDate, read: true })
                .then((result) => convertFromDatastoreToJS(result))
                .then((result) => {
                    let matchFound = false;

                    // Check notifications list for matching notification
                    let index = notifications.findIndex(
                        (notification) => notification.id === result.id
                    );
                    if (index !== -1) {
                        matchFound = true;
                        // Update the client side copy to match the return data
                        const updatedNotifications = [...notifications];
                        updatedNotifications[index] = result;
                        setNotifications(updatedNotifications);
                    } else {
                        // Check notifications list for matching notification
                        index = pinnedNotifications.findIndex(
                            (notification) => notification.id === result.id
                        );
                        if (index !== -1) {
                            matchFound = true;
                            // Update the client side copy to match the return data
                            const updatedPinnedNotifications = [
                                ...pinnedNotifications,
                            ];
                            updatedPinnedNotifications[index] = result;
                            setPinnedNotifications(updatedPinnedNotifications);
                        }
                    }

                    // If successfuly changed to read, decrement the unread counter
                    if (matchFound && result.read) {
                        setUnreadNotificationsCount(
                            unreadNotificationsCount - 1
                        );
                    }

                    setIsLoading(false);
                });
        },
        [pinnedNotifications, notifications, unreadNotificationsCount]
    );

    /**
     * Set pinned indicator on a notification.
     * @param {string} id id of the notification to update
     * @param {boolean} pinned is notification being pinned
     * @param {string} title datastore required field required for patch call
     * @param {string} createdDate id of the notification to update
     */
    const setPinned = useCallback(
        ({ id, pinned, title, createdDate } = {}) => {
            setIsLoading(true);

            datastore
                .collection(`notifications`)
                .doc(id)
                .update({ title, createdDate, pinned })
                .then((result) => convertFromDatastoreToJS(result))
                .then((result) => {
                    // Update the local copy of the notifications using the result return
                    let updatedNotifications = [...notifications];
                    let updatedPinnedNotifications = [...pinnedNotifications];

                    // Move the notification from one list to the other
                    if (pinned) {
                        // Being pinned so remove from regular list
                        updatedNotifications = notifications.filter(
                            (notification) => notification.id !== result.id
                        );
                        // Add into pinned list
                        updatedPinnedNotifications.push(result);
                        // Sort it into the correct place in the list (newest shown first)
                        updatedPinnedNotifications.sort((a, b) => {
                            return (
                                b.createdDate.getTime() -
                                a.createdDate.getTime()
                            );
                        });

                        // Update notifications list to remove the now pinned notification
                        setNotifications(updatedNotifications);
                    } else {
                        // Being unpinned so remove from pinned list
                        updatedPinnedNotifications = pinnedNotifications.filter(
                            (notification) => notification.id !== result.id
                        );

                        /*
                            To keep the paginated loading in sync the notifications list may needs to be 
                            refreshed if the notification being removed doesnt slot in above the last loaded
                            notification as otherwise its unknown its place in the order.

                            If the unpinned notification is newer (higher getTime number) than the last item
                            in the notifications list it can just be dropped into the list; if its older or
                            equal a reload is needed to be sure where it should be in the pageing.
                        */
                        const lastNotification = updatedNotifications
                            .slice(-1)
                            .pop();
                        if (
                            !lastNotification ||
                            result.createdDate.getTime() <
                                lastNotification.createdDate.getTime()
                        ) {
                            // Reload needed to check where the notification should be
                            getNotifications({
                                reload: true,
                                start: 0,
                                max: notifications.length,
                            });
                        } else {
                            // No reload needed, add into notifications list
                            updatedNotifications.push(result);
                            // Sort it into the correct place in the list (newest shown first)
                            updatedNotifications.sort((a, b) => {
                                return (
                                    b.createdDate.getTime() -
                                    a.createdDate.getTime()
                                );
                            });
                            // Update notifications list
                            setNotifications(updatedNotifications);
                        }
                    }

                    // Add or remove from the pinned notifications area
                    setPinnedNotifications(updatedPinnedNotifications);
                    setIsLoading(false);
                });

            setIsLoading(false);
        },
        [notifications, pinnedNotifications]
    );

    return (
        <NotificationsContext.Provider
            value={{
                isLoading,
                notifications,
                getNotifications,
                isMoreNotifications,

                setRead,
                unreadNotificationsCount,
                getUnreadNotificationsCount,

                pinnedNotifications,
                getPinnedNotifications,
                setPinned,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};
